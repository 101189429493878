import ModalActionButton from '@commons/Button/ModalActionButton'
import GenerateFormHook from '@commons/GenerateFormHook'
import { CustomErrorMessage } from '@commons/GenerateFormHookControllers'
import AutoCompleteBox from '@commons/Inputs/AutoCompleteBox'
import FileInput from '@commons/Inputs/FileInput'
import AppModal from '@commons/Modal/AppModal'
import LinearProgressWithLabel from '@commons/ProgressBar/LinearProgressWithLabel'
import Text from '@commons/Text'
import { FILE } from '@constants/formInputTypes'
import AppDatePicker from '@layouts/Search/AppDatePicker'
import { Box, Grid, Tooltip } from '@mui/material'
import { removeDuplicatesFromSelected, removeSelectedObjFromArr, validateDateIsPresentOrFuture } from '@utils/other'
import { validator } from '@utils/validator'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'

const ComplianceUploadPolicyForm = ({
	handleFormSubmit = () => { },
	formConst,
	handleModalClose = () => { },
	uploadDocProgress = 0,
	complianceControlsList = [],
	documentLink
}) => {
	const {
		control,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors },
		watch
	} = useFormContext()
	const [openUploadedDocumentsListModal, setOpenUploadedDocumentsListModal] = useState(false)

	const toggleHandleToggleUploadedDocumentsModal = () => {
		setOpenUploadedDocumentsListModal(!openUploadedDocumentsListModal)
	}

	const filteredControlList = complianceControlsList?.filter((ele) => ele?.status !== 'Published')

	return (
		<form onSubmit={handleSubmit(handleFormSubmit)}>
			<Grid item xs={12} padding={'0rem 0.7rem'} mb={3}>
				<Controller
					name='control'
					control={control}
					defaultValue={[]}
					render={({ field: { name, onChange, value } }) => (
						<>
							<AutoCompleteBox
								name={name}
								options={removeDuplicatesFromSelected(filteredControlList, value)}
								// options={complianceControlsList}
								// onChange={(_, value) => onChange(value)}
								onChange={(event, newValue) => {
									const uniqueArray = removeSelectedObjFromArr(newValue, 'value')
									return onChange(uniqueArray)
								}}
								value={value}
								showLabel
								multiple
								label='Control ID *'
								limitTags={2}
								disablePortal
							/>

							<CustomErrorMessage name={name} errors={errors} />
						</>
					)}
					rules={validator.isRequired('This field')}
				/>
			</Grid>
			<GenerateFormHook control={control} formConstant={formConst}>
				<Grid container alignItems='center' component={Box} mb={3}>
					<Grid item lg={3}>
						<Text ascent='primary'>Review Date</Text>
					</Grid>
					<Grid item lg={9} component={Box} pr={3}>
						<Controller
							render={({ field: { onChange, value, name } }) => (
								<AppDatePicker
									handleDateChange={(date) => {
										if (date.isValid()) {
											if (validateDateIsPresentOrFuture(date)) {
												if (errors[name]) {
													clearErrors(name)
												}
												onChange(date)
											} else {
												setError(name, {
													message: 'Date must be present or future. Past dates are not allowed!',
													type: 'pattern'
												})
											}
										} else {
											setError(name, {
												message: 'Must be a valid date',
												type: 'pattern'
											})
										}
									}}
									disablePast
									selectedDate={value}
									fullWidth
									sx={{
										display: 'flex',
										padding: '0.25rem 0 0.25rem 1.25rem',
										marginLeft: 'auto'
									}}
								/>
							)}
							control={control}
							name={'date'}
							defaultValue={null}
						/>
						<Box ml={2.5}>
							<CustomErrorMessage name={'date'} />
						</Box>
					</Grid>
				</Grid>
				<Grid
					container
					sx={{
						margin: '0rem 0'
					}}
				>
					<>
						<Grid item sm={12}>
							<Controller
								defaultValue={null}
								render={({ field: { onChange, name, value } }) => (
									<FileInput
										fileType={FILE}
										multiple
										errorConfig={[
											{
												code: 'max_file_size',
												maxFileSize: 10 * 1024 * 1024,
												require: true,
												errorCallBack: () => {
													setError('document', {
														type: 'manual',
														message: 'Document size must not exceed 10MB !'
													})
												}
											}
										]}
										onChange={(e) => {
											onChange(e)
											clearErrors(name)
										}}
										name={name}
										buttonLabel={
											value
												? Array.isArray(value) // Check if value is an array
													? `${value.length} ${value.length === 1 ? 'file' : 'files'} ${value.length === 1 ? 'has' : 'have'
													} been selected`
													: '1 file has been selected' // Assume a single file if value is not an array
												: 'Drag and Drop here or Browse files'
										}
										onClick={(e) => {
											// e.preventDefault()
											// e.stopPropagation()
											onChange('')
											e.target.value = null
										}}
										// imageName={value && value.length > 0 && value[0].name}
										wrapperProps={{
											sx: {
												width: '100%',
												alignItems: 'center'
											}
										}}
										accept='application/pdf'
									/>
								)}
								control={control}
								name='document'
							/>
						</Grid>
						<Grid item sm={12}>
							{watch('status') == 'Published' ? (
								<CustomErrorMessage name='document' />
							) : (
								<CustomErrorMessage name='document' />
							)}
						</Grid>
						<Grid item sm={12}>
							{watch('status') === 'Published' && (
								<Text margin='1rem 0 0.5rem 0' ascent='secondary'>
									Document is required if the status is published.
								</Text>
							)}
							<Text margin='1rem 0' ascent='secondary'>
								Accepted file types: .pdf only. Max file size 10MB
							</Text>
							{Array.isArray(documentLink) && documentLink.length > 0 && (
								<Text
									fontSize={16}
									cursor='pointer'
									ascent='primary'
									margin='1rem 0 0 0'
									onClick={toggleHandleToggleUploadedDocumentsModal}
								>
									See all uploaded documents
								</Text>
							)}
						</Grid>
					</>
					<Grid
						item
						sm={6}
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center'
						}}
					>
						{uploadDocProgress > 0 && (
							<Box sx={{ width: '100%' }}>
								<LinearProgressWithLabel value={uploadDocProgress} />
							</Box>
						)}
					</Grid>
					<Grid
						item
						sm={6}
						sx={{
							display: 'flex',
							justifyContent: 'flex-end'
						}}
					>
						<ModalActionButton
							type='submit'
							disabled={uploadDocProgress > 0 || Object.keys(errors).length > 0}
							ctaButtonTitle={uploadDocProgress === 0 ? 'Submit' : `Saving...(${uploadDocProgress}%)`}
							cancelHandler={handleModalClose}
						/>
					</Grid>
					{Array.isArray(documentLink) && documentLink.length > 0 && (
						<AppModal
							title='All uploaded documents'
							open={openUploadedDocumentsListModal}
							onClose={toggleHandleToggleUploadedDocumentsModal}
						>
							<>
								<Box overflow={'scroll'} maxHeight={'25rem'} minWidth={'15rem'} py={2}>
									{documentLink?.map((ele, index) => {
										const fileName = decodeURI(
											ele.replace('devzeron/compliance_documents/', '').split('?')[0].split('/').pop()
										)
										return (
											<>
												<Box display='flex' mb={1}>
													<Tooltip
														title='Depending on the compatibility of the browser, the uploaded file will either
														download or open.'
													>
														<Link
															underline='always'
															to={ele}
															target='_blank'
															onClick={(e) => e.stopPropagation()}
														>
															<Text fontSize={16}>
																{index + 1}. {fileName}
															</Text>
														</Link>
													</Tooltip>
												</Box>
											</>
										)
									})}
								</Box>
							</>
						</AppModal>
					)}
				</Grid>
			</GenerateFormHook>
		</form>
	)
}

export default ComplianceUploadPolicyForm
