import React from 'react'
import Text from '../Text'

const ErrorBoundaryFallback = ({ error }) => {
	React.useEffect(() => {}, [error])

	return (
		<div
			role='alert'
			style={{
				margin: '1rem 0',
				position: 'absolute',
				top: 0,
				right: 0,
				width: '100vw',
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<Text variant='h3'>
				We ran into a problem. Please
				<Text variant='span' ascent='primary' style={{ cursor: 'pointer' }} onClick={() => window.location.reload()}>
					{' '}
					reload this page.
				</Text>
			</Text>
		</div>
	)
}

export default ErrorBoundaryFallback
