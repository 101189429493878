import adminRoutesConstant from '@constants/adminRoutesConstant'
import { useSelector } from 'react-redux'
import RedirectRoute from './RedirectRoute'

const IndexRoute = () => {
	const token = useSelector((state) => state.auth.token.info)
	console.log('🚀 ~ file: IndexRoute.js:6 ~ IndexRoute ~ token:', token)

	const userRedux = useSelector((state) => state.auth.user.info?.role?.policy_list)
	let routeDetails = []
	userRedux?.forEach((item) => {
		if (adminRoutesConstant.adminPaths[item.RouteName]?.url()) {
			routeDetails.push({ "routeName": item.RouteName, "url": adminRoutesConstant.adminPaths[item.RouteName]?.url() })
		}
	})
	const screenOrder = [
		'/dashboard',
		'/attack-surface-overview',
		'/attack-surface-asset',
		'/attack-surface-findings',
		'/business-posture',
		'/overview-analysis',
		'/controls',
		'/rbi-nbfc',
		'/rbi-papg',
		'/rbi-sar',
		'/sebi',
		'/cert-in',
		'/rmit',
		'/uae-iar',
		'/compliance/overview',
		'/compliance/gdpr',
		'/compliance/pci-dss',
		'/compliance/nist',
		'/compliance/iso-27001',
		'/compliance/iso-27701',
		'/compliance/iso-27001-2022',
		'/compliance/soc2',
		'/compliance/hipaa',
		'/compliance/audit-readiness',
		'/securityOverview/hosts',
		'/securityOverview/detections',
		'/securityOverview/securityEvents',
		'/riskMitigation',
		'/integration',
		'/networks',
		'/cloudMonitor/cspm-overview',
		'/cloudMonitor/aws',
		'/cloudMonitor/gcp',
		'/cloudMonitor/azure',
		'/others/management',
		'/others/administration',
		'/others/help'
	]

	const orderedRoutes = screenOrder.map(filterUrl => {
		return routeDetails.find(route => route.url === filterUrl);
	}).filter(Boolean);

	return <RedirectRoute routeKey={token ? orderedRoutes[0]?.routeName : 'LoginScreen'} />
}

export default IndexRoute
