import axios from 'axios'
import ApiService from './ApiService'

axios.defaults.timeout = 200000

const schema = window.location.protocol

const baseURL =
	import.meta.env.VITE_APP_ENABLE_ONPREM === 'true' ? `${schema}//${window.location.host}` : import.meta.env.VITE_APP_BASE_API_URL

// export const baseURL =
//   import.meta.env.VITE_APP_BASE_API_URL || "https://devapi.zeron.one";

const reportGeneratorURL = import.meta.env.VITE_APP_PDF_REPORT_URL

const Axios = axios.create({
	baseURL
})

export const AxiosService = new ApiService({
	baseURL: baseURL
})

export const SecurityReportService = new ApiService({
	baseURL: import.meta.env.VITE_APP_SECURITY_REPORT_URL
	// withCredentials: true,
})

export const SamService = new ApiService({
	// baseURL: "https://asm.zeron.one"
	baseURL: import.meta.env.VITE_APP_SAM_API_URL
})

// SAM V1.5 Base URL function

export const ASMservice = new ApiService({
	// baseURL: "https://asm.zeron.one"
	baseURL: import.meta.env.VITE_APP_SAMV2_API_URL
})

// ASM V2.0 Base URL function

export const ASM2service = new ApiService({
	// baseURL: "https://asm.zeron.one"
	baseURL: import.meta.env.VITE_APP_SAMV3_API_URL
})

//ASM v2.0 Open Search API
export const ASMOpenSearchv2 = new ApiService({
	baseURL: import.meta.env.VITE_APP_ASM_OPEN_SEARCH
})

export const ReportGeneratorService = new ApiService({
	baseURL: reportGeneratorURL
})

export const BuyZeronApi = new ApiService({
	baseURL: import.meta.env.VITE_APP_BUY_EVENT_URL
})

export const CspmService = new ApiService({
	baseURL: import.meta.env.VITE_CSPM_URL
})

export const MitreAttackService = new ApiService({
	baseURL: import.meta.env.VITE_APP_MITRE_API_BASE_URL
})

export const CrqService = new ApiService({
	baseURL: import.meta.env.VITE_APP_CRQ_URL
})

export const ConnectorService = new ApiService({
	baseURL: import.meta.env.VITE_API_CONNECTOR_URL
})

export const RCVarZeronService = new ApiService({
	baseURL: import.meta.env.VITE_APP_RCVAR_URL
})

export const ApiConnectorService = new ApiService({
	baseURL: import.meta.env.VITE_API_CONNECTOR_URL
})

export const ComplianceService = new ApiService({
	baseURL: import.meta.env.VITE_APP_COMP_URL
})

//QBER CRQ v1- .env 
export const CRQv1Service = new ApiService({
	baseURL: import.meta.env.VITE_APP_CRQv1_QBER_URL
})
//QBER CRQ v2- .env 
export const CRQv2Service = new ApiService({
	baseURL: import.meta.env.VITE_APP_CRQv2_QBER_URL
})

export default Axios
