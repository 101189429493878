import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dashboardCount: {},
    findingsCount: {},
    asset_discovery_funnel: {},
    threat_wise_asset: {},
    key_insights: {},
    org_risk_score: {}

}

export const attackFrameData = createSlice({
    name: 'attack_report',
    initialState,
    reducers: {
        findingsReducer: (state, action) => {
            state.findingsCount = action.payload
        },
        assetDiscoveryFunnelReducer: (state, action) => {
            state.asset_discovery_funnel = action.payload
        },
        dashboardCountReducer: (state, action) => {
            state.dashboardCount = action.payload
        },
        threatWiseAssetReducer: (state, action) => {
            state.threat_wise_asset = action.payload
        },
        keyInsightsReducer: (state, action) => {
            state.key_insights = action.payload
        },
        orgRiskScoreReducer: (state, action) => {
            state.org_risk_score = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { findingsReducer , assetDiscoveryFunnelReducer , dashboardCountReducer , threatWiseAssetReducer , keyInsightsReducer , orgRiskScoreReducer } = attackFrameData.actions

export const reportReducer = attackFrameData.reducer
