import { permissionKeys } from '@constants/permissions'
import { replaceByRegex } from '@utils/other'

export const childComplianceTabsByKey = {
	dashboard: { label: 'dashboard', value: 'dashboard' },
	controls: { label: 'controls', value: 'controls' },
	documents: { label: 'documents', value: 'documents' }
}

export const complianceFrameworkTypes = {
	Mandate: 'Mandate',
	Compliance: 'Compliance'
}

// TODO: use this everywhere
// Used in api req bodies, RBAC ,
export const complianceKeys = {
	GDPR_SCREEN: 'GdprScreen',
	PCIDSS_SCREEN: 'PciDssScreen',
	NIST_SCREEN: 'NistScreen',
	ISO_27001_SCREEN: 'Iso27001Screen',
	TSC_SCREEN: 'Soc2Screen',
	HIPPA_SCREEN: 'HippaScreen',
	CSASCREEN: 'CSAScreen'
}

// TODO: use this everywhere
//  Used in route paths, tabs in UI, dropdowns
export const complianceLabels = {
	[complianceKeys.GDPR_SCREEN]: 'GDPR',
	[complianceKeys.PCIDSS_SCREEN]: 'PCI DSS',
	[complianceKeys.NIST_SCREEN]: 'NIST',
	[complianceKeys.ISO_27001_SCREEN]: 'ISO 27001',
	[complianceKeys.TSC_SCREEN]: 'TSC',
	[complianceKeys.HIPPA_SCREEN]: 'HIPAA',
	[complianceKeys.CSASCREEN]: 'CSA'
}

export const complianceTabs = ((tabs = []) => {
	return tabs.map((each) => ({
		...each,
		slug: replaceByRegex(each.label.toLowerCase(), '-', / /g)
	}))
})([
	{
		label: 'GDPR',
		displayName: 'GDPR',
		key: 'GdprScreen'
	},
	{
		label: 'PCI DSS',
		displayName: 'PCI DSS',
		key: 'PciDssScreen'
	},
	{
		label: 'NIST',
		displayName: 'NIST',
		key: 'NistScreen'
	},
	{
		label: 'ISO 27001',
		displayName: 'ISO 27001',
		key: 'Iso27001Screen'
	},
	{
		label: 'ISO 27701',
		displayName: 'ISO 27701',
		key: 'Iso27701Screen'
	},
	{
		label: 'ISO 27001:2022',
		displayName: 'ISO 27001:2022',
		key: 'Iso270012022Screen'
	},
	{
		label: 'SOC 2',
		displayName: 'SOC2',
		key: 'Soc2Screen'
	},
	{
		label: 'CSA',
		displayName: 'CSA',
		key: 'csa'
	},
	{
		label: 'HIPAA',
		displayName: 'HIPAA',
		key: 'HippaScreen'
	}
])

export const complianceOptions = complianceTabs.map((each) => ({
	label: each.displayName,
	value: replaceByRegex(each.label, '_', / /g)
}))

export const frameworkKeyTypes = {
	GDPR: 'gdpr',
	PCIDSS: 'pci-dss',
	NIST: 'nist',
	ISO_27001: 'iso-27001',
	ISO_27701: 'iso-27701',
	ISO_27001_2022: 'iso-27001-2022',
	SOC2: 'soc2',
	HIPAA: 'hipaa',
	UAEIAR: 'uae-iar',
	SEBI: 'sebi',
	RBINBFC: 'rbi-nbfc',
	CERTIN: 'cert-in',
	IRDAI: 'irdai',
	RBI_PAPG: 'rbi_papg',
	RBI_SAR: 'rbi_sar',
	ITGRC: 'rbi-itgrc',
	CSASCREEN: 'csa',
	SEBICS: 'sebi-cs',
	BSP: 'bsp',
	SEBICSCRF: 'SEBI-CSCRF'
}

export const frameworkKeyTypesByPermissions = {
	[frameworkKeyTypes.GDPR]: permissionKeys.GdprScreen,
	[frameworkKeyTypes.PCIDSS]: permissionKeys.PciDssScreen,
	[frameworkKeyTypes.NIST]: permissionKeys.NistScreen,
	[frameworkKeyTypes.ISO_27001]: permissionKeys.Iso27001Screen,
	[frameworkKeyTypes.ISO_27701]: permissionKeys.Iso27701Screen,
	[frameworkKeyTypes.ISO_27001_2022]: permissionKeys.Iso270012022Screen,
	[frameworkKeyTypes.SOC2]: permissionKeys.Soc2Screen,
	[frameworkKeyTypes.CSASCREEN]: permissionKeys.CSAScreen,
	[frameworkKeyTypes.HIPAA]: permissionKeys.HippaScreen,
	[frameworkKeyTypes.SEBICS]: permissionKeys.SebiCloudServicesMandatesOverviewScreen,
	[frameworkKeyTypes.BSP]: permissionKeys.BSPMandatesOverviewScreen,
	[frameworkKeyTypes.SEBICSCRF]: permissionKeys.SEBICSCRF,
	[frameworkKeyTypes.SEBI]: permissionKeys.SebiMandatesOverviewScreen,
	[frameworkKeyTypes.RBINBFC]: permissionKeys.RBIMandatesOverviewScreen,
	[frameworkKeyTypes.CERTIN]: permissionKeys.CertInMandatesOverviewScreen,
	[frameworkKeyTypes.IRDAI]: permissionKeys.IrdaiMandatesOverviewScreen,
	[frameworkKeyTypes.RBI_PAPG]: permissionKeys.RbiPapgMandatesOverviewScreen,
	[frameworkKeyTypes.RBI_SAR]: permissionKeys.RbiSarMandatesOverviewScreen,
	[frameworkKeyTypes.RMIT_SCREEN]: permissionKeys.RmitScreen,
	[frameworkKeyTypes.UAEIAR]: permissionKeys.UAEIAR,
	[frameworkKeyTypes.ITGRC]: permissionKeys.RbiItgrcMandatesOverviewScreen
}
