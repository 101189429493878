import { AxiosService } from '@interceptors/axiosInstance'

export const configureMfa = (config) => {
	return AxiosService.post('/rest/v1/mfa/configure/', config)
}

export const enableMfa = (config) => {
	return AxiosService.post('/rest/v1/mfa/enable/', config)
}

export const getMfaRecoveryCodes = (config) => {
	return AxiosService.get('/rest/v1/mfa/recovery_code/', config)
}

export const disableMfa = (config) => {
	return AxiosService.post('/rest/v1/mfa/disable/', config)
}

export const checkMfaStatus = (config) => {
	return AxiosService.get('/rest/v1/mfa/enable/', config)
}

export const verifyMfa = (config) => {
	return AxiosService.post('/rest/v1/mfa/verify/', {
		...config,
		checkToken: false
	})
}
