import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Box, Grid, Paper, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Text from '../Text'

const WrapperCard = ({
	id,
	title,
	children,
	paperProps,
	extraPaperClasses,
	boxProps,
	padding = 2,
	hideShadow = false,
	ascents,
	titleClasses,
	titleTextProps,
	titleText,
	titleClassName,
	RightComponent,
	rightComponentProps,
	childrenWrapperProps,
	about,
	infoText
}) => {
	return (
		<Paper
			id={id}
			elevation={hideShadow ? 0 : undefined}
			className={extraPaperClasses}
			component={Box}
			{...paperProps}
			sx={{
				borderRadius: 16,
				backgroundColor: (theme) => `${theme.palette.ascents[ascents]} !important`,
				boxShadow: (theme) => (hideShadow ? undefined : `0 0 0.625rem 0 ${theme.palette.ascents.shadow} !important`),
				position: 'relative',
				'&:hover': {
					'& .footerArea': {
						opacity: 1,
						zIndex: 999
					}
				},
				...paperProps?.sx
			}}
		>
			<Grid container component={Box} display={!title && !RightComponent ? 'none' : 'flex'} padding={padding}>
				{/* Remove when above gets fixed  */}
				<Grid item xs={!RightComponent ? 11 : 6}>
					<Box {...boxProps}>
						{title && (
							<>
								<Box display={'flex'} gap={1}>
									<Text
										letterSpacing={1}
										fontFamily={'"Open Sans", sans-serif !important'}
										isCustomColor
										ascent={'grey'}
										className={titleClasses}
										textTransform='uppercase'
										fontSize={'0.75rem !important'}
										{...titleTextProps}
									>
										{title}
									</Text>
									{infoText?.length > 0 && (
										<Box mt={'-2px'}>
											<Tooltip title={infoText} placement='right-end'>
												<InfoOutlinedIcon
													className={'footerArea'}
													fontSize="small"
													sx={{
														position: 'relative',
														opacity: 0,
														transition: 'all 0.5s'
													}}
												/>
											</Tooltip>
										</Box>
									)}
								</Box>
							</>
						)}
					</Box>
				</Grid>
				<Grid item xs={!RightComponent ? 1 : 6}>
					{(!!about || !!RightComponent) && (
						<Box>
							<Box
								// position="absolute"
								right={0}
								display='flex'
								alignItems='center'
								justifyContent='flex-end'
								{...rightComponentProps}
							>
								{!!about && (
									<Tooltip title={about} placement='left-end'>
										<HelpOutlineOutlinedIcon
											className={'footerArea'}
											sx={{
												position: 'relative',
												opacity: 0,
												transition: 'all 0.5s',
												'& svg': {
													color: (theme) => theme.palette.ascents.base,
													fontSize: '1.25rem '
												}
											}}
										/>
									</Tooltip>
								)}
								{RightComponent}
							</Box>
						</Box>
					)}
				</Grid>
			</Grid>
			{titleText && (
				<Text
					ascent='grey'
					isCustomColor
					letterSpacing={1}
					className={titleClassName}
					sx={{
						padding: '1rem 1.5rem',
						fontFamily: '"Open Sans", sans-serif'
					}}
					{...titleTextProps}
				>
					{titleText}
				</Text>
			)}
			<Box
				padding={padding}
				paddingTop={!title && !RightComponent ? undefined : '0'}
				{...boxProps}
				marginTop={RightComponent && '2rem'}
				{...childrenWrapperProps}
			>
				{children}
			</Box>
		</Paper>
	)
}

export default WrapperCard
