import { validAscents } from '@constants/propTypesValidation'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { FormControl, InputLabel, Select as MUISelect } from '@mui/material'
import { fadeColor } from '@utils/styles'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import DropdownItem from '../Dropdown/DropdownItem'
import MenuItem from '@mui/material/MenuItem';

// eslint-disable-next-line react/display-name
const Select = forwardRef(
	(
		{
			ascent = 'primary',
			required,
			parentClassName,
			labelId = `select-${Date.now()}`,
			id = `select-input-${Date.now()}`,
			value,
			onChange,
			fullWidth = true,
			startIcon,
			placeholder = '',
			setMultipleSelect = false,
			label,
			readOnly = false,
			options = [],
			disableSortOption = false,
			rootProps,
			...props
		},
		ref
	) => {
		const Options = React.useMemo(() => {
			// eslint-disable-next-line react/display-name
			return (each) => {
				return (
					<DropdownItem
						// className={classes.listItem}
						sx={{
							'&.MuiMenuItem-root': {
								borderRadius: '4px',
								margin: '0.75rem 0',

								'&:first-child': {
									margin: 0
								},
								'&:last-child': {
									margin: 0
								}
							},

							'&.MuiMenuItem-root.Mui-selected': {
								background: (theme) => `${fadeColor(theme.palette.ascents.base, 0.2)} !important`,
								color: (theme) => `${theme.palette.ascents.white} !important`
							},
							'&.MuiListItem-button': {
								transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
							},
							'&.MuiMenuItem-root:hover': {
								background: (theme) => `${fadeColor(theme.palette.ascents.base, 0.1)} !important`,
								color: (theme) => `${theme.palette.ascents.white} !important`
							}
						}}
						selected
						key={each.value}
						value={each.value}
					>
						{each.label}
					</DropdownItem>
				)
			}
		}, [])

		const sortedOptions = React.useMemo(() => {
			return disableSortOption ? options : options.sort((a, b) => (a.label > b.label ? 1 : -1))
		}, [options])

		return (
			<FormControl required={required} fullWidth={fullWidth} className={parentClassName} {...rootProps}>
				{!!label && (
					<InputLabel
						sx={{
							color: (theme) => theme.palette.ascents[ascent],
							'&.MuiInputLabel-shrink': {
								color: (theme) => theme.palette.ascents[ascent]
							},
							marginLeft: '-14px',
							fontSize: '0.875rem'
						}}
						// className={clsx(classes.label, className)}
						id={labelId}
					>
						{props.multiple ? label + ' (multi-select)' : label}
					</InputLabel>
				)}
				<MUISelect
					MenuProps={{
						sx: {
							'& .MuiList-padding': {
								padding: '8px !important',
								maxHeight: '300px'
							},

							'& .MuiPopover-paper': {
								borderRadius: '0.75rem'
							}
						}
					}}
					sx={{
						backgroundColor: 'transparent !important',
						marginTop: 1,
						'& .MuiSelect-select': {
							padding: '0.5rem 1rem 0.5rem 0',

							'&:focus': {
								backgroundColor: 'transparent !important'
							}
						}
					}}
					labelId={labelId}
					startAdornment={startIcon}
					required={required}
					id={id}
					value={value}
					onChange={onChange}
					multiple={setMultipleSelect ? true : false}
					// className={clsx(classes.input, className)}
					readOnly={readOnly}
					IconComponent={KeyboardArrowDownIcon}
					variant='filled'
					inputRef={ref}
					{...props}
				>
					<MenuItem value="" disabled>
						{placeholder}
					</MenuItem>
					{sortedOptions.map(Options)}
				</MUISelect>
			</FormControl>
		)
	}
)

Select.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object),
	ascent: validAscents,
	labelId: PropTypes.string,
	label: PropTypes.string
}

export default React.memo(Select)
