import '@assets/i18n';
import CssBaseline from '@commons/CssBaseLine';
import AppErrorBoundary from '@commons/ErrorBoundaries/AppErrorBoundary';
import PageTracker from '@commons/Tracker/PageTracker';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { persistor, store } from '@redux/store';
import AppRoutes from '@routesv2/AppRoutes';
import { initializeClarity } from '@utils/clarityAnalytics';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import theme from './styles/theme';

function PathnameLogger({ setUrlParams, setUserEmail, setUserOrg }) {
	const location = useLocation();
	const pathname = location.pathname;
	const userEmailState = useSelector((state) => state.auth.user.info.email);
	const userOrganisation = useSelector((state) => state.auth?.user?.info?.organization?.name);

	useEffect(() => {
		setUrlParams(pathname);
		setUserEmail(userEmailState);
		setUserOrg(userOrganisation)
	}, [pathname, userEmailState, setUrlParams, setUserEmail, userOrganisation]);

	return null;
}

function App() {
	const [urlParams, setUrlParams] = useState('/');
	const [userEmail, setUserEmail] = useState(null);
	const [userOrg, setUserOrg] = useState(null);

	useEffect(() => {
		const clarityKey = import.meta.env.VITE_MSCLARITY_KEY;
		if (clarityKey && urlParams && userEmail && userOrg) {
			initializeClarity(clarityKey, urlParams, userEmail, userOrg);
		}
	}, [urlParams, userEmail, userOrg]);

	useEffect(() => {
		(function () {
			window.Chargebee &&
				window.Chargebee.init({
					site: import.meta.env.VITE_APP_CHARGEBEE_SITE_NAME
				});

			let preloaderSvg = document.getElementById('preloader-static');

			setTimeout(() => {
				!!preloaderSvg && preloaderSvg.classList.add('active');
			}, 100);

			setTimeout(() => {
				let preloaderScript = document.getElementById('preloader-script');
				!!preloaderScript && preloaderScript.remove();

				!!preloaderSvg && preloaderSvg.remove();

				let preloaderCss = document.getElementById('preloader-css');
				!!preloaderCss && preloaderCss.remove();
			}, 2000);
		})();
		mixpanel.init(import.meta.env.VITE_APP_MIXPANEL_API_KEY, {
			debug: import.meta.env.VITE_APP_DISABLE_LOGS
		});
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<BrowserRouter>
							<AppErrorBoundary>
								<PageTracker />
								<PathnameLogger setUrlParams={setUrlParams} setUserEmail={setUserEmail} setUserOrg={setUserOrg} />
								<AppRoutes />
							</AppErrorBoundary>
						</BrowserRouter>
					</LocalizationProvider>
				</PersistGate>
			</Provider>
		</ThemeProvider>
	);
}

export default App;
