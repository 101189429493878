import WrapperCard from '@commons/Card/WrapperCard'
import Table from '@commons/Table/Table'
import Text from '@commons/Text'
import { colorsByStatus } from '@constants/Chart/chartOptions'
import { Box } from '@mui/material'
import { getSubControlsDetailsById } from '@services/cspmService'
// import { formatDateTime } from '@utils/other'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const ComplianceControlsTable = ({ activeRequirementKey, frameworkKey }) => {
	const [tableData, setTableData] = useState([])
	const [isFetching, setIsFetching] = useState(false)
	const complianceList = useSelector((state) => state.compliances?.compliances?.list || [])
	// const [isOpenModal, setIsOpenModal] = useState(false)
	// const [rowDetails, setRowDetails] = useState([])
	// const [remediationData, setRemediationData] = useState([])
	// const [isFetchingModalData, setIsFetchingModalData] = useState(false)

	const getFrameworkName = (id) => {
		if (complianceList?.length > 0) {
			return complianceList.find((item) => item?.key === id)?.name
		} else {
			return id
		}
	}

	const columns = [
		{
			label: 'Title',
			name: 'title',
			options: {
				cell: (info) => {
					return <Text fontFamily='Open Sans'>{info.getValue()}</Text>
				},
				colSpan: 3
			}
		},
		{
			label: 'Control ID',
			name: 'control_id',
			options: {
				cell: (info) => {
					return <Text fontFamily='Open Sans'>{info.getValue()}</Text>
				},
				colSpan: 1
			}
		},

		{
			label: 'Framework',
			name: 'framework'
		},
		{
			label: 'Resource ID',
			name: 'resource_id',
			options: {
				cell: (info) => {
					return <Text fontFamily='Open Sans'>{info.getValue()}</Text>
				},
				colSpan: 2
			}
		},
		{
			label: 'Compliance Framework',
			name: 'compliance_framework',
			options: {
				cell: (info) => {
					return <Text fontFamily='Open Sans'>{getFrameworkName(info.getValue())}</Text>
				},
				exportAs: (info) => {
					const value = getFrameworkName(info.compliance_framework)
					return value
				},
				colSpan: 2
			}
		},
		{
			label: 'Check ID',
			name: 'check_id'
		},

		{
			label: 'Status',
			name: 'status',
			options: {
				cell: (info) => {
					const value = info.getValue()
					return (
						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
							<Box
								sx={{
									width: 8,
									height: 8,
									marginRight: '3px',
									borderRadius: '50%',
									backgroundColor:
										value === 'pass'
											? colorsByStatus.ACTIVE.rgb().toString()
											: colorsByStatus.DISCONNECTED.rgb().toString()
								}}
							/>
							<Text fontSize={12}>{value}</Text>
						</Box>
					)
				}
			}
		}
	]

	const fetchTableData = () => {
		const getSubControlsDetailsByIdApi = getSubControlsDetailsById()

		setIsFetching(true)
		getSubControlsDetailsByIdApi
			.api({
				params: {
					subcontrol_id: activeRequirementKey,
					framework: frameworkKey
				}
			})
			.then((response) => {
				setIsFetching(false)
				if (response) {
					if (!response.error) {
						setTableData(response.results.data || [])
					}
				}
			})

		return getSubControlsDetailsByIdApi
	}

	useEffect(() => {
		const subControlsDetailsByIdApi = fetchTableData()
		return () => {
			subControlsDetailsByIdApi.cancel()
		}
	}, [JSON.stringify(activeRequirementKey), JSON.stringify(frameworkKey)])

	return (
		<WrapperCard hideShadow>
			<Table
				data={tableData}
				columns={columns}
				isEmpty={tableData?.length === 0}
				loading={isFetching}
				disableFilter
				disableSearchBy
				totalSize={tableData != null ? tableData?.length : 0}
				// enableRowClick
				// onRowClickCustomHandler={onRowClickCustomHandler}
			/>
			{/* <AppModal open={isOpenModal} onClose={handleClose} hideClose fullWidth title='Details'>
				{isFetchingModalData ? (
					<Loader />
				) : remediationData == undefined || null ? (
					<>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100%'
							}}
						>
							<NoData text='No data found!' />
						</Box>
					</>
				) : (
					<>
						<Grid xs={4}>
							<Text ascent='primary' fontSize='2rem' padding={'0 0 1rem 1rem'}>
								Remediation
							</Text>
						</Grid>
						<Divider />
						<Grid xs={12} padding={'2rem 0 0 1rem'}>
							<Box
								fontSize={14}
								margin={'1rem 1rem 3rem 0'}
								sx={{
									overflow: 'scroll',
									'& *': {
										fontFamily: '"Open Sans", sans-serif'
									}
								}}
							>
								<Markdown fontSize={14} markdown={remediationData} />
							</Box>
						</Grid>
						<Table
							data={remediationData ? remediationData : []}
							columns={modalColumns}
							disableFilter
							disableSearchBy
							totalSize={remediationData?.length}
							disableGroupBy
						/>

					</>
				)}
			</AppModal> */}
		</WrapperCard>
	)
}

export default ComplianceControlsTable
