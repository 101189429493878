import Loader from '@commons/Loader'
import { Box } from '@mui/material'
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { TableVirtuoso } from 'react-virtuoso'
import NoData from '../NoData'
import AppTable from './AppTable'
import TData from './TData'
import THead from './THead'
import TableFilterButton from './TableFilterButton'
import TableFooter from './TableFooter'
import TableGroupButton from './TableGroupButton'
import TableMenuButton from './TableMenuButton'
import TableRow from './TableRow'
import TableSearch from './TableSearch'
import TableSelectCheckbox from './TableSelectCheckbox'

const columnHelper = createColumnHelper()

export const Table = ({
	id,
	data = [],
	columns = [],
	invisibleFieldColumns = [],
	selectedColumns = [],
	handleUpdateSelectedColumns,
	fetchMore,
	loading,
	sorting,
	setSorting,
	totalSize = 0,
	hideToolbox = false,
	options,
	fetchOnSearch,
	filterProps,
	isEmpty,
	searchProps,
	resetSearchResult,
	disableSearchBy,
	disableFilter,
	disableGroupBy = true,
	disablePagination = false,
	disableMenu,
	enableDynamicColumns = false,
	enableRowSelection = false,
	isCustomColumnExport = false,
	onRowSelectionChange = () => { },
	CustomToolbarSelect = () => { },
	isComplianceTable = false,
	isMandateTable = false,
	isNotificationTable = false,
	rowSelection = {},
	setRowSelection,
	enableRowClick = false,
	headerBackgroundColor = null,
	headerColor = null,
	onRowClickCustomHandler = () => () => { },
	height = 500,
	showLoadMoreButton = false,
	noDataText = 'No data found. Try to search or tweak the filters',
	dataToExport
}) => {
	const [autoSorting, setAutoSorting] = React.useState([])
	const tableColumns = React.useMemo(() => {
		const initialColumns = []
		if (enableRowSelection) {
			initialColumns.push({
				id: 'select',
				name: 'select',
				options: {
					colSpan: 1,
					enableSorting: false,
					header: ({ table }) => {
						return (
							<Box px={1}>
								<TableSelectCheckbox
									{...{
										checked: table.getIsAllRowsSelected(),
										indeterminate: table.getIsSomeRowsSelected(),
										onChange: table.getToggleAllRowsSelectedHandler()
									}}
								/>
							</Box>
						)
					},
					cell: ({ row }) => {
						return (
							<Box px={1}>
								<TableSelectCheckbox
									{...{
										checked: row.getIsSelected(),
										disabled: !row.getCanSelect(),
										indeterminate: row.getIsSomeSelected(),
										onChange: row.getToggleSelectedHandler()
									}}
								/>
							</Box>
						)
					}
				}
			})
		}

		return columns?.length > 0
			? initialColumns.concat(enableDynamicColumns && selectedColumns.length > 0 ? selectedColumns : columns).map((each) =>
				columnHelper.accessor(
					(item) => {
						return Object.byString(item, each.name)
					},
					{
						id: each.name,
						header: each.label || each.name,
						colSpan: 1,
						value: each.name,
						label: each.label || each.name,
						...each.options
					}
				)
			)
			: []
	}, [columns, selectedColumns, enableDynamicColumns])

	const searchOptions = React.useMemo(() => {
		if (columns.length > 0) {
			const options = columns
				.filter((each) => !!each.searchKey)
				.map((each) => ({
					label: each.label,
					value: each.searchKey
				}))
			if (enableDynamicColumns && invisibleFieldColumns.length > 0) {
				for (let i = 0; i < invisibleFieldColumns.length; i++) {
					const invisibleFieldKey = invisibleFieldColumns[i]
					if (!columns.find((col) => col.searchKey === invisibleFieldKey)) {
						options.push({
							label: invisibleFieldKey,
							value: invisibleFieldKey
						})
					}
				}
			}
			return options
		}
		return []
	}, [JSON.stringify(columns), JSON.stringify(invisibleFieldColumns), enableDynamicColumns])

	const table = useReactTable({
		data,
		id,
		columns: tableColumns,
		state: {
			sorting: !options?.manualSorting ? autoSorting : sorting,
			rowSelection
			// columnVisibility: tableColumns.map(column => column.hid)
		},
		autoResetAll: false,
		onSortingChange: !options?.manualSorting ? setAutoSorting : setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		// getCoreRowModel: getCoreRowModel(),
		enableRowSelection,
		onRowSelectionChange: (state) => {
			onRowSelectionChange(state)
			setRowSelection(state)
		},
		enableSortingRemoval: false,
		renderFallbackValue: 'N.A',
		...options
	})

	const handleHideColumn = (column) => (e) => {
		e.stopPropagation()
		const filteredColumns = selectedColumns.filter((col) => col.name !== column.id)
		handleUpdateSelectedColumns(filteredColumns.length > 0 ? filteredColumns : columns)
	}

	const { rows } = table.getRowModel()


	return (
		<>
			{!hideToolbox && <Box display={'flex'} justifyContent='flex-end' marginBottom={'1rem'} alignItems='center'>
				{Object.keys(rowSelection).length > 0 && <Box mr={2}>{CustomToolbarSelect}</Box>}
				<TableSearch
					fetchOnSearch={fetchOnSearch}
					resetSearchResult={resetSearchResult}
					options={searchOptions}
					disableSearchBy={disableSearchBy}
					{...searchProps}
				/>
				{!disableFilter && (
					<TableFilterButton
						enableDynamicColumns={enableDynamicColumns}
						selectedColumns={selectedColumns}
						handleUpdateSelectedColumns={handleUpdateSelectedColumns}
						invisibleFieldColumns={invisibleFieldColumns}
						tableColumns={tableColumns}
						columns={columns}
						{...filterProps}
					/>
				)}
				{!disableGroupBy && <TableGroupButton />}
				{!disableMenu && <TableMenuButton dataToExport={dataToExport} rawData={data} isCustomColumnExport={isCustomColumnExport} tableColumns={tableColumns} isComplianceTable={isComplianceTable} isMandateTable={isMandateTable} isNotificationTable={isNotificationTable}
					// tableData={rows}
					tableData={rows}
				/>}
			</Box>}
			<TableVirtuoso
				style={{ height: height ? height : 500 }}
				totalCount={isEmpty ? 1 : rows.length}
				endReached={fetchMore}
				// data={rows}
				overscan={2}
				components={{
					Table: (props) => (
						<AppTable
							fetchOnSearch={fetchOnSearch}
							searchOptions={searchOptions}
							fetchMore={totalSize !== rows.length ? fetchMore : undefined}
							isEmpty={rows.length === 0}
							colSpan={
								tableColumns.length > 0 &&
								tableColumns.reduce((prevCol, currCol) => ({
									colSpan: prevCol?.colSpan + currCol?.colSpan
								})).colSpan
							}
							showLoadMoreButton={showLoadMoreButton}
							loading={loading}
							{...props}
						/>
					),
					TableRow: (props) => {
						return (
							<TableRow
								onClick={
									isEmpty
										? undefined
										: onRowClickCustomHandler(table.getSortedRowModel().flatRows[props['data-index']]?.original)
								}
								clickable={!isEmpty && enableRowClick}
								{...props}
							/>
						)
					}
				}}
				defaultItemHeight={130}
				fixedHeaderContent={() =>
					table.getHeaderGroups()?.map?.((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<THead
										tableColumns={tableColumns}
										enableDynamicColumns={enableDynamicColumns}
										handleHideColumn={handleHideColumn(header.column.columnDef)}
										sortable={header.column.getCanSort()}
										onSort={header.column.getToggleSortingHandler()}
										sort={sorting}
										sortedAs={header.column.getIsSorted()}
										key={header.id}
										bgColor={headerBackgroundColor}
										color={headerColor}
										colSpan={header.column.columnDef.colSpan}
										{...header.column.columnDef.props}
									>
										{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
									</THead>
								)
							})}
						</TableRow>
					))
				}
				itemContent={(index) => {
					const row = rows[index]
					return loading && data.length === 0 ? (
						<td
							colSpan={
								tableColumns.reduce((prevCol, currCol) => ({
									colSpan: prevCol?.colSpan + currCol?.colSpan
								})).colSpan
							}
						>
							<Box height={395} display={'flex'} alignItems={'center'} justifyContent={'center'}>
								<Loader />
							</Box>
						</td>
					) : isEmpty ? (
						<td
							colSpan={
								tableColumns.reduce((prevCol, currCol) => ({
									colSpan: prevCol?.colSpan + currCol?.colSpan
								})).colSpan
							}
						>
							<Box height={395}>
								<NoData text={noDataText} />
							</Box>
						</td>
					) : (
						row.getVisibleCells().map((cell) => (
							<TData
								key={cell.id}
								bgAscent='trans'
								colorAscent='baseLow'
								isAlignRight={cell.column.columnDef.props?.isAlignRight}
								colSpan={cell.column.columnDef.colSpan}
							>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</TData>
						))
					)
				}}
				fixedFooterContent={() => {
					return (
						<>
							{!disablePagination ? <tr>
								<th
									colSpan={
										tableColumns.length > 0 &&
										tableColumns.reduce((prevCol, currCol) => ({
											colSpan: prevCol?.colSpan + currCol?.colSpan
										})).colSpan
									}
								>
									<TableFooter currentSize={rows?.length} total={totalSize} loading={loading} />
								</th>
							</tr> : <></>}
						</>
					)
				}}
			/>
		</>
	)
}

export default Table
