/* eslint-disable react/display-name */
import { useDebounce } from '@hooks/useDebounce'
import { Box, ClickAwayListener, Grow, IconButton, Paper, Popper, Tooltip } from '@mui/material'
import { SvgIcons } from '@utils/icons'
import React, { memo } from 'react'
import Button from '../Button/Button'
import Icon from '../Icon'
import Text from '../Text'
import TableColumnFilter from './TableColumnFilter'
import TableFilterOption from './TableFilterOption'

const TableFilterButton = memo(
	({
		enableDynamicColumns,
		columns,
		items = [],
		fetchOnChangeFilter,
		onFilterReset,
		tableColumns,
		invisibleFieldColumns,
		// eslint-disable-next-line no-unused-vars
		handleUpdateSelectedColumns = (selectedColumns) => {},
		selectedColumns = [],
		disableReset
	}) => {
		const [anchorEl, setAnchorEl] = React.useState(null)

		const open = Boolean(anchorEl)

		const handleToggle = (event) => {
			setAnchorEl(anchorEl ? null : event.currentTarget)
		}

		const handleClose = () => {
			setAnchorEl(null)
		}

		// eslint-disable-next-line no-unused-vars
		const id = open ? 'simple-popper' : undefined

		const [selectedFiltersById, setSelectedFiltersById] = React.useState({})

		const debouncedSelectedFiltersById = useDebounce(selectedFiltersById, 1200)

		const onFilterChange = React.useCallback((requestKey, filterItem) => {
			setSelectedFiltersById((state) => {
				// for 1st item of each filter
				if (!state[requestKey]) {
					return {
						...state,
						[requestKey]: [
							{
								...filterItem,
								requestKey
							}
						]
					}
				} else if (Array.isArray(state[requestKey])) {
					if (state[requestKey].find((each) => each.id === filterItem.id)) {
						return {
							...state,
							[requestKey]: state[requestKey].filter((each) => each.id !== filterItem.id)
						}
					}
					return {
						...state,
						[requestKey]: [
							...(state[requestKey] ? state[requestKey] : []),
							{
								...filterItem,
								requestKey
							}
						]
					}
				}
				return state
			})
		}, [])

		const reset = React.useCallback(() => {
			setSelectedFiltersById({})
			typeof onFilterReset === 'function' && onFilterReset()
		}, [])

		React.useEffect(() => {
			if (Object.keys(debouncedSelectedFiltersById).length > 0) {
				console.log({ debouncedSelectedFiltersById })
				const req = fetchOnChangeFilter(debouncedSelectedFiltersById)

				return () => {
					req?.cancel?.()
				}
			}
		}, [JSON.stringify(debouncedSelectedFiltersById)])

		return (
			<>
				<Tooltip title='Filter'>
					<IconButton
						sx={{
							marginLeft: '1rem'
						}}
						onClick={handleToggle}
					>
						<Icon ascent='textDark'>
							<SvgIcons.FilterSVG />
						</Icon>
					</IconButton>
				</Tooltip>
				<Popper
					anchorEl={anchorEl}
					open={open}
					role={undefined}
					transition
					disablePortal
					sx={{
						zIndex: 999
					}}
					placement='bottom-end'
					// keepMounted
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
							}}
						>
							<div>
								<ClickAwayListener onClickAway={handleClose}>
									<Paper
										elevation={6}
										sx={{
											minWidth: 360,
											maxHeight: 500,
											maxWidth: 767,
											overflow: 'auto',
											padding: '1.5rem 1rem',
											borderRadius: '0.75rem',
											'& .MuiMenuItem-root': {
												whiteSpace: 'normal'
											},
											'& #menu-list-grow': {
												minWidth: 360,
												overflowY: 'scroll'
											}
										}}
									>
										<Box display={'flex'} alignItems='center' justifyContent={'space-between'}>
											<Text margin='0 0 0 0'>Filters</Text>
											{!disableReset && (
												<Button
													disableRipple
													variant='text'
													colorAscent='primary'
													ascent='white'
													minWidth={'inherit'}
													padding='0rem 0'
													onClick={reset}
												>
													Reset
												</Button>
											)}
										</Box>
										{enableDynamicColumns && (
											<Box mt={3} mb={6}>
												<TableColumnFilter
													columns={columns}
													tableColumns={tableColumns}
													invisibleFieldColumns={invisibleFieldColumns}
													handleUpdateSelectedColumns={handleUpdateSelectedColumns}
													selectedColumns={selectedColumns}
												/>
											</Box>
										)}
										{items.map((each) => (
											// eslint-disable-next-line react/jsx-key
											<TableFilterOption
												title={each.title}
												filters={each.filters}
												onFilterChange={onFilterChange}
												requestBodyKey={each.requestBodyKey}
												selectedFilter={selectedFiltersById[each.requestBodyKey]}
											/>
										))}
									</Paper>
								</ClickAwayListener>
							</div>
						</Grow>
					)}
				</Popper>
			</>
		)
	}
)

export default TableFilterButton
