import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Box, Paper } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import Text from '../Text'

const CardWrapperByRatio = ({
	id,
	aspectRatio = 1,
	title,
	borderRadius = 16,
	children,
	paperProps,
	extraPaperClasses,
	boxProps,
	padding = 2,
	hideShadow = false,
	boxClassName,
	marginTopForChildren = true,
	RightComponent,
	headerProps,
	titleProps,
	wrapperProps,
	childrenContainerClass,
	about,
	tooltipProps = {},
	infoIconCss = {}
}) => {
	return (
		<Paper
			id={id}
			elevation={hideShadow ? 0 : undefined}
			className={extraPaperClasses}
			component={Box}
			{...paperProps}
			sx={{
				borderRadius: `${borderRadius}px !important`,
				paddingTop: `${aspectRatio * 100}%`,
				position: 'relative',
				overflow: 'hidden',
				boxShadow: (theme) => (hideShadow ? undefined : `0 0 0.625rem 0 ${theme.palette.ascents.shadow} !important`),
				'&:hover': {
					'& .footerArea, .infoIcon': {
						opacity: 1,
						visibility: 'visible'
					}
				},

				MUIDataTableToolbarSelect: {
					root: {
						boxShadow: 'none',
						margin: '0.25rem 0'
					},

					title: {
						padding: 0
					}
				},
				...paperProps?.sx
			}}
		>
			<Box
				padding={padding}
				className={boxClassName}
				display='flex'
				flexDirection='column'
				{...boxProps}
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					...boxProps?.sx
				}}
			>
				{(!!title || !!about || RightComponent) && (
					<Box
						display='flex'
						justifyContent='space-between'
						alignItems='center'
						mb={2}
						sx={{
							position: 'relative',
							'& svg': {
								color: (theme) => `${theme.palette.ascents.base} !important`
							},

							'& .infoIcon': {
								visibility: 'hidden',
								opacity: 0,
								transition: 'all 0.5s',
								fontSize: '1.25rem !important',
								...infoIconCss
							}
						}}
						{...headerProps}
					>
						{!!title && (
							<Text
								component='h2'
								fontSize={'0.75rem !important'}
								textTransform='uppercase'
								fontWeight={400}
								isCustomColor
								ascent={'grey'}
								fontFamily='"Open Sans", sans-serif !important'
								flex='1 1 auto'
								letterSpacing={1}
								{...titleProps}
							>
								{title}
							</Text>
						)}
						<Box position='absolute' right={0} display='flex' alignItems='center'>
							{!!about && (
								<Tooltip title={about} placement='left-end' {...tooltipProps}>
									<HelpOutlineOutlinedIcon className={'infoIcon'} />
								</Tooltip>
							)}
							{RightComponent}
						</Box>
					</Box>
				)}
				<Box
					flex='1 1 auto'
					className={childrenContainerClass}
					marginTop={marginTopForChildren && RightComponent && '2rem'}
					{...wrapperProps}
				>
					{children}
				</Box>
			</Box>
		</Paper>
	)
}

export default CardWrapperByRatio
