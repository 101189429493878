import { Box, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import PropTypes from 'prop-types'
import React from 'react'
import ModaTitle from './ModalTitle'
import { images } from '@utils/icons'

const AppModal = ({
	children,
	onClose,
	open,
	className,
	title,
	hideClose,
	textAscent,
	ActionComponent,
	showActions = true,
	hideHeader = false,
	fullWidth = false,
	customQuantificationHeader = false,
	contentClassName,
	contentProps,
	minHeight,
	titleProps,
	CustomHeader,
	showOverflowContent = false,
	...props
}) => {
	React.useEffect(() => {
		const root = document.getElementById('root')

		if (open) {
			root.classList.add('blur-it')
		} else {
			root.classList.remove('blur-it')
		}
	}, [open])

	return (
		<Dialog
			fullWidth={fullWidth}
			maxWidth={'xl'}
			open={open}
			onClose={onClose}
			aria-labelledby='modal'
			minHeight={250}
			disableEnforceFocus
			className={className}
			sx={{
				'& .MuiDialog-paperScrollPaper': {
					minWidth: '25rem',
					minHeight: '10rem',
					overflow: showOverflowContent ? 'visible' : 'hidden'
				},
				'& .MuiDialogContent-root': {
					overflow: showOverflowContent ? 'visible' : 'auto'
				},
				'& .MuiPaper-rounded': {
					borderRadius: '16px'
				},
				'& .MuiDialogActions-root': {
					padding: '12px 16px 16px 16px'
				}
			}}
			{...props}
		>
			{customQuantificationHeader && (
				<Grid padding={0}>
					<Grid padding={0} position='relative'>
						{CustomHeader}
						<Box
							component={'img'}
							src={images.HeaderSVG}
							sx={{
								width: '100%'
							}}
						/>
					</Grid>
				</Grid>
			)}
			{!hideHeader && (
				<ModaTitle ascent={textAscent} onClose={onClose} customQuantificationHeader={customQuantificationHeader} {...titleProps}>
					{title}
				</ModaTitle>
			)}
			<Box
				component={DialogContent}
				className={contentClassName}
				{...contentProps}
				sx={{
					padding: '0.5rem 1rem 1rem 1rem !important',
					minHeight,
					...contentProps?.sx
				}}
			>
				{children}
			</Box>
			{showActions && (
				<DialogActions>
					{!hideClose && (
						<Button onClick={onClose} colorAscent='textDark'>
							Close
						</Button>
					)}
					{ActionComponent}
				</DialogActions>
			)}
		</Dialog>
	)
}

AppModal.propTypes = {
	onClose: PropTypes.func,
	showActions: PropTypes.bool,
	title: PropTypes.string,
	hideHeader: PropTypes.bool,
	contentProps: PropTypes.object,
	ActionComponent: PropTypes.node,
	showOverflowContent: PropTypes.bool
}

export default React.memo(AppModal)
