import { AxiosService, ComplianceService, MitreAttackService } from '@interceptors/axiosInstance'

export const uploadComplianceDocs = (config) => {
	return AxiosService.post('/rest/v1/compliance/', config)
}

export const updateComplianceDocs = (id, config) => {
	return AxiosService.put(`/rest/v1/compliance/${id}/`, config)
}

export const uploadMandateDocs = (config) => {
	return AxiosService.post('/rest/v1/mandate-document/', config)
}

export const updateMandateDocs = (id, config) => {
	return AxiosService.put(`/rest/v1/mandate-document/${id}/`, config)
}

export const getAllDocs = (config) => {
	return AxiosService.get('/rest/v1/compliance/', config)
}

export const getAllMandateDocs = (config) => {
	return AxiosService.get('/rest/v1/mandate-document/', config)
}

export const deleteDocs = (id, config) => {
	return AxiosService.delete(`/rest/v1/compliance/${id}/`, config)
}

export const existingDocument = (doc, config) => {
	return AxiosService.delete(`/rest/v1/${doc}`, config)
}

export const deleteMandateDocs = (id, config) => {
	return AxiosService.delete(`/rest/v1/mandate-document/${id}/`, config)
}

export const getComplianceScore = (config) => {
	return AxiosService.post('/rest/v1/compliancescore/', config)
}

// export const getAllMandates = config => {
//     return AxiosService.get('/rest/v1/mandate/', config)
// }

export const getAllMandatesControls = (config) => {
	return AxiosService.get('/rest/v1/mandate-controls/', config)
}

export const getAllMandates = (config) => {
	return AxiosService.get('/rest/v1/mandate/', config)
}

export const getFrameworksList = (config) => {
	return AxiosService.get('/rest/v1/compliance-framework/', config)
}

export const getComplianceFrameworkListByType = (type, config) => {
	return AxiosService.get(`/rest/v1/compliance-framework/?type=${type}`, config)
}

export const getControlsByFrameworkId = (config) => {
	return AxiosService.get('/rest/v1/control/', config)
}

export const getMitreDetailsById = (config) => {
	return MitreAttackService.post(
		'/mitre/?fields=name,description,mitigations.name,mitigations.description,mitigations.mitigation_id,mitigations.labels,kill_chain_phases.phase_name',
		config
	)
}

export const getUpcomingSecurityAudit = (config) => {
	return AxiosService.get('/rest/v1/compliance-audit/', config)
}

export const deleteUpcomingSecurityAudit = (id, config) => {
	return AxiosService.delete('/rest/v1/compliance-audit/' + id + '/', config)
}

export const getMandateGap = (config) => {
	return AxiosService.post('/rest/v1/mandate_gap/', config)
}

export const createUpcomingSecurityAudit = (config) => {
	return AxiosService.post('/rest/v1/compliance-audit/', config)
}

export const editUpcomingSecurityAudit = (id, config) => {
	return AxiosService.put('/rest/v1/compliance-audit/' + id + '/', config)
}

export const updateUpcomingSecurityAudit = (config) => {
	return AxiosService.patch('/rest/v1/compliance-audit/', config)
}

export const getAllEvidences = (config) => {
	return AxiosService.get('/rest/v1/evidence-master/', config)
}

export const downloadAllEvidences = (config) => {
	return AxiosService.get('/rest/v1/evidence-download/', config)
}

export const downloadAllMandates = (config) => {
	return AxiosService.get('rest/v1/download-mandate-docs/', config)
}

export const downloadAllCompliance = (config) => {
	return AxiosService.get('rest/v1/download-compliance-docs/', config)
}

export const createEvidence = (config) => {
	return AxiosService.post('/rest/v1/evidence-master/', config)
}

export const updateEvidenceById = (id, config) => {
	return AxiosService.put(`/rest/v1/evidence-master/${id}/`, config)
}
//Deleting the Evidence:
export const deleteEvidenceById = (id, config) => {
	return AxiosService.delete(`rest/v1/evidence-master/${id}/`, config)
}
export const getAllImplementedAndUnimplementedControls = (config) => {
	return AxiosService.get('/rest/v1/document_implemented/', config)
}

export const getActiveIntegrationByRecommendedTool = (config) => {
	return AxiosService.get('/rest/v1/active-integration/', config)
}

export const getIntegrationtypemaster = (config) => {
	return AxiosService.get('/rest/v1/integrationtypemaster/', config)
}

export const getEvidenceBadges = (config) => {
	return AxiosService.get('/rest/v1/integrationtypemaster/', config)
}

export const ZScoreRemediationApi = (timeframe = '', config) => {
	return AxiosService.get(`/rest/v1/score-evolution/?timeframe=${timeframe}`, config)
}

//VRM Token
export const getVRMToken = (config) => {
	return AxiosService.get('/rest/v1/fetch-vrm-token/', config)
}

//VRM Overview KPI 
export const getVRMOverviewKPI = (config) => {
	return AxiosService.get('/rest/v1/fetch-vrm-overview/', config)
}

//VRM all vendors
export const getVRMAllVendorDetails = (config) => {
	return AxiosService.get('/rest/v1/fetch-vrm-all-vendors/', config)
}

//Compliance v1.5 apis::::

//All Compliance API
export const getAllCompliance = (config) => {
	return ComplianceService.get('rest/v1/compliance-v1.5/get-all-compliance/', config)
}

//Get Framework Control:
export const getFrameworkControl = (config) => {
	return ComplianceService.post('/rest/v1/compliance-v1.5/get-framework-control/', config)
}


//Put Fucntion ---> Compliance SOA ----> to be triggered if compliance.status is preset
export const applicableTrigger = (id, config) => {
	return AxiosService.put(`/rest/v1/compliance/${id}/`, config)
}

//Post Fucntion ----> Compliance SOA ----> to be triggered if compliance ==== {}
export const applicableTriggerConditional = (config) => {
	return AxiosService.post('/rest/v1/compliance/', config)
}


//CCI APIs

export const getAllParameters = (config) => {
	return AxiosService.get(`/cci/v1/get_all_parameters/`, config)
}
export const setPhase = (config) => {
	return AxiosService.get(`/cci/v1/set_phase/`, config)
}

export const generateReport = (config) => {
	return AxiosService.post(`/cci/v1/generate_report/`, config)
}


//yearly
export const configureAssessmentYearlyAPI = (config) => {
	return AxiosService.post(`/cci/v1/configure_assessment/`, config)
}
//half-yearly & custom
export const configureAssessmentCustomAPI = (config) => {
	return AxiosService.post(`/cci/v1/configure_assessment/`, config)
}

//dashboard data api
export const getParameters = (config) => {
	return AxiosService.get(`/cci/v1/get_parameters/`, config)
}

//get configurations
export const fetchConfigurations = (config) => {
	return AxiosService.get(`/cci/v1/configure_assessment/`, config)
}

//fetch parameters
export const fetchParameters = (config) => {
	return AxiosService.get(`/cci/v1/variables/get_variables_by_parameter_org/`, config)
}

//save variable values
export const saveVariables = (config) => {
	return AxiosService.post('/cci/v1/variables/save_variable_value/', config)
}

//save evidences
export const saveEvidences = (config) => {
	return AxiosService.post(`/cci/v1/evidences/save_evidence/`, config)
}

//audit link
export const getAuditLink = (config) => {
	return AxiosService.post(`/cci/v1/audit_link/`, config)
}

//delete evidence
export const deleteEvidenceAPI = (config) => {
	return AxiosService.delete(`/cci/v1/evidences/delete_evidence/`, config)
}

export const getVariables = (config) => {
	return AxiosService.get(`/cci/v1/variables/`, config)
}
export const getEvidences = (config) => {
	return AxiosService.get(`/cci/v1/evidences/get_evidences_by_parameter_org/`, config)
}
export const getAuditLogs = (config) => {
	return AxiosService.get(`/cci/v1/auditlogs/`, config)
}

export const getCommments = (config) => {
	return AxiosService.get(`/cci/v1/comments/`, config)
}

//Auditor API

export const getAuditParameters = (config) => {
	return AxiosService.get(`/cci/v1/get_audit_parameters/`, config)
}

export const getAuditVariables = (config) => {
	return AxiosService.get(`/cci/v1/get_audit_variables/`, config)
}

export const getAuditEvidences = (config) => {
	return AxiosService.get(`/cci/v1/get_audit_evidences/`, config)
}

export const addAuditorComment = (config) => {
	return AxiosService.post(`/cci/v1/audit_comments/`, config)
}

export const getAuditorComment = (config) => {
	return AxiosService.get(`/cci/v1/audit_comments/`, config)
}

export const deleteAuditorComment = (id, config) => {
	return AxiosService.delete(`/cci/v1/audit_comments/${id}/`, config)
}

export const updateEvidenceStatus = (config) => {
	return AxiosService.post(`/cci/v1/get_audit_evidences/update_evidence_status/`, config)
}

export const finalizeComment = (config) => {
	return AxiosService.post(`/cci/v1/final_audit_acceptance/final_acceptance/`, config)
}

export const getFinalizeComment = (config) => {
	return AxiosService.get(`cci/v1/final_audit_acceptance/get_comments/`, config)
}