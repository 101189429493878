import { createSlice } from '@reduxjs/toolkit'
import { getPolicyLists } from './reducer'

const initialState = {
	allIds: [],
	byId: {},
	byRouteName: {},
	allRouteNamesByPolicy: []
}

export const policyListsSlice = createSlice({
	name: 'policy',
	initialState,
	extraReducers: {
		// [getPolicyLists.fulfilled]: (_, action) => action.payload,
		[getPolicyLists.fulfilled]: (state, action) => {
			if (action.payload?.length > 0) {
				state.allIds = action.payload?.map((item) => item.id)
			}
			if (action.payload?.length > 0) {
				const policyById = {},
					policyByRouteName = {}
				const policyList = action.payload

				for (let index = 0; index < policyList.length; index++) {
					const item = policyList[index]

					policyById[item.id] = { ...item }
					policyByRouteName[item.RouteName] = { ...item }
				}

				state.byId = policyById
				state.byRouteName = policyByRouteName
				state.allRouteNamesByPolicy = Object.keys(policyByRouteName)
			}
		}
	}
})

export const policyListsReducer = policyListsSlice.reducer
