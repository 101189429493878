import adminRoutesConstant, { AUTH, BASE } from '@constants/adminRoutesConstant'
import { customEvents } from '@constants/analytics'
import { Buffer } from 'buffer'
import mixpanel from 'mixpanel-browser'
import queryString from 'query-string'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * Use this hook when you need to call the function only when the dependencies change, skipping the initial function call
 * @param {*} callback Function you want to call
 * @param {*} dependencies Array of dependency values
 */
export const useUpdateEffect = (callback, dependencies) => {
	const firstRenderRef = useRef(true)

	useEffect(() => {
		if (firstRenderRef.current) {
			firstRenderRef.current = false
			return
		}
		return callback()
	}, dependencies)
}

export const useLoadingStatus = (options = {}) => {
	const { timeout = 100000 } = options // automatically remove loader after 100 secs (by default)

	const [loading, setLoading] = React.useState(false)

	const startLoading = () => setLoading(true)

	const stopLoading = () => setLoading(false)

	React.useEffect(() => {
		if (loading) {
			setTimeout(() => {
				stopLoading()
			}, timeout)
		}
	}, [loading])

	return {
		loading,
		startLoading,
		stopLoading
	}
}

export const useScroller = (options = {}) => {
	// NOTE: `thershold` controls how much early should we declare that scrollbar has reached bottom or top
	const {
		componentRef = {},
		thershold = 0.25,
		shouldUpdateLive = true,
		useUncontrolledMode = false,
		onScroll,
		onScrollToBottom,
		onScrollToTop
	} = options

	const initialState = {
		scrollHeight: 0,
		scrollTop: 0,
		clientHeight: 0,
		scrollPosition: 0,
		isAtBottom: false,
		isAtTop: true,
		scrollable: false
	}

	const [scrollInfo, setScrollInfo] = React.useState({ ...initialState })

	React.useLayoutEffect(() => {
		console.log('component', componentRef)
		if (componentRef.current) {
			if (shouldUpdateLive && componentRef.current.scrollHeight <= componentRef.current.clientHeight) {
				setScrollInfo({
					scrollHeight: componentRef.current.scrollHeight,
					scrollTop: 0,
					clientHeight: componentRef.current.clientHeight,
					scrollPosition: 0,
					isAtBottom: true,
					isAtTop: true
				})

				return
			}

			// eslint-disable-next-line no-inner-declarations
			function handleOnScroll(event) {
				const { scrollHeight, scrollTop, clientHeight } = event.target

				const scrollPosition = scrollHeight - scrollTop - clientHeight
				const isAtBottom = scrollPosition <= clientHeight * thershold
				const isAtTop = scrollTop >= clientHeight * thershold
				// console.log(
				//     "SCROLL",
				//     isAtTop,
				//     isAtBottom,
				//     scrollTop,
				//     clientHeight,
				//     clientHeight * thershold
				// );
				if (shouldUpdateLive) {
					if (useUncontrolledMode && typeof onScroll === 'function') {
						onScroll(event, {
							isAtBottom,
							isAtTop,
							scrollPosition
						})
						return
					}

					setScrollInfo({
						scrollHeight,
						scrollTop,
						clientHeight,
						scrollPosition,
						isAtBottom,
						isAtTop
					})
				} else {
					if (useUncontrolledMode) {
						if (isAtBottom && typeof onScrollToBottom === 'function') {
							onScrollToBottom(event, {
								isAtBottom,
								scrollPosition
							})
						}
						if (isAtTop && typeof onScrollToTop === 'function') {
							onScrollToBottom(event, {
								isAtTop,
								scrollPosition
							})
						}
						console.log('scrollInfo-hook::onScrollToBottom', {
							scrollHeight,
							scrollTop,
							clientHeight,
							scrollPosition,
							isAtBottom,
							isAtTop
						})
						return
					}

					setScrollInfo({
						scrollHeight,
						scrollTop,
						clientHeight,
						scrollPosition,
						isAtBottom,
						isAtTop
					})
				}
			}

			componentRef.current.addEventListener('scroll', handleOnScroll)

			return () => {
				console.log('component::Unmount', componentRef)

				componentRef.current && componentRef.current.removeEventListener('scroll', handleOnScroll)
			}
		}
	}, [componentRef.current])

	return {
		scrollInfo
	}
}

// NOTE: It is improvised version
// Learn More here: https://usehooks.com/useWindowSize/
export const useWindowSize = () => {
	// Initialize state with undefined width/height so server and client renders match
	const [windowSize, setWindowSize] = React.useState({
		width: undefined,
		height: undefined
	})
	React.useLayoutEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight
			})
		}
		// Add event listener
		window.addEventListener('resize', handleResize)
		// Call handler right away so state gets updated with initial window size
		handleResize()
		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize)
	}, []) // Empty array ensures that effect is only run on mount
	return windowSize
}

// FUTURE: Need to implement https://snowplowanalytics.com/
// GA auto does it, but this is to improvise
export const usePageTracking = () => {
	const location = useLocation()
	const token = useSelector((state) => state?.auth?.token?.info)
	const userRole = useSelector((state) => state?.auth?.user?.info?.role)
	const userDetails = useSelector((state) => state.auth.user.info)
	const navigate = useNavigate()

	React.useEffect(() => {
		const pathName = location?.pathname
		const currentRootRoute = adminRoutesConstant.routesByUrls['/'.concat(pathName.split('/')[1])] || {}

		if (currentRootRoute.label) {
			if (currentRootRoute.label === 'Index Route') {
				document.title = 'ZERON — Cyber Security Platform'
			} else if (currentRootRoute.label === 'Dashboard') {
				document.title = 'ZERON — Cyber Security Platform'
			} else {
				document.title = 'ZERON — ' + currentRootRoute.label
			}
		}

		if (!window.gtag) return

		window.gtag('send', 'pageview', {
			page: location.pathname + location.search
		})

		// mixpanel page tracking
		mixpanel.time_event(customEvents.PAGE_VISIT)
		return () => {
			mixpanel.track(customEvents.PAGE_VISIT, {
				url: location.pathname,
				hash: location.hash,
				search: location.search
			})
		}
	}, [location])

	React.useEffect(() => {
		const loginPath = adminRoutesConstant.adminPaths.LoginScreen.url()
		if (!(token && userRole) && location.pathname !== loginPath && adminRoutesConstant.routesByUrls[location.pathname]) {
			if (![AUTH, BASE].includes(adminRoutesConstant.routesByUrls[location.pathname]?.type)) {
				navigate(
					{
						pathname: loginPath,
						search: queryString.stringify({
							next: Buffer.from(location.pathname).toString('base64')
						})
					},
					{ replace: true }
				)
			}
		}
	}, [userDetails, token, location])

	// Send user data to mixpanel
	React.useEffect(() => {
		if (userDetails.user && token) {
			mixpanel.identify(userDetails.user.email)
			mixpanel.people.set({
				email: userDetails.user.email,
				first_name: userDetails.user.first_name,
				last_name: userDetails.user.last_name,
				user_id: userDetails.user.id,
				organization_id: userDetails.organization.id,
				organization_name: userDetails.organization.name
			})
		}
	}, [userDetails, token])

	return location
}
