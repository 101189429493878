import Text from '@commons/Text'
import { useDebounce } from '@hooks/useDebounce'
import { Close } from '@mui/icons-material'
import Search from '@mui/icons-material/Search'
import { Box, IconButton } from '@mui/material'
import queryString from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Icon from '../Icon'
import SelectBox from '../Inputs/SelectBox'
import TextBox from '../Inputs/TextBox'

// TODO: create context for search box instead of PROP DRILLING
const TableSearch = ({ options, resetSearchResult, fetchOnSearch, searchPlaceholder, disableSearchBy }) => {
	const [searchByDefaultValue, setSearchByDefaultValue] = useState('')

	const searchByRef = React.useRef()

	const [searchString, setSearchString] = React.useState(undefined)

	const reloadToken = useSelector((state) => state.reloadToken)

	const { searchBy, searchQuery } = useMemo(() => queryString.parseUrl(window.location.href).query, [window.location.href])

	// NOTE: Debouncing to rerender less frequently
	const searchText = useDebounce(searchString, 1200)

	const resetInput = () => {
		setSearchString('')
	}

	const fetchData = () => {
		if (typeof fetchOnSearch === 'function' && searchText?.length > 0 && searchByRef.current) {
			const req = fetchOnSearch(searchByRef.current.value, searchText)
			return req
		} else {
			if (typeof resetSearchResult === 'function' && String(searchText) !== 'undefined' && searchText?.length === 0) {
				resetSearchResult()
			}
		}
	}

	const handleSearch = (e) => {
		setSearchString(e.target.value)
	}

	const onKeyDown = (e) => {
		if (e.keyCode === 13) {
			fetchData()
		}
	}

	React.useEffect(() => {
		if (typeof searchText === 'string') {
			const req = fetchData()

			return () => {
				req?.cancel?.()
			}
		}
	}, [searchText])

	// This is for auto searching in table via url
	useEffect(() => {
		if (!!searchBy && !!searchQuery) {
			setSearchByDefaultValue(searchBy)
			setSearchString(searchQuery)
		} else {
			setSearchByDefaultValue(options?.[0]?.value)
			resetInput()
		}
	}, [options, searchBy, searchQuery])

	useEffect(() => {
		resetInput()
	}, [searchByDefaultValue, reloadToken])

	// React.useEffect(() => {
	// 	if (reloadToken) {
	// 		const requestToken = resetInput()

	// 		return () => {
	// 			requestToken.cancel()
	// 		}
	// 	}
	// }, [reloadToken])

	return (
		<>
			{!disableSearchBy && (
				<>
					<Text margin='0 0.5rem 0 0'>Search By:</Text>

					<Box
						sx={{
							flex: '0 0 auto',
							// marginLeft: "1rem",
							// marginTop: "0.5rem",
							display: 'flex',
							background: (theme) => theme.palette.ascents.washLight,
							borderRadius: '0.5rem',
							overflow: 'hidden',
							// maxWidth: "30%",
							// minWidth: "10%",
							width: 'auto',

							'& .MuiFormControl-root': {
								margin: 0,
								paddingLeft: '0.7rem'
							},
							'& .MuiInput-root': {
								'&:after, &:before': {
									display: 'none'
								}
							},
							'& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before ':
								{
									borderBottom: 'none'
								},
							'& fieldset': {
								border: 'none'
							},
							'& .MuiIconButton-root': {
								borderRadius: '0rem 0.5rem 0.5rem 0rem',
								'&:hover': {
									backgroundColor: (theme) => theme.palette.ascents.wash
									// backgroundColor: "#eeecf7",
								}
							},
							'& .MuiInputAdornment-root': {
								position: 'absolute',
								right: 0
							}
						}}
						// className={classes.searchBox}
					>
						<Box
							sx={{
								// marginLeft: "1rem",
								display: 'flex',
								background: (theme) => theme.palette.ascents.washLight,
								borderRadius: '0.5rem',
								overflow: 'hidden',

								'& .MuiFormControl-root': {
									margin: 0
									// padding: "0.4375rem 1rem 0.4375rem 1rem",
								},

								// "& .MuiInputBase-root": {
								//     margin: "0 !important",
								// },

								'& .MuiInput-root': {
									'&:after, &:before': {
										display: 'none'
									}
								},
								'& .MuiFilledInput-underline:before, .MuiFilledInput-underline:after, .MuiFilledInput-underline:hover:not(.Mui-disabled):before':
									{
										borderBottom: 'none !important'
									},
								'& fieldset': {
									border: 'none'
								},
								'& .MuiIconButton-root': {
									borderRadius: '0rem 0.5rem 0.5rem 0rem',
									'&:hover': {
										backgroundColor: (theme) => theme.palette.ascents.wash
										// backgroundColor: "#eeecf7",
									}
								},
								'& .MuiInputAdornment-root': {
									position: 'absolute',
									right: 0
								},
								maxWidth: 'max-content',
								width: 'auto',
								flex: '0 0 auto',
								minWidth: '15%'
							}}
						>
							<SelectBox
								ref={searchByRef}
								options={options}
								name={'searchBy'}
								value={searchByDefaultValue}
								onChange={(e) => setSearchByDefaultValue(e.target.value)}
								rootProps={{
									sx: {
										margin: 0,
										maxWidth: '10rem',
										'& .MuiInputBase-root': {
											margin: '0 !important',
											height: '100%'
										},
										'& .MuiInput-formControl': {
											fontWeight: '400',
											borderRadius: '0.25rem',
											background: 'none !important',

											'&:hover, &:before, &:after': {
												border: 'none !important'
											}

											// '& .MuiFilledInput-root': {
											// }
										},
										'& .MuiSelect-select': {
											fontSize: 12,
											paddingLeft: '1rem'
											// padding:
											//     "3px 35px 6px 8px !important",
										}
									}
								}}
							/>
						</Box>

						<TextBox
							name='searchBox'
							// NOTE: NOT passing `searchString` as `value` prop is not a mistake, but a good optimization technique!
							onChange={handleSearch}
							value={searchString}
							label={searchPlaceholder || 'Search for anything...'}
							inputProps={{
								onKeyDown,
								sx: {
									marginLeft: '0 !important'
								}
							}}
						/>

						{searchText?.length > 0 ? (
							<IconButton disableRipple onClick={resetInput}>
								<Icon ascent='grey'>
									<Close />
								</Icon>
							</IconButton>
						) : (
							<Box borderRadius={'0rem 0.5rem 0.5rem 0rem'} padding='8px'>
								<Icon ascent='grey'>
									<Search />
								</Icon>
							</Box>
						)}
					</Box>
				</>
			)}
		</>
	)
}

export default TableSearch
