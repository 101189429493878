import adminRoutesConstant from '@constants/adminRoutesConstant'
import { Box, Tooltip } from '@mui/material'
import { getActiveIntegrationByRecommendedTool } from '@services/complianceService'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AppChip from './AppChip'
import Loader from './Loader'
import Text from './Text'

const RecommendedTools = ({ recommendedTools = [] }) => {
	const [toolsDetailsByKey, setToolsDetailsByKey] = useState({})
	const [isLoading, setIsLoading] = useState(false)
	const [evidenceData, setEvidenceData] = useState([])

	// const checkEvidencePermission = useSelector((state) => state?.auth?.policyByRouteName[permissionKeys.EvidenceDetailsScreen])

	const resetTools = () => {
		setToolsDetailsByKey({})
		setIsLoading(false)
		setEvidenceData([])
	}

	const fetchData = async () => {
		const getActiveIntegrationByRecommendedToolApi = getActiveIntegrationByRecommendedTool()
		setIsLoading(true)
		let newToolDetails = {}

		const toolsImplementedByEvidence = []
		for (let i = 0; i < recommendedTools.length; i++) {
			const tool = recommendedTools[i]
			const response = await getActiveIntegrationByRecommendedToolApi.api({
				params: {
					enabled: 1,
					recommended_tool: tool.id
				}
			})

			if (response.error) {
				setIsLoading(false)
			}
			if (!response.error && response.results?.data && Array.isArray(response.results.data) && response.results.data.length > 0) {
				newToolDetails = {
					...newToolDetails,
					[tool.name]: {
						url: `${adminRoutesConstant.adminPaths.IntegrationViewScreen.url()}?selected-integration=${response.results.data[0].integration_master.id
							}`,
						errorMessage: null
					}
				}
			} else {
				const toolByEvidenceResponse = await getActiveIntegrationByRecommendedToolApi.api({
					params: {
						enabled: 1,
						recommended_tool: tool.id,
						by_evidence: 1
					}
				})
				if (toolByEvidenceResponse) {
					if (!toolByEvidenceResponse.error && toolByEvidenceResponse?.results && toolByEvidenceResponse?.results?.data) {
						const each = toolByEvidenceResponse?.results?.data
						toolsImplementedByEvidence.push({
							evidenceId: each.id,
							tool
						})
					} else {
						newToolDetails = {
							...newToolDetails,
							[tool.name]: {
								url: `${adminRoutesConstant?.adminPaths?.IntegrationScreen?.url()}`,
								errorMessage: toolByEvidenceResponse.data.detail
							}
						}
					}
				}
			}
		}
		setIsLoading(false)
		setToolsDetailsByKey(newToolDetails)
		setEvidenceData(toolsImplementedByEvidence)

		return getActiveIntegrationByRecommendedToolApi
	}

	useEffect(() => {
		if (recommendedTools.length > 0) {
			fetchData()
		} else {
			resetTools()
		}
	}, [recommendedTools])

	return isLoading ? (
		<Loader />
	) : recommendedTools?.length === 0 && evidenceData?.length === 0 ? (
		<Text>N.A</Text>
	) : (
		<>
			{recommendedTools &&
				Object.keys(toolsDetailsByKey).map((key) => {
					if (toolsDetailsByKey[key].errorMessage) {
						return (
							<Tooltip key={key} title={toolsDetailsByKey[key].errorMessage}>
								<Box display={'inline'} margin={'0.75rem 0.5rem 3rem 0'}>
									<AppChip
										cursor={'pointer'}
										label={key}
										variant={'outlined'}
										colorAscent='textDark'
										ascent='washDark'
									/>
								</Box>
							</Tooltip>
						)
					}
					return (
						<>
							<Tooltip title='Imlemented via integrations'>
								<Link key={key} to={toolsDetailsByKey[key].url}>
									<Box display={'inline'} margin={'0.75rem 0.5rem 3rem 0'}>
										<AppChip variant='contained' sx={{ fontWeight: 400, cursor: 'pointer' }} label={key} />
									</Box>
								</Link>
							</Tooltip>
						</>
					)
				})}
			{/* {evidenceData &&
				evidenceData.map((info) => (
					<>
						{checkEvidencePermission ? (
							<Tooltip title='Implemented via evidence'>
								<Link to={`/evidence-details/${evidenceData[0]?.evidenceId}`}>
									<Box display={'inline'} margin={'0.75rem 0.5rem 3rem 0'}>
										<AppChip
											variant='contained'
											sx={{
												fontWeight: 400,
												backgroundColor: ASCENTS.primary,
												color: ASCENTS.white,
												cursor: 'pointer'
											}}
											label={info?.tool.name}
										/>
									</Box>
								</Link>
							</Tooltip>
						) : (
							<Tooltip title='Implemented via evidence. You do not have permission for this evidence'>
								<Box display={'inline'} margin={'0.75rem 0.5rem 3rem 0'}>
									<AppChip
										variant='contained'
										sx={{
											fontWeight: 400,
											backgroundColor: ASCENTS.primary,
											color: ASCENTS.white
										}}
										label={info?.tool.name}
									/>
								</Box>
							</Tooltip>
						)}
					</>
				))} */}
		</>
	)
}

export default RecommendedTools
