/* eslint-disable no-unused-vars */
import { AutoCompleteGenerator, SelectGenerator, TextGenerator } from '../formConstantGenerator'
import validator from '../validator'

export const ComplianceControlStatus = {
	// Pending: 'Pending',
	// Draft: 'Draft',
	Published: 'Published'
}

export const complianceDocumentFormConstant = (users, statusOptions) => [
	new AutoCompleteGenerator(
		'assigned_to',
		'Assign to user',
		users,
		true,
		false,
		{
			required: validator.isRequired('This field is required').required
		},
		6,
		{
			canAddNewOption: false
		}
	),
	new AutoCompleteGenerator(
		'status',
		'Status',
		statusOptions,
		true,
		false,
		{
			// required: validator.isRequired('This field is required').required
		},
		6,
		{
			canAddNewOption: false
		}
	)
	// new SelectGenerator(
	// 	'status',
	// 	'Status',
	// 	Object.entries(ComplianceControlStatus).map(([key, value]) => ({
	// 		label: value,
	// 		value
	// 	})),
	// 	false,
	// 	{},
	// 	6
	// )
]

export const MandateStatus = {
	Published: 'Published'
	// 'Non-Compliant': 'Non-Compliant',
	// Draft: 'Draft',
	// Pending: 'Pending'
}

export const mandateDocumentFormConstant = (users, statusOptions) => [
	// new TextGenerator('observation', 'Observation', false, {}, 6, 'text', {
	// 	rootProps: {
	// 		sx: {
	// 			height: '100%',
	// 			marginBottom: '0',
	// 			'& .MuiInput-root': {
	// 				marginTop: '6px !important',

	// 				height: '100%'
	// 			}
	// 		}
	// 	}
	// }),
	// new TextGenerator('remark', 'Remark', false, {}, 6, 'text', {
	// 	rootProps: {
	// 		sx: {
	// 			height: '100%',
	// 			marginBottom: '0',
	// 			'& .MuiInput-root': {
	// 				marginTop: '6px !important',

	// 				height: '100%'
	// 			}
	// 		}
	// 	}
	// }),
	new AutoCompleteGenerator(
		'assigned_to',
		'Assign to user',
		users,
		true,
		false,
		{
			required: validator.isRequired('This field is required').required
		},
		6,
		{
			canAddNewOption: false
		}
	),
	// new SelectGenerator(
	// 	'status',
	// 	'Status',
	// 	Object.entries(MandateStatus).map(([key, value]) => ({
	// 		label: value,
	// 		value
	// 	})),
	// 	false,
	// 	{
	// 		// required: validator.isRequired("Status is required!"),
	// 	},
	// 	6
	// )
	new AutoCompleteGenerator(
		'status',
		'Status',
		statusOptions,
		true,
		false,
		{
			// required: validator.isRequired('This field is required').required
		},
		6,
		{
			canAddNewOption: false
		}
	)
]

export const complianceAuditFormConstant = (complianceList, label = 'Compliance') => [
	new AutoCompleteGenerator(
		'compliance',
		label,
		complianceList,
		// [],
		true,
		false,
		{
			required: validator.isRequired('Compliance is required').required
		},
		12,
		{
			canAddNewOption: false
		}
	),
	new TextGenerator(
		'vendor',
		'Vendor',
		false,
		{
			required: validator.isRequired('vendor').required
		},
		6,
		'text',
		{
			rootProps: {
				sx: {
					height: '100%',
					marginBottom: '0',
					'& .MuiInput-root': {
						marginTop: '6px !important',

						height: '100%'
					}
				}
			}
		}
	),
	new SelectGenerator(
		'recurrence',
		'Recurrence',
		[
			{ label: 'Monthly - (30 days)', value: 'Monthly' },
			{ label: 'Quarterly - (90 days)', value: 'Quarterly' },
			{ label: 'Half Yearly - (180 days)', value: 'Half Yearly' },
			{ label: 'Yearly - (365 days)', value: 'Yearly' }
		],
		false,
		{ required: validator.isRequired('Recurrence').required },
		6
	)
]
