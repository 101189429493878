import { Box } from '@mui/material'
import { SvgIcons } from '@utils/icons'
import { getSeverityNameFromLevel } from '@utils/other'
import { fadeColor } from '@utils/styles'
import { memo, useMemo } from 'react'
import AppChip from './AppChip'
import Icon from './Icon'

// eslint-disable-next-line react/display-name
const HostChip = memo(({ name, riskValue = 0 }) => {
	const risk = useMemo(() => {
		return getSeverityNameFromLevel(Number(riskValue))
	}, [riskValue])
	return (
		<AppChip
			colorAscent='textDark'
			label={name}
			customColor={fadeColor(risk.color, 0.96)}
			icon={
				<Icon fontSize={20} color={risk.color}>
					<SvgIcons.HOST_PC_SVG />
				</Icon>
			}
			// border={`1px solid ${ASCENTS["wash"]}`}
			component={Box}
			padding='0.5rem 0rem 0.5rem 1rem !important'
			height='auto !important'
			borderRadius={'5rem !important'}
			justifyContent={'flex-start'}
		/>
	)
})

export default HostChip
