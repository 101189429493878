import AutoCompleteBox from '@commons/Inputs/AutoCompleteBox'
import { generateInvisibleColumnOption } from '@utils/other'
import React from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'

const TableColumnFilter = ({ columns = [], invisibleFieldColumns = [], handleUpdateSelectedColumns = () => {}, selectedColumns = [] }) => {
	const [columnsForFiltering, setColumnsForFiltering] = React.useState([])

	const { control, reset } = useForm()

	const visibleColumnsWatcher = useWatch({
		defaultValue: [],
		name: 'visibleColumns',
		control
	})

	const handleResetVisibleColumns = () => {
		const filteredInvisibleColumn = invisibleFieldColumns
			.map((each) => `_source.${each}`)
			.filter((each) => !columns.find((col) => col.id === each))
			.map((each) => generateInvisibleColumnOption(each))
		setColumnsForFiltering(columns.concat(filteredInvisibleColumn))
	}

	React.useEffect(() => {
		if (Array.isArray(columns) && Array.isArray(invisibleFieldColumns) && columns.length > 0) {
			handleResetVisibleColumns()
		}
	}, [columns, invisibleFieldColumns])

	React.useEffect(() => {
		handleUpdateSelectedColumns(visibleColumnsWatcher.length > 0 ? visibleColumnsWatcher : columns)
	}, [visibleColumnsWatcher])

	React.useEffect(() => {
		reset({
			visibleColumns: selectedColumns
		})
	}, [])

	return (
		<form>
			<Controller
				control={control}
				name='visibleColumns'
				defaultValue={[]}
				render={({ field: { name, onChange, value } }) => {
					return (
						<AutoCompleteBox
							id={name}
							value={value}
							onChange={(_, option) => onChange(option)}
							label='Choose visible columns'
							options={columnsForFiltering}
							multiple
						/>
					)
				}}
			/>
		</form>
	)
}

export default TableColumnFilter
