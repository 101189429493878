/* eslint-disable no-unused-vars */
import { validAscents } from '@constants/propTypesValidation'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Collapse, ListItemText } from '@mui/material'
import { getUuid } from '@utils/other'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import ListItem from '../List/ListItem'
import Text from '../Text'

const Collapsible = ({
	label = 'Collapsible',
	borderwidth = 4,
	ascent = 'base',
	className,
	open,
	handleToggle,
	children,
	expandIconPostion = 'start',
	listItemClassName,
	isForRoute = false,
	path,
	history,
	labelProps,
	iconName,
	disableToggle = false,
	sx,
	collapseProps,
	openByDefault = false,
	...props
}) => {
	const [isOpen, setIsOpen] = useState(openByDefault)

	const { current: id } = React.useRef(getUuid())

	const handleClick = () => {
		if (!disableToggle) {
			setIsOpen(!isOpen)
		}
		if (handleToggle) {
			handleToggle()
		}
	}

	const activeCollapse = (isActive) => () => {
		setIsOpen(isActive)
	}

	useEffect(() => {
		open && setIsOpen(Boolean(open))
	}, [open])

	return (
		<>
			<ListItem
				id={`collapse-toggler-${id}`}
				button
				onClick={handleClick}
				ascent={ascent}
				className={clsx(
					'collapseToggler',
					{
						collapseOpen: isOpen
					},
					className
				)}
				sx={{
					borderBottom: (theme) => (isForRoute ? '1px solid ' : `1px solid ${theme.palette.ascents[ascent]}`),

					'&.MuiListItem-button': {
						'&:hover, &:focus': {
							background: 'none'
						}
					},
					...sx
				}}
			>
				{expandIconPostion === 'start' ? !isOpen ? <ExpandMore /> : <ExpandLess /> : null}

				{isForRoute && iconName}
				<ListItemText
					disableTypography
					primary={
						<Text component='span' {...labelProps}>
							{label}
						</Text>
					}
					sx={{
						marginLeft: 0,
						'& .MuiTypography-body1': {
							fontSize: '1.0625rem',

							fontFamily: (theme) => theme.typography.h6.fontFamily
						}
					}}
				/>
				{expandIconPostion === 'end' ? !isOpen ? <ExpandMore /> : <ExpandLess /> : null}
			</ListItem>
			<Collapse id={`collapse-${id}`} in={isOpen} timeout={'auto'} {...collapseProps} {...props}>
				{children}
			</Collapse>
		</>
	)
}

Collapsible.propTypes = {
	ascent: validAscents,
	open: PropTypes.bool,
	label: PropTypes.string,
	borderwidth: PropTypes.number,
	className: PropTypes.string,
	expandIconPostion: PropTypes.oneOf(['start', 'end'])
}

export default Collapsible
