import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllPolicies } from '@services/masterDataService'

export const getPolicyLists = createAsyncThunk('policy/getPolicyLists', async (_, thunkApi) => {
	const { rejectWithValue } = thunkApi
	try {
		const response = await getAllPolicies().api()

		if (response) {
			if (!response.error) {
				// const allIds = response.map((item) => item.id);
				// const byId = response.reduce((accu, item) => {
				//     const val = item;
				//     const valId = item.id;

				//     return { ...accu, [valId]: val };
				// }, {});
				// return { allIds: allIds, byId: byId ,allRouteNamesByPolicy:[]};
				return response.results.data
			} else {
				rejectWithValue({
					response
				})
			}
		} else {
			rejectWithValue({
				response: {
					data: {
						// Because same structure of error comes from backend. Keeps code error free.
						detail: 'We are facing an issue right now. Please try again later.'
					}
				}
			})
		}
	} catch (error) {
		return rejectWithValue(error)
	}
})
