import { AxiosService, CspmService } from '@interceptors/axiosInstance'

export const getAllComplianceControls = (config) => {
	return CspmService.get('/v1/cloud-monitor/policy-result', config)
}

export const getAllExposedPorts = (config) => {
	return CspmService.get('/v1/cloud-monitor/open-ports', config)
}

export const getMgaConfigured = (config) => {
	return CspmService.get('/v1/cloud-monitor/mfa-configured', config)
}

export const getPassword = (config) => {
	return CspmService.get('/v1/cloud-monitor/password-rotated', config)
}

export const getCloudIntegrations = (config) => {
	return CspmService.get('/v1/cloud-monitor/cloud-integrations', config)
}

export const getCloudAccounts = (config) => {
	return CspmService.get('/v1/cloud-monitor/accounts', config)
}

export const getCloudResources = (config) => {
	return CspmService.get('/v1/cloud-monitor/resources', config)
}

export const getCloudBenchmark = (config) => {
	return CspmService.get('/v1/cloud-monitor/benchmark', config)
}

export const getCloudLocations = (config) => {
	return CspmService.get('/v1/cloud-monitor/resource-map', config)
}

export const getCloudBenchmarkRowClick = (config) => {
	return CspmService.get(`/v1/cloud-monitor/policy-result-detail`, config)
}

export const getMfaNotConfiguredRowClick = (id, config) => {
	return CspmService.get(`/v1/cloud-monitor/mfa-configured-detail`, config)
}

export const getPasswordNotRotatedRowClick = (id, config) => {
	return CspmService.get(`/v1/cloud-monitor/password-rotated-detail`, config)
}

// export const updateMandateDocs = (id, config) => {
//     return AxiosService.put(`/rest/v1/mandate-document/${id}/`, config);
// };

export const getIntegrateCloudMonitorList = (config) => {
	return CspmService.get('/v1/cloud-monitor/org-cloud-list', config)
}

export const createIntegrateCloudMonitorList = (config) => {
	return AxiosService.post('/rest/v1/cloud_monitor/', config)
}

export const deleteByIdIntegrateCloudMonitorList = (id, config) => {
	return CspmService.delete(`/v1/cloud-monitor/org-cloud?id=${id}`, config)
}

export const hasCloudIntegrations = (config) => {
	return CspmService.get('/v1/cloud-monitor/cloud-provider', config)
}

export const getOpenPortResources = (config) => {
	return CspmService.get('/v1/cloud-monitor/open-ports-resource', config)
}

export const getSubControlsDetailsById = (config) => {
	return CspmService.get('/v1/cloud-monitor/iso-cis-map', config)
}

export const getDashBoard = (config) => {
	return CspmService.get('/v2/cloud-monitor/getDashBoard', config)
}

export const getResources = (config) => {
	return CspmService.get('/v2/cloud-monitor/getResources', config)
}

export const getCloudChecks = (config) => {
	return CspmService.get('/v2/cloud-monitor/getChecks', config)
}
