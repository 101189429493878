import { AxiosService } from '@interceptors/axiosInstance'

export const verifyEmail = (config) => {
	return AxiosService.get('/rest/v1/mail/', {
		...config,
		checkToken: false
	})
}

export const signIn = (config) => {
	return AxiosService.post('/rest/v1/login/', {
		...config,
		checkToken: false
	})
}

export const signUp = (config) => {
	return AxiosService.post('/rest/v1/signup/', {
		...config,
		checkToken: false
	})
}

export const validateToken = (config) => {
	return AxiosService.get('/rest/v1/signup/prefill/', config)
}

export const sendLicenseKey = (config) => {
	return AxiosService.post('/rest/v1/license/activate/', config)
}

export const getLoggedInUserDetails = (extraHeaders = {}) => {
	if (Object.keys(extraHeaders).length > 0) {
		return AxiosService.get('/rest/v1/me/', { headers: extraHeaders })
	}

	return AxiosService.get('/rest/v1/me/')
}

export const updateUserDetails = (config) => {
	return AxiosService.put('/rest/v1/me/', config)
}

export const refreshCurrentToken = (config) => {
	return AxiosService.post('/rest/v1/refresh-token/', {
		...config,
		checkToken: false
	})
}

export const logoutUser = (config) => {
	return AxiosService.get('/rest/v1/logout/', config)
}
