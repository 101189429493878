/* eslint-disable no-useless-escape */
const validator = {
	alphaNumericSpace: {
		value: /^[a-zA-Z0-9 ]*$/,
		message: 'Special characters are not allowed!'
	},

	alphaNumeric: {
		value: /^[a-zA-Z0-9]*$/,
		message: 'only alphabets and numbers are allowed!'
	},

	isEmail: {
		value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		message: 'Invalid email!'
	},

	isPhone: {
		value: /^[0-9]{10}$/,
		message: 'Phone number should be numeric and must have exactly 10 digits!'
	},

	onlyAlphabets: {
		value: /^[A-Za-z ]+$/,
		message: 'Only alphabets are allowed!'
	},

	onlyNumeric: {
		value: /^[0-9]*$/,
		message: 'Only numbers are allowed!'
	},

	aadharCard: {
		value: /^\d{4}\s\d{4}\s\d{4}$/g,
		message: 'Invalid Aadhar Number!'
	},

	isRequired: (inputName) => ({
		required: {
			value: true,
			message: `${inputName} is required!`
		}
	}),

	isMin: (value) => ({
		min: {
			value: value,
			message: `Minimum ${value} value is required!`
		}
	}),

	isMax: (value) => ({
		max: {
			value: value,
			message: `Maximum ${value} value is allowed!`
		}
	}),

	isMinLength: (value) => ({
		minLength: {
			value: value,
			message: `Minimum ${value} character(s) are required!`
		}
	}),

	isMaxLength: (value) => ({
		maxLength: {
			value: value,
			message: `Maximum ${value} character(s) are allowed!`
		}
	}),

	isPincode: {
		value: /^[0-9]{6}$/,
		message: `Pincode must be exactly 6 digit(s)`
	},

	isGSTIN: {
		value: /^[a-zA-Z0-9 ]{14}$/,
		message: `GSTIN must have alphabets + numbers & must be exactly 14 digits!`
	}
}

export default validator
