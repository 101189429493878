import { createSlice } from '@reduxjs/toolkit'
import { fetchAllNotificationsReducer } from './reducers'

const initialState = {
	allNotifications: [],
	isLoading: false
}

export const notificationsSlice = createSlice({
	initialState,
	name: 'notifications',
	extraReducers: {
		[fetchAllNotificationsReducer.pending]: (state) => {
			state.isLoading = true
		},
		[fetchAllNotificationsReducer.fulfilled]: (state, action) => {
			state.isLoading = false
			state.allNotifications = Array.isArray(action.payload) ? action.payload : []
		},
		[fetchAllNotificationsReducer.rejected]: (state) => {
			state.isLoading = false
		}
	}
})

export const notificationsReducer = notificationsSlice.reducer
