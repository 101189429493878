import { MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

const DropdownItem = ({ className, children, onClick, ...props }) => {
	return (
		<MenuItem onClick={onClick} className={className} {...props}>
			{children}
		</MenuItem>
	)
}

DropdownItem.propTypes = {
	onClick: PropTypes.func.isRequired
}

export default React.memo(DropdownItem)
