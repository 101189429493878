export const getTicketStatusColors = () => ({
	open: '#005aff',
	closed: '#6a5a7b',
	backlog: '#ff7702',
	'on-process': '#0dad45'
})

export const riskValueByStatus = {
	noData: 0,
	stable: 0.4,
	medium: 0.7,
	high: 0.75,
	critical: 1.0
}

export const severityByStatus = {
	UnKnown: 'UnKnown',
	Low: 'Low',
	Medium: 'Medium',
	High: 'High',
	Critical: 'Critical',
	HighlyCritical: 'Highly Crtical',
	Info: 'Info',

	getStatusByValue: (value) => {
		if (value === 0) {
			return 'UnKnown'
		} else if (value <= 0.33) {
			return 'Low'
		} else if (value <= 0.66) {
			return 'Medium'
		} else if (value <= 0.75) {
			return 'High'
		}
		else if (value <= 1) {
			return 'Critical'
		}
	}
}

export const taskStatus = {
	Completed: 'Completed',
	NotDone: 'Not Done'
}

export const asmTargetStatus = {
	Running: 'Running',
	Stopped: 'Stopped',
	NeverStarted: 'Never Started',
	InProgress: 'In Progress'
}

export const statusColors = {
	HighlyCritical: 'hsl(300, 100%, 50%)',
	critical: 'hsl(0, 70%, 50%)',
	high: 'hsl(30, 70%, 50%)',
	medium: 'hsl(50, 70%, 50%)',
	low: 'hsl(143, 70%, 50%)'
}
