import { validAscents } from '@constants/propTypesValidation'
import { FormControl, Input, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import Text from '../Text'

// eslint-disable-next-line react/display-name
const TextBox = React.forwardRef(
	(
		{
			id,
			ascent = 'primary',
			colorAscent,
			endAdornment,
			startAdornment,
			value,
			type = 'text',
			label = 'Enter Something',
			showLabel = false,
			multiline = false,
			onChange = () => {},
			onKeyDown = () => {},
			parentClassName,
			defaultValue,
			labelClassName,
			inputProps,
			labelProps,
			labelForDate,
			rootProps,
			readOnly = false,
			required = false,
			outlined = false,
			disableCopy = false,
			disablePaste = false,
			crqModalFlag = false,
			...props
		},
		ref
	) => {
		// WARNING: Firefox allows user to enter chars in numeric input field.
		// SEE : https://bugzilla.mozilla.org/show_bug.cgi?id=1398528
		// NOTE: This fixes it
		const disableCharsInNumberInput = React.useMemo(() => {
			return type === 'number'
				? (event) => {
						if (event.which == 8) {
							return
						} // to allow BackSpace
						if (event.which < 48 || event.which > 57) {
							event.preventDefault()
						}
				  }
				: undefined
		}, [type])

		const handlePreventDefault = React.useMemo(() => (e) => e.preventDefault(), [])

		return (
			<FormControl
				fullWidth
				required={required}
				{...props}
				className={parentClassName}
				{...rootProps}
				sx={{
					marginBottom: (theme) => theme.spacing(crqModalFlag ? 1 : 6),
					'& .MuiInput-root': {
						marginTop: '0.5rem'
					},
					...rootProps?.sx
				}}
			>
				{showLabel ? (
					<InputLabel
						// className={clsx(classes.label, labelClassName)}
						htmlFor={id}
						sx={{
							color: (theme) => theme.palette.ascents[colorAscent || ascent],
							fontSize: '0.875rem',
							marginLeft: '-14px',

							'&.MuiInputLabel-shrink': {
								color: (theme) => theme.palette.ascents.primary
							},
							'&.MuiInputLabel-asterisk': {
								color: (theme) => theme.palette.ascents.orange
							},
							...labelProps?.sx
						}}
						// shrink
						{...labelProps}
					>
						{label.concat(required ? '*' : '')}
					</InputLabel>
				) : labelForDate ? (
					<Text
						// className={clsx(
						//     classes.label,
						//     classes.labelForDate,
						//     labelClassName
						// )}
						sx={{
							color: colorAscent ? (theme) => theme.palette.ascents[colorAscent] : (theme) => theme.palette.ascents[ascent],
							fontSize: '0.875rem',
							'&.MuiInputLabel-shrink': {
								color: (theme) => theme.palette.ascents.primary
							},
							'&.MuiInputLabel-asterisk': {
								color: (theme) => theme.palette.ascents.orange
							},
							...labelProps?.sx
						}}
						fontSize={10}
						className={labelClassName}
					>
						{labelForDate.concat(required ? '*' : '')}
					</Text>
				) : null}
				{outlined ? (
					<OutlinedInput
						id={id}
						inputRef={ref}
						type={type}
						value={value}
						onChange={onChange}
						onKeyDown={onKeyDown}
						multiline={multiline}
						onCopy={disableCopy ? handlePreventDefault : undefined}
						onPaste={disablePaste ? handlePreventDefault : undefined}
						// className={clsx(classes.input, inputClassName)}
						sx={{
							color: (theme) => theme.palette.ascents.textDark,

							borderRadius: (theme) => theme.spacing(1),

							'& .MuiOutlinedInput-input': {
								padding: (theme) => theme.spacing(1.5)
							},
							'&:before': {
								borderBottom: (theme) => `1px solid ${theme.palette.ascents.primary} !important`
							},
							'&:after': {
								borderBottomColor: (theme) => theme.palette.ascents[ascent],
								borderBottom: 'none',
								height: '2px'
							},
							'&.MuiInputLabel-asterisk': {
								color: (theme) => theme.palette.ascents.orange
							},
							'&:hover': {
								'&:before': {
									borderBottom: (theme) => `2px solid ${theme.palette.ascents.primary} !important`
								}
							},
							...inputProps?.sx
						}}
						placeholder={!showLabel ? label.concat(required ? '*' : '') : null}
						endAdornment={endAdornment ? <InputAdornment position='end'>{endAdornment}</InputAdornment> : null}
						startAdornment={startAdornment ? <InputAdornment position='start'>{startAdornment}</InputAdornment> : null}
						onKeyPress={disableCharsInNumberInput}
						required={required}
						readOnly={readOnly}
						autoFocus
						defaultValue={defaultValue}
						{...inputProps}
					/>
				) : (
					<Input
						id={id}
						type={type}
						inputRef={ref}
						value={value}
						onChange={onChange}
						onKeyDown={onKeyDown}
						multiline={multiline}
						onCopy={disableCopy ? handlePreventDefault : undefined}
						onPaste={disablePaste ? handlePreventDefault : undefined}
						sx={{
							color: (theme) => theme.palette.ascents.textDark,

							borderRadius: (theme) => theme.spacing(6),

							'& .MuiOutlinedInput-input': {
								padding: (theme) => theme.spacing(1.5)
							},
							'&:before': {
								borderBottom: (theme) => `1px solid ${theme.palette.ascents.primary} !important`
							},
							'&:after': {
								borderBottomColor: (theme) => theme.palette.ascents[ascent],
								borderBottom: 'none',
								height: '2px'
							},
							'&.MuiInputLabel-asterisk': {
								color: (theme) => theme.palette.ascents.orange
							},
							'&:hover': {
								'&:before': {
									borderBottom: (theme) => `2px solid ${theme.palette.ascents.primary} !important`
								}
							},
							...inputProps?.sx
						}}
						placeholder={!showLabel ? label.concat(required ? '*' : '') : null}
						endAdornment={endAdornment ? <InputAdornment position='end'>{endAdornment}</InputAdornment> : null}
						startAdornment={startAdornment ? <InputAdornment position='start'>{startAdornment}</InputAdornment> : null}
						required={required}
						readOnly={readOnly}
						onKeyPress={disableCharsInNumberInput}
						defaultValue={defaultValue}
						{...inputProps}
					/>
				)}
			</FormControl>
		)
	}
)

TextBox.propTypes = {
	ascent: validAscents,
	endAdornment: PropTypes.node,
	startAdornment: PropTypes.node,
	inputProps: PropTypes.object,
	labelProps: PropTypes.object,
	onChange: PropTypes.func,
	onKeyDown: PropTypes.func,
	showLabel: PropTypes.bool
}

export default TextBox
