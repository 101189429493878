import TicketSvg from '@assets/icons/ticket.svg'
import Button from '@commons/Button/Button'
import CheckboxInput from '@commons/Inputs/CheckboxInput'
import AppModal from '@commons/Modal/AppModal'
import { customEvents } from '@constants/analytics'
import { Box, IconButton, List, Tooltip } from '@mui/material'
import { createInternalTicket } from '@services/userService'
import { logGAEvent, toggleItemInArray } from '@utils/other'
import mixpanel from 'mixpanel-browser'
import { useSnackbar } from 'notistack'
import React from 'react'

const HostSecurityConfigRaiseTicket = ({ id, title, description, memberList = [] }) => {
	const { enqueueSnackbar } = useSnackbar()

	const [members, setMembers] = React.useState([])
	const [modelStatus, setModelStatus] = React.useState(false)

	const newTicket = React.useMemo(
		() => {
			return createInternalTicket({
				onError: (error) => {
					enqueueSnackbar(error?.data?.detail || 'Error creating ticket', {
						variant: 'error'
					})
					logGAEvent(customEvents.FORM_SUBMIT_FAILED, {
						event_label: 'new ticket raise action failed'
					})
				}
			})
		},
		[id, title, description, JSON.stringify(members), JSON.stringify(memberList)],
		modelStatus
	)

	const raiseTicket = () => {
		if (members.length <= 0) {
			enqueueSnackbar('Need to select at least one member', {
				variant: 'error'
			})

			return
		}

		newTicket
			.api({
				data: {
					assigned_to: members,
					ticket_level: 'open',
					title: `#${id}-${title}`,
					content: description
				}
			})
			.then((resp) => {
				if (resp) {
					if (!resp.error) {
						enqueueSnackbar('Ticket created successfully!', {
							variant: 'success'
						})

						logGAEvent(customEvents.FORM_SUBMIT_SUCCESS, {
							event_label: 'new ticket raise action success'
						})
					}
				}
				closeModel()
			})
	}

	const toggleMembersSelect = (id) => () => {
		setMembers(toggleItemInArray(members, id))
	}

	const openModel = (e) => {
		e.stopPropagation()
		setModelStatus(true)
		logGAEvent(customEvents.BUTTON_CLICKED, {
			event_label: 'new ticket raise modal open'
		})
	}

	const closeModel = () => {
		if (!modelStatus) {
			mixpanel.time_event(customEvents.TOGGLED_MODAL)
		} else {
			mixpanel.track(customEvents.TOGGLED_MODAL, {
				url: window.location.pathname,
				title: `Host Security Config Ticket - Select to assign ticket`
			})
		}
		setMembers([])
		setModelStatus(false)
		logGAEvent(customEvents.BUTTON_CLICKED, {
			event_label: 'new ticket raise modal closed'
		})
	}

	return (
		<>
			<Tooltip title={'Raise Ticket'} placement='top'>
				<IconButton padding={'0.375rem 1rem'} onClick={openModel}>
					<Box width={'1.5rem'} height={'1.5rem'}>
						<img src={TicketSvg} width='100%' height='100%' />
					</Box>
				</IconButton>
			</Tooltip>

			<AppModal
				open={modelStatus}
				onClose={closeModel}
				onClick={(e) => e.stopPropagation()}
				title={'Select to assign ticket'}
				ActionComponent={<Button onClick={raiseTicket}>Raise Ticket</Button>}
			>
				<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
					{memberList.map((each) => (
						<CheckboxInput
							key={each.value}
							label={each.label}
							isChecked={members.indexOf(each.value) > -1}
							onChange={toggleMembersSelect(each.value)}
						/>
					))}
				</List>
			</AppModal>
		</>
	)
}

export default HostSecurityConfigRaiseTicket
