import { ComplianceControlStatus } from '@constants/formConstants/complianceFormConstants'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import { Box } from '@mui/material'
import { ASCENTS } from '@styles/theme'
import Text from './Text'

const RenderControlStatus = ({ value }) => {
	if (value === ComplianceControlStatus.Published) {
		return (
			<Box display='flex'>
				<Text fontSize={12} fontWeight={600} margin='0 0.35rem 0 0' ascent='mediumDarkGreen'>
					{value}
				</Text>
				<CheckCircleIcon
					style={{
						fontSize: 16,
						color: ASCENTS['mediumDarkGreen']
					}}
				/>
			</Box>
		)
	} else if (value === ComplianceControlStatus['Not Uploaded']) {
		return (
			<Box display='flex'>
				<Text fontSize={12} fontWeight={600} margin='0 0.35rem 0 0' ascent='amber'>
					{value}
				</Text>
				<ErrorIcon
					style={{
						fontSize: 16,
						color: ASCENTS['amber']
					}}
				/>
			</Box>
		)
	} else {
		return (
			<Box display='flex'>
				<Text fontSize={12} fontWeight={600} margin='0 0.35rem 0 0' ascent='textGrey'>
					{value || ComplianceControlStatus.Draft}
				</Text>
				<QueryBuilderIcon
					style={{
						fontSize: 16,
						color: ASCENTS.textGrey
					}}
				/>
			</Box>
		)
	}
}

export default RenderControlStatus
