import Alertpic from '@assets/icons/AlertPic.svg'
import AlertIcon from '@assets/icons/alertIconRed.svg'
import Text from '@commons/Text'
import adminRoutesConstant from '@constants/adminRoutesConstant'
import { Box, Icon } from '@mui/material'
import { SvgIcons } from '@utils/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const SubscriptionExpiredScreen = () => {
	const user = useSelector((state) => state.auth.user.info)
	const location = useLocation()
	const paidSubscriptionDetails = useSelector((state) => state.auth.paidSubscriptionDetails.info)
	const { current: billingUrl } = React.useRef(adminRoutesConstant.adminPaths.BillingScreen.url())
	const [isPlanExpired, setIsPlanExpired] = React.useState(false)

	React.useEffect(() => {
		const delayHandler = setTimeout(() => {
			const expired = user.email && !(location.pathname === billingUrl) && !paidSubscriptionDetails
			setIsPlanExpired(expired)
		}, 400)

		return () => clearTimeout(delayHandler)
	}, [location, JSON.stringify(user), paidSubscriptionDetails, billingUrl])

	React.useEffect(() => {
		const page = document.getElementById('page')

		if (page) {
			if (isPlanExpired) {
				page.classList.add('blur-children')
			} else {
				page.classList.remove('blur-children')
			}
		}
	}, [isPlanExpired])

	return (
		<Box
			position='absolute'
			top='50%'
			left='50%'
			display={isPlanExpired ? 'block' : 'none'}
			width='100%'
			height='100%'
			sx={{
				filter: 'none !important',
				'& *': {
					filter: 'none !important'
				},

				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				zIndex: 99999,
				background: 'rgba(255, 255, 255, 0.4)'
			}}
		>
			<Box display={'flex'} justifyContent={'center'} sx={{ backgroundColor: '#D7373F', padding: '0.5rem' }}>
				<Icon sx={{ marginRight: '0.6rem' }}>
					<SvgIcons.ALERTICON_SVG />
				</Icon>
				<Text sx={{ color: '#ffff', paddingTop: '0.3rem', fontWeight: 500, fontSize: '12px' }}>Your Zeron Plan Has Expired </Text>
			</Box>
			<Box
				align='center'
				sx={{
					width: '100%',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					zIndex: 99999,
					background: 'rgba(255, 255, 255, 0.4)'
				}}>
				<img src={Alertpic} />
				<img src={AlertIcon} style={{ position: 'absolute', marginTop: '5.2rem', marginLeft: '-4.1rem' }} />
				<Text fontSize={'1.7rem'} fontWeight={500}>
					{/* <span>You need to{'  '}</span>
				<Text fontSize={'inherit'} ascent={'base'} component={Link} textDecoration='underline' to={billingUrl}>
					subscribe
				</Text>
				<span>{'  '}to unlock this page!</span> */}
					Your License has expired.
				</Text>
				<Text fontSize={'12px'}>
					Renew it to continue using our Platform. Need help? Our support team is ready. Contact support.
				</Text>
			</Box>

		</Box>
	)
}

export default SubscriptionExpiredScreen
