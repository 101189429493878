import { ErrorMessage } from '@hookform/error-message'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import Collapsible from './Collapsible/Collapsible'
import AutoCompleteBox from './Inputs/AutoCompleteBox'
import CheckboxInput from './Inputs/CheckboxInput'
import FileInput from './Inputs/FileInput'
import SelectBox from './Inputs/SelectBox'
import TextBox from './Inputs/TextBox'
import Text from './Text'

export const CustomErrorMessage = ({ name, textProps, ...props }) => (
	<ErrorMessage
		as={
			<Text
				className='form-error-message'
				ascent='lightRed'
				style={{
					// marginTop: "6px",
					// marginLeft: "8px",
					display: 'block'
				}}
				component='small'
				variant='body2'
				fontWeight={500}
				letterSpacing={1}
				{...textProps}
			/>
		}
		name={name}
		{...props}
	/>
)

export const TextController = ({ formObj, control, eachWidth, showError = true, ...props }) => {
	const [passwordIsVisible, setPasswordIsVisible] = React.useState(false)

	const handlePasswordVisibilityToggle = () => setPasswordIsVisible(!passwordIsVisible)

	return (
		<Grid
			key={formObj.name}
			sx={{
				padding: (theme) => theme.spacing(1.5),
				textAlign: 'left'
			}}
			item
			xs={12}
			sm={formObj.eachWidth || eachWidth}
			component={Box}
			{...props.wrapperProps}
		>
			<Controller
				render={({ field: { onChange, value } }) => (
					<TextBox
						onChange={(e) => onChange(e.target.value)}
						name={formObj.name}
						multiline={formObj.multiline}
						id={formObj.name}
						label={formObj.validationRules && formObj.validationRules.required ? formObj.label?.concat(' *') : formObj.label}
						type={formObj.type === 'password' ? (passwordIsVisible ? 'text' : 'password') : formObj.type}
						readOnly={formObj.readOnly}
						value={value}
						showLabel
						endAdornment={
							formObj.type === 'password' &&
							(passwordIsVisible ? (
								<VisibilityIcon
									onClick={handlePasswordVisibilityToggle}
									sx={{
										color: (theme) => theme.palette.ascents.primary
									}}
								/>
							) : (
								<VisibilityOffIcon
									onClick={handlePasswordVisibilityToggle}
									sx={{
										color: (theme) => theme.palette.ascents.primary
									}}
								/>
							))
						}
						{...formObj.props}
					/>
				)}
				control={control}
				name={formObj.name}
				rules={{ ...formObj.validationRules }}
				defaultValue={formObj.props?.defaultValue || ''}
			/>

			{showError && <CustomErrorMessage name={formObj.name} />}
		</Grid>
	)
}

export const CheckboxController = ({ formObj = {}, control, eachWidth }) => {
	return (
		<Grid
			key={formObj.name}
			sx={{
				padding: (theme) => theme.spacing(1.5),
				textAlign: 'left'
			}}
			item
			xs={12}
			sm={formObj.eachWidth || eachWidth}
			// onClick={toggleCheck}
		>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CheckboxInput
						onChange={(e) => {
							!formObj.readOnly && onChange(e.target.checked ? '1' : '0')
						}}
						name={formObj.name}
						label={formObj.validationRules && formObj.validationRules.required ? formObj.label?.concat(' *') : formObj.label}
						readOnly={formObj.readOnly}
						isChecked={value == '1'}
						{...formObj.inputProps}
					/>
				)}
				control={control}
				name={formObj.name}
				rules={{ ...formObj.validationRules }}
				defaultValue={formObj.isChecked ? '1' : '0'}
			/>
			<CustomErrorMessage name={formObj.name} />
		</Grid>
	)
}

export const SelectController = ({ formObj, control, eachWidth, ...props }) => {
	return (
		<Grid
			key={formObj.name}
			sx={{
				padding: (theme) => theme.spacing(1.5),
				textAlign: 'left'
			}}
			item
			xs={12}
			sm={formObj.eachWidth || eachWidth}
		>
			<Controller
				render={({ field }) => (
					<SelectBox
						name={formObj.name}
						id={formObj.name}
						label={formObj.validationRules && formObj.validationRules.required ? formObj.label?.concat(' *') : formObj.label}
						options={formObj.options}
						readOnly={formObj.readOnly}
						{...field}
						{...formObj.props}
					/>
				)}
				control={control}
				name={formObj.name}
				key={formObj.name}
				rules={{ ...formObj.validationRules }}
				defaultValue={formObj.props && formObj.props.multiple ? [] : ''}
				{...props}
			/>
			<CustomErrorMessage name={formObj.name} />
		</Grid>
	)
}

export const FileController = ({
	formObj = {},
	control,
	eachWidth,
	fileType,
	collapsible = {
		wrapInCollapsible: false,
		collapsibleIsOpen: false
	},
	previewUrl
}) => {
	const renderController = () => (
		<Controller
			defaultValue={''}
			render={({ field: { onChange, name, value } }) => (
				<FileInput
					fileType={fileType}
					onChange={(e) => {
						onChange(!!formObj.props && formObj.props.multiple ? e : e[0])
					}}
					name={name}
					buttonLabel={formObj.validationRules && formObj.validationRules.required ? formObj.label?.concat(' *') : formObj.label}
					previewUrl={(value && URL.createObjectURL(value)) || previewUrl}
					onClick={(e) => {
						e.stopPropagation(), e.preventDefault()
						onChange('')
						e.target.value = null
					}}
					imageName={value && value.length > 0 && value[0].name}
					accept={formObj.accept}
					{...formObj.props}
				/>
			)}
			control={control}
			name={formObj.name}
		/>
	)

	return (
		<Grid
			key={formObj.name}
			sx={{
				padding: (theme) => theme.spacing(1.5),
				textAlign: 'left'
			}}
			item
			xs={12}
			sm={formObj.eachWidth || eachWidth}
		>
			{collapsible && collapsible.wrapInCollapsible ? (
				<Collapsible open={collapsible.collapsibleIsOpen} label={formObj.label}>
					{renderController()}
				</Collapsible>
			) : (
				renderController()
			)}
		</Grid>
	)
}

export const VideoController = ({
	formObj = {},
	control,
	eachWidth,
	fileType,
	collapsible = {
		wrapInCollapsible: false,
		collapsibleIsOpen: false
	},
	previewUrl = ''
}) => {
	const [videoPreviewUrl, setVideoPreviewUrl] = React.useState(previewUrl)

	const renderController = () => (
		<Controller
			defaultValue={''}
			render={({ field: { onChange, name, value } }) => (
				<FileInput
					fileType={fileType}
					onChange={(e) => onChange(e.target.files)}
					name={name}
					buttonLabel={formObj.label}
					previewUrl={
						videoPreviewUrl && videoPreviewUrl.length > 0
							? videoPreviewUrl
							: value && value.length > 0 && URL.createObjectURL(value[0])
					}
					onClick={(e) => {
						onChange('')
						e.target.value = null
						setVideoPreviewUrl(null)
					}}
					imageName={value && value.length > 0 && value[0].name}
					accept={formObj.accept}
					readOnly={formObj.disableAccept}
				/>
			)}
			control={control}
			name={formObj.name}
		/>
	)
	return (
		<Grid
			key={formObj.name}
			sx={{
				padding: (theme) => theme.spacing(1.5),
				textAlign: 'left'
			}}
			item
			xs={12}
			sm={formObj.eachWidth || eachWidth}
		>
			{collapsible && collapsible.wrapInCollapsible ? (
				<Collapsible open={collapsible.collapsibleIsOpen} label={formObj.label}>
					{renderController()}
				</Collapsible>
			) : (
				renderController()
			)}
		</Grid>
	)
}

export const TypographyController = ({ key, as, label, eachWidth, ...props }) => {
	return (
		<Grid
			key={key}
			sx={{
				padding: (theme) => theme.spacing(1.5),
				textAlign: 'left'
			}}
			item
			xs={12}
			sm={eachWidth}
			{...props}
		>
			<Typography variant={as}>{label}</Typography>
		</Grid>
	)
}

export const AutoCompleteController = ({ formObj, control, eachWidth, showError = true, ...props }) => {
	return (
		<Grid
			key={formObj.name}
			sx={{
				padding: (theme) => theme.spacing(1.5),
				textAlign: 'left'
			}}
			item
			xs={12}
			sm={formObj.eachWidth || eachWidth}
			component={Box}
			{...props.wrapperProps}
		>
			<Controller
				render={({ field: { onChange, value } }) => (
					<AutoCompleteBox
						id={formObj.name}
						options={formObj.options}
						onChange={(e, value) => onChange(value)}
						sx={{
							'& .MuiSvgIcon-root': {
								color: (theme) => theme.palette.ascents.primary
							}
						}}
						value={value}
						textFieldProps={{
							label:
								formObj.validationRules && formObj.validationRules.required ? formObj.label?.concat(' *') : formObj.label,
							sx: {
								'& :before': {
									borderBottom: (theme) => `1px solid ${theme.palette.ascents.primary} !important`
								}
							}
						}}
						readOnly={formObj.readOnly}
						canAddNewOption={formObj.canAddNewOption}
						{...formObj.props}
					/>
				)}
				name={formObj.name}
				control={control}
				defaultValue={formObj.props && formObj.props.multiple ? [] : ''}
				{...props}
			/>
			{showError && <CustomErrorMessage name={formObj.name} />}
		</Grid>
	)
}
