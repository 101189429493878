import { memo } from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
// eslint-disable-next-line react/display-name
const Markdown = memo(({ markdown }) => {
	return (
		<ReactMarkdown
			linkTarget={'_blank'}
			components={{
				code({ inline, className, children, ...props }) {
					const match = /language-(\w+)/.exec(className || '')
					return !inline && match ? (
						<SyntaxHighlighter
							// eslint-disable-next-line react/no-children-prop
							children={String(children).replace(/\n$/, '')}
							style={dark}
							language={match[1]}
							PreTag='div'
							{...props}
						/>
					) : (
						<code className={className} {...props}>
							{children}
						</code>
					)
				}
			}}
			// eslint-disable-next-line react/no-children-prop
			children={markdown}
			remarkPlugins={[remarkGfm]}
			rehypePlugins={[rehypeRaw]}
		/>
	)
})

export default Markdown
