import { Checkbox } from '@mui/material'
import React from 'react'

const TableSelectCheckbox = ({ indeterminate, ...rest }) => {
	const ref = React.useRef(null)

	React.useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate
		}
	}, [ref, indeterminate])

	return (
		<Checkbox
			type='checkbox'
			ref={ref}
			sx={{
				fontSize: 12,
				'& .MuiSvgIcon-root': {
					fontSize: 18
				}
			}}
			{...rest}
		/>
	)
}

export default TableSelectCheckbox
