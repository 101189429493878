import TableRowClickDrawer from '@commons/TableRowClickDrawer'
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { formatDateTime } from '@utils/other'

const AutoRowDetails = ({
	rowDetails = {},
	useAggregationsData = false,
	renderRowDetailsHeading = () => {},
	hiddenKeysForRowDetails = [],
	openDrawer = false,
	handleClose = () => {}
}) => {
	const update_key = (input) => {
		return input.toLowerCase().replace(/_/g, ' ').replace(/\./g, ' ').replace(/\w\S*/g, function(word) {
			return word.charAt(0).toUpperCase() + word.substr(1);
		});
	}

	return (
		<TableRowClickDrawer
			open={openDrawer}
			handleClose={handleClose}
			title={
				typeof renderRowDetailsHeading === 'function'
					? renderRowDetailsHeading(rowDetails)
					: useAggregationsData
					? 'Details'
					: rowDetails && rowDetails._source?.agent?.ip
					? `IP: ${rowDetails && (rowDetails._source?.agent?.ip || 'Not Found')}`
					: ''
			}
		>
			{Object.keys(rowDetails).length > 0 && (
				<TableContainer elevation={0} component={Paper}>
					<Table>
						{/* <TableHead>
                                        <TableRow>
                                            <TableCell>Key</TableCell>
                                            <TableCell>Value</TableCell>
                                        </TableRow>
                                    </TableHead> */}
						<TableBody>
							{Object.entries(
								useAggregationsData
									? rowDetails
									: Object.flatten({
											...rowDetails._source,
											manager: undefined,
											decoder: undefined,
											input: undefined,
											predecoder: undefined
									  })
							)
								.filter(
									([key]) =>
										![
											'cluster.node',
											'cluster.name',
											'agent.id',
											'predecoder.program_name',
											'@timestamp',
											'full_log',
											'location',
											'rule.mail',
											'previous_output',
											'previous_log',
											...(hiddenKeysForRowDetails ? hiddenKeysForRowDetails : [])
										].includes(key)
								)
								.map(([key, value]) => {
									return (
										(value || value === 0) && (
											<TableRow key={key}>
												<TableCell
													sx={{
														border: 'none',
														fontWeight: 500,
														fontSize: 14,
														verticalAlign: 'text-top'
													}}
												>
													{key === '@timestamp' || key === 'timestamp' ? 'Collected at' : update_key(key)}
												</TableCell>
												<TableCell
													sx={{
														border: 'none',
														fontWeight: 400,
														fontSize: 14,
														wordBreak: 'break-word',
														verticalAlign: 'text-top',
														lineHeight: 1.75
													}}
													style={{
														wordBreak: 'break-word'
													}}
												>
													{key === '@timestamp' || key === 'timestamp' ? formatDateTime(value) : value || '---'}
												</TableCell>
											</TableRow>
										)
									)
								})}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</TableRowClickDrawer>
	)
}

export default AutoRowDetails
