export const initializeClarity = (key, urlParams, userEmail, userOrg) => {
	;(function (c, l, a, r, i, t, y) {
		c[a] =
			c[a] ||
			function () {
				;(c[a].q = c[a].q || []).push(arguments)
			}
		t = l.createElement(r)
		t.async = 1
		t.src = `https://www.clarity.ms/tag/${key}`
		y = l.getElementsByTagName(r)[0]
		y.parentNode.insertBefore(t, y)
	})(window, document, 'clarity', 'script', key)

	window.clarity =
		window.clarity ||
		function () {
			;(window.clarity.q = window.clarity.q || []).push(arguments)
		}

	// Wait for Clarity to be initialized before setting custom tags
	const clarityInterval = setInterval(() => {
		if (window.clarity && window.clarity.q) {
			clearInterval(clarityInterval)
			window.clarity('set', 'pageId', urlParams) // Set the URL
			window.clarity('set', 'Email', userEmail) // Set the Email
			window.clarity('set', 'organisation', userOrg) //Set the organisation
		}
	}, 100)
}
