import { AxiosService } from '@interceptors/axiosInstance'

export const addAgent = (config) => {
	return AxiosService.post('/rest/v1/agent/', config)
}

export const getAgents = (config) => {
	return AxiosService.get(`/rest/v1/agent/`, config)
}

export const getAllVulnerabilities = (config) => {
	return AxiosService.get(`/rest/v1/agent/vulnerabilities/`, config)
}

export const deleteAgents = (config) => {
	return AxiosService.post(`/rest/v1/delete-agents/`, config)
}

export const deleteApiConfig = (id, config) => {
	return AxiosService.delete(`/rest/v1/integration-config/${id}/`, config)
}

export const getActiveAgents = (config) => {
	return AxiosService.get(`/rest/v1/agent/?pretty=true&sort=-dateAdd&limit=1&status=active`, config)
}

export const getSecurityScoreByAgentId = (config) => {
	return AxiosService.get(`/rest/v1/agent/sca/`, config)
}

export const getSecurityScoreCheck = (config) => {
	return AxiosService.get(`/rest/v1/agent/sca-check/`, config)
}

export const getAssessmentDetails = (config) => {
	return AxiosService.get(`/rest/v1/assessment/`, config)
}

export const getNewAssessmentDetails = (config) => {
	return AxiosService.post(`/rest/v1/assessment/`, config)
}

// Also used to GET status of on-going activity.
export const startAssessmentActivity = (config) => {
	return AxiosService.post(`/rest/v1/assessment/activity/`, config)
}

export const createEndpointGroup = (config) => {
	return AxiosService.post('/rest/v1/endpoint/', config)
}

export const getEndpointGroup = (config) => {
	return AxiosService.get('/rest/v1/endpoint/', config)
}

export const updateEndpointGroup = (config) => {
	return AxiosService.put('/rest/v1/endpoint/', config)
}

export const removeAgentFromEndpoint = (config) => {
	return AxiosService.delete('/rest/v1/endpoint/', config)
}

export const deleteGroup = (config) => {
	return AxiosService.delete('/rest/v1/endpoint/group/', config)
}

export const getAgentNetworkInterface = () => {
	return AxiosService.get(`/rest/v1/agent/syscollector/`)
}

export const getZensorConfigDetailsForDev = () => {
	return AxiosService.get(`/rest/v1/agent/group/configuration/`)
}

export const updateZensorConfigDetailsForDev = () => {
	// NOTE: group_id as params is actually agent_id
	return AxiosService.post(`/rest/v1/agent/group/configuration/`)
}

export const getAllIntegrations = () => {
	return AxiosService.get(`/rest/v1/active-integration/`)
}

export const updateIntegration = (id) => {
	return AxiosService.put(`/rest/v1/active-integration/${id}/`)
}

export const getAllIntegrationsConfig = () => {
	// Params: integrationmaster_id
	return AxiosService.get(`/rest/v1/integration-config/`)
}

export const getAllIntegrationsConfigById = () => {
	// Params: integrationmaster_id
	return AxiosService.get(`/rest/v1/integration-config/`)
}

export const updateIntegrationsConfig = (id) => {
	// Params: integrationmaster_id
	return AxiosService.put(`/rest/v1/integration-config/${id}/`)
}

export const uploadIntegrationsConfig = () => {
	// Params: integrationmaster_id
	return AxiosService.post(`/rest/v1/integration-config/`)
}

export const createIntegrationsConfig = () => {
	// Params: integrationmaster_id
	return AxiosService.post(`/rest/v1/integration-config/`)
}

export const createAssetDetails = () => {
	// Params: integrationmaster_id
	return AxiosService.post(`/rest/v1/asset-details/`)
}

export const getAssetInformationAnalytics = () => {
	return AxiosService.get(`/rest/v1/asset-information-analytics/`)
}

export const getAssetDetails = () => {
	// Params: integrationmaster_id
	return AxiosService.get(`/rest/v1/asset-details/`)
}

export const updateAssetDetails = (id) => {
	// Params: integrationmaster_id
	return AxiosService.put(`/rest/v1/asset-details/${id}/`)
}

export const createAssetRating = () => {
	// Params: integrationmaster_id
	return AxiosService.post(`/rest/v1/asset-rating/`)
}

export const getAssetRating = () => {
	// Params: integrationmaster_id
	return AxiosService.get(`/rest/v1/asset-rating/`)
}
export const updateAssetRating = (id) => {
	// Params: integrationmaster_id
	return AxiosService.patch(`/rest/v1/asset-rating/${id}/`)
}

export const createBusinessImpactDetails = () => {
	// Params: integrationmaster_id
	return AxiosService.post(`/rest/v1/business-impact/`)
}

export const getBusinessImpactDetails = () => {
	// Params: integrationmaster_id
	return AxiosService.get(`/rest/v1/business-impact/`)
}
export const updateBusinessImpactDetails = (id) => {
	// Params: integrationmaster_id
	return AxiosService.patch(`/rest/v1/business-impact/${id}/`)
}

export const createDatabaseContainedDetails = () => {
	// Params: integrationmaster_id
	return AxiosService.post(`/rest/v1/database-contained/`)
}

export const getDatabaseContainedDetails = () => {
	// Params: integrationmaster_id
	return AxiosService.get(`/rest/v1/database-contained/`)
}
export const updateDatabaseContainedDetails = (id) => {
	// Params: integrationmaster_id
	return AxiosService.put(`/rest/v1/database-contained/${id}/`)
}
export const createCriticalityDetails = () => {
	// Params: integrationmaster_id
	return AxiosService.post(`/rest/v1/criticality/`)
}

export const getCriticalityDetails = () => {
	// Params: integrationmaster_id
	return AxiosService.get(`/rest/v1/criticality/`)
}
export const updateCriticalityDetails = (id) => {
	// Params: integrationmaster_id
	return AxiosService.patch(`/rest/v1/criticality/${id}/`)
}

export const downloadXLTemplateForAssetProfile = () => {
	// Params: integrationmaster_id
	return AxiosService.get(`/rest/v1/asset-information-bulk/`)
}

export const uploadXLTemplateForAssetProfile = (config) => {
	// Params: integrationmaster_id
	return AxiosService.post(`/rest/v1/asset-information-bulk/`, config)
}
export const getAgentsByOs = () => {
	return AxiosService.get(`/rest/v1/agent-by-os/`)
}
