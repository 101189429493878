import Text from '@commons/Text'

const UnAuthorizedScreen = () => {
	return (
		<div
			style={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				width: '100%',
				padding: '3rem'
			}}
		>
			<Text variant='h1' align='center'>
				You are not authorized to view this page.
			</Text>
			<Text align='center' fontSize={22} margin='1rem 0 0 0'>
				Contact admin to get access.
			</Text>
		</div>
	)
}

export default UnAuthorizedScreen
