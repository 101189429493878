import { Box, ClickAwayListener, Grow, IconButton, Paper, Popper, Tooltip } from '@mui/material'
import { SvgIcons } from '@utils/icons'
import React, { memo } from 'react'
import Button from '../Button/Button'
import Icon from '../Icon'
import Text from '../Text'

// eslint-disable-next-line react/display-name
const TableGroupButton = memo(({ fetchOnChangeFilter, onFilterReset }) => {
	const [anchorEl, setAnchorEl] = React.useState(null)

	const open = Boolean(anchorEl)

	const handleToggle = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	// eslint-disable-next-line no-unused-vars
	const id = open ? 'simple-popper' : undefined

	const [selectedFiltersById, setSelectedFiltersById] = React.useState({})

	// eslint-disable-next-line no-unused-vars
	const onFilterChange = React.useCallback((requestKey, filterItem) => {
		setSelectedFiltersById((state) => ({
			...state,
			[requestKey]: {
				...filterItem,
				requestKey
			}
		}))
	}, [])

	const reset = React.useCallback(() => {
		setSelectedFiltersById({})
		typeof onFilterReset === 'function' && onFilterReset()
	}, [])

	React.useEffect(() => {
		if (Object.keys(selectedFiltersById).length > 0) {
			const req = fetchOnChangeFilter(selectedFiltersById)

			return () => {
				req?.cancel?.()
			}
		}
	}, [JSON.stringify(selectedFiltersById)])

	return (
		<>
			<Tooltip title='Filter'>
				<IconButton
					sx={{
						marginLeft: '0.5rem'
					}}
					onClick={handleToggle}
				>
					<Icon ascent='textDark'>
						<SvgIcons.TableGroupSVG />
					</Icon>
				</IconButton>
			</Tooltip>
			<Popper
				anchorEl={anchorEl}
				open={open}
				role={undefined}
				transition
				disablePortal
				sx={{
					zIndex: 999
				}}
				placement='bottom-end'
				// keepMounted
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
						}}
					>
						<div>
							<ClickAwayListener onClickAway={handleClose}>
								<Paper
									elevation={6}
									sx={{
										minWidth: 360,
										maxHeight: 400,
										maxWidth: 767,
										overflow: 'auto',
										padding: '1rem',
										borderRadius: '0.75rem',
										'& .MuiMenuItem-root': {
											whiteSpace: 'normal'
										},
										'& #menu-list-grow': {
											minWidth: 360,
											overflowY: 'auto'
										}
									}}
								>
									<Box display={'flex'} alignItems='center' justifyContent={'space-between'}>
										<Text margin='0 0 0 0'>Group by</Text>
										<Button
											disableRipple
											variant='text'
											colorAscent='primary'
											ascent='white'
											minWidth={'inherit'}
											padding='0rem 0'
											onClick={reset}
										>
											Reset
										</Button>
									</Box>
								</Paper>
							</ClickAwayListener>
						</div>
					</Grow>
				)}
			</Popper>
		</>
	)
})

export default TableGroupButton
