import '@assets/css/loader.css'
import Text from '@commons/Text'
import { Box } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { useSelector } from 'react-redux'

const security_facts = [
	'The number of cyber attacks is going UP not down.',
	'Ransomware is leading the way in modern cyber security events.',
	'91% of cyber attacks in 2017 started with a phishing email.',
	'Cyber-crime damages will cost the world $6 trillion annually by 2021.',
	'Financial organizations are the biggest targets of cyber attacks.',
	'Mortgage companies are the #1 target.',
	'93% of breaches could have been avoided.'
]

const Loader = ({ messageProps, showSecurityFacts = true, customMessage, ascent = 'base' }) => {
	const securityFactsFromState = useSelector((state) => state.securityFacts)

	const [active, setActive] = React.useState(false)

	const [securityFacts] = React.useState(securityFactsFromState.length > 0 ? securityFactsFromState : security_facts)

	const toggleActive = () => setActive(true)

	React.useEffect(() => {
		const timer1 = setTimeout(toggleActive, 0)
		const timer2 = setInterval(() => {
			setActive((isActive) => !isActive)
		}, 3000)

		return () => {
			clearTimeout(timer1)
			clearTimeout(timer2)
		}
	}, [])

	return (
		<Box
			display={'flex'}
			flexDirection='column'
			alignContent={'center'}
			alignItems={'center'}
			justifyContent={'center'}
			width={'100%'}
			className='loaderRoot'
		>
			<Box
				width={'3.5rem'}
				height={'3.5rem'}
				className='loaderBox'
				sx={{
					'& .cls-2': {
						stroke: (theme) => theme.palette.ascents[ascent]
					}
				}}
			>
				<svg
					className={clsx('loader', {
						active: active
					})}
					id='loader'
					data-name='Layer 1'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 521.98 497.51'
					width='521.97998046875'
					height='497.510009765625'
				>
					<g className='cls-1'>
						<path
							className={`cls-2 svg-elem-1`}
							d='M21.36,260.69C29,259,36.88,258,44.7,257c16.29-2,16.71-1.82,19.64-18.39,5.86-33.25,18.83-63.33,39.53-89.93C167.59,66.83,274.28,46,360.16,88.13c44.71,21.93,77.36,55.25,98.57,100.05,2.93,6.19,5.59,12.5,6.62,19.31,2.68,17.86-7.28,33.57-25.45,40.41-6.9,2.59-10.31,3.85-10.9,6s1.61,5.22,5.93,11.39a191.24,191.24,0,0,0,50.48,49.15c4.35,2.92,9,5.52,12.08,10'
						></path>
						<path
							className={`cls-2 svg-elem-2`}
							d='M40.35,349c6.1-3.47,12.95-4.1,19.85-4.18,23.36-.28,44.12-8.26,63.49-20.85,15.9-10.33,23.86-24.74,23.83-43.53,0-22.56,5.77-43.42,16.44-63.31,29.42-54.87,101.14-79,162.57-48.52,30.06,14.94,48.76,39.22,60.84,69.43,17.18,43,46.58,75.58,84.53,101.16'
						></path>
						<path
							className={`cls-2 svg-elem-3`}
							d='M14.26,299.1c-.45-3.7,1.82-6.08,5-7.05a111.93,111.93,0,0,1,15.29-3.68c10.37-1.58,20.83-2.59,31.21-4.1,16.63-2.42,23.08-8.71,24.86-25.31,4.41-41,19.39-77.71,47.85-107.39,31.89-33.27,71-53.94,118.09-55.75,43.08-1.65,83.59,7.06,119.09,33.14,26.48,19.44,45.1,45.13,59.06,74.47a50,50,0,0,1,2.2,6.58'
						></path>
						<path
							className={`cls-2 svg-elem-4`}
							d='M9.5,207.17c6.44-6.78,8.31-15.63,11.57-23.89C58.62,88,127.55,31.24,228.77,13.17c39.75-7.09,78.33-3.55,116.66,7.53a75.74,75.74,0,0,1,10.41,3.6'
						></path>
						<path
							className={`cls-2 svg-elem-5`}
							d='M226.06,43.35c9.82-1.65,19.69-3.09,29.59-4.13,5.19-.55,10.48-.1,15.73-.1,63.44.08,117.5,22.61,163,66.84,23.34,22.66,41.66,48.28,54.19,78.29,5.87,14.05,8.3,28.53,4.79,43.05-2.81,11.62.74,19.23,9.53,25.83a76,76,0,0,1,9.56,8.15'
						></path>
						<path
							className={`cls-2 svg-elem-6`}
							d='M218.36,318.39c-12.52-22.48-19.14-45.71-6.58-70.42,12-23.67,31.39-35.93,58.18-35.86,31.08,0,50.5,13.07,62.71,43.37a289.69,289.69,0,0,0,68.19,101C413,368.26,427,377.64,440.6,387.45c3,2.15,6.18,4,9.08,6.27'
						></path>
						<path
							className={`cls-2 svg-elem-7`}
							d='M341.07,476.36c-4.3-1-7.16-4.22-10.42-6.84-33.44-26.9-61.83-58.29-84.37-94.86-.55-.89-1.11-1.79-1.7-2.65-8.16-11.95-8.46-12-18-1.58-26.29,28.89-57.41,50.6-94.43,63.5-6.93,2.41-13.88,5-21.36,5.06'
						></path>
						<path
							className={`cls-2 svg-elem-8`}
							d='M269.43,356.86a303.62,303.62,0,0,1-24-44.21c-3.74-8.64-6.85-17.57-10-26.46-8.35-23.5,15.44-46.76,32.33-45.58,19.42.33,31,8.79,37.69,24.19,7.78,17.79,15.86,35.45,26.35,51.85,15.61,24.4,33.1,47.21,55.79,65.67'
						></path>
						<path
							className={`cls-2 svg-elem-9`}
							d='M82.32,400.29c4.41-1.57,9.14-2.3,13.74-3.36,30.27-7,57.49-20,80.21-41.42C193,339.76,194,336.66,185,315.7c-12.43-29.09-11.09-57.08,3.66-84.87,6.07-11.44,15-20.12,24.68-28.28a26.66,26.66,0,0,1,6.78-3.94'
						></path>
						<path
							className={`cls-2 svg-elem-10`}
							d='M122.67,252c-.69-5.84.54-11.44,2.14-17A148.94,148.94,0,0,1,270.65,127.22c4.44.11,12.42-.8,20.07,1.35'
						></path>
						<path
							className={`cls-2 svg-elem-11`}
							d='M271.2,277.78c7.6,16.71,15.46,33.27,24.84,49.11a312.17,312.17,0,0,0,85,93.71c3.81,2.79,7.72,5.47,11.32,8.51'
						></path>
						<path
							className={`cls-2 svg-elem-12`}
							d='M257.16,184.32c5.22-.39,10.48-.08,15.72-.08,36.11.59,63.13,17,80.37,48.5,7.79,14.22,13.87,29.38,20.66,44.13a22.71,22.71,0,0,1,2,5.94'
						></path>
						<path
							className={`cls-2 svg-elem-13`}
							d='M13.58,234c1.89-.85,4-1.87,6-1.84,14.76.24,19-9.2,22.25-21.55a207.84,207.84,0,0,1,35.67-73'
						></path>
						<path
							className={`cls-2 svg-elem-14`}
							d='M308.59,485.79c-6-1-10.18-5.17-14.39-9.08a443.29,443.29,0,0,1-48.74-53.2c-2.25-2.89-4.53-5.76-6.67-8.74-3.93-5.47-8.34-7.84-14.38-2.86a24.71,24.71,0,0,1-5.32,3.29'
						></path>
						<path className={`cls-2 svg-elem-15`} d='M422.2,58.31a260,260,0,0,1,77.49,85.46,36.75,36.75,0,0,1,2,4.27'></path>
						<path
							className={`cls-2 svg-elem-16`}
							d='M265.35,488c-7-5.53-12.73-12.39-18.52-19.19-14-16.4-23.36-17.84-42.46-7.12-7.3,4.11-14.61,8.23-22.08,12'
						></path>
						<path
							className={`cls-2 svg-elem-17`}
							d='M372,462.57A45,45,0,0,1,359.54,456c-26-19.78-49.32-42.22-69.21-68.16a48.77,48.77,0,0,1-5.06-7.92'
						></path>
						<path className={`cls-2 svg-elem-18`} d='M109.75,100.31c19.45-18.93,42.62-32.09,67.31-42.79'></path>
						<path className={`cls-2 svg-elem-19`} d='M408.12,323.27c16.5,17.71,35.48,32.3,55.83,45.27'></path>
						<path
							className={`cls-2 svg-elem-20`}
							d='M187.23,439.65c-12.28,7-24.84,13.54-38.11,18.55a36.14,36.14,0,0,1-6,1.74'
						></path>
						<path className={`cls-2 svg-elem-21`} d='M414,229s-21-63-67-85'></path>
					</g>
				</svg>
			</Box>
			{showSecurityFacts && (
				<Text margin={'0.5rem 0 0 0'} padding={'0 1em'} {...messageProps}>
					{securityFacts[Math.floor(Math.random() * securityFacts.length)]}
				</Text>
			)}
			{!!customMessage && (
				<Text margin={'0.5rem 0 0 0'} padding={'0 1em'} {...messageProps}>
					{customMessage}
				</Text>
			)}
		</Box>
	)
}

export default Loader
