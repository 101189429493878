import { Box } from '@mui/material'

const ChartCustomTooltipWrapper = ({ hideShadow = false, children , customColor = '' }) => {
	return (
		<Box
			sx={{
				background: customColor.length > 0  ? customColor : 'white',
				boxShadow: !hideShadow ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : undefined,
				borderRadius: '10px',
				padding: '9px 12px',
				color: '#4a4a4a'
			}}
		>
			{children}
		</Box>
	)
}

export default ChartCustomTooltipWrapper
