import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: false,
		lng: 'EN',
		resources: {
			EN: {
				//English Translation
				translation: {
					//No Data Found:
					NoDataFound: 'No Data Found!!',
					//Severity Labels:
					Critical: 'Critical',
					High: 'High',
					Medium: 'Medium',
					Low: 'Low',
					//Vendor Pulse Page:
					VPButton: 'Vendor Pulse Control Panel',
					VPTitle1: 'Vendor Details',
					VPTitle2: 'AGGREGATE RISK SCORE',
					VPTitle3: 'RISK BASED VENDOR CLASSIFICATION',
					VPTitle4: 'TOTAL VENDORS',
					VPParam1: 'Created',
					VPParam2: 'Ongoing Assessment',
					VPParam3: 'Onboarded',
					VPParam4: 'Rejected',
					VPParam5: 'Terminated',
					VPTableHead1: 'Company Name',
					VPTableHead2: 'Risk Score',
					VPTableHead3: 'Service',
					VPTableHead4: 'Contract Period(in Days)',
					VPTableHead5: 'Vendor Reassessment Date',
					VPTableHead6: 'Status',
					Dashboard: 'Dashboard',
					Business: 'B - Business posture',
					CloudManager: 'Cloud Monitor',
					SecurityAssessment: 'A - Attack surface',
					SecurityAssessment2: 'A - Attack surface v2.0',
					Compliance: 'C - Compliance',
					ManagementParent: 'Others',
					OverView: 'Overview'
				}
			},
			JP: {
				//Japanese Translation
				translation: {
					NoDataFound: 'データが見つかりませんでした!!',
					Critical: 'クリティカル',
					High: '高い',
					Medium: '中',
					Low: '低い',
					VPButton: 'ベンダーパルス制御パネル',
					VPTitle1: 'ベンダーの詳細',
					VPTitle2: '総合リスクスコア',
					VPTitle3: 'リスクベースのベンダー分類',
					VPTitle4: 'ベンダー総数',
					VPParam1: '作成済み',
					VPParam2: '進行中の評価',
					VPParam3: 'オンボード完了',
					VPParam4: '拒否された',
					VPParam5: '終了',
					VPTableHead1: '会社名',
					VPTableHead2: 'リスクスコア',
					VPTableHead3: 'サービス',
					VPTableHead4: '契約期間（日）',
					VPTableHead5: 'ベンダー再評価日',
					VPTableHead6: 'ステータス',
					Dashboard: 'ダッシュボード',
					Business: 'B - ビジネスポスチャー',
					CloudManager: 'クラウドモニター',
					SecurityAssessment: 'A - 攻撃面',
					SecurityAssessment2: 'A - 攻撃面 v2.0',
					Compliance: 'C - コンプライアンス',
					ManagementParent: 'その他',
					OverView: 'Overview11'
				}
			},
			FR: {
				//French Translation
				translation: {
					NoDataFound: 'Aucune donnée trouvée !!',
					Critical: 'Critique',
					High: 'Élevé',
					Medium: 'Moyen',
					Low: 'Faible',
					VPButton: 'Panneau de Contrôle Vendor Pulse',
					VPTitle1: 'Détails du Fournisseur',
					VPTitle2: 'SCORE DE RISQUE GLOBAL',
					VPTitle3: 'CLASSIFICATION DES FOURNISSEURS BASÉE SUR LE RISQUE',
					VPTitle4: 'TOTAL DES FOURNISSEURS',
					VPParam1: 'Créé',
					VPParam2: 'Évaluation en Cours',
					VPParam3: 'Intégré',
					VPParam4: 'Rejeté',
					VPParam5: 'Terminé',
					VPTableHead1: 'Nom de l’Entreprise',
					VPTableHead2: 'Score de Risque',
					VPTableHead3: 'Service',
					VPTableHead4: 'Période du Contrat (en jours)',
					VPTableHead5: 'Date de Réévaluation du Fournisseur',
					VPTableHead6: 'Statut',
					Dashboard: 'Tableau de Bord',
					Business: 'B - Posture Commerciale',
					CloudManager: 'Gestionnaire de Cloud',
					SecurityAssessment: 'A - Surface d’Attaque',
					SecurityAssessment2: 'A - Surface d’Attaque v2.0',
					Compliance: 'C - Conformité',
					ManagementParent: 'Autres'
				}
			},
			GR: {
				//German Translation
				translation: {
					NoDataFound: 'Keine Daten gefunden!!',
					Critical: 'Kritisch',
					High: 'Hoch',
					Medium: 'Mittel',
					Low: 'Niedrig',
					VPButton: 'Vendor Pulse Kontrollpanel',
					VPTitle1: 'Anbieterdetails',
					VPTitle2: 'AGGREGIERTE RISIKOBEWERTUNG',
					VPTitle3: 'RISIKOBASIERTE ANBIETERKLASSIFIZIERUNG',
					VPTitle4: 'GESAMTANBIETER',
					VPParam1: 'Erstellt',
					VPParam2: 'Laufende Bewertung',
					VPParam3: 'Onboarded',
					VPParam4: 'Abgelehnt',
					VPParam5: 'Beendet',
					VPTableHead1: 'Firmenname',
					VPTableHead2: 'Risikobewertung',
					VPTableHead3: 'Dienst',
					VPTableHead4: 'Vertragszeitraum (in Tagen)',
					VPTableHead5: 'Neubewertungsdatum des Anbieters',
					VPTableHead6: 'Status',
					Dashboard: 'Dashboard',
					BusinessPosture: 'B - Geschäftshaltung',
					CloudManager: 'Cloud-Manager',
					SecurityAssessment: 'A - Angriffsoberfläche',
					SecurityAssessment2: 'A - Angriffsoberfläche v2.0',
					Compliance: 'C - Compliance',
					ManagementParent: 'Andere'
				}
			},
			CH: {
				//Chinese(Mandarin) Translation
				translation: {
					NoDataFound: '未找到数据!!',
					Critical: '严重',
					High: '高',
					Medium: '中',
					Low: '低',
					VPButton: '供应商脉冲控制面板',
					VPTitle1: '供应商详情',
					VPTitle2: '综合风险评分',
					VPTitle3: '基于风险的供应商分类',
					VPTitle4: '供应商总数',
					VPParam1: '已创建',
					VPParam2: '正在评估',
					VPParam3: '已入驻',
					VPParam4: '已拒绝',
					VPParam5: '已终止',
					VPTableHead1: '公司名称',
					VPTableHead2: '风险评分',
					VPTableHead3: '服务',
					VPTableHead4: '合同期限（天数）',
					VPTableHead5: '供应商重新评估日期',
					VPTableHead6: '状态',
					Dashboard: 'Dashboard'
				}
			},
			FP: {
				translation: {
					NoDataFound: 'Walang Natagpuang Datos!!',
					Critical: 'Kritikal',
					High: 'Mataas',
					Medium: 'Katamtaman',
					Low: 'Mababa',
					VPButton: 'Control Panel ng Vendor Pulse',
					VPTitle1: 'Detalye ng Vendor',
					VPTitle2: 'KABUUANG ISKOR NG PANGANIB',
					VPTitle3: 'PAG-UURI NG VENDOR BATAY SA PANGANIB',
					VPTitle4: 'KABUUANG MGA VENDOR',
					VPParam1: 'Nalikha',
					VPParam2: 'Patuloy na Pagsusuri',
					VPParam3: 'Na-onboard',
					VPParam4: 'Tinanggihan',
					VPParam5: 'Nagwakas',
					VPTableHead1: 'Pangalan ng Kumpanya',
					VPTableHead2: 'Iskor ng Panganib',
					VPTableHead3: 'Serbisyo',
					VPTableHead4: 'Panahon ng Kontrata (sa Araw)',
					VPTableHead5: 'Petsa ng Muling Pagsusuri sa Vendor',
					VPTableHead6: 'Katayuan',
					Dashboard: 'Dashboard',
					BusinessPosture: 'B - Posisyon sa Negosyo',
					CloudManager: 'Tagapamahala ng Cloud',
					SecurityAssessment: 'A - Ibabaw ng Pag-atake',
					SecurityAssessment2: 'A - Ibabaw ng Pag-atake v2.0',
					Compliance: 'C - Pagsunod',
					ManagementParent: 'Iba Pa'
				}
			},
			BA: {
				translation: {
					NoDataFound: 'কোনো ডেটা পাওয়া যায়নি!!',
					Critical: 'সংকটপূর্ণ',
					High: 'উচ্চ',
					Medium: 'মাঝারি',
					Low: 'নিম্ন',
					VPButton: 'ভেন্ডর পালস নিয়ন্ত্রণ প্যানেল',
					VPTitle1: 'ভেন্ডর বিবরণী',
					VPTitle2: 'সমষ্টিগত ঝুঁকি স্কোর',
					VPTitle3: 'ঝুঁকি ভিত্তিক ভেন্ডর শ্রেণীবিন্যাস',
					VPTitle4: 'মোট ভেন্ডর',
					VPParam1: 'তৈরি',
					VPParam2: 'চলমান মূল্যায়ন',
					VPParam3: 'অনবোর্ডেড',
					VPParam4: 'প্রত্যাখ্যাত',
					VPParam5: 'সমাপ্ত',
					VPTableHead1: 'কোম্পানির নাম',
					VPTableHead2: 'ঝুঁকির স্কোর',
					VPTableHead3: 'সেবা',
					VPTableHead4: 'চুক্তির মেয়াদ (দিন)',
					VPTableHead5: 'ভেন্ডর পুনর্মূল্যায়নের তারিখ',
					VPTableHead6: 'অবস্থা',
					Dashboard: 'ড্যাশবোর্ড',
					BusinessPosture: 'B - ব্যবসায়িক মনোভাব',
					CloudManager: 'ক্লাউড মনিটর',
					SecurityAssessment: 'A - আক্রমণের পৃষ্ঠপোষকতা',
					SecurityAssessment2: 'A - আক্রমণের পৃষ্ঠপোষকতা v2.0',
					Compliance: 'C - অনুসরণ',
					ManagementParent: 'অন্যান্য'
				}
			}
		}
	})
