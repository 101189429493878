import { Box } from '@mui/material'
import { SvgIcons } from '@utils/icons'
import Text from './Text'

const NothingIsSelected = ({ text = '', header = '' }) => {
	return (
		<Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' height='100%'>
			<SvgIcons.NoMandatesSVG></SvgIcons.NoMandatesSVG>
			{header?.length > 0 && <Text margin={'0 0 0.375rem 0'} fontWeight={700} fontSize={18} fontFamily='Open Sans'>
				{header}
			</Text>}
			{header?.length === 0 && <Text margin={'0 0 0.375rem 0'} fontWeight={700} fontSize={18} fontFamily='Open Sans'>
				Nothing is selected!
			</Text>}
			{text?.length > 0 && <Text fontWeight={'400'} fontSize={14} fontFamily='Open Sans'>
				{text}
			</Text>}
			{text?.length === 0 && <Text fontWeight={'400'} fontSize={14} fontFamily='Open Sans'>
				Please select any requirement from list to see the details.
			</Text>}
		</Box>
	)
}

export default NothingIsSelected
