import { createAsyncThunk } from '@reduxjs/toolkit'
import { getMasterData } from '@services/masterDataService'

export const getSecurityFacts = createAsyncThunk('security/getSecurityFacts', async (_, thunkApi) => {
	const { rejectWithValue } = thunkApi
	try {
		const response = await getMasterData().api()

		if (response) {
			if (!response.error) {
				const securityFacts = response?.find((el) => el.key === 'security_facts')

				return JSON.parse(securityFacts.value)
			}
		}
	} catch (error) {
		return rejectWithValue(error)
	}
})
