import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllNotifications } from '@services/userService'
import { sortDateByISODate } from '@utils/other'

export const fetchAllNotificationsReducer = createAsyncThunk('fetchAllNotificationsReducer', async () => {
	try {
		const response = await getAllNotifications().api({
			params: {
				page: 1,
				page_size: 4
			}
		})
		if (response) {
			if (!response.error) {
				return sortDateByISODate(response.results.data)
			}
		} else {
			return undefined
		}
	} catch (error) {
		return undefined
	}
})
