import { AxiosService, BuyZeronApi, ReportGeneratorService } from '@interceptors/axiosInstance'

export const sendInviteToUser = (config) => {
	return AxiosService.post('/rest/v1/invite/', config)
}

export const removeMemberFromOrganisation = (config) => {
	return AxiosService.delete('/rest/v1/invite/', config)
}

export const getUsersOrganisation = (config = {}) => {
	return AxiosService.get('/rest/v1/myorganization/', config)
}

export const updateUsersOrganisation = (config) => {
	return AxiosService.put('/rest/v1/myorganization/', config)
}

export const partialUpdateUsersOrganisation = (config) => {
	return AxiosService.patch('/rest/v1/myorganization/', config)
}

export const resetPassword = (config) => {
	return AxiosService.post('/rest/v1/passwd/reset/', { checkToken: false, ...config })
}

export const changeUserPasswordConfirm = (config) => {
	return AxiosService.post('/rest/v1/passwd/reset/cnfrm/', { checkToken: false, ...config })
}

export const fetchOrderIdForSubscription = (config) => {
	return AxiosService.post(`/rest/v1/subscribe/`, config)
}

export const buyPlan = (config, id) => {
	return AxiosService.put(`/rest/v1/subscribe/${id}/`, config)
}

export const sendFeedback = (config) => {
	return AxiosService.post('/rest/v1/feedback/', config)
}

export const getFeedback = (config) => {
	return AxiosService.get('/rest/v1/feedback/', config)
}

export const sendFCMToken = (config) => {
	return AxiosService.post('/rest/v1/devices/', config)
}

export const getAllNotifications = (config) => {
	return AxiosService.get('/rest/v1/notifications/', config)
}

//API Call for changing is_read status of notification:
export const updateReadNotifications = (id, config) => {
	console.log('id', id)
	return AxiosService.put(`/rest/v1/notifications-status/${id}/`, config)
}

export const getAllInternalTickets = (config) => {
	return AxiosService.get('/rest/v1/api/tickets/', config)
}

export const createInternalTicket = (config) => {
	return AxiosService.post('/rest/v1/api/tickets/', config)
}

export const updateInternalTicket = (config, id) => {
	return AxiosService.put(`/rest/v1/api/tickets/${id}/`, config)
}

export const updateInternalAssignedTicket = (config, id) => {
	return AxiosService.put(`/rest/v1/api/my-tickets/${id}/`, config)
}

export const deleteInternalTickets = (config, id) => {
	return AxiosService.delete(`/rest/v1/api/tickets/${id}/`, config)
}

export const getInternalTicketsAssignToUser = (config) => {
	return AxiosService.get('/rest/v1/api/my-tickets/', config)
}

export const deleteUserInternalTickets = (config, id) => {
	return AxiosService.delete(`/rest/v1/api/my-tickets/${id}/`, config)
}

export const getAlerts = (config) => {
	return AxiosService.get(`/rest/v1/alert/`, config)
}

export const createAlert = (config) => {
	return AxiosService.post(`/rest/v1/alert/`, config)
}

export const updateAlert = (id, config) => {
	return AxiosService.put(`/rest/v1/alert/${id}/`, config)
}

export const deleteAlert = (id, config) => {
	return AxiosService.delete(`/rest/v1/alert/${id}/`, config)
}

export const generateReport = (config) => {
	return ReportGeneratorService.post(`/api/report`, config)
}

export const getClusterConfig = (config) => {
	return AxiosService.get('/rest/v1/cluster-config/?node_name=node01&raw=true', config)
}

export const updateClusterConfig = (config) => {
	return AxiosService.post('/rest/v1/cluster-config/?node_id=node01', config)
}

export const getSecurityBlogs = (config) => {
	return AxiosService.get('/rest/v1/security-blogs/', config)
}

export const eventForDemoUser = (config) => {
	return BuyZeronApi.post(`/api/Event/createEventDetails`, {
		...config,
		headers: {
			xApiKey: '2022ZERON450Linus972458TCAPIKEY'
		}
	})
}

export const getAllOrganizationMembers = (config) => {
	return AxiosService.get('/rest/v1/org_user_details/', config)
}

export const edituserInfo = (userID,config) => {
	return AxiosService.put(`/rest/v1/invite/${userID}/`, config)
}

export const getAllSiteNames = (config) => {
	return AxiosService.get('/core/sites/', config)
}
export const postSiteName = (config) => {
	return AxiosService.post('/core/sites/', config)
}
export const DeleteSite = (id, config) => {
	return AxiosService.delete(`/core/sites/${id}/`, config)
}
export const EditSite = (id, config) => {
	return AxiosService.put(`/core/sites/${id}/`, config)
}
