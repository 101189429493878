import adminRoutesConstant from '@constants/adminRoutesConstant'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getLoggedInUserDetails, signIn } from '@services/authService'
import { verifyMfa } from '@services/mfaServices'
import { getUserSubscriptionDetails } from '@services/paymentService'
import { getParameterByName } from '@utils/other'

export const userMfaLogin = createAsyncThunk('auth/userLogin', async (formData, thunkApi) => {
	const { rejectWithValue } = thunkApi

	try {
		const response = await verifyMfa().api({
			data: {
				otp_id: formData.otp_id,
				email: formData.email,
				verification_code: formData.verification_code
			}
		})

		if (response) {
			if (!response.error) {
				return response.token?.access
			} else if (response.code === 1000) {
				return rejectWithValue({
					response: {
						data: {
							// Because same structure of error comes from backend. Keeps code error free.
							detail: [response.detail]
						}
					}
				})
			}
		} else {
			return rejectWithValue({
				response: {
					data: {
						// Because same structure of error comes from backend. Keeps code error free.
						detail: ['We are facing an issue right now. Please try again later.']
					}
				}
			})
		}
	} catch (error) {
		return rejectWithValue(error)
	}
})

export const userLogin = createAsyncThunk('auth/userLogin', async (formData, thunkApi) => {
	const { rejectWithValue } = thunkApi
	try {
		const response = await signIn().api({
			data: {
				email: formData.email,
				password: formData.password
			}
		})

		if (response) {
			if (response.error) {
				if (response.data.code === 1000) {
					return rejectWithValue({
						response: {
							data: {
								// Because same structure of error comes from backend. Keeps code error free.
								detail: [response.data.detail]
							}
						}
					})
				}
			} else if (response.results.data.first_login) {
				console.log('🚀 ~ file: reducers.js:10 ~ response ~ response:', response)
				const activation_key = response.results.data.activation_key
				if (response.results.data.first_login && activation_key !== '') {
					const redirectTo = adminRoutesConstant.adminPaths.ResetPasswordScreen.url().concat(`?activation_key=${activation_key}`)
					window.location.href = redirectTo
					return
				}
			} else if (response.results.data.token) {
				return response.results.data.token?.access
			} else if (response.results.data.mfa_enabled) {
				if (response.results.data.otp_id) {
					const nextPageUrl = getParameterByName('next')
					const redirectTo = adminRoutesConstant.adminPaths.LoginWithMfaScreen.url().concat(
						`/?email=${formData.email}&otp_id=${response.results.data.otp_id}${nextPageUrl ? `&next=${nextPageUrl}` : ''}`
					)
					window.location.href = redirectTo
					return
				} else {
					return rejectWithValue({
						response: {
							data: {
								// Because same structure of error comes from backend. Keeps code error free.
								detail: ['We have disabled login for your account for next 1 hour.']
							}
						}
					})
				}
			} else {
				console.log('🚀 ~ file: reducers.js:10 ~ response ~ response:', response)
				return rejectWithValue({
					response
				})
			}
		} else {
			return rejectWithValue({
				response: {
					data: {
						// Because same structure of error comes from backend. Keeps code error free.
						detail: ['We are facing an issue right now. Please try again later.']
					}
				}
			})
		}
	} catch (error) {
		return rejectWithValue(error)
	}
})

export const redirectSsoLoginUser = createAsyncThunk('auth/redirectSsoLoginUser', async (formData, thunkApi) => {
	const { rejectWithValue } = thunkApi

	if (formData?.access) {
		return formData?.access
	}
	return rejectWithValue('You have not access token.')
})

export const refreshUserData = createAsyncThunk('auth/refreshUserData', async (formData, thunkApi) => {
	const { rejectWithValue } = thunkApi

	try {
		const response = await getLoggedInUserDetails().api()

		if (response) {
			if (!response.error) {
				return response.results.data
			} else {
				rejectWithValue({
					response
				})
			}
		} else {
			rejectWithValue({
				response: {
					data: {
						// Because same structure of error comes from backend. Keeps code error free.
						detail: 'We are facing an issue right now. Please try again later.'
					}
				}
			})
		}
	} catch (error) {
		return rejectWithValue(error)
	}
})

export const updateSubscriptionDetails = createAsyncThunk('auth/updateSubscriptionDetails', async (formData, thunkApi) => {
	const { rejectWithValue } = thunkApi

	try {
		const response = await getUserSubscriptionDetails().api(
			// {
			// params: {
			// 	status: 'active'
			// }
			// }
		)

		if (response) {
			if (!response.error) {
				if (Array.isArray(response.results.data)) {
					return response?.results?.data[0]
				}

				rejectWithValue({
					response: {
						data: {
							// Because same structure of error comes from backend. Keeps code error free.
							detail: 'Active Subscription'
						}
					}
				})
			} else {
				rejectWithValue({
					response
				})
			}
		} else {
			rejectWithValue({
				response: {
					data: {
						// Because same structure of error comes from backend. Keeps code error free.
						detail: 'Active Subscription'
					}
				}
			})
		}
	} catch (error) {
		return rejectWithValue(error)
	}
})
