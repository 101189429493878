import CloseIcon from '@mui/icons-material/Close'
import { Box, Drawer } from '@mui/material'

import IconButton from '@mui/material/IconButton'
import React from 'react'
import Text from './Text'

const TableRowClickDrawer = ({ anchor = 'right', open = false, handleClose, title, children }) => {
	React.useEffect(() => {
		const root = document.getElementById('root')

		if (open) {
			root.classList.add('blur-it')
		} else {
			root.classList.remove('blur-it')
		}
	}, [open])

	return (
		<Drawer
			anchor={anchor}
			open={open}
			onClose={handleClose}
			sx={{
				'& .MuiDrawer-paper': {
					maxWidth: '50%'
				}
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					padding: '1rem 1rem 1rem 1rem',
					alignItems: 'center',
					marginBottom: '2rem'
				}}
			>
				<Text textTransform='capitalize' fontWeight={500}>
					{title ? title : 'EVENT DETAILS'}
				</Text>
				<IconButton onClick={handleClose}>
					<CloseIcon />
				</IconButton>
			</Box>
			<Box
				sx={{
					padding: '1rem'
				}}
			>
				{children}
			</Box>
		</Drawer>
	)
}

export default TableRowClickDrawer
