/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unused-vars */
import { logoutUser } from '@redux/auth/slice'
import store from '@redux/store'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'

function ApiService(options) {
	const { onSuccess, onError, Authorization, ...otherOptions } = options

	// TODO: Improvise this function
	this.onError = (customErrorCallback) => (error) => {
		if (customErrorCallback) {
			customErrorCallback(error)
		}
	}

	this.onSuccess = (customSuccessCallback) => (response) => {
		if (customSuccessCallback) {
			customSuccessCallback(response)
		}

		return response
	}

	this.baseUrl = otherOptions.baseUrl

	this.service = axios.create(otherOptions)

	// this.service.interceptors.response.use(this.onSuccess(onSuccess), this.onError(onError))

	// data, params will be submitted while invoking the returned function
	const makeRequest =
		(method = 'GET', url, options = {}) =>
		async (body = {}) => {
			const {
				transformResponse = [],
				onSuccess = () => {},
				onError = (error = {}) => {},
				checkToken = true,
				checkReportToken = false,
				headers,
				auth,
				cancelToken = this.getCancelInstance().token,
				...config
			} = options

			const { data, params, headers: customHeaders } = body

			let response

			try {
				switch (method.toUpperCase()) {
					case 'GET':
						response = await this.service.get(url, {
							...config,
							headers: {
								...(checkToken ? getAuthHeaderWithToken() : checkReportToken ? getAuthHeaderWithToken(true) : {}),
								...headers,
								...customHeaders
							},
							auth,
							params,
							cancelToken: cancelToken
						})

						break

					case 'POST':
						response = await this.service.post(url, data, {
							...config,
							headers: {
								...(checkToken ? getAuthHeaderWithToken() : checkReportToken ? getAuthHeaderWithToken(true) : {}),
								...headers,
								...customHeaders
							},
							auth,
							cancelToken: cancelToken,
							params
						})

						break

					case 'PUT':
						response = await this.service.put(url, data, {
							...config,
							headers: {
								...(checkToken ? getAuthHeaderWithToken() : checkReportToken ? getAuthHeaderWithToken(true) : {}),
								...headers,
								...customHeaders
							},
							auth,
							cancelToken: cancelToken,
							params
						})

						break

					case 'PATCH':
						response = await this.service.patch(url, data, {
							...config,
							headers: {
								...(checkToken ? getAuthHeaderWithToken() : checkReportToken ? getAuthHeaderWithToken(true) : {}),
								...headers,
								...customHeaders
							},
							auth,
							cancelToken: cancelToken,
							params
						})

						break
					case 'DELETE':
						response = await this.service.delete(url, {
							...config,
							data,
							headers: {
								...(checkToken ? getAuthHeaderWithToken() : checkReportToken ? getAuthHeaderWithToken(true) : {}),
								...headers,
								...customHeaders
							},
							auth,
							params,
							cancelToken: cancelToken
						})

						break
				}

				if (response) {
					onSuccess(response.data)

					if (url.includes('/search')) {
						if (!window.res_length) {
							window.res_length = []
						}

						window.res_length.push(JSON.stringify(response.data || {}).length)
					}

					return response.data
				}
			} catch (error) {
				console.warn(`An error occured while requesting a url : ${url} \n Error: ${JSON.stringify(error)}`)

				if (axios.isCancel(error)) {
					onError({
						isCancel: true
					})
				} else if (error.response) {
					// Request made and server responded

					if (
						store &&
						store.getState().auth &&
						store.getState().auth.token &&
						store.getState().auth.token.info &&
						error.response &&
						error.response.status
					) {
						if (error.response.status === 401) {
							store.dispatch(logoutUser())
						}
					}

					if (String(error.response.data?.code) === '1000') {
						if (typeof error.response.data?.detail === 'string') {
							enqueueSnackbar(error.response.data?.detail, {
								variant: 'error'
							})
						} else {
							console.log(
								`
                                    =========== API RESPONSE ERROR ===========
                                    URL: ${url}
                                    METHOD: ${method}
                                    =========== API RESPONSE ERROR ===========
                                `,
								{ body: data, response: error.response.data }
							)
						}
					}

					onError(error.response || {})

					return { error: true, ...error.response }
				} else if (error.request) {
					// The request was made but no response was received
					onError(error.request || {})
				} else {
					// Something happened in setting up the request that triggered an Error
					onError(error.message || {})
				}
			}
		}
	// TODO: This is poor approach need to fix
	const getAuthHeaderWithToken = (reportToken = false) => {
		if (!!Authorization) {
			return {
				Authorization
			}
		} else if (reportToken) {
			if (
				store &&
				store.getState().auth &&
				(store.getState().auth.reportToken || (store.getState().auth.admin && store.getState().auth.admin.jwt))
			) {
				return {
					Authorization: `Bearer ${store.getState().auth.reportToken || store.getState().auth.admin.jwt}`
				}
			}
		} else if (store && store.getState().auth && store.getState().auth.token?.info) {
			return {
				Authorization: `Bearer ${store.getState().auth.token?.info}`
			}
		}
	}

	this.getCancelInstance = () => {
		return axios.CancelToken.source()
	}

	this.getAxiosInstance = () => {
		return this.service
	}

	this.getBaseUrl = () => {
		return this.baseUrl
	}

	this.get = (url, options = {}) => {
		const cancelApi = this.getCancelInstance()

		return {
			api: makeRequest('GET', url, {
				cancelToken: cancelApi.token,
				...options
			}),
			cancel: cancelApi.cancel
		}
	}

	this.post = (url, options = {}) => {
		const cancelApi = this.getCancelInstance()

		return {
			api: makeRequest('POST', url, {
				cancelToken: cancelApi.token,
				...options
			}),
			cancel: cancelApi.cancel
		}
	}

	this.put = (url, options = {}) => {
		const cancelApi = this.getCancelInstance()

		return {
			api: makeRequest('PUT', url, {
				cancelToken: cancelApi.token,
				...options
			}),
			cancel: cancelApi.cancel
		}
	}

	this.patch = (url, options = {}) => {
		const cancelApi = this.getCancelInstance()

		return {
			api: makeRequest('PATCH', url, {
				cancelToken: cancelApi.token,
				...options
			}),
			cancel: cancelApi.cancel
		}
	}

	this.delete = (url, options = {}) => {
		const cancelApi = this.getCancelInstance()

		return {
			api: makeRequest('DELETE', url, {
				cancelToken: cancelApi.token,
				...options
			}),
			cancel: cancelApi.cancel
		}
	}
}

export default ApiService
