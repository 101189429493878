import Button from '@commons/Button/Button'
import AppTabs from '@commons/Tabs/AppTabs'
import TabPanel from '@commons/Tabs/TabPanel'
import ComplianceDashboard from '@layouts/Compliance/ComplianceDashboard'
import ComplianceDocuments from '@layouts/Compliance/ComplianceDocuments'
import ComplianceControls from '@layouts/Compliance/Controls/ComplianceControls'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { Box, Grid, IconButton } from '@mui/material'
import { tabChangeTrigger } from '@redux/users/slice'
import { downloadAllCompliance, getFrameworksList } from '@services/complianceService'
import { replaceByRegex } from '@utils/other'
import { enqueueSnackbar } from 'notistack'
import queryString from 'query-string'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useDispatch } from 'react-redux'
import { createSearchParams, useNavigate } from 'react-router-dom'

const ComplianceScreen = ({ complianceType = '' }) => {
	const [tabs] = useState([
		{
			key: 0,
			label: 'Dashboard'
		},
		{
			key: 1,
			label: 'Controls'
		},
		{
			key: 2,
			label: 'Policies'
		}
	])
	const { tab, subcontrol_ref_code, subTab } = useMemo(() => queryString.parseUrl(window.location.href).query, [window.location.href])
	const navigate = useNavigate()
	const pathName = window.location.pathname.slice(12)
	const [excelData, setExcelData] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isDownloadReady, setIsDownloadReady] = useState(false)
	const csvLinkRef = useRef(null)
	const [complianceFrameworks, setComplianceFrameworks] = useState({})
	const activeTabIndex = typeof tab === 'undefined' ? 0 : Number(tab)
	const activeSubTabIndex = typeof subTab === 'undefined' ? 0 : Number(subTab)
	const dispatch = useDispatch()

	const fetchFramework = () => {
		const req = getFrameworksList()
		setIsLoading(true)
		req.api({}).then((response) => {
			if (response) {
				setIsLoading(false)
				if (!response.error && Array.isArray(response.results.data) && response.results.data?.length > 0) {
					console.log('Response for setComplianceFrameworks', response.results.data)
					setComplianceFrameworks(
						response.results.data.reduce((obj, item) => {
							obj[replaceByRegex(item.key.toLowerCase(), '-', / /g)] = item
							return obj
						}, {})
					)
				}
			}
		})

		return req
	}

	useEffect(() => {
		const request = fetchFramework()

		return () => {
			request.cancel()
		}
	}, [])

	const frameWorkId = useMemo(() => {
		return complianceFrameworks[complianceType]?.id
	}, [complianceFrameworks[complianceType]?.id])

	const handleTabChange = (e, index) => {
		if (index !== activeTabIndex) {
			dispatch(tabChangeTrigger(index))
			navigate({
				pathname: window.location.pathname,
				search: createSearchParams({
					tab: index
				}).toString()
			})
		}
	}

	const headers = [
		{
			label: 'Control Id',
			key: 'ref_code'
		},
		{
			label: 'Name',
			key: 'category'
		},
		{ label: 'Description', key: 'description' },
		{ label: 'Status', key: 'status' },
		{ label: 'Documents', key: 'documents' }
	]

	const flattenData = (data) => {
		let status = ''
		let documents = ''

		if (data.document && data.document.length > 0) {
			status = data.document[0].status || ''
			if (data.document[0].documents && data.document[0].documents.length > 0) {
				documents = data.document[0].documents.join('\n')
			}
		}

		return {
			ref_code: data.ref_code,
			category: data.category,
			description: data.description,
			status: status,
			documents: documents
		}
	}

	const handleDownloadClick = () => {
		console.log('toggle download modal', frameWorkId)
		setIsLoading(true)
		const downloadComplianceAPI = downloadAllCompliance()
		downloadComplianceAPI
			.api({
				params: {
					framework_id: frameWorkId
				}
			})
			.then((response) => {
				if (response && response.results.data.length > 0) {
					const csvData = response.results.data.map((item) => flattenData(item))
					setIsDownloadReady(true) // Enable download
					setExcelData(csvData)
					enqueueSnackbar(
						`Document Download initiated Successfully!! - It might take somewhere between 1 minute - 10 minutes to download the file so please don't shut down the window`,
						{ variant: 'success' }
					)
				} else {
					enqueueSnackbar('No data available for download', { variant: 'info' })
				}
			})
			.catch((error) => {
				enqueueSnackbar('Failed to fetch data for CSV', { variant: 'error' })
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	useEffect(() => {
		if (isDownloadReady && csvLinkRef.current) {
			csvLinkRef.current.link.click()
			setIsDownloadReady(false) // Reset download readiness
		}
	}, [isDownloadReady])

	return (
		<>
			<Grid>
				<Box display='flex' justifyContent='flex-end' mb={-3}>
					<Button variant='contained' onClick={handleDownloadClick}>
						<CloudDownloadIcon style={{ marginRight: '5px' }} />
						<Box mr={1}></Box>
						{isLoading ? 'Preparing Download...' : 'Generate Report '}
					</Button>
				</Box>
			</Grid>
			{isDownloadReady && (
				<CSVLink
					data={excelData}
					filename={pathName.toUpperCase().concat('.csv')}
					headers={headers}
					ref={csvLinkRef}
					style={{ display: 'none' }}
				/>
			)}
			{/* <AppTabs activeTabIndex={activeTabIndex} tabs={tabs} onTabChange={handleTabChange} /> */}
			<Box display={'flex'}>
				<Box>
					<IconButton
						onClick={(e) => {
							e.stopPropagation()
							navigate(`/compliance/overview`)
						}}
					>
						<ArrowBackIosIcon fontSize='medium' />
					</IconButton>
				</Box>
				<AppTabs activeTabIndex={activeTabIndex} tabs={tabs} onTabChange={handleTabChange} />
			</Box>
			<TabPanel index={activeTabIndex} value={0}>
				<ComplianceDashboard activeTabIndex={activeTabIndex} complianceType={complianceType} />
			</TabPanel>
			<TabPanel index={activeTabIndex} value={1}>
				<ComplianceControls complianceType={complianceType} subcontrol_ref_code={subcontrol_ref_code} />
			</TabPanel>
			<TabPanel index={activeTabIndex} value={2}>
				<ComplianceDocuments
					activeTabIndex={activeTabIndex}
					activeSubTabIndex={activeSubTabIndex}
					complianceType={complianceType}
				/>
			</TabPanel>
		</>
	)
}

export default ComplianceScreen
