import { createSlice } from '@reduxjs/toolkit'

const initialState = null

export const refreshSlice = createSlice({
	name: 'refresh',
	initialState,
	reducers: {
		refreshToken: () => new Date().getTime(),
		resetToken: () => initialState
	}
})

// Action creators are generated for each case reducer function
export const { refreshToken, resetToken } = refreshSlice.actions

export const refreshReducer = refreshSlice.reducer
