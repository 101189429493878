/** @jsxImportSource @emotion/react */
import Button from '@commons/Button/Button'
import Text from '@commons/Text'
import { css } from '@emotion/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { memo } from 'react'

// eslint-disable-next-line react/display-name
const AppTable = memo(({ children, showLoadMoreButton = false, fetchMore, isEmpty, colSpan, loading = false }) => {
	return (
		<table
			// eslint-disable-next-line react/no-unknown-property
			css={css({
				borderCollapse: 'separate',
				borderSpacing: '0',
				width: '100%',
				tableLayout: 'fixed'
			})}
		>
			{children}
			{showLoadMoreButton && !isEmpty && fetchMore && !loading && (
				<tr>
					<td colSpan={colSpan}>
						<Button fullWidth variant='text' onClick={fetchMore}>
							<Text fontWeight={500} ascent='primary' fontFamily='Open Sans'>
								load more
							</Text>
							<RefreshIcon sx={{ fontSize: 18, ml: 0.5 }} />
						</Button>
					</td>
				</tr>
			)}
		</table>
	)
})

export default AppTable
