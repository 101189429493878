import { Box } from '@mui/material'
import Button from './Button'

const ModalActionButton = ({
	ctaHandler = () => {},
	cancelHandler = () => {},
	cancelButtonTitle = 'Cancel',
	ctaButtonTitle = 'Save',
	isLoading,
	hideCancelBtn = false,
	...props
}) => {
	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px', marginTop: '0.5rem' }}>
				{!hideCancelBtn && (
					<Button onClick={cancelHandler} ascent='lightWhite' colorAscent='black' fontWeight='600'>
						{cancelButtonTitle}
					</Button>
				)}
				<Button onClick={ctaHandler} type='submit' isLoading={isLoading} disabled={isLoading} fontWeight='600' {...props}>
					{ctaButtonTitle}
				</Button>
			</Box>
		</>
	)
}

export default ModalActionButton
