import { riskValueByStatus, severityByStatus } from '@constants/status'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { Box, Divider, Tooltip } from '@mui/material'
import { SvgIcons } from '@utils/icons'
import { fadeColor } from '@utils/styles'
import React from 'react'
import Text from './Text'

const DisplayRiskFactor = ({ riskValue, checkForSeverity = false, count = null }) => {
	const [risk, setRisk] = React.useState({
		type: '',
		reasons: [],
		ascent: '',
		icon: null,
		tooltipTitle: '',
		tooltipSubTitle: ''
	})

	React.useEffect(() => {
		if (checkForSeverity ? riskValue === severityByStatus.UnKnown : riskValue <= riskValueByStatus.noData) {
			setRisk({
				ascent: 'grey',
				icon: ScheduleIcon,
				reasons: [],
				type: 'N.A',
				tooltipTitle: 'No data have been found!',
				tooltipSubTitle: 'We cannot find any data for this system.'
			})
		} else if (checkForSeverity ? riskValue === severityByStatus.Info : riskValue <= riskValueByStatus.stable) {
			setRisk({
				ascent: 'faintBlue',
				icon: SvgIcons.CheckSVG,
				reasons: [],
				type: checkForSeverity ? 'Info' : 'Stable',
				tooltipTitle: 'This system is stable!',
				tooltipSubTitle: 'Mi feugiat hac malesuada facilisi. Imperdiet arcu nam sed elementum vel.'
			})
		} else if (checkForSeverity ? riskValue === severityByStatus.Low : riskValue <= riskValueByStatus.stable) {
			setRisk({
				ascent: 'green',
				icon: SvgIcons.CheckSVG,
				reasons: [],
				type: checkForSeverity ? 'Low' : 'Stable',
				tooltipTitle: 'This system is stable!',
				tooltipSubTitle: 'Mi feugiat hac malesuada facilisi. Imperdiet arcu nam sed elementum vel.'
			})
		} else if (checkForSeverity ? riskValue === severityByStatus.Medium : riskValue <= riskValueByStatus.medium) {
			setRisk({
				ascent: 'amber',
				icon: SvgIcons.WarningSVG,
				reasons: ['Etiam hendrerit quam ac libero pulvinar faucibus.', 'Quisque faucibus tristique libero quis efficitur.'],
				type: 'Medium',
				tooltipTitle: 'This system is getting risky!',
				tooltipSubTitle: 'Mi feugiat hac malesuada facilisi. Imperdiet arcu nam sed elementum vel.'
			})
		} else if (checkForSeverity ? riskValue === severityByStatus.High : riskValue <= riskValueByStatus.high) {
			setRisk({
				ascent: 'lightRed',
				icon: SvgIcons.WarningSVG,
				reasons: ['Etiam hendrerit quam ac libero pulvinar faucibus.', 'Quisque faucibus tristique libero quis efficitur.'],
				type: 'High',
				tooltipTitle: 'This system is getting very risky!',
				tooltipSubTitle: 'Mi feugiat hac malesuada facilisi. Imperdiet arcu nam sed elementum vel.'
			})
		} else if (checkForSeverity ? riskValue === severityByStatus.Critical : riskValue <= riskValueByStatus.critical) {
			setRisk({
				ascent: 'lightRed',
				icon: SvgIcons.WarningSVG,
				reasons: ['Etiam hendrerit quam ac libero pulvinar faucibus.', 'Quisque faucibus tristique libero quis efficitur.'],
				type: 'Critical',
				tooltipTitle: 'This system is getting very risky!',
				tooltipSubTitle: 'Mi feugiat hac malesuada facilisi. Imperdiet arcu nam sed elementum vel.'
			})
		}
		//  else {
		// 	setRisk({
		// 		ascent: 'red',
		// 		icon: SvgIcons.CancelSVG,
		// 		reasons: [
		// 			'Etiam hendrerit quam ac libero pulvinar faucibus.',
		// 			'Quisque faucibus tristique libero quis efficitur.',
		// 			'Sed lorem mi, porta in magna sit amet, tincidunt fringilla neque.'
		// 		],
		// 		type: 'Critical',
		// 		tooltipTitle: 'This system is now critically risky!',
		// 		tooltipSubTitle: 'Mi feugiat hac malesuada facilisi. Imperdiet arcu nam sed elementum vel.'
		// 	})
		// }
		else {
			setRisk({
				ascent: 'grey',
				icon: ScheduleIcon,
				reasons: [],
				type: 'N.A',
				tooltipTitle: 'No data have been found!',
				tooltipSubTitle: 'We cannot find any data for this system.'
			})
		}
	}, [riskValue])

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',

				'& .MuiTooltip-tooltip': {
					borderColor: (theme) => `${theme.palette.ascents[risk.ascent]} !important`,
					boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.25) !important'
				}
			}}
		>
			{!!risk.type && (
				<Tooltip
					open={false}
					placement='right-end'
					title={
						<Box
							sx={{
								position: 'relative',
								padding: '2.5rem 1rem 0 1rem',
								// border: ({ ascent }) => `1px solid ${ascent}`,

								'& .iconBadge': {
									background: (theme) => theme.palette.ascents[risk.ascent],
									width: 48,
									height: 48,
									borderRadius: 100,
									position: 'absolute',
									top: -28,
									left: '50%',
									transform: 'translateX(-50%)',
									overflow: 'hidden',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',

									'& svg': {
										color: (theme) => theme.palette.ascents.white,
										width: 22,
										height: 22
									}
								},

								'& .tooltipTitle': {
									fontSize: 16,
									textAlign: 'center',
									fontWeight: 600,
									color: (theme) => theme.palette.ascents[risk.ascent],
									fontFamily: '"Open Sans"',
									marginBottom: 8
								},
								'& .tooltipSubTitle': {
									textAlign: 'center',
									fontSize: 12,
									marginBottom: 8
								}
							}}
						>
							<Box className='iconBadge'>
								<risk.icon />
							</Box>
							<Text className='tooltipTitle'>{risk.tooltipTitle}</Text>
							<Text className='tooltipSubTitle'>{risk.tooltipSubTitle}</Text>
							{risk.reasons.length > 0 && (
								<>
									<Divider />
									<Text fontWeight={600} ascent='base' margin='1rem 0' fontFamily='Open Sans'>
										Why this system is risky?
									</Text>
									{risk.reasons.map((reason, index) => (
										<Text margin='0 0 1rem 0' fontFamily='Open Sans' fontSize={12} key={reason}>
											{index + 1}. {reason}
										</Text>
									))}
								</>
							)}
						</Box>
					}
				>
					<Box
						sx={{
							background: (theme) => fadeColor(theme.palette.ascents[risk.ascent], 0.9),
							padding: '0.5rem 0.75rem',
							borderRadius: '8px',
							display: 'flex',
							justifyContent: 'center',
							color: (theme) => theme.palette.ascents[risk.ascent],
							alignItems: 'center',

							'& svg': {
								width: 16,
								height: 16
							},
							gap: '6px'
						}}
					>
						<risk.icon />
						<Text margin='0 0 0 0.5rem' ascent={risk.ascent} fontSize={`${'12px'} !important`} fontWeight={`600 !important`}>
							{risk.type}
						</Text>
						{count != null && <Text ascent={risk.ascent} fontSize={`${'12px'} !important`} fontWeight={`600 !important`} className='tooltipTitle'>{count}</Text>}
					</Box>
				</Tooltip>
			)}
		</Box>
	)
}

export default DisplayRiskFactor
