import { customEvents } from '@constants/analytics'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { logGAEvent } from '@utils/other'
import { useMemo } from 'react'

const AppTabs = ({
	tabs = [],
	ascent = 'base',
	activeTabIndex,
	customChips = false,
	onTabChange,
	useIndexBasedToggle = false,
	tabProps,
	tabStyle,
	contained,
	linkToObject,
	...props
}) => {
	const tabChangeHandler = (e, newValue) => {
		if (activeTabIndex !== newValue) {
			logGAEvent(customEvents.TAB_SWITCHED, {
				event_label: `tab switched`,
				switchedFrom: tabs[newValue]?.label,
				switchedTo: tabs[activeTabIndex]?.label
			})
		}
		if (typeof onTabChange === 'function') {
			onTabChange(e, newValue)
		}
	}

	const EachTab = (each, index) => (
		<Tab
			key={each.key || index}
			label={customChips ? each.label + " ( " + each.total + " ) " : each.label}
			value={!useIndexBasedToggle ? each.key || index : index}
			to={{
				pathname: each.key,
				...linkToObject
			}}
			{...tabProps}
			style={tabStyle}
		/>
	)

	const styles = useMemo(() => {
		return contained
			? {
				marginBottom: (theme) => `${theme.spacing(2)} !important`,
				minHeight: 'inherit !important',
				'& .MuiTabs-indicator': {
					display: 'none'
				},

				'& .MuiTab-root': {
					// flex: "1 1 auto",

					textTransform: 'uppercase',
					border: (theme) => `1px solid ${theme.palette.secondary.main}`,
					minHeight: 'inherit !important',

					fontSize: '0.8125rem',

					fontWeight: '400',
					padding: '12px 24px !important',
					minWidth: '20%',
					// zIndex: '1 !important',

					'&:first-child': {
						borderTopLeftRadius: '0.5rem',
						borderBottomLeftRadius: '0.5rem'
					},
					'&:last-child': {
						borderTopRightRadius: '0.5rem',
						borderBottomRightRadius: '0.5rem'
					}
				},
				'& .Mui-selected': {
					backgroundColor: (theme) => `${theme.palette.secondary.main} !important`,
					color: (theme) => `${theme.palette.text.systemWhite} !important`
				}
			}
			: {
				marginBottom: (theme) => `${theme.spacing(2)} !important`,
				minHeight: 'inherit !important',

				'& .MuiTab-root': {
					minHeight: 'inherit !important',
					padding: '12px 24px !important'
					// zIndex: '1 !important'
				},

				'& .MuiTabs-flexContainer': {
					color: (theme) => ` ${theme.palette.ascents[ascent]} !important`
				},
				'& .MuiTabScrollButton-root': {
					'& .MuiSvgIcon-root': {
						color: (theme) => `${theme.palette.ascents.primary} !important`
					}
				},

				'& .Mui-selected': {
					color: `inherit !important`
				},

				'& .MuiTabs-indicator': {
					backgroundColor: (theme) => `${theme.palette.secondary.main} !important`
				}
			}
	}, [ascent, contained])

	return (
		<Tabs
			value={activeTabIndex}
			onChange={tabChangeHandler}
			// className={clsx(
			//     classes.root,
			//     {
			//         [classes.contained]: contained,
			//     },
			//     tabsClasses
			// )}
			variant='scrollable'
			scrollButtons='auto'
			sx={styles}
			{...props}
		>
			{tabs.map(EachTab)}
		</Tabs>
	)
}

AppTabs.propTypes = {}

export default AppTabs
