import { Checkbox, FormControlLabel } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

const CheckboxInput = ({ isChecked = true, name, label = '', readOnly = false, ...props }) => {
	return (
		<FormControlLabel
			control={<Checkbox checked={isChecked} name={name} color='primary' value={isChecked ? 1 : 0} readOnly={readOnly} {...props} />}
			label={label}
			{...props}
		/>
	)
}

CheckboxInput.propTypes = {
	isChecked: PropTypes.bool,
	name: PropTypes.string
}

export default React.memo(CheckboxInput)
