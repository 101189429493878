import { customEvents } from '@constants/analytics'
import * as Sentry from '@sentry/react'
import { logGAEvent } from '@utils/other'
import { useState } from 'react'
import ErrorBoundaryFallback from './ErrorBoundaryFallback'
import MissingChunkErrorFallback from './MissingChunkErrorFallback'

const AppErrorBoundary = ({ children }) => {
	const [errorRegex] = useState({
		dynamicImportError: /Failed to fetch dynamically imported module:/
	})

	return (
		<Sentry.ErrorBoundary
			FallbackComponent={({ error }) =>
				errorRegex.dynamicImportError.test(error.message) ? <MissingChunkErrorFallback /> : <ErrorBoundaryFallback />
			}
			onError={(error) => {
				console.log('--error--', error, { error })
				if (errorRegex.dynamicImportError.test(error.message)) {
					window.location.reload(true)
				}

				logGAEvent(customEvents.PAGE_CRASHED, {
					event_label: `${error.name} at ${Date.now()}`
				})
			}}
		>
			{children}
		</Sentry.ErrorBoundary>
	)
}

export default AppErrorBoundary
