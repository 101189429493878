import AutoRowDetails from '@commons/Table/AutoRowDetails'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import AppModal from '../Modal/AppModal'
import Table from '../Table/Table'
import AppTabs from '../Tabs/AppTabs'
import TabPanel from '../Tabs/TabPanel'

const CustomChartModalWithTabs = ({
	open,
	Chart,
	onClose,
	title = '',
	tableData,
	columns,
	disableModalTableRowDetails = false,
	hiddenKeysForRowDetails,
	renderRowDetailsHeading,
	useAggregationsData,
	CustomModalTable,
	...props
}) => {
	const [activeTabIndex, setActiveTabIndex] = React.useState(0)
	// const [rowDetails, setRowDetails] = useState({})
	const [openDrawer, setOpenDrawer] = useState(false)

	// const onRowClickCustomHandler = (row) => () => {
	// 	setRowDetails(row)
	// 	setOpenDrawer(true)
	// }

	const closeRowDetailsDrawer = () => {
		setOpenDrawer(false)
	}

	const { current: tabs } = React.useRef([
		{
			label: 'Chart',
			key: 0
		},
		{
			label: 'Tabular Data',
			key: 1
		}
	])

	const handleTabChange = (e, newValue) => {
		if (activeTabIndex !== newValue) {
			// logGAEvent((
			//     customEvents.GRAPH_SWITCHED_TABS
			// ), {
			//     event_label: title,
			//     title,
			//     switchedFrom: (tabs[newValue]).label,
			//     switchedTo: (tabs[activeTabIndex]).label
			// })

			setActiveTabIndex(newValue)
		}
	}

	return (
		<>
			<AppModal onClose={onClose} open={open} title={title.toUpperCase()} showOverflowContent {...props}>
				{
					// show tabs only when table column data is passed
					columns || CustomModalTable ? (
						<>
							<AppTabs tabs={tabs} activeTabIndex={activeTabIndex} onTabChange={handleTabChange} />

							<TabPanel index={activeTabIndex} value={0}>
								{Chart}
							</TabPanel>

							<TabPanel index={activeTabIndex} value={1}>
								{CustomModalTable ? (
									CustomModalTable
								) : (
									<>
										<Table
											disableFilter
											disableSearchBy
											cardProps={{
												hideShadow: true
											}}
											columns={columns}
											showActionColumn
											data={tableData}
											totalSize={tableData.length}
											// enableRowClick={!disableModalTableRowDetails}
											// onRowClickCustomHandler={!disableModalTableRowDetails ? onRowClickCustomHandler : undefined}
										/>
										{!disableModalTableRowDetails && (
											<AutoRowDetails
												// rowDetails={rowDetails}
												handleClose={closeRowDetailsDrawer}
												openDrawer={openDrawer}
												hiddenKeysForRowDetails={hiddenKeysForRowDetails}
												renderRowDetailsHeading={renderRowDetailsHeading}
												useAggregationsData={useAggregationsData}
											/>
										)}
									</>
								)}
							</TabPanel>
						</>
					) : (
						// else show the chart only! (No tabs)
						Chart
					)
				}
			</AppModal>
		</>
	)
}

CustomChartModalWithTabs.propTypes = {
	Chart: PropTypes.node.isRequired,
	elasticQuery: PropTypes.object.isRequired,
	aspectRatio: PropTypes.number,
	columns: PropTypes.array.isRequired
}

export default CustomChartModalWithTabs
