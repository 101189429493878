import { scheduleTypes } from '@constants/formInputTypes'
import cronTime from 'cron-time-generator'

export const jsonToFormData = (json = {}) => {
	let formData = new FormData()

	Object.entries(json).forEach(([key, value]) => {
		formData.append(key, value)
	})

	return formData
}

export const getCronFunctionBySchduleType = (type) => {
	switch (type) {
		case scheduleTypes.INTERVAL:
			return null
		case scheduleTypes.MONTHLY:
			return cronTime.everyMonthOn
		case scheduleTypes.WEEKLY:
			return cronTime.onSpecificDaysAt
		default:
			return null
	}
}

export const validateInterval = (value, unit) => {
	switch (unit.toUpperCase()) {
		case 'MINUTES':
			return value > 0 && value <= 59
		case 'HOURS':
			// Can give ny number of hours
			return value > 0
		case 'SECONDS':
			return value > 0 && value <= 59
		default:
			return false
	}
}
