import { Box } from '@mui/material'
import { memo } from 'react'

// eslint-disable-next-line react/display-name
const TableRow = memo(
	({ children, className, clickable = false, hoverBgAscent = 'washLight', hoverBgColor, bgAscent, bgColor, ...props }) => {
		return (
			<Box
				component='tr'
				sx={{
					overflow: 'hidden',
					backgroundColor: (theme) => (bgColor ? bgColor : theme.palette.ascents[bgAscent]),

					cursor: clickable ? 'pointer' : undefined,
					'&:hover': {
						backgroundColor: (theme) =>
							clickable ? (hoverBgColor ? hoverBgColor : theme.palette.ascents[hoverBgAscent]) : undefined
					}
				}}
				className={className}
				{...props}
			>
				{children}
			</Box>
		)
	}
)

export default TableRow
