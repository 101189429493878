import { createAsyncThunk } from '@reduxjs/toolkit'
import { getRoles } from '@services/masterDataService'

export const getRoleLists = createAsyncThunk('roles/getRoleLists', async (_, thunkApi) => {
	const { rejectWithValue } = thunkApi
	try {
		const response = await getRoles().api()

		if (response) {
			if (!response.error) {
				const allIds = response.results.data.map((item) => item.id)
				const byId = response.results.data.reduce((accu, item) => {
					const val = item
					const valId = item.id

					return { ...accu, [valId]: val }
				}, {})
				return { allIds: allIds, byId: byId }
			}
		}
	} catch (error) {
		return rejectWithValue(error)
	}
})
