import { AUTOCOMPLETE, CHECKBOX, IMAGE_FILE, SELECT, TEXT, TYPOGRAPHY, VIDEO_FILE } from '@constants/formInputTypes'
import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import {
	AutoCompleteController,
	CheckboxController,
	FileController,
	SelectController,
	TextController,
	TypographyController,
	VideoController
} from './GenerateFormHookControllers'

const GenerateFormHook = ({ children, formConstant = [], eachWidth = 4, gridContainerClassName, gridContainerProps = {}, control }) => {
	const { formHookContext } = useFormContext()

	const renderForm = () => {
		return formConstant.map((formObj) => {
			switch (formObj.fieldType) {
				case TEXT:
					return (
						<TextController
							key={formObj.key}
							formObj={formObj}
							control={control}
							eachWidth={eachWidth}
							errors={formHookContext?.errors}
						/>
					)
				case SELECT:
					return (
						<SelectController
							key={formObj.key}
							formObj={formObj}
							control={control}
							eachWidth={eachWidth}
							errors={formHookContext?.errors}
						/>
					)

				case VIDEO_FILE:
					return (
						<VideoController
							key={formObj.key}
							formObj={formObj}
							control={control}
							eachWidth={eachWidth}
							fileType={VIDEO_FILE}
							collapsible={{
								wrapInCollapsible: false,
								collapsibleIsOpen: false
							}}
							previewUrl={formObj.previewUrl}
						/>
					)

				case IMAGE_FILE:
					return (
						<FileController
							key={formObj.key}
							formObj={formObj}
							control={control}
							eachWidth={eachWidth}
							fileType={IMAGE_FILE}
							collapsible={{
								wrapInCollapsible: false,
								collapsibleIsOpen: false
							}}
							previewUrl={formObj.previewUrl}
						/>
					)

				case CHECKBOX:
					return <CheckboxController key={formObj.key} formObj={formObj} control={control} eachWidth={eachWidth} />
				case TYPOGRAPHY:
					return <TypographyController key={formObj.key} as={formObj.as} label={formObj.label} eachWidth={formObj.eachWidth} />

				case AUTOCOMPLETE:
					return (
						<AutoCompleteController
							key={formObj.key}
							control={control}
							formObj={formObj}
							eachWidth={eachWidth}
							errors={formHookContext?.errors}
						/>
					)

				default:
					return null
			}
		})
	}

	return (
		<Grid container className={gridContainerClassName} {...gridContainerProps}>
			{renderForm()}
			{children && (
				<Grid
					sx={{
						padding: (theme) => theme.spacing(1.5),
						textAlign: 'left'
					}}
					item
					xs={12}
				>
					{children}
				</Grid>
			)}
		</Grid>
	)
}

export default GenerateFormHook
