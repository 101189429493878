import adminRoutesConstant from '@constants/adminRoutesConstant'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const RedirectRoute = ({ routeKey }) => {
	console.log('🚀 ~ file: RedirectRoute.js:12 ~ routeKey:', routeKey)
	const navigate = useNavigate()

	React.useEffect(() => {
		const to = adminRoutesConstant.adminPaths[routeKey] && adminRoutesConstant.adminPaths[routeKey].url()

		if (!to) {
			console.warn(`Cannot found path '${to}' for route name ${routeKey}`)
		}

		navigate(to)
	}, [routeKey])

	return null

	// return (
	//     <CompatRoute
	//         {...props}
	//         render={(props) => (
	//             <Navigate
	//                 replace
	//                 to={{
	//                     pathname: adminRoutesConstant.adminPaths[routeKey] && adminRoutesConstant.adminPaths[routeKey].url(),
	//                     state: { from: from ? from : props.location, ...state },
	//                 }}
	//             />
	//         )}
	//     />
	// );
}

export default RedirectRoute
