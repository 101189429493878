import { AxiosService, BuyZeronApi } from '@interceptors/axiosInstance'

export const getAllPlans = (config) => {
	return AxiosService.get('/rest/v1/plans/', config)
}

export const getAllPlansV2 = (config) => {
	return AxiosService.get('/payment/v1/plans/', config)
}
// TODO: This is temporary. REMOVE when done
export const getAllPlansV3 = (config) => {
	return BuyZeronApi.post('/api/Item/getAll?limit=5', config)
}

// TODO: This is temporary. REMOVE when done
export const getPlansPricesByPlanId = (config) => {
	return BuyZeronApi.post('/api/ItemPrice/getByItemId', config)
}

export const getAllPolicies = (config) => {
	return AxiosService.get('/rest/v1/policy/', config)
}

export const getRoles = (config) => {
	return AxiosService.get('/rest/v1/roles/', config)
}

export const createRoles = (config) => {
	return AxiosService.post('/rest/v1/roles/', config)
}

export const editRoles = (config, id) => {
	return AxiosService.put(`/rest/v1/roles/${id}/`, config)
}

export const removeRoles = (config, id) => {
	return AxiosService.delete(`/rest/v1/roles/${id}/`, config)
}

export const getMasterData = (config) => {
	return AxiosService.get('/rest/v1/masterdata/', config)
}
