import SearchIcon from '@mui/icons-material/Search'
import { Box, IconButton } from '@mui/material'
import { TextController } from '@commons/GenerateFormHookControllers'
import withFormProvider from '@commons/HOCs/FormProviderHOC'
import Icon from '@commons/Icon'
import AppModal from '@commons/Modal/AppModal'
import OpenSearchDataTable from '@commons/Table/OpenSearchDataTable'
import { customEvents } from '@constants/analytics'
import { allSignalsHeadCells } from '@constants/headcells/hostsHeadCells'
import mixpanel from 'mixpanel-browser'
import moment from 'moment/moment'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

const SearchBox = () => {
	const [searchString, setSearchString] = React.useState('')
	const reloadToken = useSelector((state) => state.reloadToken)
	const { control, handleSubmit, getValues, reset } = useFormContext()
	const [open, setOpen] = React.useState(false)
	const dateFilter = moment()

	const elasticQuery = React.useMemo(() => {
		return {
			script_fields: {},
			docvalue_fields: [
				{
					field: 'data.aws.createdAt',
					format: 'date_time'
				},
				{
					field: 'data.aws.end',
					format: 'date_time'
				},
				{
					field: 'data.aws.resource.instanceDetails.launchTime',
					format: 'date_time'
				},
				{
					field: 'data.aws.service.eventFirstSeen',
					format: 'date_time'
				},
				{
					field: 'data.aws.service.eventLastSeen',
					format: 'date_time'
				},
				{
					field: 'data.aws.start',
					format: 'date_time'
				},
				{
					field: 'data.aws.updatedAt',
					format: 'date_time'
				},
				{
					field: 'data.timestamp',
					format: 'date_time'
				},
				{
					field: 'data.vulnerability.published',
					format: 'date_time'
				},
				{
					field: 'data.vulnerability.updated',
					format: 'date_time'
				},
				{
					field: 'syscheck.mtime_after',
					format: 'date_time'
				},
				{
					field: 'syscheck.mtime_before',
					format: 'date_time'
				},
				{
					field: 'timestamp',
					format: 'date_time'
				}
			],
			query: {
				bool: {
					must: [
						{
							query_string: {
								query: `${searchString}*`,
								analyze_wildcard: true
							}
						},
						{
							range: {
								timestamp: {
									lte: dateFilter.toISOString(),
									gte: dateFilter.subtract(6, 'months').toISOString()
								}
							}
						}
					]
				}
			},
			from: 0,
			size: 10,
			sort: []
		}
	}, [searchString])

	const findInPage = (data) => {
		if (parseInt(navigator.appVersion) < 4) return

		const str = data && data.mainSearch ? data.mainSearch : getValues('mainSearch')

		if (str) {
			setSearchString(str)
			setOpen(true)
			mixpanel.track(customEvents.GLOBAL_SEARCH, {
				query: str
			})
		}
	}

	React.useEffect(() => {
		if (reloadToken) {
			reset()
		}
	}, [reloadToken])

	return (
		<>
			<Box
				component='form'
				sx={{
					flex: '1 1 auto',
					width: '100%',
					marginRight: '1rem',

					'& .MuiFormControl-root': {
						margin: 0,
						borderRadius: '0.5rem',
						background: (theme) => theme.palette.ascents.washLight,
						padding: '0.4375rem 0 0.4375rem 1rem'
					},
					'& .MuiInput-root': {
						'&:after, &:before': {
							display: 'none'
						}
					},
					'& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before ': {
						borderBottom: 'none'
					},
					'& fieldset': {
						border: 'none'
					},
					'& .MuiIconButton-root': {
						borderRadius: '0rem 0.5rem 0.5rem 0rem',
						'&:hover': {
							backgroundColor: (theme) => theme.palette.ascents.wash
						}
					},
					'& .MuiInputAdornment-root': {
						position: 'absolute',
						right: 0
					}
				}}
				onSubmit={handleSubmit(findInPage)}
			>
				<TextController
					formObj={{
						name: 'mainSearch',
						props: {
							showLabel: false,
							inputProps: {
								sx: {
									marginTop: '0 !important'
								}
							},
							endAdornment: (
								<IconButton onClick={findInPage}>
									<Icon fontSize='1.25rem'>
										<SearchIcon />
									</Icon>
								</IconButton>
							)
						},
						label: 'Search'
					}}
					control={control}
				/>
			</Box>
			<AppModal
				title='Showing all search results'
				fullWidth
				hideClose
				open={open}
				onClose={() => {
					setOpen(false)
				}}
			>
				<Box>
					<OpenSearchDataTable
						detailsOnRowClick
						columns={allSignalsHeadCells}
						elasticQuery={elasticQuery}
						disableGroupBy
						includeManager
					/>
				</Box>
			</AppModal>
		</>
	)
}

export default withFormProvider(SearchBox)
