import { Box } from '@mui/material'
import { memo } from 'react'

// eslint-disable-next-line react/display-name
const TData = memo(
	({
		children,
		className,
		bgAscent = 'primary',
		bgColor,
		colorAscent = 'baseLow',
		color,
		textAlign = 'left',
		isAlignRight,
		colSpan,
		...props
	}) => {
		return (
			<Box
				component='td'
				className={className}
				colSpan={colSpan}
				sx={{
					backgroundColor: (theme) => (bgColor ? bgColor : theme.palette.ascents[bgAscent]),

					padding: '1rem',
					color: (theme) => (color ? color : theme.palette.ascents[colorAscent]),
					fontWeight: '400',
					overflowWrap: 'break-word',
					textAlign: isAlignRight ? 'right' : textAlign,

					borderTop: 'none',
					maxWidth: '250px',
					verticalAlign: 'top',
					lineHeight: '1.75',
					fontFamily: "'Open Sans', sans-serif",
					fontSize: '12px',
					'&:first-child': {
						borderTopLeftRadius: '0.65rem',
						borderBottomLeftRadius: '0.65rem',
						borderWidth: '1px 0px 1px 1px'
					},
					'&:last-child': {
						borderTopRightRadius: '0.65rem',
						borderBottomRightRadius: '0.65rem',
						borderWidth: '1px 1px 1px 0px'
					},
					'& .MuiChip-root': {
						verticalAlign: 'bottom !important',
						fontSize: '12px !important'
					},

					// whiteSpace: 'nowrap',
					// overflow: 'hidden',
					// textOverflow: 'ellipsis',

					'& .MuiSvgIcon-root': {
						color: (theme) => (color ? color : theme.palette.ascents[colorAscent])
					}
				}}
				{...props}
			>
				{children}
			</Box>
		)
	}
)

export default TData
