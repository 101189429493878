import { Box, LinearProgress } from '@mui/material'
import Text from '../Text'

const LinearProgressWithLabel = ({ value, ...props }) => {
	return (
		<Box display='flex' alignItems='center'>
			<Box width='100%' mr={1}>
				<LinearProgress variant='determinate' value={value} {...props} />
			</Box>
			<Box minWidth={35}>
				<Text variant='body2' color='textSecondary'>{`${Math.round(value)}%`}</Text>
			</Box>
		</Box>
	)
}

export default LinearProgressWithLabel
