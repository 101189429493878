import { colorsByStatus } from '@constants/Chart/chartOptions'
import Text from './Text'

const DisplayStatus = ({ status, successValue = 'active', successValue1 = 'Active', failureValue = 'disconnected', disconnectedValue= 'Inactive', count }) => {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div
				style={{
					width: 8,
					height: 8,
					marginRight: 4,
					borderRadius: '50%',
					backgroundColor:
						status === successValue
							? colorsByStatus.ACTIVE.rgb().toString()
							: status === successValue1
							? colorsByStatus.ACTIVE.rgb().toString()
							:status === failureValue
							? colorsByStatus.DISCONNECTED.rgb().toString():
							status === disconnectedValue
							? colorsByStatus.DISCONNECTED.rgb().toString()
							: colorsByStatus.NEVER_CONNECTED.rgb().toString()
				}}
			/>
			<Text fontSize={'12px !important'}>{count && (<> {count} </>)}{status}</Text>
		</div>
	)
}

export default DisplayStatus
