import { chartTypes } from '@constants/Chart/chartOptions'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import RestoreIcon from '@mui/icons-material/Restore'
import { Box, IconButton } from '@mui/material'
import { ResponsiveBar } from '@nivo/bar'
import { linearGradientDef } from '@nivo/core'
import { ResponsiveLine } from '@nivo/line'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveRadar } from '@nivo/radar'
import { ResponsiveSankey } from '@nivo/sankey'
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import { fadeColor } from '@utils/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import Icon from '../Icon'
import Loader from '../Loader'
import NoData from '../NoData'
import CustomChartModalWithTabs from './CustomChartModalWithTabs'

{
	/* TODO: REMOVE when done */
}
function downloadObjectAsJson(exportObj, exportName) {
	var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj))
	var downloadAnchorNode = document.createElement('a')
	downloadAnchorNode.setAttribute('href', dataStr)
	downloadAnchorNode.setAttribute('download', exportName + '.json')
	document.body.appendChild(downloadAnchorNode) // required for firefox
	downloadAnchorNode.click()
	downloadAnchorNode.remove()
}

const CustomNivoChart = ({
	type,
	chartWrapperProps,
	wrapperClassName,
	options = {},
	optionsForFull = {},
	elasticIndex = import.meta.env.VITE_APP_OS_DB_INDEX || 'zeron-alerts-*',
	elasticQuery = {},
	tableColumns,
	wrapperProps,
	params = {},
	errorText,
	letParentHandleState = false,
	data = [],
	tableData,
	fullWidth = true,
	maxWidth = 'xl',
	title = '',
	aspectRatio = 113 / 319,
	checkIconOnNoData = false,
	checkIconTextOnNoData,
	onlyGraph,
	showSecurityFacts = true,
	axisBottom,
	enableGridX = false,
	enableGridY = false,
	chartLegend = [],
	marginRight = 123,
	marginBottom = 64,
	customTooltip,
	hideLegend,
	disableModalTableRowDetails = false,
	hiddenKeysForRowDetails,
	renderRowDetailsHeading,
	useAggregationsData,
	CustomModalTable,
	...props
}) => {
	// const reloadToken = useSelector((state) => state.reloadToken)
	// const quickFilter = useSelector((state) => state.timeFrame.quickFilter)
	// const intervalsFilter = useSelector((state) => state.timeFrame.intervals)

	const [modalOpen, setModalOpen] = React.useState(false)
	const [loading, setLoading] = React.useState(false)
	const [graphData] = React.useState([])
	const [error] = React.useState({
		status: -1,
		message: ''
	})

	// TODO: REMOVE WHEN DONE
	const [TEMPresponse] = React.useState({})
	const [TEMPGraph] = React.useState({})

	const previousModalState = React.useRef(modalOpen)

	const toggleModal = () => setModalOpen(!modalOpen)
	const handleCloseModel = () => setModalOpen(false)

	const legends = React.useMemo(() => {
		const dataForChart = Array.isArray(data) ? data : []

		return dataForChart.length <= 5
			? chartLegend
			: [
					{
						...chartLegend[0],
						translateY: 50,
						data: dataForChart.slice(0, Math.floor(dataForChart.length / 2)).map((curr) => curr)
					},
					{
						...chartLegend[0],
						translateY: 85,
						data: dataForChart.slice(Math.floor(dataForChart.length / 2)).map((curr) => curr)
					}
			  ]
	}, [chartLegend, data])

	const getChartByType = (type, chartLegend) => {
		const gData = letParentHandleState ? data : graphData
		switch (type) {
			case chartTypes.SANKEY:
				return (
					<ResponsiveSankey
						data={gData}
						margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
						align='justify'
						colors={{ scheme: 'category10' }}
						nodeOpacity={1}
						nodeHoverOthersOpacity={0.35}
						nodeThickness={18}
						nodeSpacing={24}
						nodeBorderWidth={0}
						nodeBorderColor={{
							from: 'color',
							modifiers: [['darker', 0.8]]
						}}
						nodeBorderRadius={3}
						linkOpacity={0.3}
						linkHoverOthersOpacity={0.1}
						linkContract={10}
						enableLinkGradient={true}
						labelPosition='outside'
						labelOrientation='horizontal'
						labelPadding={16}
						labelTextColor={{
							from: 'color',
							modifiers: [['darker', 1]]
						}}
						axisBottom={axisBottom}
						{...props}
						// legends={[
						//     {
						//         anchor: 'bottom-right',
						//         direction: 'column',
						//         translateX: 130,
						//         itemWidth: 100,
						//         itemHeight: 14,
						//         itemDirection: 'right-to-left',
						//         itemsSpacing: 2,
						//         itemTextColor: '#999',
						//         symbolSize: 14,
						//         effects: [
						//             {
						//                 on: 'hover',
						//                 style: {
						//                     itemTextColor: '#000'
						//                 }
						//             }
						//         ]
						//     }
						// ]}
					/>
				)

			case chartTypes.PIE:
				return (
					<ResponsivePie
						data={gData}
						margin={{ top: 12, right: 12, bottom: 12, left: 12 }}
						colors={{
							datum: 'data.color'
							// scheme: "category10",
						}}
						enableArcLinkLabels={false}
						enableArcLabels={false}
						activeOuterRadiusOffset={6}
						animate
						isInteractive
						legends={chartLegend}
						{...props}
					/>
				)
			case chartTypes.DOUGHNUT:
				return (
					<ResponsivePie
						data={gData}
						// margin={{ top: 12, right: 12, bottom: 112, left: 12 }}
						colors={{
							datum: 'data.color'
							// scheme: "category10",
						}}
						enableArcLinkLabels={false}
						enableArcLabels={false}
						activeOuterRadiusOffset={6}
						animate
						isInteractive
						innerRadius={0.5}
						padAngle={1}
						cornerRadius={8}
						legends={chartLegend}
						margin={{
							top: 12,
							right: 12,
							bottom: modalOpen ? 95 : 12,
							left: 12
						}}
						tooltip={customTooltip || undefined}
						// {...props}
					/>
				)
			case chartTypes.RADAR:
				return (
					<ResponsiveRadar
						data={gData?.data}
						keys={gData?.keys}
						indexBy='x'
						margin={{ top: 36, right: 48, bottom: 36, left: 48 }}
						enableLabel={false}
						enableGridX={false}
						enableGridY={false}
						// colors={({ id, data }) => String(data[`_${id}Color`])}
						borderWidth={2}
						borderColor={'white'}
						tooltip={customTooltip || undefined}
						{...props}
					/>
				)
			case chartTypes.BAR:
				return (
					<ResponsiveBar
						data={gData?.data}
						keys={gData?.keys}
						indexBy='x'
						margin={{
							top: 36,
							right: modalOpen ? marginRight : 38,
							bottom: modalOpen ? marginBottom : 68,
							left: 48
						}}
						enableLabel={false}
						enableGridX={enableGridX}
						enableGridY={enableGridY}
						colors={({ id, data }) => String(data[`_${id}Color`])}
						valueScale={{ type: 'linear' }}
						borderWidth={2}
						borderColor={'white'}
						groupMode='stacked'
						isInteractive
						axisLeft={{
							tickValues: 4
						}}
						axisBottom={axisBottom}
						legends={chartLegend}
						tooltip={customTooltip}
						{...props}
					/>
				)
			case chartTypes.GROUP_BAR:
				console.log('gData::TYPE', type, gData, axisBottom)
				return (
					<ResponsiveBar
						data={gData?.data}
						keys={gData?.keys}
						indexBy='x'
						margin={{ top: 36, right: 48, bottom: 36, left: 48 }}
						enableLabel={false}
						colors={({ id, data }) => String(data[`_${id}Color`])}
						valueScale={{ type: 'linear' }}
						borderWidth={2}
						borderColor={'white'}
						groupMode='grouped'
						enableGridX={enableGridX}
						enableGridY={enableGridY}
						// isInteractive
						tooltipLabel
						axisBottom={axisBottom}
						{...props}
					/>
				)
			case chartTypes.LINE:
				console.log('gData::LINE', type, gData)
				return (
					<ResponsiveLine
						data={gData}
						colors={{
							datum: 'color'
							// scheme: "category10",
						}}
						margin={{
							top: 36,
							right: 48,
							bottom: modalOpen ? 54 : 36,
							left: 48
						}}
						xScale={{
							type: 'time',
							precision: 'minute',
							format: '%Y-%m-%d'
						}}
						xFormat='time:%Y-%m-%d @%H:%M'
						axisBottom={{
							tickValues: 4,
							format: '%m-%d @%H:%M',
							legendPosition: 'center',
							...axisBottom
						}}
						yScale={{
							type: 'linear'
						}}
						axisLeft={{
							tickValues: 4
						}}
						pointSize={6}
						pointBorderColor={{ from: 'color', modifiers: [] }}
						pointBorderWidth={3}
						useMesh={true}
						animate
						enableArea={true}
						areaOpacity={1}
						defs={gData?.map?.((c) =>
							linearGradientDef(`g${c.id}`, [
								{
									offset: 0,
									color: c.color,
									opacity: 0.85
								},
								{
									offset: 100,
									color: c.color,
									opacity: 0
								}
							])
						)}
						fill={gData?.map?.((each) => ({
							match: { id: each.id },
							id: `g${each.id}`
						}))}
						curve='monotoneX'
						enableGridX={enableGridX}
						enableGridY={enableGridY}
						legends={
							modalOpen
								? [
										{
											anchor: 'bottom',
											direction: 'row',
											justify: false,
											translateX: 0,
											translateY: hideLegend ? 171 : 54,
											itemsSpacing: 8,
											itemDirection: 'left-to-right',
											itemWidth: 96,
											itemHeight: 16,
											symbolSize: 8,
											symbolShape: 'circle',
											symbolBorderColor: 'rgba(0, 0, 0, .5)'
										}
								  ]
								: undefined
						}
						{...props}
					/>
				)
			case chartTypes.SCATTER:
				console.log('🚀 ~ file: CustomNivoChart.js:498 ~ getChartByType ~ gData:', gData)
				return (
					<ResponsiveScatterPlot
						data={gData?.data}
						colors={(node) => {
							return gData?.colors?.[node.serieId]
						}}
						nodeSize={{
							key: 'data.y',
							values: gData.minMax,
							sizes: [10, 32]
						}}
						blendMode='multiply'
						margin={{ top: 36, right: 48, bottom: 36, left: 48 }}
						axisBottom={{
							tickValues: 4,
							...axisBottom
						}}
						axisLeft={{
							tickValues: 4
						}}
						pointSize={6}
						pointBorderColor={{ from: 'color', modifiers: [] }}
						pointBorderWidth={3}
						animate
						enableGridX={enableGridX}
						enableGridY={enableGridY}
						{...props}
					/>
				)

			default:
				return
		}
	}

	const AppChart = ({ chartLegend = [] }) => (
		<Box
			sx={{
				position: 'absolute',
				height: '100%',
				width: '100%',
				minHeight: 'inherit',
				'&.grid': {
					'& svg g line': {
						strokeDasharray: '9 35',
						strokeDashoffset: 51,
						stroke: 'rgb(221 221 221)'
					}
				}
			}}
			className={clsx({
				grid: enableGridX || enableGridY
			})}
			{...wrapperProps}
		>
			{getChartByType(type, chartLegend)}
		</Box>
	)

	const CardActionComponent = ({ className }) => (
		<Box
			sx={{
				position: 'absolute',
				bottom: 0,
				right: 0,
				display: 'flex',

				'& .MuiIconButton-root:not(.MuiSwitch-switchBase)': {
					borderRadius: '16px',
					backgroundColor: (theme) => `${fadeColor(theme.palette.ascents.white, 0.6)}`,
					padding: '0.5rem',

					'& .MuiSvgIcon-root': {
						fontSize: '1.25rem'
					},

					'&:hover': {
						backgroundColor: (theme) => theme.palette.ascents.wash
					}
				}
			}}
			className={className}
		>
			{/* TODO: REMOVE when done */}
			{['localhost', '127.0.0.1', 'dev.zeron.one'].includes(window.location.hostname) && (
				<IconButton
					className='downloadJson'
					onClick={() => {
						downloadObjectAsJson(
							TEMPresponse,
							`${title.toLowerCase().split(' ').join('_')}-${window.location.pathname.split('/').join('_')}-RESPONSE`
						)
						downloadObjectAsJson(
							TEMPGraph,
							`${title.toLowerCase().split(' ').join('_')}-${window.location.pathname.split('/').join('_')}-GRAPH`
						)
						downloadObjectAsJson(
							{
								data: {
									body: elasticQuery,
									index: elasticIndex
								},
								params
							},
							`${title.toLowerCase().split(' ').join('_')}-${window.location.pathname.split('/').join('_')}-REQUEST`
						)
						downloadObjectAsJson(
							elasticQuery,
							`${title.toLowerCase().split(' ').join('_')}-${window.location.pathname.split('/').join('_')}-R-BODY`
						)
						downloadObjectAsJson(
							options,
							`${title.toLowerCase().split(' ').join('_')}-${window.location.pathname.split('/').join('_')}-OPTIONS`
						)
						downloadObjectAsJson(
							optionsForFull,
							`${title.toLowerCase().split(' ').join('_')}-${window.location.pathname.split('/').join('_')}-OPTIONSFORFULL`
						)
					}}
				>
					<Icon ascent='base'>
						<CloudDownloadIcon />
					</Icon>
				</IconButton>
			)}
			{graphData.hiddenSlices && graphData.hiddenSlices.length > 0 && (
				<IconButton>
					<Icon ascent='base'>
						<RestoreIcon />
					</Icon>
				</IconButton>
			)}
			<IconButton onClick={toggleModal}>
				<Icon ascent='base'>{modalOpen ? <FullscreenExitIcon /> : <FullscreenIcon />}</Icon>
			</IconButton>
		</Box>
	)

	const isEmpty =
		(letParentHandleState ? data : graphData).length <= 0 ||
		((type === chartTypes.BAR || type === chartTypes.GROUP_BAR) && (letParentHandleState ? data : graphData)?.data?.length === 0)

	React.useEffect(() => {
		if (loading) {
			const timer = setTimeout(() => {
				setLoading(false)
			}, 30000)

			return () => {
				clearTimeout(timer)
			}
		}
	}, [loading])

	React.useEffect(() => {
		if (previousModalState.current !== modalOpen) {
			// logGAEvent(
			//     modalOpen
			//         ? customEvents.GRAPH_FULL_SCREEN_ON
			//         : customEvents.GRAPH_FULL_SCREEN_OFF,
			//     {
			//         event_label: title,
			//         title,
			//         value: 1,
			//     }
			// );
		}

		previousModalState.current = modalOpen
	}, [modalOpen])

	return loading && isEmpty ? (
		<Box
			sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%,-50%)',
				width: '100%'
			}}
		>
			<Loader showSecurityFacts={showSecurityFacts} />
		</Box>
	) : isEmpty || error.message.length > 0 || error.status >= 400 ? (
		<NoData
			text={(letParentHandleState ? data : graphData).detail || errorText}
			checkIcon={checkIconOnNoData}
			checkIconText={checkIconTextOnNoData}
		/>
	) : (
		<Box
			sx={{
				height: '100%',
				minHeight: 'inherit',
				position: 'relative',
				'&:hover': {
					'& .toggler': {
						opacity: 1,
						transition: 'all 0.5s'
					}
				}
			}}
			className={wrapperClassName}
			{...chartWrapperProps}
		>
			{!onlyGraph && (
				<Box
					className={'toggler'}
					sx={{
						margin: 0,
						position: 'absolute',
						zIndex: 999,
						bottom: 0,
						opacity: 0,
						right: 0,
						transition: 'all 0.5s',
						background: `${fadeColor((theme) => theme.palette.ascents.base, 9)}`,
						padding: (theme) => theme.spacing(1, 0, 0, 1),
						borderRadius: '0.75rem'
					}}
				>
					<CardActionComponent />
				</Box>
			)}

			{/* {getChartByType(type)} */}
			<AppChart chartLegend={[]} />

			{!onlyGraph && (
				<CustomChartModalWithTabs
					maxWidth={maxWidth}
					fullWidth={fullWidth}
					title={title}
					onClose={handleCloseModel}
					open={modalOpen}
					hideClose
					showActions={false}
					aspectRatio={aspectRatio}
					columns={tableColumns}
					tableData={tableData}
					disableModalTableRowDetails={disableModalTableRowDetails}
					hiddenKeysForRowDetails={hiddenKeysForRowDetails}
					renderRowDetailsHeading={renderRowDetailsHeading}
					useAggregationsData={useAggregationsData}
					Chart={
						<Box
							sx={{
								paddingTop: `${Number(aspectRatio * 100).toFixed(3)}%`,
								position: 'relative'
							}}
						>
							<Box
								sx={{
									minHeight: 'inherit',

									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									width: '100%',
									height: '100%',

									'&.grid': {
										'& svg g line': {
											strokeDasharray: '9 35',
											strokeDashoffset: 51,
											stroke: 'rgb(221 221 221)'
										}
									}
								}}
								display={'flex'}
							>
								<AppChart chartLegend={legends} />
							</Box>
							{!modalOpen && <CardActionComponent />}
						</Box>
					}
					CustomModalTable={CustomModalTable}
					showOverflowContent
				/>
			)}
		</Box>
	)
}

CustomNivoChart.propTypes = {
	type: PropTypes.oneOf(Object.values(chartTypes)).isRequired,
	options: PropTypes.object,
	optionsForFull: PropTypes.object,
	elasticQuery: PropTypes.object.isRequired,
	elasticTableQuery: PropTypes.object,
	recursiveConfig: PropTypes.object,
	params: PropTypes.object,
	letParentHandleState: PropTypes.bool,
	setData: PropTypes.func,
	hideLabel: PropTypes.bool,
	data: PropTypes.object,
	fullWidth: PropTypes.bool,
	aspectRatio: PropTypes.number
}

export default CustomNivoChart
