import Loader from '@commons/Loader'
import React from 'react'

const MissingChunkErrorFallback = ({ error }) => {
	React.useEffect(() => {}, [error])

	return (
		<div
			role='alert'
			style={{
				margin: '1rem 0',
				position: 'absolute',
				top: 0,
				right: 0,
				width: '100vw',
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<Loader />
		</div>
	)
}

export default MissingChunkErrorFallback
