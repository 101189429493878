import { validAscents } from '@constants/propTypesValidation'
import { ListItem as MUIListItem } from '@mui/material'
import { fadeAscentColor, styledBy } from '@utils/styles'
import PropTypes from 'prop-types'
import React from 'react'

const ListItem = ({ className, children, ...props }) => {
	return (
		<MUIListItem
			sx={{
				textTransform: 'capitalize',
				'&.MuiListItem-button': {
					'&.active, &:hover': {
						backgroundColor: (theme) => fadeAscentColor(theme)
					},

					'& .MuiTouchRipple-child': {
						backgroundColor: (theme) => styledBy('ascent', theme.palette.ascents)
					}
				},
				// '&.active .MuiTypography-root': {
				// 	color: (theme) => theme.palette.text.primary
				// },
				'&.active .MuiTypography-root': {
					color: (theme) => styledBy('ascent', theme.palette.ascents)
				},
				'&:hover': {
					cursor: 'pointer'
				}
			}}
			className={className}
			{...props}
		>
			{children}
		</MUIListItem>
	)
}

ListItem.propTypes = {
	ascent: validAscents,
	color: validAscents,
	active: PropTypes.bool
}

export default React.memo(ListItem)
