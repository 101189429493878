import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Avatar, Box } from '@mui/material'
import Text from '../Text'
import MoreVerticalMenu from './MoreVerticalMenu'

const UserProfileBar = ({ name = 'Hello, User', position = 'System Administrator', avatar, openSidebar }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				padding: '0.75rem 0.5rem 1.5rem  0.5rem',
				margin: '0 1rem',
				borderTop: (theme) => `1px solid ${theme.palette.ascents.baseLow}`,
				justifyContent: 'center',
				flexDirection: openSidebar ? 'row' : 'column'
			}}
		>
			{/* TODO: Make it reusable */}
			<Avatar
				// className={clsx(classes.avatar, {
				//     [classes.defaultAvatar]: !avatar,
				// })}
				sx={{
					marginRight: (theme) => (openSidebar ? theme.spacing(1) : 0),
					padding: '3px',
					border: (theme) => `1px solid ${theme.palette.ascents.secondary}`,
					width: openSidebar ? '3rem' : '2.5rem',
					height: openSidebar ? '3rem' : '2.5rem',
					background: (theme) => (!avatar ? theme.palette.ascents.base : undefined),

					'& img': {
						borderRadius: 'inherit',
						width: '100%',
						height: '100%',
						background: (theme) => (!avatar ? theme.palette.ascents.secondary : undefined)
					}
				}}
				src={avatar ? avatar : AccountCircleIcon}
			/>
			{openSidebar ? (
				<Box width='100%' display='flex' justifyContent='space-between' maxWidth={'80%'}>
					<Box overflow='hidden' flex='1 1 100%'>
						<Text
							ascent='washLight'
							textTransform='capitalize'
							variant='body2'
							fontSize={'0.75rem'}
							noWrap
							title={'Hi, ' + name}
						>
							{name}
						</Text>
						<Text ascent='secondary' textTransform='capitalize' variant='body2'>
							{position}
						</Text>
					</Box>
					<Box textAlign='right'>
						<MoreVerticalMenu openSidebar={openSidebar} />
					</Box>
				</Box>
			) : (
				<MoreVerticalMenu openSidebar={openSidebar} />
			)}
		</Box>
	)
}

export default UserProfileBar
