import { createAsyncThunk } from '@reduxjs/toolkit'
import { getFrameworksList } from '@services/complianceService'

export const resetCompliancesDataReducer = createAsyncThunk('auth/resetCompliancesDataReducer', async () => {
	try {
		const response = await getFrameworksList().api()
		if (response) {
			if (!response.error) {
				return response.results.data
			}
		} else {
			return undefined
		}
	} catch (error) {
		return undefined
	}
})
