import { getUuid } from '@utils/other'
import { AUTOCOMPLETE, CHECKBOX, RADIO, SELECT, TEXT, TYPOGRAPHY } from './formInputTypes'

/**
 *
 * @param {*} name The name attribute of the text input box
 * @param {} label The Label that will be displayed to the user.
 * @param {} readOnly If true, the field will become readonly
 * @param {} validationRules The rules that will be applied for form validation
 * @param {} eachWidth Width of the input field
 * @param {} type 'type' attribute of input box, e.g - text/email/password/number...
 */
export function TextGenerator(
	name,
	label = 'Placeholder Label',
	readOnly = false,
	validationRules = {},
	eachWidth = 4,
	type = 'text',
	props
) {
	this.key = getUuid()
	this.fieldType = TEXT
	this.type = type
	this.label = label
	this.name = name
	this.eachWidth = eachWidth
	this.validationRules = validationRules
	this.readOnly = readOnly
	this.props = props
}

/**
 *
 * @param {*} name The name attribute of the text input box
 * @param {} label The Label that will be displayed to the user.
 * @param {} options The options that will be given to the user to choose.
 * @param {} readOnly If true, the field will become readonly
 * @param {} validationRules The rules that will be applied for form validation
 * @param {} eachWidth Width of the input field
 */
export function SelectGenerator(
	name,
	label = 'Placeholder Label',
	options = [],
	readOnly = false,
	validationRules = {},
	eachWidth = 4,
	props
) {
	this.key = getUuid()
	this.fieldType = SELECT
	this.label = label
	this.name = name
	this.options = options
	this.eachWidth = eachWidth
	this.validationRules = validationRules
	this.readOnly = readOnly
	this.props = props
}

/**
 *
 * @param {*} name The name attribute of the text input box
 * @param {*} fieldType decides the type of file
 * @param {*} projectFileId Takes these values:-
 * - Thumbnail - 1
 * - Banner - 2
 * - Trailer - 3
 * - Teaser - 4
 * @param {*} accept The File Formats to be accepted
 * @param {*} label The label that will be displayed to the user
 * @param {*} eachWidth Width of the field
 * @param {*} previewUrl url of the file (e.g -> http://www.example.com/some-random-ids-234234)
 * @param {*} disableAccept Disables input
 * @param {*} aspectRatio Takes aspect ratio
 */
export function FileGenerator(
	name,
	fieldType,
	projectFileId = null,
	accept,
	label = 'Choose your file',
	eachWidth = 4,
	previewUrl = '',
	disableAccept = false,
	aspectRatio
) {
	this.key = getUuid()
	this.fieldType = fieldType
	this.name = name
	this.accept = accept
	this.label = label
	this.eachWidth = eachWidth
	this.projectFileId = projectFileId
	this.previewUrl = previewUrl
	this.disableAccept = disableAccept
	this.aspectRatio = aspectRatio
}

export function CheckBoxGenerator(
	name,
	label = 'Placeholder label',
	isChecked = false,
	readOnly = false,
	validationRules = {},
	eachWidth = 4
) {
	this.key = getUuid()
	this.fieldType = CHECKBOX
	this.name = name
	this.label = label
	this.eachWidth = eachWidth
	this.validationRules = validationRules
	this.readOnly = readOnly
	this.isChecked = isChecked
}

export function TypographyGenerator(as = 'p', label = '', eachWidth = 4) {
	this.key = getUuid()
	this.fieldType = TYPOGRAPHY
	this.as = as
	this.label = label
	this.eachWidth = eachWidth
}

export function RadioGenerator(
	name,
	label,
	options,
	selectedValue = '',
	disabledValues = [],
	showOptionsHorizontally = false,
	readOnly = false,
	validationRules = {},
	eachWidth = 4
) {
	this.key = getUuid()
	this.fieldType = RADIO
	this.name = name
	this.label = label
	this.options = options
	this.selectedValue = selectedValue
	this.disabledValues = disabledValues
	this.showOptionsHorizontally = showOptionsHorizontally
	this.readOnly = readOnly
	this.validationRules = validationRules
	this.eachWidth = eachWidth
}

/**
 *
 * @param {*} name The name attribute of the text input box
 * @param {} label The Label that will be displayed to the user.
 * @param {} options The options that will be given to the user to choose.
 * @param {} canAddNewOption If true, user can type new option in the text box
 * @param {} readOnly If true, the field will become readonly
 * @param {} validationRules The rules that will be applied for form validation
 * @param {} eachWidth Width of the input field
 */
export function AutoCompleteGenerator(
	name,
	label = 'Placeholder Label',
	options = [],
	canAddNewOption = false,
	readOnly = false,
	validationRules = {},
	eachWidth = 4,
	props
) {
	this.key = getUuid()
	this.fieldType = AUTOCOMPLETE
	this.name = name
	this.label = label
	this.options = options
	this.readOnly = readOnly
	this.validationRules = validationRules
	this.eachWidth = eachWidth
	this.canAddNewOption = canAddNewOption
	this.props = props
}
