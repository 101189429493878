import React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { styled } from '@mui/material/styles'
import Check from '@mui/icons-material/Check'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import Text from '@commons/Text'
import { Grid } from '@mui/material'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: 'calc(-50% + 10px)',
		right: 'calc(50% + 10px)',
		transition: 'width 0.5s ease-in-out'
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: '#9753D9'
		}
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: '#9753D9'
		}
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 0
	}
}))

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
	display: 'flex',
	height: 22,
	alignItems: 'center',
	...(ownerState.active && {
		color: '#9753D9'
	}),
	'& .QontoStepIcon-completedIcon': {
		width: 22,
		height: 22,
		color: '#ffffff',
		padding: 2,
		backgroundColor: '#9753D9',
		zIndex: 1,
		fontSize: 24,
		borderRadius: '15px'
	},
	'& .QontoStepIcon-circle': {
		width: 22,
		height: 22,
		zIndex: 1,
		borderRadius: '50%',
		backgroundColor: 'currentColor'
	}
}))

function QontoStepIcon(props) {
	const { active, completed, className } = props

	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<Check className='QontoStepIcon-completedIcon' />
			) : active ? (
				<RadioButtonCheckedIcon className='QontoStepIcon-circle-active' />
			) : (
				<div className='QontoStepIcon-circle' />
			)}
		</QontoStepIconRoot>
	)
}

export default function CustomStepper({ steps, activeStep }) {
	return (
		<Box sx={{ width: '100%', background: 'transparent' }}>
			<Grid item xs={12} display={'flex'} justifyContent={'center'}>
				<Text fontWeight={500} ascent='white' fontSize={18} margin='0.6rem 0' fontFamily='Poppins'>
					{activeStep === steps.length - 1 ? ' ' : 'Enter'}
					{'  '}
					{steps[activeStep]}
				</Text>
			</Grid>
			<Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
				{steps.map((label, index) => (
					<Step key={label}>
						<StepLabel StepIconComponent={QontoStepIcon}>
							<Text
								fontWeight={activeStep === index || index < activeStep ? 500 : 300}
								ascent={activeStep === index || index < activeStep ? 'highlightColor' : 'white'}
								fontSize={'12px'}
								margin='1rem 0'
								fontFamily='Poppins'
							>
								{label}
							</Text>
						</StepLabel>
					</Step>
				))}
			</Stepper>
		</Box>
	)
}
