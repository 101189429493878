import { Box, LinearProgress } from '@mui/material'
import { memo } from 'react'

// eslint-disable-next-line react/display-name
const TableFooter = memo(({ total = 0, currentSize = 0, loading }) => {
	return (
		<>
			<Box
				sx={{
					position: 'sticky',
					bottom: '-1rem',
					borderRadius: '0rem 0rem 0.5rem 0.5rem ',
					overflow: 'hidden',
					background: '#fff',
					border: (theme) => `1px solid ${theme.palette.ascents.wash}`,
					width: '100%',
					padding: '1rem',
					fontSize: '0.75rem',
					fontFamily: "'Roboto', sans-serif",
					marginBottom: '-1px'
				}}
			>
				{loading && (
					<LinearProgress
						sx={{
							position: 'absolute',
							width: '100%',
							left: '0',
							top: '0'
						}}
					/>
				)}
				{loading ? 'Loading...' : `Showing ${currentSize} out of ${total}`}
			</Box>
		</>
	)
})

export default TableFooter
