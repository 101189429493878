import { Box } from '@mui/material'
import React from 'react'
import { useDropzone } from 'react-dropzone'

//--------------  IMPORTANT-----------
//errorConfig is array like this
//const errorConfig = [{code: 'max_file_size', maxFileSize: 500*1024, errorCallBack: ()=>{}}]

//you must add new error object here if you want to add new validation rule
const errorObjects = {
	max_file_size: {
		validator: (file, config) => {
			if (file.size > config.maxFileSize) {
				const errorObject = {
					code: 'max_file_size',
					message: `File Size must not exceed maximum file size`,
					errorCallBack: config.errorCallBack || (() => {})
				}

				return errorObject
			}
			return null
		}
	},
	min_file_size: {
		validator: (file, config) => {
			if (file.size < config.minFileSize) {
				const errorObject = {
					code: 'min_file_size',
					message: `File Size must be greater than minimum size`,
					errorCallBack: config.errorCallBack || (() => {})
				}

				return errorObject
			}
			return null
		}
	},
	file_format: {
		validator: (file, config) => {
			const allowedFormats = config.allowedFormats || []
			const fileExtension = file.name.split('.').pop().toLowerCase()
			if (!allowedFormats.includes(fileExtension)) {
				const errorObject = {
					code: 'file_format',
					message: `File format is not supported. Supported formats are ${allowedFormats.join(', ')}`,
					errorCallBack: config.errorCallBack || (() => {})
				}
				return errorObject
			}
			return null
		}
	}
}

const fileValidator =
	(errorConfig = []) =>
	(file) => {
		if (errorConfig.length > 0) {
			for (let config of errorConfig) {
				if (config.code in errorObjects) {
					if (errorObjects?.[config.code]?.validator(file, config)) {
						return errorObjects?.[config.code]?.validator(file, config)
					}
				}
			}
		}

		return null
	}

const withDragAndDrop = (Component = React.Component) => {
	// eslint-disable-next-line react/display-name
	return ({ multiple, onChange, errorConfig = [], ...props }) => {
		const { getRootProps, getInputProps, ...dropZoneProps } = useDropzone({
			multiple,
			onDrop: (acceptedFiles, fileRejections, event) => {
				if (fileRejections?.length > 0) {
					for (let fileRejection of fileRejections) {
						if (fileRejection?.errors?.length > 0) {
							for (let error of fileRejection.errors) {
								if (typeof error.errorCallBack === 'function') {
									error.errorCallBack(fileRejection.file)
								}
							}
						}
					}
					return
				}
				onChange(acceptedFiles, fileRejections, event)
			},
			validator: fileValidator(errorConfig),
			...props
		})

		return (
			<Box
				className='drag-and-drop'
				sx={{
					display: 'flex',
					justifyContent: 'center'
				}}
				{...getRootProps()}
			>
				<Component multiple={multiple} {...getInputProps({ onChange })} {...props} {...dropZoneProps} />
			</Box>
		)
	}
}

export default withDragAndDrop
