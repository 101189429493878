export const TEXT = 'TEXT'
export const RICH_TEXT = 'RICH_TEXT'
export const SELECT = 'SELECT'
export const RADIO = 'RADIO'
export const CHECKBOX = 'CHECKBOX'
export const SWITCH = 'SWITCH'
export const FILE = 'FILE'
export const TYPOGRAPHY = 'TYPOGRAPHY'
export const AUTOCOMPLETE = 'AUTOCOMPLETE'

export const VIDEO_FILE = 'VIDEO_FILE'
export const IMAGE_FILE = 'IMAGE_FILE'

export const fileExts = {
	allVideos: 'video/*',
	imagePngJpg: 'image/png, image/jpeg',
	imageAll: 'image/*',
	pdf: '.pdf',
	msXL: '.xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
}

export const getFileDetailsByExt = (ext) => {
	// @todo -> Need this from backend
	const formatTypes = [
		{
			type: IMAGE_FILE,
			formats: ['jpg', 'jpeg', 'png', 'webp'],
			accept: 'image/png, image/jpeg, image/jpg, image/webp'
		},
		{
			type: VIDEO_FILE,
			formats: ['mp4', 'mkv', 'mov'],
			accept: 'video/*'
		}
	]

	let fileDetails = null
	for (let i = 0; i < formatTypes.length; i++) {
		const eachFormat = formatTypes[i]

		if (eachFormat.formats.indexOf(ext) > -1) {
			fileDetails = {
				type: eachFormat.type,
				accept: eachFormat.accept
			}
			break
		}
	}
	return fileDetails
}

export const scheduleTypes = {
	INTERVAL: 'INTERVAL',
	WEEKLY: 'WEEKLY',
	MONTHLY: 'MONTHLY'
}
