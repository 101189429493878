/* eslint-disable no-useless-escape */
export const validator = {
	alphaNumericSpace: {
		value: /^[a-zA-Z0-9 ]*$/,
		message: 'Special characters are not allowed!'
	},

	// /\:[a-zA-Z0-9\_]*/g
	alphaNumeric: {
		value: /^[a-zA-Z0-9]*$/,
		message: 'only alphabets and numbers are allowed!'
	},

	isEmail: {
		value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		message: 'Invalid email!'
	},

	isPhone: {
		value: /^[0-9]{10}$/,
		message: 'Phone number should have exactly 10 digits!'
	},

	onlyAlphabets: {
		value: /^[A-Za-z ]+$/,
		message: 'Only alphabets are allowed!'
	},

	onlyNumeric: {
		value: /^[0-9]*$/,
		message: 'Only numbers are allowed!'
	},

	aadharCard: {
		value: /^\d{4}\s\d{4}\s\d{4}$/g,
		message: 'Invalid Aadhar Number!'
	},

	guid: {
		value: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
		message: 'Invalid token!'
	},

	uuid: {
		value: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
		message: 'Invalid token!'
	},

	isLink: {
		value: /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
		message: `The link is invalid.`
	},
	// ^([a-zA-Z]:)?(\\[^<>:"/\\|?*]+)+\\?$
	isWindowsP: {
		value: /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
		message: `The link is invalid.`
	},

	isValidPassword: (message) => (value) =>
		/^(?!.*\s)(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/g.test(value)
			? /(.)\1\1/.test(value)
				? message || 'Characters must not be repeated more than twice. Example - aaa, 222, are not allowed'
				: true
			: message ||
			  'The password must be minimum 8 charaters long, including atleast one Upper letter, one lower letter, one number and one symbol & no white space',

	isLessThan3Repetition: (value) =>
		/(.)\1\1/.test(value) ? 'Characters must not be repeated more than twice. Example - aaa, 222, are not allowed' : false,

	isRequired: (inputName) => ({
		required: {
			value: true,
			message: `${inputName} is required!`
		}
	}),

	isMin: (value) => ({
		min: {
			value: value,
			message: `Minimum ${value} value is required!`
		}
	}),

	isMax: (value) => ({
		max: {
			value: value,
			message: `Maximum ${value} value is allowed!`
		}
	}),

	isMinLength: (value) => ({
		minLength: {
			value: value,
			message: `Minimum ${value} character(s) are required!`
		}
	}),

	isMaxLength: (value) => ({
		maxLength: {
			value: value,
			message: `Maximum ${value} character(s) are allowed!`
		}
	}),

	isMaxWords: () => (value) => ({
		maxLength: {
			value: value,
			message: `Maximum ${value} character(s) are allowed!`
		}
	})
}
