import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Icon } from '@mui/material'
import { icons } from '@utils/icons'
import Text from './Text'

const NoData = ({
	text = 'Try adjusting your search or filter to find what you are looking for',
	checkIcon = false,
	checkIconText = 'Congratulations! No risks occurred under this category',
	textProps
}) => {
	return (
		<Box
			align='center'
			sx={{
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
				padding: '0 1rem',
				maxWidth: '100%',
				minHeight: 'inherit'
			}}
		>
			<Box width={48} height={48} margin='0 auto'>
				{checkIcon ? (
					<CheckCircleOutlineIcon
						fontSize='large'
						// className={classes.checkIcon}
						sx={{
							color: (theme) => theme.palette.success.main
						}}
					/>
				) : (
					<img width={'100%'} height={'100%'} alt='error-icon' src={icons.ERROR_SVG} />
				)}
			</Box>
			<Text
				ascent='base'
				variant='body2'
				title={typeof (checkIcon ? checkIconText : text) === 'string' && (checkIcon ? checkIconText : text)}
				sx={{
					maxWidth: '100%'
				}}
				align='center'
				noWrap={false}
				{...textProps}
			>
				{checkIcon ? checkIconText : text}
			</Text>
		</Box>
	)
}

export default NoData
