import Icon from '@commons/Icon'
import { SvgIcons } from '@utils/icons'

function SidebarListKeyConstantGenerator(key, label, icon, routeName) {
	this.key = key
	this.label = label
	this.isShownOnSidebar = true
	this.Icon = icon
	this.routeName = routeName || key
}

export const SidebarListKeyConstants = {
	DashboardScreen: new SidebarListKeyConstantGenerator('DashboardScreen', 'Dashboard', (props) => (
		<Icon {...props}>
			<SvgIcons.DASHBOARD_SVG className={props?.className} />
		</Icon>
	)),
	BusinessRiskPostureScreenParent: new SidebarListKeyConstantGenerator(
		'BusinessRiskPostureScreenParent',
		'B - Business posture',
		(props) => (
			<Icon {...props}>
				<SvgIcons.BriefcaseSvg className={props?.className} />
			</Icon>
		)
	),
	BusinnessPosturev2Parent: new SidebarListKeyConstantGenerator(
		'BusinnessPosturev2Parent',
		'B - Business posture v1.5',
		(props) => (
			<Icon {...props}>
				<SvgIcons.BriefcaseSvg className={props?.className} />
			</Icon>
		)
	),
	BusinessPostureManagement: new SidebarListKeyConstantGenerator(
		'BusinessPostureManagement',
		'B - Business posture v2.0',
		(props) => (
			<Icon {...props}>
				<SvgIcons.BriefcaseSvg className={props?.className} />
			</Icon>
		)
	),
	SecurityOverviewScreen: new SidebarListKeyConstantGenerator('SecurityOverviewScreen', 'D - Defence', (props) => (
		<Icon {...props}>
			<SvgIcons.SECURITYOVERVIEW_SVG className={props?.className} />
		</Icon>
	)),
	// RiskMitigationScreen: new SidebarListKeyConstantGenerator(
	//     "RiskMitigationScreen",
	//     "Risk mitigation",
	//     (props) => (
	//         <Icon {...props}>
	//             <SvgIcons.ToolsSvg className={props?.className} />
	//         </Icon>
	//     )
	// ),
	CloudManagerScreen: new SidebarListKeyConstantGenerator('CloudManagerScreen', 'Cloud Monitor', (props) => (
		<Icon {...props}>
			<SvgIcons.CLOUD_SVG className={props?.className} />
		</Icon>
	)),
	SecurityAssessmentScreen2: new SidebarListKeyConstantGenerator('SecurityAssessmentScreen2', 'A - Attack surface', (props) => (
		<Icon {...props}>
			<SvgIcons.FireSvg className={props?.className} />
		</Icon>
	)),
	AttackSurfaceV3: new SidebarListKeyConstantGenerator('AttackSurfaceV3', 'A - Attack surface v2.5', (props) => (
		<Icon {...props}>
			<SvgIcons.FireSvg className={props?.className} />
		</Icon>
	)),
	ComplianceScreen: new SidebarListKeyConstantGenerator('ComplianceScreen', 'C - Compliance', (props) => (
		<Icon {...props}>
			<SvgIcons.COMPLIANCE_SVG className={props?.className} />
		</Icon>
	)),

	ManagementScreenParent: new SidebarListKeyConstantGenerator('ManagementScreenParent', 'Others', (props) => (
		<Icon {...props}>
			<SvgIcons.CogSvg className={props?.className} />
		</Icon>
	)),

	RiskRegister: new SidebarListKeyConstantGenerator('RiskRegister', 'Risk Register', (props) => (
		<Icon {...props}>
			<SvgIcons.DASHBOARD_SVG className={props?.className} />
		</Icon>
	))
	// AdministrationScreen: new SidebarListKeyConstantGenerator(
	//     "AdministrationScreen",
	//     "Administration",
	//     (props) => (
	//         <Icon {...props}>
	//             <SvgIcons.ADMINISTRATION_SVG className={props?.className} />
	//         </Icon>
	//     )
	// ),
	// HelpScreen: new SidebarListKeyConstantGenerator(
	//     "HelpScreen",
	//     "Help",
	//     (props) => (
	//         <Icon {...props}>
	//             <SvgIcons.HELP_SVG className={props?.className} />
	//         </Icon>
	//     )
	// ),
}

const SidebarOrderedListConstant = [
	'DashboardScreen',
	'SecurityAssessmentScreen2',
	'AttackSurfaceV3',
	'BusinessRiskPostureScreenParent',
	'BusinnessPosturev2Parent',
	'BusinessPostureManagement',
	'ComplianceScreen',
	'SecurityOverviewScreen',
	'CloudManagerScreen',
	'RiskRegister',
	'ManagementScreenParent'
]

export default SidebarOrderedListConstant
