import NotificationDropdown from '@layouts/NotificationDropdown'
import SearchBox from '@layouts/Search/SearchBox'
import MenuIcon from '@mui/icons-material/Menu'
// import TranslateIcon from '@mui/icons-material/Translate'
import { AppBar, Box, IconButton, Toolbar } from '@mui/material'
import { DEFAULT_TIMESTAMP_FORMAT } from '@utils/other'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Text from '../Text'
import DateRangeDropdown from './DateRangeDropdown'
import RefreshButton from './RefreshButton'
const Navbar = ({
	title,
	openSidebar,
	handleToggleSidebar,
	dateRangePickerProps,
	handleTimeFrameChange,
	selectedTimeFrame,
	filtersByTime,
	onClickDarkMode,
	enableSearchBar = true,
	enableDateFilter = true,
	enableReset = true
}) => {
	const timeRange = useSelector((state) => state.timeFrame.timeRange)
	const quickFilter = useSelector((state) => state.timeFrame.quickFilter)
	const [darkFlag, setDarkFlag] = React.useState(false)
	const [startDate, setStartDate] = React.useState({
		date: null,
		value: null
	})

	const [endDate, setEndDate] = React.useState({
		date: null,
		value: null
	})

	const [showCustomDateSaveBtn, setShowCustomDateSaveBtn] = React.useState(false)

	const handleStartDateChange = (date) => {
		if (!endDate.date) {
			setEndDate({
				date: moment(),
				value: moment().format(DEFAULT_TIMESTAMP_FORMAT)
			})
		}
		setStartDate({
			date,
			value: date?.format?.(DEFAULT_TIMESTAMP_FORMAT)
		})

		setShowCustomDateSaveBtn(true)
	}

	const handleEndDateChange = (date) => {
		setEndDate({
			date,
			value: date?.format?.(DEFAULT_TIMESTAMP_FORMAT)
		})

		setShowCustomDateSaveBtn(true)
	}

	const handleDarkModeClick = (value) => {
		console.log('Clicked value: parent', value)
		setDarkFlag(value)
		onClickDarkMode && onClickDarkMode(value)
	}

	const resetDatesinLocal = () => {
		if (!!startDate.date && !!endDate.date) {
			setStartDate({ date: null, value: null })
			setEndDate({ date: null, value: null })
		}
	}

	useEffect(() => {
		if (quickFilter.index === -1 && timeRange.isSetByUser) {
			handleStartDateChange(moment(timeRange.startDate))
			handleEndDateChange(moment(timeRange.endDate))
		} else if (quickFilter.index > -1 && !timeRange.isSetByUser) {
			resetDatesinLocal()
		}
	}, [JSON.stringify({ timeRange, quickFilter })])

	return (
		<AppBar
			position='fixed'
			sx={{
				zIndex: (theme) => theme.zIndex.drawer + 1,
				transition: (theme) =>
					theme.transitions.create(['width', 'margin'], {
						easing: (theme) => theme.transitions.easing.sharp,
						duration: (theme) => theme.transitions.duration.leavingScreen
					}),
				backgroundColor: (theme) => (!darkFlag ? theme.palette.ascents.white : theme.palette.ascents.dark),
				// transition: 'background-color 0.3s ease',
				padding: (theme) => theme.spacing(0, 2),
				boxShadow: 'none !important',

				position: 'sticky',
				top: 0,

				'& .MuiToolbar-regular': {
					// padding: "1.75rem",
					padding: '1.3755rem 0 !important',

					minHeight: 'inherit !important'
				},

				marginLeft: openSidebar ? 0 : undefined,
				width: openSidebar ? '100%' : undefined
			}}
		>
			<Toolbar>
				{/* <Box flex='1 0 auto' display='flex' mr={2} alignItems='center'>
					<IconButton
						onClick={handleToggleSidebar}
						sx={{
							color: (theme) => `${theme.palette.ascents.black} !important`
						}}
					>
						<MenuIcon />
					</IconButton>
					<Text
						fontSize='1.25rem !important'
						ascent='base'
						fontWeight={`500 !important`}
					>
						{title ? title : ''}
					</Text>
				</Box> */}
				<Box flex='1 0 auto' display='flex' mr={2} alignItems='center'>
					<IconButton
						onClick={handleToggleSidebar}
						sx={{
							color: darkFlag ? 'white !important' : 'black !important'
						}}
					>
						<MenuIcon />
					</IconButton>
					<Text
						fontSize='1.25rem !important'
						// ascent='base' 
						fontWeight={`500 !important`}
						fontColor={darkFlag ? 'white !important' : 'base'}>
						{title ? title : ''}
					</Text>
				</Box>

				{enableSearchBar && <SearchBox />}

				{enableDateFilter && <DateRangeDropdown
					startDate={startDate}
					endDate={endDate}
					handleEndDateChange={handleEndDateChange}
					handleStartDateChange={handleStartDateChange}
					selectedTimeFrame={selectedTimeFrame}
					handleTimeFrameChange={handleTimeFrameChange}
					filtersByTime={filtersByTime}
					resetDatesinLocal={resetDatesinLocal}
					showSaveBtn={showCustomDateSaveBtn}
					setShowSaveBtn={setShowCustomDateSaveBtn}
					{...dateRangePickerProps}
				/>}

				{enableReset && <Box
					sx={{
						'& .MuiIconButton-root': {
							boxShadow:
								'0px 0px 4px -1px rgb(188 193 221 / 15%), 0px 1px 8px 2px rgb(188 193 221 / 25%), 0px 0px 10px 1px rgb(188 193 221 / 20%)',
							// boxShadow:"0px 4px 10px 5px rgba(188, 193, 221, 0.5)",
							borderRadius: '0.75rem',
							padding: (theme) => theme.spacing(1),
							backgroundColor: !darkFlag ? 'primary' : 'white'
						},

						'& img': {
							width: '1.25rem',
							height: '1.25rem'
						},

						'& .MuiSvgIcon-root': {
							color: (theme) => theme.palette.ascents.base
						}
					}}
					mr={2}
				>
					<RefreshButton title={title} />
				</Box>}
				{/* <Box
					sx={{
						'& .MuiIconButton-root': {
							boxShadow:
								'0px 0px 4px -1px rgb(188 193 221 / 15%), 0px 1px 8px 2px rgb(188 193 221 / 25%), 0px 0px 10px 1px rgb(188 193 221 / 20%)',
							// boxShadow:"0px 4px 10px 5px rgba(188, 193, 221, 0.5)",
							borderRadius: '0.75rem',
							padding: (theme) => theme.spacing(1),
							backgroundColor: !darkFlag ? 'primary' : 'white'
						},

						'& img': {
							width: '1.25rem',
							height: '1.25rem'
						},

						'& .MuiSvgIcon-root': {
							color: (theme) => theme.palette.ascents.base
						}
					}}
					mr={2}
				>
					<DarkMode onButtonClick={handleDarkModeClick} />
				</Box> */}

				<NotificationDropdown
					sx={{
						'& .MuiIconButton-root': {
							boxShadow:
								'0px 0px 4px -1px rgb(188 193 221 / 15%), 0px 1px 8px 2px rgb(188 193 221 / 25%), 0px 0px 10px 1px rgb(188 193 221 / 20%)',
							// boxShadow:"0px 4px 10px 5px rgba(188, 193, 221, 0.5)",
							borderRadius: '0.75rem',
							padding: (theme) => theme.spacing(1),
							backgroundColor: !darkFlag ? 'primary' : 'white'
						},

						'& img': {
							width: '1.25rem',
							height: '1.25rem'
						},

						'& .MuiSvgIcon-root': {
							color: (theme) => theme.palette.ascents.base
						}
					}}
				/>
				{/* <Box
					sx={{
						'& .MuiIconButton-root': {
							boxShadow:
								'0px 0px 4px -1px rgb(188 193 221 / 15%), 0px 1px 8px 2px rgb(188 193 221 / 25%), 0px 0px 10px 1px rgb(188 193 221 / 20%)',
							// boxShadow:"0px 4px 10px 5px rgba(188, 193, 221, 0.5)",
							borderRadius: '0.75rem',
							padding: (theme) => theme.spacing(1),
							backgroundColor: !darkFlag ? 'primary' : 'white'
						},

						'& img': {
							width: '1.25rem',
							height: '1.25rem'
						},

						'& .MuiSvgIcon-root': {
							color: (theme) => theme.palette.ascents.base
						}
					}}
					ml={2}
				>
					<IconButton >
						<TranslateIcon />
					</IconButton>
				</Box> */}
			</Toolbar>
		</AppBar>
	)
}

export default Navbar
