import firebase from 'firebase/compat/app'
import { getMessaging, isSupported } from 'firebase/messaging'

const firebaseConfig = {
	apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
	projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
	messagingSenderId: import.meta.env.VITE_APP_FCM_SENDER_ID,
	appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
	measurementId: import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID
}

// let app, analytics, messaging, perf;

const app = firebase.initializeApp(firebaseConfig)
// WARNING: Removing can crash the app.
// Why so complex assigment ? Because `isSupported` is a Promise and we need a `messaging` instance.
let messaging
isSupported()
	.then((supported) => {
		messaging = supported ? getMessaging(app) : undefined
	})
	.catch((e) => {
		console.log('ERROR: ' + e)
	})

export { messaging }
