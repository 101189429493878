import CardWrapperByRatio from '@commons/Card/CardWrapperByRatio'
import WrapperCard from '@commons/Card/WrapperCard'
import ChartCustomTooltipWrapper from '@commons/Charts/ChartCustomTooltipWrapper'
import CustomNivoChart from '@commons/Charts/CustomNivoChart'
import Loader from '@commons/Loader'
import Text from '@commons/Text'
import { chartTypes } from '@constants/Chart/chartOptions'
import { Box, Grid, List, ListItem, Tooltip } from '@mui/material'
import { getAllImplementedAndUnimplementedControls } from '@services/complianceService'
import { ASCENTS } from '@styles/theme'
import { fadeColor } from '@utils/styles'
import queryString from 'query-string'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const ComplianceDashboard = ({ complianceType, activeTabIndex }) => {
	const compliancesIsLoading = useSelector((state) => state.compliances.isLoading)
	const compliancesByKeys = useSelector((state) => state.compliances.compliances.byKeys)
	const [isLoadingData, setIsLoadingData] = useState(true)
	const [chartData, setChartData] = useState({
		keys: ['Total controls', 'Implemented controls'],
		data: [
			// {
			//     "x": "A.1",
			//     "Implemented controls": 55,
			//     "Total controls": 100,
			// }
		]
	})

	const dataByKeys = useMemo(() => {
		const dataWithKeys = {}
		for (let i = 0; i < chartData.keys.length; i++) {
			// eslint-disable-next-line no-unused-vars
			const key = chartData.keys[i]

			for (let j = 0; j < chartData.data.length; j++) {
				const dataObj = chartData.data[j]
				dataWithKeys[dataObj.x] = dataObj
			}
		}
		return dataWithKeys
	}, [chartData])

	const controls = useMemo(() => {
		return chartData.data.map((dataPoint) => ({
			controlId: dataPoint.x,
			totalChildControls: dataPoint['Total controls'],
			totalPassedChildControls: dataPoint['Implemented controls'],
			control: dataPoint.control
		}))
	}, [chartData])

	const fetchData = () => {
		const getAllImplementedAndUnimplementedControlsApi = getAllImplementedAndUnimplementedControls()

		setIsLoadingData(true)
		getAllImplementedAndUnimplementedControlsApi
			.api({
				params: {
					framework_id: compliancesByKeys[complianceType].id
				}
			})
			.then((response) => {
				setIsLoadingData(false)
				if (response) {
					if (!response.error && response?.results?.data?.result) {
						const data = []
						Object.keys(response.results.data.result).map((key) => {
							const control = response.results.data.result[key]
							const implemented = Object.keys(control.implemented).length
							const unimplemented = Object.keys(control.unimplemented).length
							data.push({
								x: key,
								'Implemented controls': implemented,
								'Total controls': implemented + unimplemented,
								control: {
									implemented: Object.entries(control.implemented).map(([key, value]) => ({
										subcontrolId: key,
										subcontrolRefCode: value
									})),

									unimplemented: Object.entries(control.unimplemented).map(([key, value]) => ({
										subcontrolId: key,
										subcontrolRefCode: value
									}))
								}
							})
						})
						setChartData({
							...chartData,
							data
						})
					}
				}
			})
		return getAllImplementedAndUnimplementedControlsApi
	}

	// console.log({ chartData })

	const RenderSubControlListItem = (subcontrol) => {
		return (
			<Link
				to={queryString.stringifyUrl({
					url: window.location.pathname,
					query: {
						tab: 1,
						subcontrol_ref_code: subcontrol.subcontrolRefCode
					}
				})}
			>
				<ListItem>
					<Text ascent='primary'>{subcontrol.subcontrolRefCode}</Text>
				</ListItem>
			</Link>
		)
	}

	const RenderControlProgress = (control) => {
		const progress = (control.totalPassedChildControls / control.totalChildControls) * 100

		return (
			<Grid container spacing={3} sx={{ mb: 2 }}>
				<Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
					<Text margin='0 1rem 0 0'>{control.controlId}</Text>
					{activeTabIndex === 0 && (
						<Tooltip
							arrow
							placement='left'
							title={
								<Box overflow={'scroll'} maxHeight={'25rem'} minWidth={'15rem'} p={2}>
									<Text fontSize={16} fontWeight={600}>
										Implemented:-
									</Text>
									<List sx={{ mb: 4 }}>
										{control.control.implemented.length === 0 ? (
											<ListItem sx={{ color: ASCENTS.textDark }}>No subcontrol found!</ListItem>
										) : (
											control.control.implemented.map(RenderSubControlListItem)
										)}
									</List>
									<Text fontSize={16} fontWeight={600}>
										Unimplemented:-
									</Text>
									<List>
										{control.control.unimplemented.length === 0 ? (
											<ListItem sx={{ color: ASCENTS.textDark }}>No subcontrol found!</ListItem>
										) : (
											control.control.unimplemented.map(RenderSubControlListItem)
										)}
									</List>
								</Box>
							}
						>
							<Box
								px={progress < 1 ? 1 : 0}
								width={'100%'}
								sx={{ backgroundColor: (theme) => fadeColor(theme.palette.ascents.base, 0.95) }}
							>
								<Box
									py={0.75}
									px={progress < 1 ? 0 : 1}
									width={`${progress}%`}
									sx={{ backgroundColor: (theme) => fadeColor(theme.palette.ascents.base, 0.25) }}
								>
									<Text ascent={progress < 1 ? undefined : 'white'}>
										{control.totalPassedChildControls}/{control.totalChildControls}
									</Text>
								</Box>
							</Box>
						</Tooltip>
					)}
				</Grid>
			</Grid>
		)
	}

	const RenderCustomTooltip = (tooltip) => {
		const data = tooltip.data[0]
		const x = tooltip.index

		return data ? (
			<ChartCustomTooltipWrapper>
				<Text fontWeight={500} fontFamily='Open Sans' margin='0 0 0.5rem 0' fontSize={16}>
					Controls
				</Text>
				<Text>Ref code: {x}</Text>
				<Text>Implemented: {dataByKeys[x]['Implemented controls']}</Text>
				<Text>Total: {dataByKeys[x]['Total controls']}</Text>
			</ChartCustomTooltipWrapper>
		) : null
	}

	// Custom legend component
	// eslint-disable-next-line no-unused-vars
	const CustomLegend = ({ legends }) => (
		<div>
			{legends.map((legend, index) => (
				<div key={index} style={{ fontSize: '14px' }}>
					{legend.symbol} {legend.label}
				</div>
			))}
		</div>
	)

	useEffect(() => {
		if (!compliancesIsLoading && compliancesByKeys[complianceType] && activeTabIndex === 0) {
			const fetchDataApi = fetchData()
			return () => {
				fetchDataApi.cancel()
			}
		}
	}, [compliancesIsLoading, complianceType, compliancesByKeys, activeTabIndex])

	// console.log('chartData', chartData)
	// console.log('complianceType', complianceType)
	return isLoadingData || compliancesIsLoading ? (
		<Loader />
	) : (
		<Grid container spacing={3}>
			<Grid item xs={6}>
				<CardWrapperByRatio
					hideShadow
					aspectRatio={4 / 5.5}
					// title={'Control summary'}
					paperProps={{
						sx: {
							overflow: 'visible'
						}
					}}
				>
					<CustomNivoChart
						title={'Control summary'}
						data={chartData}
						letParentHandleState
						type={chartTypes.RADAR}
						maxWidth='xxl'
						gridShape={'circular'}
						onlyGraph
						sliceTooltip={RenderCustomTooltip}
						colors={[ASCENTS.greyBlue, ASCENTS.primary]}
						dotSize={10}
						legends={[
							{
								anchor: 'bottom-left',
								direction: 'column',
								translateX: -0,
								translateY: -45,
								itemWidth: 80,
								itemHeight: 20,
								itemTextColor: ASCENTS.textDark,
								symbolSize: 12,
								symbolShape: 'circle'
							}
						]}
					/>
				</CardWrapperByRatio>
			</Grid>
			<Grid item xs={6}>
				<WrapperCard hideShadow>
					{controls.map(RenderControlProgress)}
					<Grid container spacing={3} component={Box} mt={6}>
						<Grid item xs={12}>
							<Box display='flex' alignItems='center'>
								<Box
									width={16}
									height={16}
									sx={{ mr: 2, backgroundColor: (theme) => fadeColor(theme.palette.ascents.base, 0.25) }}
								/>
								<Text>Implemented Sub-controls</Text>
							</Box>
							<Box display='flex' alignItems='center' mt={1.5}>
								<Box
									width={16}
									height={16}
									sx={{ mr: 1, backgroundColor: (theme) => fadeColor(theme.palette.ascents.base, 0.95) }}
								/>
								<Text>Total number of Sub-controls</Text>
							</Box>
						</Grid>
					</Grid>
				</WrapperCard>
			</Grid>
		</Grid>
	)
}

export default ComplianceDashboard
