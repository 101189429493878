import WrapperCard from '@commons/Card/WrapperCard'
import Icon from '@commons/Icon'
import Loader from '@commons/Loader'
import AppModal from '@commons/Modal/AppModal'
import RenderControlStatus from '@commons/RenderControlStatus'
import ZeronDataTable from '@commons/Table/ZeronDataTable'
import Text from '@commons/Text'
import { customEvents } from '@constants/analytics'
import { Delete } from '@mui/icons-material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box, IconButton, Tooltip } from '@mui/material'
import { getAllDocs } from '@services/complianceService'
import { ASCENTS } from '@styles/theme'
import { formatDateTime } from '@utils/other'
import mixpanel from 'mixpanel-browser'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
// import { useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'

const ComplianceUploadedPolicies = ({
	deleteDocument = () => { },
	membersById = {},
	// eslint-disable-next-line no-unused-vars
	deletePoliciesDocument,
	isLoading,
	frameworkId,
	uploadedPolicyList = []
}) => {
	const [isOpenModal, setIsModalOpen] = useState(false)

	const [rowDetails, setRowDetails] = useState([])
	const policyByRouteName = useSelector((state) => state.auth.policyByRouteName)
	// eslint-disable-next-line no-unused-vars
	const onRowClickCustomHandler = (state) => (e) => {
		setIsModalOpen(true)
		setRowDetails(state)
	}

	const handleClose = () => {
		if (!isOpenModal) {
			mixpanel.time_event(customEvents.TOGGLED_MODAL)
		} else {
			mixpanel.track(customEvents.TOGGLED_MODAL, {
				url: window.location.pathname,
				title: `Compliance Document Details`
			})
		}
		setIsModalOpen(false)
	}

	const columns = useMemo(
		() => [
			{
				name: 'control_details.ref_code',
				// sortKey: 'ref_code',
				label: 'Control Id',
				searchKey: 'ref_code',
				options: {
					enableSorting: false,
					sort: false,
					// viewColumns: false,
					filter: false,
					print: false,
					cell: (info) => {
						const value = info.getValue()
						return value ? value : 'N/A'
					}
				}
			},
			{
				name: 'control_details.category',
				// sortKey: 'category',
				// searchKey: 'category',
				label: 'Control Category',
				options: {
					enableSorting: false,
					sort: false,
					// viewColumns: false,
					filter: false,
					print: false,
					cell: (info) => {
						const value = info.getValue()
						return value ? value : 'N/A'
					}
				}
			},
			{
				name: 'assigned_to',
				label: 'Assigned to',
				// sortKey: 'assigned_to',
				// searchKey: 'assigned_to',
				options: {
					enableSorting: false,
					cell: (info) => {
						const value = Number(info.getValue())
						return Object.keys(membersById).length > 0 ? (
							<Text fontWeight={value && !!membersById[value] ? 600 : 400} fontSize={12}>
								{value && !!membersById[value] ? membersById[value]?.username : 'Not assigned'}
							</Text>
						) : (
							<Text fontSize={12}>Loading...</Text>
						)
					},
					exportAs: (info) => {
						const value = info.assigned_to
						const userName =
							value && !!membersById[value]
								? `${membersById[value]?.first_name} ${membersById[value]?.last_name}`
								: 'Not assigned'
						return userName
					}
				}
			},
			{
				name: 'status',
				label: 'Status',
				options: {
					enableSorting: false,
					cell: (info) => {
						const value = info.getValue()
						return <RenderControlStatus value={value} />
					},
					exportAs: (info) => {
						const value = info.status
						return value ? value : 'Draft'
					}
				}
			},
			{
				name: 'date',
				label: 'Date',
				// sortKey: 'date',
				options: {
					enableSorting: false,
					cell: (info) => {
						const value = info.getValue()
						return value && value !== '--'
							? formatDateTime(value, {
								format: 'DD MMMM YYYY'
							})
							: '---'
					},
					exportAs: (info) => {
						const value = info.date
						return value && value !== '--'
							? formatDateTime(value, {
								format: 'DD MMMM YYYY'
							})
							: '---'
					}
				}
			},
			{
				name: 'control_details.description',
				label: 'Description',
				// searchKey: 'description',
				// sortKey: 'description',
				options: {
					enableSorting: false,
					colSpan: 2,
					cell: (info) => info.row.original?.control_details?.description
				}
			},
			{
				name: 'id',
				label: 'Action',
				options: {
					enableSorting: false,
					cell: (info) => {
						const value = info.getValue()
						return (
							value && (
								<>
									{info.row.original.documents && (
										<Tooltip
											arrow
											placement='left'
											title={
												<Box overflow={'scroll'} maxHeight={'25rem'} minWidth={'15rem'} p={2}>
													<Text fontSize={16} fontWeight={600} mb={2} sx={{ color: `${ASCENTS.primary}` }}>
														All Uploaded Documents
													</Text>
													{info.row.original?.documents?.map((ele) => {
														const fileName = decodeURI(
															ele.replace('devzeron/compliance_documents/', '').split('?')[0].split('/').pop()
														)
														return (
															<>
																<Box display='flex' justifyContent={'space-between'} alignItems={'center'}>
																	<Tooltip
																		title='Depending on the compatibility of the browser, the uploaded file will either
														download or open.'
																	>
																		<Link
																			underline='always'
																			to={ele}
																			target='_blank'
																			onClick={(e) => e.stopPropagation()}
																		>
																			<Text fontSize={16}>{fileName}</Text>
																		</Link>
																	</Tooltip>
																</Box>
															</>
														)
													})}
												</Box>
											}
										>
											<IconButton padding={'0.375rem 1rem'}>
												<Icon fontSize={'1rem'}>
													<VisibilityIcon />
												</Icon>
											</IconButton>
										</Tooltip>
									)}
									<IconButton padding={'0.375rem 1rem'} onClick={deleteDocument(value)}>
										<Icon fontSize={'1rem'}>
											<Delete />
										</Icon>
									</IconButton>
								</>
							)
						)
					}
				},

				filter: false,
				print: false,
				searchable: false,
				sort: false,
				download: false,
				exportAs: (info) => {
					return info.document
				}
			}
		],
		[membersById, JSON.stringify(uploadedPolicyList)]
	)

	return isLoading || Object.keys(membersById).length === 0 ? (
		<Box display='flex' justifyContent='center' alignItems='center' height='100%'>
			<Loader />
		</Box>
	) : (
		<>
			<WrapperCard>
				<ZeronDataTable
					columns={columns}
					apiFuncService={getAllDocs}
					apiFuncQueryParams={{
						framework_id: frameworkId,
						status: 'Published'
					}}
					disableFilter
					// disableSearchBy
					enableCustomRestructureResponse
					enableRowClick
					onRowClickCustomHandler={onRowClickCustomHandler}
				/>
				<AppModal fullWidth maxWidth='md' hideClose open={isOpenModal} onClose={handleClose} title='Document details'>
					<Text ascent='primary' fontWeight='500' fontSize='1.25rem'>
						Control Id
					</Text>

					<Text margin='0 0 1rem 1rem'>{rowDetails?.control_details?.ref_code || 'N.A'}</Text>

					<Text ascent='primary' fontWeight='500' fontSize='1.25rem'>
						Description
					</Text>

					<Text margin='0 0 1rem 1rem'>{rowDetails?.control_details?.description || 'N.A'}</Text>

					<Text ascent='primary' fontWeight='500' fontSize='1.25rem'>
						Category
					</Text>

					<Text margin='0 0 1rem 1rem'>{rowDetails?.control_details?.category || 'N.A'}</Text>
				</AppModal>
			</WrapperCard>
		</>
	)
}

export default ComplianceUploadedPolicies
