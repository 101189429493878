import { Box } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const AppDatePicker = ({
	handleDateChange = () => {},
	selectedDate,
	inputClassName,
	wrapperClassName,
	rootProps,
	disablePast = false,
	...props
}) => {
	return (
		<Box className={wrapperClassName} {...rootProps}>
			<DatePicker
				ampm={false}
				value={selectedDate}
				onChange={handleDateChange}
				onError={console.warn}
				format='DD/MM/YYYY'
				allowKeyboardControl={false}
				placeholder='DD/MM/YYYY'
				className={inputClassName}
				autoOk
				disablePast={disablePast}
				{...props}
				sx={
					disablePast
						? {
								...(props.sx ? props.sx : {}),
								width: '100%',
								'& .MuiOutlinedInput-notchedOutline': {
									borderColor: 'rgb(0 0 0 / 23%) !important'
								}
						  }
						: props.sx
				}
			/>
		</Box>
	)
}

export default AppDatePicker
