/* eslint-disable react/display-name */
import { severityLevels } from '@constants/Chart/chartOptions'
import HostSecurityConfigRaiseTicket from '@layouts/Host/HostSecurityConfigRaiseTicket'
import { getAgents } from '@services/agentService'
import { getElasticData } from '@services/graphService'
import { getUsersOrganisation } from '@services/userService'
import { useLoadingStatus } from '@utils/hooks'
import { SvgIcons } from '@utils/icons'
import { generateInvisibleColumnOption } from '@utils/other'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import Bullet from '../Bullet'
import Icon from '../Icon'
import AutoRowDetails from './AutoRowDetails'
import Table from './Table'

const OpenSearchDataTable = memo(
	({
		showActionColumn = false,
		columns = [],
		elasticIndex = import.meta.env.VITE_APP_OS_DB_INDEX || 'zeron-alerts-*',
		elasticQuery = {},
		letParentHandleState = false,
		tableData = [],
		detailsOnRowClick = false,
		// newRawData = false,
		disableGroupBy = true,
		useAggregationsData = false,
		disableSearchBy = false,
		disableFilter = false,
		dontSendBoolInQuery = false,
		enableDynamicColumns = false,
		includeManager = false,
		height = null,
		sizeParam,
		filterPaths,
		hiddenKeysForRowDetails = [],
		renderRowDetailsHeading,
		restructureExportableData = () => {},
		reformatRowDetailsForDrawer
	}) => {
		const [rawData, setRawData] = React.useState({})
		const [noData, setNoData] = React.useState(false)
		const [noDataText, setNoDataText] = React.useState('')
		const [sorting, setSorting] = React.useState([])
		const [data, setData] = React.useState([])
		const [rowDetails, setRowDetails] = React.useState({})
		const [openDrawer, setOpenDrawer] = React.useState(false)
		const [selectedColumns, setSelectedColumns] = React.useState([...columns])
		const [invisibleFieldColumns, setInvisibleFieldColumns] = React.useState([])
		const [organisation, setOrganisation] = React.useState({
			members: [],
			memberById: {}
		})
		const [orgMembersList, setOrgMembersList] = React.useState([])
		const userRedux = useSelector((state) => state.auth.user)
		const [apiResponse, setApiResponse] = React.useState({
			response: null,
			reset: false
		})
		const [totalCountExport, setTotalCountExport] = React.useState(0)

		// console.log("SizeParam", sizeParam)

		const [agents, setAgents] = React.useState({})
		const [selectedFiltersById, setSelectedFiltersById] = React.useState({})

		const handleUpdateSelectedColumns = (newlySelectedColumns) => {
			setSelectedColumns(newlySelectedColumns)
		}

		const [searchField, setSearchField] = React.useState({
			searchBy: '',
			text: ''
		})

		const { loading, startLoading, stopLoading } = useLoadingStatus()

		// eslint-disable-next-line no-unused-vars
		const searchByRef = React.useRef()

		const reloadToken = useSelector((state) => state.reloadToken)

		const [tablePaginationInfo, setTablePaginationInfo] = React.useState({
			rowsPerPage: 10,
			page: 0
		})

		const handleClose = () => setOpenDrawer(false)

		const onRowClickCustomHandler = (state) => () => {
			setOpenDrawer(true)
			const reformattedRowDetails = typeof reformatRowDetailsForDrawer === 'function' ? reformatRowDetailsForDrawer(state) : state
			setRowDetails(useAggregationsData ? Object.flatten(reformattedRowDetails) : reformattedRowDetails)
		}

		const actualTableData = React.useMemo(() => {
			const dataToFilter = letParentHandleState ? tableData : data
			//
			if (showActionColumn) {
				const filteredData = dataToFilter.map((each) => ({
					...each,
					ticketData: {
						id: '',
						title: each[selectedColumns.find((col) => col?.name?.includes('description'))?.name],
						description: selectedColumns
							.map((c) => c.name)
							.filter((c) => !c.includes('description'))
							.reduce((acc, cv) => {
								return `${acc}<p>${cv}</p><p>${each[cv]}</p>`
							}, ''),
						memberList: orgMembersList,
						memberListById: organisation.memberById
					}
				}))

				return filteredData
			}

			return dataToFilter
		}, [letParentHandleState, tableData, data, showActionColumn])

		const actualTableColumn = React.useMemo(() => {
			if (showActionColumn) {
				return columns.concat({
					name: 'ticketData',
					label: 'Action',
					options: {
						cell: (info) => {
							const value = info.getValue()

							return (
								<HostSecurityConfigRaiseTicket
									id={value.id}
									title={value.title}
									description={value.description}
									memberList={value.memberList}
									memberListById={value.memberListById}
								/>
							)
						},
						enableSorting: false,
						filter: false,
						print: false,
						searchable: false,
						sort: false,
						download: false
					}
				})
			}
			return columns
		}, [showActionColumn, columns])

		const fetchOrganisation = () => {
			const organisationApi = getUsersOrganisation()

			organisationApi.api().then((response) => {
				if (response) {
					if (!response.error) {
						setOrganisation({
							...response,
							// TODO: Implement REDUX for THIS API
							memberById: {
								[userRedux.info.user.id]: userRedux.info.user,
								...response.results.data.members.reduce((obj, item) => {
									obj[item.id] = item
									return obj
								}, {})
							}
						})

						const memberOptions = response.results.data.members.map((each) => ({
							label: `${each.first_name || each.last_name ? each.first_name + ' ' + each.last_name + ' - ' : ''}${
								each.email
							}`,
							value: each.id
						}))

						setOrgMembersList(memberOptions)
					}
				}
			})

			return organisationApi
		}

		const filterOptions = React.useMemo(() => {
			return [
				{
					title: 'Host',
					requestBodyKey: 'agent.name',
					filters: Object.values(agents).map((each, index) => ({
						...each,
						id: each.id,
						text: each.name,
						value: each.name,
						filterType: 'match',
						index,
						LeftIcon: (props) => (
							<Icon block fontSize={16} ascent={props.isSelected ? 'white' : 'grey'}>
								<SvgIcons.HOST_PC_SVG />
							</Icon>
						)
					}))
				},
				{
					title: 'Severity Level',
					requestBodyKey: 'rule.level',
					filters: Object.values(severityLevels).map((each, index) => ({
						...each,
						id: each.id,
						index,
						filterType: 'range',
						text: each.label,
						value: {
							gt: each.greaterThan,
							lte: each.lessThanOrEqual
						},
						// eslint-disable-next-line no-unused-vars
						LeftIcon: (props) => <Bullet color={each.color} />
					}))
				}
			]
		}, [JSON.stringify(agents)])

		const updatePage = React.useCallback(() => {
			if (data.length > 0 && !useAggregationsData) {
				setTablePaginationInfo((state) => ({
					...state,
					rowsPerPage: state.rowsPerPage + 10,
					page: state.page + 1
				}))
			}
		}, [JSON.stringify(data)])

		const getUpdatedQueryForSearch = (searchBy, searchText, reset = false) => {
			if (reset || (!(searchBy && searchText) && !(searchField.searchBy && searchField.text))) {
				return undefined
			}

			return {
				wildcard:
					searchBy && searchText
						? {
								[searchBy]: {
									// NOTE: Why `\\ `? To escape the whitespace while searching in DB
									value: `*${searchText.split(' ').join('\\ ')}*`,
									case_insensitive: true
								}
						  }
						: {
								[searchField.searchBy]: {
									// NOTE: Why `\\ `? To escape the whitespace while searching in DB
									value: `*${searchField.text.split(' ').join('\\ ')}*`,
									case_insensitive: true
								}
						  }
			}
		}

		const getQueryObjectForFilter = (key, itemList) => {
			if (!Array.isArray(itemList) || !key) return
			if (itemList.find((each) => !each.filterType) || itemList.find((each) => !each.value)) return

			// This is for agent filter
			if (itemList.find((each) => each.filterType === 'match')) {
				return {
					bool: {
						should: itemList.map((item) => ({
							match: {
								[key]: item.value
							}
						})),
						minimum_should_match: 1
					}
				}
			}
			// This is for severity filter
			else if (itemList.find((each) => each.filterType === 'range')) {
				return {
					bool: {
						should: itemList.map((item) => ({
							range: {
								[key]: item.value
							}
						}))
					}
				}
			}
		}

		const getFilterQuery = (filterData) => {
			const filters = []

			const queriesList = Object.entries(filterData)

			if (queriesList.length > 0) {
				// Object.entries(filterData)
				for (let index = 0; index < queriesList.length; index++) {
					const [key, queryList] = queriesList[index]

					const filterQuery = getQueryObjectForFilter(key, queryList)

					if (filterQuery) filters.push(filterQuery)
				}
			}

			return filters
		}

		const setStateFromResponse = (response, reset) => {
			stopLoading()
			if (response) {
				if (!response.error) {
					const items = useAggregationsData
						? response &&
						  response.aggregations &&
						  Object.byString(response.aggregations, `${Object.keys(response.aggregations)[0]}.buckets`)
							? Object.byString(response.aggregations, `${Object.keys(response.aggregations)[0]}.buckets`)
							: []
						: response?.hits?.hits
						? response.hits.hits
						: []

					if (items.length === 0 && data.length === 0) {
						return setNoData(true)
					}

					if (!reset && tablePaginationInfo.page > 0) {
						setRawData(response)
						return setData((state) => state.concat(items))
					}

					setData(items)
					setRawData(response)
				} else {
					setNoData(true)
				}
			} else {
				setNoData(true)
			}
		}

		const fetchData = (reset) => {
			setNoData(false)

			startLoading()

			const queryForSearch = getUpdatedQueryForSearch('', '', reset)

			const ESTableData = getElasticData({
				onError: () => {
					stopLoading()
				}
			})
			const query = dontSendBoolInQuery
				? elasticQuery.query
				: {
						...elasticQuery.query,
						bool: {
							...elasticQuery?.query?.bool,
							must: queryForSearch
								? Array.isArray(elasticQuery.query?.bool?.must)
									? elasticQuery.query.bool?.must?.concat(queryForSearch)
									: [queryForSearch]
								: elasticQuery.query?.bool?.must,
							filter: elasticQuery.query?.bool?.filter
								? elasticQuery.query?.bool?.filter?.concat(getFilterQuery(selectedFiltersById))
								: getFilterQuery(selectedFiltersById)
						}
				  }
			ESTableData.api({
				data: {
					body: {
						...elasticQuery,
						from: reset ? 0 : data.length,
						size: filterPaths ? 0 : sizeParam > 0 ? sizeParam : 10,
						sort: [
							...sorting.map((each) => {
								const item = selectedColumns.find((e) => e.name === each.id)
								return {
									[item.sortKey]: {
										order: each.desc ? 'desc' : 'asc'
									}
								}
							})
						],
						query
					},
					index: elasticIndex,
					include_manager: includeManager,
					filter_paths: filterPaths
				}
			})
				.then((response) => {
					if (response) {
						if (response?.code === 1000) {
							setNoDataText(response?.detail)
							stopLoading()
							return
						} else {
							// console.log("This is open search response", response)
							setTotalCountExport(response.hits.total.value)
							setApiResponse({
								response,
								reset
							})
						}
					}
					stopLoading()
				})
				.catch(() => {
					stopLoading()
				})

			return ESTableData
		}

		// const fetchDataExport = (reset) => {
		// 	setNoData(false)

		// 	startLoading()

		// 	const queryForSearch = getUpdatedQueryForSearch('', '', reset)

		// 	const ESTableDataexport = getElasticData({
		// 		onError: () => {
		// 			stopLoading()
		// 		}
		// 	})
		// 	const query = dontSendBoolInQuery
		// 		? elasticQuery.query
		// 		: {
		// 				...elasticQuery.query,
		// 				bool: {
		// 					...elasticQuery?.query?.bool,
		// 					must: queryForSearch
		// 						? Array.isArray(elasticQuery.query?.bool?.must)
		// 							? elasticQuery.query.bool?.must?.concat(queryForSearch)
		// 							: [queryForSearch]
		// 						: elasticQuery.query?.bool?.must,
		// 					filter: elasticQuery.query?.bool?.filter
		// 						? elasticQuery.query?.bool?.filter?.concat(getFilterQuery(selectedFiltersById))
		// 						: getFilterQuery(selectedFiltersById)
		// 				}
		// 		  }
		// 	ESTableDataexport.api({
		// 		data: {
		// 			body: {
		// 				...elasticQuery,
		// 				from: reset ? 0 : data.length,
		// 				size: totalCountExport > 10000 ? 10000 : totalCountExport,
		// 				sort: [
		// 					...sorting.map((each) => {
		// 						const item = selectedColumns.find((e) => e.name === each.id)
		// 						return {
		// 							[item.sortKey]: {
		// 								order: each.desc ? 'desc' : 'asc'
		// 							}
		// 						}
		// 					})
		// 				],
		// 				query
		// 			},
		// 			index: elasticIndex,
		// 			include_manager: includeManager
		// 		}
		// 	})
		// 		.then((response) => {
		// 			if (response) {
		// 				if (response.error) {
		// 					stopLoading()
		// 					return
		// 				}
		// 				// console.log("This is open search response final for export", response)
		// 				// setApiResponse({
		// 				// 	response,
		// 				// 	reset
		// 				// })
		// 			}
		// 			stopLoading()
		// 		})
		// 		.catch(() => {
		// 			stopLoading()
		// 		})

		// 	// return ESTableData
		// }

		const refreshState = () => {
			setData([])

			setRawData({})

			setNoData(false)

			setApiResponse({
				response: null,
				reset: false
			})

			setSearchField({
				searchBy: '',
				text: ''
			})

			setTablePaginationInfo({
				rowsPerPage: 10,
				page: 0
			})
		}

		const resetSearchResult = () => {
			setNoData(false)

			setSearchField({
				searchBy: '',
				text: ''
			})

			fetchData(true)
		}
		const resetFilter = React.useCallback(() => {
			setSelectedFiltersById({})

			fetchData(true)
		}, [])

		const fetchOnSearch = React.useCallback(
			(searchBy, searchText) => {
				setNoData(false)

				setRawData({})
				setData([])
				startLoading()

				const searchByCol = generateInvisibleColumnOption(`_source.${searchBy}`)

				if (!selectedColumns.find((col) => col.name === searchByCol.name)) {
					handleUpdateSelectedColumns([...selectedColumns, searchByCol])
				}

				const queryForSearch = getUpdatedQueryForSearch(searchBy, searchText)

				const ESTableData = getElasticData({
					onError: () => {
						stopLoading()
					}
				})

				ESTableData.api({
					data: {
						body: {
							...elasticQuery,
							from: 0,
							size: 10,
							sort: [
								...sorting.map((each) => {
									const item = selectedColumns.find((e) => e.name === each.id)
									return {
										[item.sortKey]: {
											order: each.desc ? 'desc' : 'asc'
										}
									}
								})
							],

							query: {
								...elasticQuery.query,
								bool: {
									...elasticQuery.query?.bool,
									must: queryForSearch
										? Array.isArray(elasticQuery.query?.bool?.must)
											? elasticQuery.query?.bool?.must.concat(queryForSearch)
											: [queryForSearch]
										: elasticQuery.query?.bool?.must,
									filter: elasticQuery.query?.bool?.filter
										? elasticQuery.query?.bool?.filter.concat(getFilterQuery(selectedFiltersById))
										: getFilterQuery(selectedFiltersById)
								}
							}
						},
						index: elasticIndex,
						include_manager: includeManager
					}
				})
					.then((response) => {
						// console.log("This is open search response search ", response.hits.total.value)
						if (response?.code === 1000) {
							setNoDataText(response?.detail)
						} else {
							setApiResponse({ response, reset: true })
						}
					})
					.then(() => {
						setSearchField({
							searchBy,
							text: searchText
						})
					})
					.catch(() => {
						stopLoading()
					})

				return ESTableData
			},
			[JSON.stringify(selectedColumns), JSON.stringify(elasticQuery), elasticIndex, JSON.stringify(sorting)]
		)

		const fetchOnChangeFilter = (selectedFilter) => {
			setNoData(false)

			setRawData({})
			setData([])
			startLoading()

			const queryForSearch = getUpdatedQueryForSearch()

			const ESTableData = getElasticData({
				onError: () => {
					stopLoading()
				}
			})

			const query = dontSendBoolInQuery
				? elasticQuery.query
				: {
						...elasticQuery.query,
						bool: {
							...elasticQuery.query.bool,
							must: queryForSearch
								? elasticQuery.query.bool.must
									? elasticQuery.query.bool.must.concat(queryForSearch)
									: [queryForSearch]
								: elasticQuery.query?.bool?.must,
							filter: elasticQuery.query?.bool?.filter
								? elasticQuery.query.bool.filter.concat(getFilterQuery(selectedFilter))
								: getFilterQuery(selectedFilter)
						}
				  }

			ESTableData.api({
				data: {
					body: {
						...elasticQuery,
						from: 0,
						size: 10,
						sort: [
							...sorting.map((each) => {
								const item = selectedColumns.find((e) => e.name === each.id)
								return {
									[item.sortKey]: {
										order: each.desc ? 'desc' : 'asc'
									}
								}
							})
						],
						query
					},
					index: elasticIndex,
					include_manager: includeManager
				}
			})
				.then((response) => {
					// console.log("This is open search response filter", response.hits.total.value)
					if (response?.code === 1000) {
						setNoDataText(response?.detail)
					} else {
						setApiResponse({ response, reset: true })
					}
				})
				.then(() => {
					setSelectedFiltersById({
						...selectedFilter
					})
				})
				.catch(() => {
					stopLoading()
				})

			return ESTableData
		}

		const fetchAgents = React.useCallback(() => {
			const agent = getAgents()

			agent.api().then((response) => {
				if (response) {
					if (!response.error && response.results?.data) {
						// Converts array of objects into object of objects
						setAgents(
							response.results.data.reduce((obj, item) => {
								obj[item.id] = { ...item }
								return obj
							}, {})
						)
					}
				}
			})
			return agent
		}, [])

		React.useEffect(() => {
			if (showActionColumn) {
				const request = fetchOrganisation()

				return () => {
					request.cancel()
				}
			}
		}, [showActionColumn])

		React.useEffect(() => {
			refreshState()
			const requestToken = fetchData(!!reloadToken)
			const agentRequestToken = fetchAgents()

			return () => {
				requestToken.cancel()
				agentRequestToken.cancel()
			}
		}, [reloadToken, JSON.stringify(elasticQuery)])

		React.useEffect(() => {
			if (apiResponse.response) {
				setStateFromResponse(apiResponse.response, apiResponse.reset)
			}
		}, [JSON.stringify(apiResponse)])

		React.useEffect(() => {
			if (Object.keys(elasticQuery).length > 0 && sorting.length > 0) {
				const requestToken = fetchData(sorting.length > 0)
				return () => {
					requestToken.cancel()
				}
			}
		}, [JSON.stringify(sorting)])

		React.useEffect(() => {
			if (tablePaginationInfo.page > 0) {
				const requestToken = fetchData()

				return () => {
					requestToken.cancel()
				}
			}
		}, [JSON.stringify(tablePaginationInfo)])

		React.useEffect(() => {
			if (invisibleFieldColumns.length === 0 && data.length > 0) {
				const keys = Object.keys(useAggregationsData ? data[0] || {} : Object.flatten((data[0] || {})._source))
				setInvisibleFieldColumns(keys)
			}
		}, [data])

		// React.useEffect(() => {
		// 	fetchDataExport()
		// }, [totalCountExport])

		return (
			<>
				<Table
					dataToExport={restructureExportableData(actualTableData)}
					enableDynamicColumns={enableDynamicColumns}
					columns={actualTableColumn}
					selectedColumns={selectedColumns}
					handleUpdateSelectedColumns={handleUpdateSelectedColumns}
					invisibleFieldColumns={invisibleFieldColumns}
					data={actualTableData}
					totalSize={
						useAggregationsData && rawData?.aggregations
							? Object.byString(rawData.aggregations, `${Object.keys(rawData.aggregations)[0]}.buckets`).length
							: rawData?.hits?.total?.value
					}
					fetchMore={updatePage}
					loading={loading}
					setSorting={setSorting}
					sorting={sorting}
					fetchOnSearch={fetchOnSearch}
					disableSearchBy={disableSearchBy}
					disableFilter={disableFilter}
					resetSearchResult={resetSearchResult}
					isEmpty={noData}
					options={{
						manualSorting: true
					}}
					showLoadMoreButton
					filterProps={{
						items: filterOptions,
						fetchOnChangeFilter,
						selectedFiltersById,
						onFilterReset: resetFilter
					}}
					disableGroupBy={disableGroupBy}
					onRowClickCustomHandler={onRowClickCustomHandler}
					enableRowClick={detailsOnRowClick}
					height={height}
					noDataText={noDataText}
				/>
				{detailsOnRowClick && (
					<AutoRowDetails
						handleClose={handleClose}
						hiddenKeysForRowDetails={hiddenKeysForRowDetails}
						openDrawer={openDrawer}
						renderRowDetailsHeading={renderRowDetailsHeading}
						rowDetails={rowDetails}
						useAggregationsData={useAggregationsData}
					/>
				)}
			</>
		)
	}
)

export default OpenSearchDataTable
