import { Box } from '@mui/material'
import { ASCENTS } from '@styles/theme'
import { memo } from 'react'

// eslint-disable-next-line react/display-name
const Bullet = memo(({ size = 16, ascent = 'red', color }) => {
	return <Box component='span' width={size} height={size} borderRadius={size} bgcolor={color || ASCENTS[ascent]} display='block' />
})

export default Bullet
