import { timeFilters } from '@constants/Chart/chartOptions'
import { customEvents } from '@constants/analytics'
import { Box, ClickAwayListener, Grow, Paper, Popper, useTheme } from '@mui/material'
import { renewTimeFrame, updateCustomTimeRange, updateInterval, updateQuickFilter } from '@redux/timeFrame/slice'
import { icons } from '@utils/icons'
import { DEFAULT_TIMESTAMP_FORMAT, checkDateIsAfter, hourFormatter, logGAEvent } from '@utils/other'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../Button/Button'
import WrapperCard from '../Card/WrapperCard'
import Text from '../Text'

import AppDatePicker from '@layouts/Search/AppDatePicker'
import moment from 'moment'

const DateRangeDropdown = ({
	startDate = {},
	endDate = {},
	handleEndDateChange,
	handleStartDateChange,
	resetDatesinLocal,
	setShowSaveBtn = () => {},
	showSaveBtn = false
}) => {
	const [open, setOpen] = React.useState(false)
	const theme = useTheme()
	const anchorRef = React.useRef()

	const prevOpen = React.useRef(open)

	const quickFilter = useSelector((state) => state.timeFrame.quickFilter)

	const intervalsRedux = useSelector((state) => state.timeFrame.intervals)

	const dispatch = useDispatch()

	const [filtersByTime] = React.useState({ ...timeFilters })

	const [intervalID, setIntervalID] = React.useState(null)

	const [readyToDispatch, setReadyToDispatch] = React.useState(false)

	const [error, setError] = React.useState('')

	const validateDateIsAfter = startDate.value && endDate.value && checkDateIsAfter(startDate.date, endDate.date)
	// && validateDurataionIsLessThanGivenDays(startDate.date, endDate.date, 90);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleSaveBtnClick = () => {
		setReadyToDispatch(true)
	}

	const updateDatesOnInterval = () => {
		// Will always contain a previous state value

		// console.log("intervalID-updateDatesOnInterval", intervalID);
		dispatch(renewTimeFrame())
	}

	// eslint-disable-next-line no-unused-vars
	const handleIntervalChange =
		(timeFrame = {}) =>
		() => {
			if (startDate.date && endDate.date) {
				resetDatesinLocal()
			}

			if (timeFrame.index === intervalsRedux.index && timeFrame.type === intervalsRedux.type) {
				return
			}

			setOpen(false)
			dispatch(updateInterval(timeFrame))
		}

	const updateDates = () => {
		// console.log("startDate", startDate, endDate);
		if (startDate.date && endDate.date) {
			if (validateDateIsAfter) {
				dispatch(
					updateCustomTimeRange({
						startDate: startDate.date.toISOString(),
						endDate: endDate.date.toISOString()
					})
				)
				setShowSaveBtn(false)
				setReadyToDispatch(false)
				setOpen(false)
				logGAEvent(customEvents.DURATION_CHANGED_IN_TOOLBAR, {
					event_label: `duration changed in toolbar - ${startDate.value} to ${endDate.value}`
				})
			} else {
				setShowSaveBtn(false)
				setReadyToDispatch(false)
			}
		} else {
			setShowSaveBtn(false)
			setReadyToDispatch(false)
			dispatch(renewTimeFrame())
		}
	}

	const handleQuickFilterChange =
		(timeFrame = {}) =>
		() => {
			if (timeFrame.index === quickFilter.index && timeFrame.type === quickFilter.type) {
				return
			}

			resetDatesinLocal()
			setOpen(false)
			dispatch(updateQuickFilter(timeFrame))
		}

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}

		setOpen(false)
	}

	React.useEffect(() => {
		if (prevOpen.current === true && open === false && !!anchorRef.current) {
			anchorRef.current.focus()
		}
		prevOpen.current = open
	}, [open, anchorRef])

	// React.useEffect(() => {
	// 	if (startDate.date && endDate.date) {
	// 	} else {
	// 		// dispatch(updateStartEndDateDispatcher());
	// 	}
	// }, [startDate, endDate])

	React.useEffect(() => {
		if (!!startDate.date && !!endDate.date && !!intervalID) {
			// console.log("intervalID-IF", intervalID);

			clearInterval(intervalID)
		} else {
			if (intervalsRedux.value > 0) {
				let timerID

				if (!intervalID) {
					timerID = setInterval(() => {
						updateDatesOnInterval()
					}, intervalsRedux.value * 1000)

					setIntervalID(timerID)

					// console.log("intervalID-ELSE_IF", intervalID);
				} else {
					clearInterval(intervalID)

					timerID = setInterval(() => {
						updateDatesOnInterval()
					}, intervalsRedux.value * 1000)

					setIntervalID(timerID)
					// console.log("intervalID-ELSE_ELSE", intervalID);
				}

				return () => {
					// console.log("intervalID-CLEANUP", intervalID);
					clearInterval(timerID)
				}
			}
		}
	}, [intervalsRedux.value, startDate, endDate])

	React.useEffect(() => {
		if (startDate.value && endDate.value) {
			if (!checkDateIsAfter(startDate.date, endDate.date)) {
				setError('End date must be greater than start date.')
			}
			// else if (
			//     !validateDurataionIsLessThanGivenDays(
			//         startDate.date,
			//         endDate.date,
			//         365
			//     )
			// ) {
			//     setError("Duration must be less than or equal to 90 days.");
			// }
			else {
				setError('')
			}
		}
	}, [startDate.value, endDate.value])

	React.useEffect(() => {
		if (readyToDispatch) {
			updateDates()
		}
	}, [startDate, endDate, readyToDispatch])

	// console.log({ startDate, endDate })

	return (
		<Box
			mr={2}
			id='dateRangeDropdown'
			sx={{
				flex: '1 1 auto',
				minWidth: '27%',

				'& .MuiButton-root': {
					borderRadius: '0.5rem',
					padding: '0.75rem !important',
					fontSize: '13px',
					width: '100%',
					color: startDate.value && endDate.value && !validateDateIsAfter ? 'ascent.red' : undefined,

					'&:hover': {
						boxShadow: 'none !important'
					}
				},

				[theme.breakpoints.down('md')]: {
					minWidth: 'unset',
					boxShadow:
						'0px 0px 4px -1px rgb(188 193 221 / 15%), 0px 1px 20px 1px rgb(188 193 221 / 25%), 0px 0px 15px 5px rgb(188 193 221 / 20%)',
					borderRadius: '0.75rem',

					'& .MuiButton-root': {
						minWidth: 0,
						width: 'unset',
						backgroundColor: (theme) => theme.palette.ascents.white,
						padding: (theme) => theme.spacing(1),

						'& img': {
							marginRight: 0
						},

						'& .MuiTypography-root': {
							display: 'none'
						}
					}
				},

				border: startDate.value && endDate.value && !validateDateIsAfter ? '1px solid red !important' : undefined
			}}
		>
			<Button ref={anchorRef} onClick={handleToggle} ascent='washLight' colorAscent='base' fullWidth>
				{startDate.value && endDate.value ? (
					`${startDate.value}  →  ${endDate.value}`
				) : (
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							'& img': {
								marginRight: '1rem',
								width: 20,
								height: 20
							}
						}}
					>
						<img src={icons.HOURGLASS_SVG} alt='hour glass' />
						<Text fontWeight={400} fontSize={`${'14px'} !important`} fontFamily={'"Open Sans", sans-serif !important'}>
							{quickFilter.label || intervalsRedux.label}
						</Text>
					</Box>
				)}
			</Button>

			<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
						}}
					>
						<Paper
							sx={{
								boxShadow:
									'0px 0px 4px -1px rgba(188, 193, 221, 0.15), 0px 1px 20px 1px rgba(188, 193, 221, 0.25), 0px 0px 15px 5px rgba(188, 193, 221, 0.2) !important',
								padding: '1.5rem !important',
								borderRadius: '0.5rem !important'
							}}
						>
							<ClickAwayListener onClickAway={handleClose}>
								<Box>
									{/* <WrapperCard
                                        ascents="washLight"
                                        hideShadow
                                        paperProps={{
                                            sx: {
                                                marginBottom: "1.5rem",
                                            },
                                        }}
                                        titleTextProps={{
                                            sx: {
                                                marginTop: 0,
                                            },
                                        }}
                                        title="In Intervals"
                                    >
                                        <Box marginTop="0.5rem" width={"100%"}>
                                            {filtersByTime.intervals.map(
                                                (item, index) => (
                                                    <Text
                                                        key={index}
                                                        component="span"

                                                        sx={{
                                                            cursor: "pointer",
                                                            marginLeft:
                                                                index !== 0
                                                                    ? "1rem !important"
                                                                    : "0",
                                                            fontWeight:
                                                                index ===
                                                                intervalsRedux.index
                                                                    ? "600 !important"
                                                                    : undefined,
                                                        }}
                                                        ascent={
                                                            index ===
                                                            intervalsRedux.index
                                                                ? "primary"
                                                                : undefined
                                                        }
                                                        onClick={handleIntervalChange(
                                                            {
                                                                ...item,
                                                                type: "intervals",
                                                                index,
                                                            }
                                                        )}
                                                    >
                                                        {item.value > 0
                                                            ? `Every ${item.value} secs`
                                                            : "Disable"}
                                                    </Text>
                                                )
                                            )}
                                        </Box>
                                    </WrapperCard> */}
									<WrapperCard
										ascents='washLight'
										hideShadow
										paperProps={{
											marginBottom: '1.5rem'
										}}
										titleTextProps={{
											sx: {
												marginTop: 0
											}
										}}
										title='Quick Filter'
									>
										<Box marginTop='0.5rem' width={'100%'}>
											{filtersByTime.fixed.map((item, index) => (
												<Text
													key={index}
													sx={{
														cursor: 'pointer',
														marginLeft: index !== 0 ? '1rem !important' : '0',
														fontWeight: index === quickFilter.index ? '600 !important' : undefined
													}}
													ascent={index === quickFilter.index ? 'primary' : undefined}
													component='span'
													onClick={handleQuickFilterChange({
														...item,
														type: 'fixed',
														index
													})}
												>
													{hourFormatter(item.value)}
												</Text>
											))}
										</Box>
									</WrapperCard>
									<WrapperCard
										ascents='washLight'
										hideShadow
										titleTextProps={{
											sx: {
												marginTop: 0
											}
										}}
										title='Custom'
									>
										<Box
											sx={{
												margin: 0,
												display: 'flex',
												'& .MuiTypography-body1': {
													margin: 'auto 0'
												},
												marginTop: '0.5rem'
											}}
										>
											<Text
												sx={{
													pr: 1
												}}
											>
												From
											</Text>
											<AppDatePicker
												selectedDate={startDate.date}
												handleDateChange={handleStartDateChange}
												maxDate={moment()}
												format={DEFAULT_TIMESTAMP_FORMAT}
											/>
										</Box>

										<Box
											sx={{
												display: 'flex',
												marginTop: '1rem !important',
												'& .MuiTypography-body1': {
													margin: 'auto 0'
												}
											}}
										>
											<Text
												sx={{
													pr: 3
												}}
											>
												To
											</Text>
											<AppDatePicker
												selectedDate={endDate.date}
												handleDateChange={handleEndDateChange}
												maxDate={moment()}
												format={DEFAULT_TIMESTAMP_FORMAT}
											/>
										</Box>
									</WrapperCard>
									{showSaveBtn && validateDateIsAfter && (
										<Button onClick={handleSaveBtnClick}>Save Custom Date Range</Button>
									)}
									{error && (
										<Text ascent='red' align='center' isCustomColor fontSize={'14px'}>
											{error}
										</Text>
									)}
								</Box>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Box>
	)
}

export default DateRangeDropdown
