import Button from '@commons/Button/Button'
import Loader from '@commons/Loader'
import Text from '@commons/Text'
import adminRoutesConstant from '@constants/adminRoutesConstant'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import TuneIcon from '@mui/icons-material/Tune'
import { Box, Dialog, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Tooltip } from '@mui/material'
import { fetchAllNotificationsReducer } from '@redux/notifications/reducers'
import { sendFCMToken, updateReadNotifications } from '@services/userService'
import { messaging } from '@utils/fcm'
import { timeSince } from '@utils/other'
import { getToken, isSupported } from 'firebase/messaging'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const NotificationDropdown = ({ ...props }) => {
	const [open, setOpen] = React.useState(false)

	const [fcmToken, setFcmToken] = React.useState(null)
	const reloadToken = useSelector((state) => state.reloadToken)
	const policies = useSelector((state) => state.auth.allRouteNamesByPolicy)
	const browserNotificationApiIsSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window
	const { isLoading, allNotifications } = useSelector((state) => state.notifications)
	const dispatch = useDispatch()

	const anchorRef = React.useRef()

	const fetchAllNotifications = () => {
		dispatch(fetchAllNotificationsReducer())
	}

	const refresh = () => {
		if (fcmToken) setFcmToken(null)
		if (fcmToken) setFcmToken(null)

		fetchAllNotifications()
	}

	const handleFCMToken = async () => {
		if (!fcmToken) {
			if (navigator && 'serviceWorker' in navigator) {
				navigator.serviceWorker.ready.then(async () => {
					if (!(await isSupported())) return

					const token = await getToken(messaging, {
						vapidKey: import.meta.env.VITE_APP_FIREBASE_VAPID_KEY
					})

					setFcmToken(token)
				})
				// return token;
			}
		}
	}

	const sendFcmToApi = () => {
		const apiInstance = sendFCMToken()

		apiInstance
			.api({
				data: {
					name: '',
					registration_id: fcmToken,
					device_id: '',
					type: 'web'
				}
			})
			.then((response) => {
				if (response) {
					if (!response.error && response.id) {
						setFcmToken(null)
					}
				}
			})

		return apiInstance
	}

	const handleToggle = () => {
		if (browserNotificationApiIsSupported()) {
			if (Notification.permission !== 'granted') {
				Notification.requestPermission()
					.then((result) => {
						if (result === 'granted') {
							handleFCMToken()
						}
					})
					.catch(() => {})
			}

			setOpen((prevOpen) => !prevOpen)
		}
	}

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}

		setOpen(false)
	}

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault()
			setOpen(false)
		}
	}

	const tooltipContent = (details) => {
		const message = details.message

		const extractLinkFromMessage = (message) => {
			const parser = new DOMParser()

			const doc = parser.parseFromString(message, 'text/html')

			const linkElement = doc.querySelector('a')

			if (linkElement) {
				return linkElement.getAttribute('href')
			}
			return null
		}
		const link = extractLinkFromMessage(message)

		const extractTextBeforeLink = (message) => {
			const startIndex = message.indexOf('<a')

			if (startIndex !== -1) {
				return message.substring(0, startIndex)
			}
			return message
		}
		const textMessage = extractTextBeforeLink(message)

		return (
			<>
				<Text margin='1rem 0 0 1rem' ascent='primary' fontWeight='600'>
					{details.title}
				</Text>
				<Text fontSize={12} margin='0 0 0 1rem'>
					{timeSince(String(details?.created_on) + 'Z')}
				</Text>
				<Text margin='1rem 0 1rem 1rem'>
					{textMessage} <a href={link}>click here to view it.</a>
				</Text>
			</>
		)
	}

	const MenuItems = (item, index) => {
		const messageSplitByComma = item.message.split(',')
		const message = messageSplitByComma.length > 0 ? messageSplitByComma[0] : 'N.A'
		const handleMenuItemClick = () => {
			if (!item.is_read) {
				const responseAPI = updateReadNotifications(item.id)
				responseAPI
					.api({
						data: {
							is_read: true
						}
					})
					.then((response) => {
						if (response) {
							if (!response.error) {
								fetchAllNotifications()
							}
						}
					})
			}
		}

		return (
			<MenuItem
				// onClick={handleClose}
				onClick={handleMenuItemClick}
				component={Link}
				to={item?.metadata?.link} //?.substring(0, item?.metadata?.link.lastIndexOf('&'))
				key={item.id || index}
			>
				<Grid container>
					<Grid item xs={0.75}>
						{!item.is_read && <Text ascent='primary'>●</Text>}
					</Grid>
					<Grid item xs={11.25}>
						<Tooltip placement='left' title={tooltipContent(item)}>
							<Box>
								<Text ascent={!item.is_read ? 'primary' : 'textDark'} fontWeight={600}>
									{item.title}
								</Text>
								<Text whiteSpace='nowrap' overflow='hidden' display='block' textOverflow='ellipsis' fontSize={12}>
									{message}
								</Text>
							</Box>
						</Tooltip>
					</Grid>
				</Grid>
			</MenuItem>
		)
	}

	const prevOpen = React.useRef(open)

	React.useEffect(() => {
		if (prevOpen.current === true && open === false && !!anchorRef.current) {
			anchorRef.current.focus()
		}

		prevOpen.current = open
	}, [open, anchorRef])

	React.useEffect(() => {
		fetchAllNotifications()

		if (
			!!(
				window.location.hostname === 'localhost' ||
				// [::1] is the IPv6 localhost address.
				window.location.hostname === '[::1]' ||
				// 127.0.0.1/8 is considered localhost for IPv4.
				window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
			) ||
			window.location.protocol !== 'http:'
		) {
			if (browserNotificationApiIsSupported()) {
				if (Notification.permission === 'granted') {
					handleFCMToken()
				}
			}

			if (navigator) {
				if ('serviceWorker' in navigator) {
					navigator.serviceWorker.addEventListener('message', () => fetchAllNotifications())

					return () => {
						navigator.serviceWorker.removeEventListener('message', () => {})
					}
				}
			}
		}
	}, [])

	React.useEffect(() => {
		if (fcmToken) {
			const requestToken = sendFcmToApi()

			return () => {
				requestToken.cancel()
			}
		}
	}, [fcmToken])

	React.useEffect(() => {
		if (reloadToken) {
			refresh()
			fetchAllNotifications()
		}
	}, [reloadToken])

	return (
		<Box {...props}>
			<IconButton
				ref={anchorRef}
				ascent='white'
				onClick={handleToggle}
				id='notificationButton'
				sx={{
					// marginRight: theme.spacing(2),
					// border: `1px solid ${theme.palette.ascents.wash}`,
					'&.MuiIconButton-root': {
						padding: (theme) => `${theme.spacing(0.75)}px !important`
					},
					'& .MuiSvgIcon-root': {
						color: (theme) => theme.palette.ascents.base
					}
				}}
			>
				<NotificationsNoneIcon fontSize='default' />
			</IconButton>
			<Dialog open={open} onClick={handleClose} hideBackdrop>
				<Popper sx={{ zIndex: 9999 }} placement='bottom-start' open={open} anchorEl={anchorRef.current} role={undefined} transition>
					{({ TransitionProps }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: 'center top'
							}}
						>
							<Paper
								sx={{
									minWidth: '320px',
									maxWidth: 360,
									borderRadius: '0.5rem',
									'& .MuiMenuItem-root': {
										whiteSpace: 'normal'
									},
									'& #menu-list-grow': {
										maxHeight: 280,
										overflowY: 'auto'
									}
								}}
								elevation={6}
							>
								{/* <ClickAwayListener > */}
								<Box>
									<Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
										<Text fontWeight={700} fontSize={20}>
											Notifications
										</Text>
										<Box>
											{policies?.includes?.('NotificationSettingsScreen') && (
												<Tooltip title='Configure notifications'>
													<IconButton
														sx={{
															boxShadow: 'none !important'
														}}
														onClick={handleClose}
														component={Link}
														to={adminRoutesConstant.adminPaths.NotificationSettingsScreen.url()}
														color='primary'
													>
														<TuneIcon />
													</IconButton>
												</Tooltip>
											)}
										</Box>
									</Box>

									{isLoading ? (
										<Box pb={3} display={'flex'} justifyContent={'center'}>
											<Loader customMessage={'Loading all notifications...'} showSecurityFacts={false} />
										</Box>
									) : allNotifications.length > 0 ? (
										<>
											<MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
												{allNotifications.map(MenuItems)}
											</MenuList>

											<Box
												sx={{
													textAlign: 'right'
												}}
											>
												<Button
													fullWidth
													onClick={handleClose}
													component={Link}
													to={adminRoutesConstant.adminPaths.NotificationsScreen.url()}
													ascent='trans'
													colorAscent='primary'
												>
													See All
												</Button>
											</Box>
										</>
									) : (
										<Box p={2}>
											<Text>You have no new notifications. Come back later!</Text>
										</Box>
									)}
								</Box>
								{/* </ClickAwayListener> */}
							</Paper>
						</Grow>
					)}
				</Popper>
			</Dialog>
		</Box>
	)
}

export default NotificationDropdown
