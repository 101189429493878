import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

const withFormProvider = (Component = React.Component) => {
	// eslint-disable-next-line react/display-name
	return (props) => {
		const methods = useForm()
		return (
			<FormProvider {...methods}>
				<Component {...props} />
			</FormProvider>
		)
	}
}

export default withFormProvider
