import { Chip } from '@mui/material'
import { fadeColor } from '@utils/styles'

const AppChip = ({
	ascent = 'primary',
	colorAscent = 'white',
	customColor,
	fontWeight = 500,
	fontSize,
	textDecoration,
	variant,
	borderRadius,
	bgColor,
	margin,
	border,
	label = '',
	cursor,
	...prop
}) => {
	return (
		<Chip
			variant={variant}
			label={label}
			sx={{
				color: (theme) => theme.palette.ascents[colorAscent],
				background: (theme) => (variant === 'outlined' ? 'transparent' : customColor ? customColor : bgColor ? bgColor : theme.palette.ascents[ascent]),
				fontWeight,
				borderRadius,
				fontSize,
				fontFamily: "'Open Sans', sans-serif",
				margin,
				textDecoration,
				'&.MuiChip-clickable': {
					'&:hover, &:focus': {
						background: (theme) => `${fadeColor(theme.palette.ascents[ascent])}`
					}
				},
				cursor: cursor,
				border: (theme) =>
					variant !== 'outlined' || border
						? border
						: customColor
							? `1px solid ${customColor}`
							: `1px solid ${theme.palette.ascents[ascent]}`
			}}
			{...prop}
		/>
	)
}

export default AppChip
