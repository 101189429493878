import { IMAGE_FILE } from '@constants/formInputTypes'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { Box, Button } from '@mui/material'
import { ASCENTS } from '@styles/theme'
import React from 'react'
import withDragAndDrop from '../HOCs/DropZoneHOC'
import Text from '../Text'

const FileInput = ({
	// errorConfig = [],
	// name,
	accept,
	fileType,
	width = 'auto',
	height = 'auto',
	onChange,
	// ascent,
	buttonLabel = 'Choose File',
	id,
	// label = '',
	// className,
	// children,
	previewUrl,
	imageName,
	readOnly = false,
	wrapperProps,
	buttonProps,
	multiple,
	...props
}) => {
	const RenderPreview = React.useMemo(() => {
		switch (fileType) {
			case IMAGE_FILE:
				return (
					<Box
						sx={{
							width,
							height,
							maxWidth: '100%',

							'& > img': {
								width: '100%',
								height: '100%',
								objectFit: 'contain',
								maxHeight: '150px'
							}
						}}
					>
						<img src={previewUrl} alt={imageName} />
					</Box>
				)

			default:
				break
		}
	}, [previewUrl])

	return (
		<Box
			sx={{
				width: '100%',
				alignItems: 'center',
				padding: (theme) => theme.spacing(2, 0, 2, 0),
				...wrapperProps?.sx
			}}
			// className={clsx(classes.root)}
			{...wrapperProps}
		>
			<Button
				component='label'
				htmlFor={id}
				// className={clsx(classes.inputLabel, `${name}-${fileType}`)}

				variant='outlined'
				disabled={readOnly}
				onClick={(e) => e.stopPropagation()}
				{...buttonProps}
				sx={{
					padding: '20px !important',
					marginLeft: 'auto',
					boxSizing: 'border-box',
					width: '100%',
					minHeight: 150,
					overflow: 'hidden',
					zIndex: 0,
					border: 'none',
					'&:after': {
						content: "''",
						position: 'absolute',
						top: '0',
						left: '0',
						right: '0',
						bottom: '0',
						borderStyle: 'dashed',
						borderWidth: 2,
						borderColor: (theme) => theme.palette.ascents.base,
						zIndex: -1
					},

					...buttonProps?.sx
				}}
			>
				{previewUrl ? (
					<div>
						{RenderPreview}
						{!readOnly && (
							<Text variant='p' color='primary'>
								<Text
									variant='body2'
									// className={classes.imageName}
									sx={{
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap'
									}}
								>
									{imageName || ''}
								</Text>
								Change File
							</Text>
						)}
					</div>
				) : (
					<Box>
						<Box display='flex'>
							<Text variant='body1'>{buttonLabel}</Text>
							<AttachFileIcon sx={{ color: ASCENTS.textDark, ml: '0.5rem' }} fontSize='small' />
						</Box>
						{multiple && (
							<Text margin='0.5rem 0 0 0' align='center' variant='body2'>
								Multiple file upload is allowed.
							</Text>
						)}
					</Box>
				)}

				<Box
					accept={accept}
					type='file'
					onChange={onChange}
					// className={classes.input}
					readOnly={readOnly}
					component='input'
					sx={{
						display: 'none'
					}}
					multiple={multiple}
					{...props}
				/>
			</Button>
		</Box>
	)
}

FileInput.propTypes = {}

export default withDragAndDrop(FileInput)
