import DisplayRiskFactor from '@commons/DisplayRiskFactor'
import DisplayStatus from '@commons/DisplayStatus'
import HostChip from '@commons/HostChip'
import RuleChipsGroup from '@commons/RuleChipsGroup'
import Text from '@commons/Text'
import HostSecurityConfigRaiseTicket from '@layouts/Host/HostSecurityConfigRaiseTicket'
import ReferencesList from '@layouts/SecurityOverview/Detection/ReferencesList'
import { Tooltip } from '@mui/material'
import { formatDateTime, getCortexOsType, getOffice365MappingByUserType, getSeverityNameFromLevel } from '@utils/other'
import { Link } from 'react-router-dom'

export const allHostsHeadCells = {
	id: {
		id: 'id',
		hidden: true
	},
	hostName: {
		id: 'hostName',
		label: 'Host Name'
	},
	lastSeen: {
		id: 'lastSeen',
		label: 'Last Seen'
	},
	operationSys: {
		id: 'operationSys',
		label: 'Operation System'
	},
	version: {
		id: 'version',
		label: 'Version'
	}
}

export const timestampOption = {
	customBodyRender: (value) => {
		const dateTime = value.split('@')

		return (
			<Tooltip title={dateTime[1]} placement='left-end'>
				<div>{dateTime[0].trim()}</div>
			</Tooltip>
		)
	}
}

export const allVulnerabilityDetectionHeadCells = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false
		},
		// ? Perform action on each data
		elasticConfig: {
			callback: (data) => {
				return formatDateTime(data.pop())
			}
		}
	},
	{
		name: '_source.agent.name',
		label: 'Host'
	},
	{
		name: '_source.data.vulnerability.title',
		label: 'Title',
		options: {
			filter: false
		}
	},
	{
		name: '_source.data.vulnerability.cve',
		label: 'CVE',
		options: {
			filter: false
		}
	},
	{
		name: '_source.data.vulnerability.package.name',
		label: 'Package',
		options: {
			filter: false
		}
	},
	{
		name: '_source.data.vulnerability.cvss.cvss3.base_score',
		label: 'CVSS',
		options: {
			customBodyRender: (value) => {
				return Number(value).toString() !== 'NaN' ? Number(value).toFixed(1) : 0
			},
			filter: false
		}
	},
	{
		name: '_source.data.vulnerability.severity',
		label: 'Severity Level'
	}
]
export const topCriticalHostsSenitnelOne = [
	{
		name: '_source.data.agentComputerName',
		label: 'Computer Name',
		sortKey: 'data.agentComputerName',
		searchKey: 'data.agentComputerName'
	},
	{
		name: '_source.data.cveId',
		label: 'CVE ID',
		sortKey: 'data.cveId',
		searchKey: 'data.cveId'
	},
	{
		name: '_source.data.score',
		label: 'CVSS',
		sortKey: 'data.score',
		searchKey: 'data.score'
	},
	{
		name: '_source.data.applicationName',
		label: 'Application Name',
		sortKey: 'data.applicationName',
		searchKey: 'data.applicationName'
	},
	{
		name: '_source.data.riskLevel',
		label: 'Risk Level',
		sortKey: 'data.riskLevel',
		searchKey: 'data.riskLevel'
	},
	{
		name: '_source.data.description',
		label: 'Description',
		sortKey: 'data.description',
		searchKey: 'data.description'
	}
]
export const integrationSentinelOneHeadCells = [
	{
		name: '_source.data.sourceHostName',
		label: 'Hostname',
		sortKey: 'data.sourceHostName',
		searchKey: 'data.sourceHostName',
		options: {
			enableSorting: false
		}
	},
	{
		name: '_source.data.siteName',
		label: 'Sitename',
		sortKey: 'data.siteName',
		searchKey: 'data.siteName',
		options: {
			enableSorting: false
		}
	},
	{
		name: '_source.data.sourceGroupName',
		label: 'Group Name',
		sortKey: 'data.sourceGroupName',
		searchKey: 'data.sourceGroupName',
		options: {
			enableSorting: false
		}
	},
	{
		name: '_source.data.sourceDnsDomain',
		label: 'DNS Domain',
		sortKey: 'data.sourceDnsDomain',
		searchKey: 'data.sourceDnsDomain',
		options: {
			enableSorting: false
		}
	},
	{
		name: '_source.data.sourceUserName',
		label: 'User Name',
		sortKey: 'data.sourceUserName',
		searchKey: 'data.sourceUserName',
		options: {
			enableSorting: false
		}
	},
	{
		name: '_source.data.threatClassification',
		label: 'Threat Classification',
		sortKey: 'data.threatClassification',
		searchKey: 'data.threatClassification',
		options: {
			enableSorting: false
		}
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		sortKey: 'rule.description',
		searchKey: 'rule.description',
		options: {
			enableSorting: false
		}
	}
]

export const activeDirectoryNoJsonHeadCells = [
	{
		name: '_source.data.lastActiveDate',
		label: 'Last Active Date',
		options: {
			filter: false,
			cell: (info) => formatDateTime(info.getValue())
		}
	},
	{
		name: '_source.data.siteName',
		label: 'Sitename'
	},
	{
		name: '_source.data.computerName',
		label: 'Computer Name'
	},
	{
		name: '_source.data.lastLoggedInUserName',
		label: 'Last Logged In User'
	},
	{
		name: '_source.data.externalIp',
		label: 'External IP'
	}
]

export const sentinelOneAgentHeadCells = [
	// {
	//     name: "fields.timestamp",
	//     label: "Date",
	//     options: {
	//         filter: false,
	//         cell: (info) => formatDateTime(info.getValue()),
	//     }, // searchKey: "fields.timestamp",
	//     sortKey: "@timestamp",
	// },
	{
		name: '_source.data.computerName',
		label: 'Computer Name',
		searchKey: '_source.data.computerName',
		sortKey: '_source.data.computerName',
		options: {
			cell: (info) => info.getValue() || '---'
		}
	},
	{
		name: '_source.data.lastLoggedInUserName',
		label: 'Last logged in User',
		searchKey: '_source.data.lastLoggedInUserName',
		sortKey: '_source.data.lastLoggedInUserName',
		options: {
			cell: (info) => info.getValue() || '---'
		}
	},
	{
		name: '_source.data.lastActiveDate',
		label: 'Last Active Date',
		searchKey: '_source.data.lastActiveDate',
		sortKey: '_source.data.lastActiveDate',
		options: {
			filter: false,
			cell: (info) => formatDateTime(info.getValue())
		}
	},
	{
		name: '_source.data.activeDirectory.computerDistinguishedName',
		label: 'Computer Distinguished Name',
		searchKey: '_source.data.activeDirectory.computerDistinguishedName',
		sortKey: '_source.data.activeDirectory.computerDistinguishedName',
		options: {
			cell: (info) => info.getValue() || '---'
		}
	},
	{
		name: '_source.rule.level',
		label: 'Severity Level',
		options: {
			cell: (info) => <DisplayRiskFactor riskValue={getSeverityNameFromLevel(info.getValue()).label} checkForSeverity />
		},
		sortKey: 'rule.level'
	},
	{
		name: '_source.data.externalIp',
		label: 'External IP',
		searchKey: 'data.externalIp',
		sortKey: 'data.externalIp',
		options: {
			cell: (info) => info.getValue() || '---'
		}
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		searchKey: 'rule.description',
		sortKey: 'rule.description'
	}
]

export const activeDirectoryHeadCells = [
	// {
	//     name: "fields.timestamp",
	//     label: "Date",
	//     options: {
	//         filter: false,
	//         cell: (info) => formatDateTime(info.getValue()),
	//     },
	//     // searchKey: "fields.timestamp",
	//     sortKey: "@timestamp",
	// },
	// {
	//     name: "_source.agent.name",
	//     label: "Host",
	//     options: {
	//         cell: (info) => {
	//             console.log(
	//                 "Info ----",
	//                 info.cell.row.original._source.rule.level
	//             );
	//             return (
	//                 <HostChip
	//                     name={info.getValue()}
	//                     riskValue={info.cell.row.original._source.rule.level}
	//                 />
	//             );
	//         },
	//     },
	//     sortKey: "agent.name",
	//     searchKey: "agent.name",
	// },
	{
		name: '_source.data.SamAccountName',
		label: 'Sam Account Name',
		searchKey: 'data.SamAccountName',
		sortKey: 'data.SamAccountName',
		options: {
			cell: (info) => info.getValue() || '---'
		}
	},
	{
		name: '_source.rule.level',
		label: 'Severity Level',
		options: {
			cell: (info) => <DisplayRiskFactor riskValue={getSeverityNameFromLevel(info.getValue()).label} checkForSeverity />
		},
		sortKey: 'rule.level'
	},
	{
		name: '_source.data.name',
		label: 'Name',
		searchKey: 'data.name',
		sortKey: 'data.name',
		options: {
			cell: (info) => info.getValue() || '---'
		}
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		searchKey: 'rule.description',
		sortKey: 'rule.description',
		options: {
			colSpan: 2
		}
	}
]

export const allSignalsHeadCells = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false,
			cell: (info) => Array.isArray(info.getValue()) && info.getValue().length > 0 && formatDateTime(info.getValue()[0]),
			exportAs: (info) => {
				const value = info.fields.timestamp[0]
				return value && value !== '--' ? formatDateTime(value) : '---'
			}
		},
		sortKey: '@timestamp'
	},
	{
		name: '_source.agent.name',
		label: 'Host',
		options: {
			cell: (info) => {
				return <HostChip name={info.getValue()} riskValue={1} />
			}
		},
		sortKey: 'agent.name'
		// searchKey: 'agent.name'
	},
	{
		name: '_source.rule.mitre.technique',
		label: 'ATT&CK Type',
		searchKey: 'rule.mitre.technique',
		sortKey: 'rule.mitre.technique',
		options: {
			cell: (info) => {
				const value = info.getValue()
				const restructuredArray = info.row.original?._source?.rule?.mitre?.id.map((id, index) => {
					return {
						id: id,
						technique: info.row.original?._source?.rule?.mitre?.technique[index]
					}
				})
				return value?.length > 0
					? restructuredArray?.map((each) => (
						<>
							<Link to={`/riskMitigation?mitigation_id=${each.id}`}>{each.technique}</Link>
							<br />
						</>
					))
					: '---'
			},
			exportAs: (info) => {
				const value = info._source.rule?.mitre?.technique

				return value ? value?.join(',') : '---'
			}
		}
	},
	{
		name: '_source.rule.level',
		label: 'Severity Level',
		options: {
			// cell: (info) => <SeverityByLevel level={info.getValue()} />
			cell: (info) => <DisplayRiskFactor riskValue={getSeverityNameFromLevel(info.getValue()).label} checkForSeverity />,
			exportAs: (info) => {
				const value = info._source.rule.level
				const severityLevel = getSeverityNameFromLevel(value)
				return value ? severityLevel?.label : 'Unknown'
			}
		},
		sortKey: 'rule.level'
		// searchKey: 'rule.level'
	},
	{
		name: '_source.rule.gdpr',
		label: 'Rule',
		searchKey: 'rule.gdpr',
		sortKey: 'rule.gdpr',
		options: {
			cell: (info) => {
				return Array.isArray(info.getValue()) ? info.getValue().join(', ') : '---'
			}
		}
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		searchKey: 'rule.description',
		sortKey: 'rule.description'
	}
]

export const dashboardHighlightCardColumns = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false,
			cell: (info) => Array.isArray(info.getValue()) && info.getValue().length > 0 && formatDateTime(info.getValue()[0]),
			exportAs: (info) => {
				const value = info.fields.timestamp[0]
				return value && value !== '--' ? formatDateTime(value) : '---'
			}
		},
		sortKey: '@timestamp'
	},
	{
		name: '_source.rule.groups',
		label: 'Rule groups',
		options: {
			cell: (info) => {
				const ruleGroups = Array.isArray(info.getValue()) ? info.getValue() : []
				return <RuleChipsGroup ruleGroup={ruleGroups} />
			},
			exportAs: (info) => {
				// const value = info.fields.timestamp[0]
				const value = info._source.rule.groups
				return value
			},
			colSpan: 2,
			enableSorting: false
		}
		// sortKey: 'rule.groups',
		// searchKey: 'rule.groups'
	},
	// {
	// 	name: '_source.rule.mitre.technique',
	// 	label: 'ATT&CK Type',
	// 	searchKey: 'rule.mitre.technique',
	// 	sortKey: 'rule.mitre.technique',
	// 	options: {
	// 		cell: (info) => {
	// 			const value = info.getValue()
	// 			return value?.join(',') || '---'
	// 		},
	// 		exportAs: (info) => {
	// 			const value = info._source.rule?.mitre?.technique

	// 			return value ? value?.join(',') : '---'
	// 		}
	// 	}
	// },
	{
		name: '_source.rule.mitre.technique',
		label: 'ATT&CK Type',
		searchKey: 'rule.mitre.technique',
		sortKey: 'rule.mitre.technique',
		options: {
			cell: (info) => {
				const value = info.getValue()
				const restructuredArray = info.row.original?._source?.rule?.mitre?.id.map((id, index) => {
					return {
						id: id,
						technique: info.row.original?._source?.rule?.mitre?.technique[index]
					}
				})
				return value?.length > 0
					? restructuredArray?.map((each) => (
						<>
							<Link to={`/riskMitigation?mitigation_id=${each.id}`}>{each.technique}</Link>
							<br />
						</>
					))
					: '---'
			},
			exportAs: (info) => {
				const value = info._source.rule?.mitre?.technique

				return value ? value?.join(',') : '---'
			}
		}
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		searchKey: 'rule.description',
		sortKey: 'rule.description',
		options: {
			colSpan: 2
		}
	}
]

export const defaultIntegrationTableCols = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false,
			cell: (info) => Array.isArray(info.getValue()) && info.getValue().length > 0 && formatDateTime(info.getValue()[0]),
			exportAs: (info) => {
				const value = info.fields.timestamp[0]
				return value && value !== '--' ? formatDateTime(value) : '---'
			}
		},
		sortKey: '@timestamp'
	},
	{
		name: '_source.agent.name',
		label: 'Host',
		options: {
			cell: (info) => {
				return <HostChip name={info.getValue()} riskValue={1} />
			}
		},
		sortKey: 'agent.name',
		searchKey: 'agent.name'
	},
	// {
	// 	name: '_source.rule.mitre.technique',
	// 	label: 'ATT&CK Type',
	// 	searchKey: 'rule.mitre.technique',
	// 	sortKey: 'rule.mitre.technique',
	// 	options: {
	// 		cell: (info) => {
	// 			const value = info.getValue()
	// 			return value?.join(',') || '---'
	// 		},
	// 		exportAs: (info) => {
	// 			const value = info._source.rule?.mitre?.technique

	// 			return value ? value?.join(',') : '---'
	// 		}
	// 	}
	// },
	{
		name: '_source.rule.level',
		label: 'Severity Level',
		options: {
			// cell: (info) => <SeverityByLevel level={info.getValue()} />
			cell: (info) => <DisplayRiskFactor riskValue={getSeverityNameFromLevel(info.getValue()).label} checkForSeverity />,
			exportAs: (info) => {
				const value = info._source.rule.level
				const severityLevel = getSeverityNameFromLevel(value)
				return value ? severityLevel.label : 'Unknown'
			}
		},
		sortKey: 'rule.level',
		searchKey: 'rule.level'
	},
	// {
	// 	name: '_source.rule.gdpr',
	// 	label: 'Rule',
	// 	searchKey: 'rule.gdpr',
	// 	sortKey: 'rule.gdpr',
	// 	options: {
	// 		cell: (info) => info.getValue() || '---'
	// 	}
	// },
	{
		name: '_source.rule.description',
		label: 'Description',
		searchKey: 'rule.description',
		sortKey: 'rule.description'
	}
]

export const fortinetIntegrationTableCols = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false,
			cell: (info) => Array.isArray(info.getValue()) && info.getValue().length > 0 && formatDateTime(info.getValue()[0]),
			exportAs: (info) => {
				const value = info.fields.timestamp[0]
				return value && value !== '--' ? formatDateTime(value) : '---'
			}
		},
		sortKey: '@timestamp'
	},
	// {
	// 	name: '_source.agent.name',
	// 	label: 'Host',
	// 	options: {
	// 		cell: (info) => {
	// 			return <HostChip name={info.getValue()} riskValue={1} />
	// 		}
	// 	},
	// 	sortKey: 'agent.name',
	// 	searchKey: 'agent.name'
	// },
	{
		name: '_source.rule.level',
		label: 'Severity Level',
		options: {
			// cell: (info) => <SeverityByLevel level={info.getValue()} />
			cell: (info) => <DisplayRiskFactor riskValue={getSeverityNameFromLevel(info.getValue()).label} checkForSeverity />,
			exportAs: (info) => {
				const value = info._source.rule.level
				const severityLevel = getSeverityNameFromLevel(value)
				return value ? severityLevel.label : 'Unknown'
			}
		},
		sortKey: 'rule.level',
		searchKey: 'rule.level'
	},
	{
		name: '_source.data.dstip',
		label: 'Destination IP',
		searchKey: 'data.dstip',
		sortKey: 'data.dstip'
	},
	{
		name: '_source.data.srcip',
		label: 'Source IP',
		searchKey: 'data.srcip',
		sortKey: 'data.srcip'
	},
	{
		name: '_source.data.action',
		label: 'Action',
		searchKey: 'data.action',
		sortKey: 'data.action'
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		searchKey: 'rule.description',
		sortKey: 'rule.description'
	}
]

export const fortinetChartTableCols = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false,
			cell: (info) => Array.isArray(info.getValue()) && info.getValue().length > 0 && formatDateTime(info.getValue()[0]),
			exportAs: (info) => {
				const value = info.fields.timestamp[0]
				return value && value !== '--' ? formatDateTime(value) : '---'
			}
		},
		sortKey: '@timestamp'
	},
	{
		name: '_source.rule.mitre.technique',
		label: 'ATT&CK Type',
		searchKey: 'rule.mitre.technique',
		sortKey: 'rule.mitre.technique',
		options: {
			cell: (info) => {
				const value = info.getValue()
				const restructuredArray = info.row.original?._source?.rule?.mitre?.id.map((id, index) => {
					return {
						id: id,
						technique: info.row.original?._source?.rule?.mitre?.technique[index]
					}
				})
				return value?.length > 0
					? restructuredArray?.map((each) => (
						<>
							<Link to={`/riskMitigation?mitigation_id=${each.id}`}>{each.technique}</Link>
							<br />
						</>
					))
					: '---'
			},
			exportAs: (info) => {
				const value = info._source.rule?.mitre?.technique

				return value ? value?.join(',') : '---'
			}
		}
	},
	// {
	// 	name: '_source.agent.name',
	// 	label: 'Host',
	// 	options: {
	// 		cell: (info) => {
	// 			return <HostChip name={info.getValue()} riskValue={1} />
	// 		}
	// 	},
	// 	sortKey: 'agent.name',
	// 	searchKey: 'agent.name'
	// },
	{
		name: '_source.rule.level',
		label: 'Severity Level',
		options: {
			// cell: (info) => <SeverityByLevel level={info.getValue()} />
			cell: (info) => <DisplayRiskFactor riskValue={getSeverityNameFromLevel(info.getValue()).label} checkForSeverity />,
			exportAs: (info) => {
				const value = info._source.rule.level
				const severityLevel = getSeverityNameFromLevel(value)
				return value ? severityLevel.label : 'Unknown'
			}
		},
		sortKey: 'rule.level',
		searchKey: 'rule.level'
	},
	{
		name: '_source.data.dstip',
		label: 'Destination IP',
		searchKey: 'data.dstip',
		sortKey: 'data.dstip'
	},
	{
		name: '_source.data.srcip',
		label: 'Source IP',
		searchKey: 'data.srcip',
		sortKey: 'data.srcip'
	},
	{
		name: '_source.data.action',
		label: 'Action',
		searchKey: 'data.action',
		sortKey: 'data.action'
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		searchKey: 'rule.description',
		sortKey: 'rule.description'
	}
]

export const office365SecurityCentreIntegrationTableCols = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false,
			cell: (info) => Array.isArray(info.getValue()) && info.getValue().length > 0 && formatDateTime(info.getValue()[0]),
			exportAs: (info) => {
				const value = info.fields.timestamp[0]
				return value && value !== '--' ? formatDateTime(value) : '---'
			}
		},
		sortKey: '@timestamp'
	},
	{
		name: '_source.data.office365.UserId',
		label: 'User ID',
		sortKey: 'data.office365.UserId',
		searchKey: 'data.office365.UserId'
	},
	{
		name: '_source.rule.level',
		label: 'Severity Level',
		options: {
			// cell: (info) => <SeverityByLevel level={info.getValue()} />
			cell: (info) => <DisplayRiskFactor riskValue={getSeverityNameFromLevel(info.getValue()).label} checkForSeverity />,
			exportAs: (info) => {
				const value = info._source.rule.level
				const severityLevel = getSeverityNameFromLevel(value)
				return value ? severityLevel.label : 'Unknown'
			}
		},
		sortKey: 'rule.level',
		searchKey: 'rule.level'
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		searchKey: 'rule.description',
		sortKey: 'rule.description'
	}
]

export const cortexIntegrationTableCols = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false,
			cell: (info) => Array.isArray(info.getValue()) && info.getValue().length > 0 && formatDateTime(info.getValue()[0]),
			exportAs: (info) => {
				const value = info.fields.timestamp[0]
				return value && value !== '--' ? formatDateTime(value) : '---'
			}
		},
		sortKey: '@timestamp'
	},
	{
		name: '_source.data.deviceName',
		label: 'Device name',
		sortKey: 'data.deviceName',
		searchKey: 'data.deviceName'
	},
	{
		name: '_source.data.osType',
		label: 'OS Type',
		options: {
			// cell: (info) => <SeverityByLevel level={info.getValue()} />
			cell: (info) => getCortexOsType(info.getValue()),
			exportAs: (info) => {
				const value = info._source.data.osType
				const osType = getCortexOsType(value)
				return value ? osType.label : 'Unknown'
			}
		},
		sortKey: 'data.osType',
		searchKey: 'data.osType'
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		searchKey: 'rule.description',
		sortKey: 'rule.description'
	}
]

export const office365DefaultIntegrationTableCols = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false,
			cell: (info) => Array.isArray(info.getValue()) && info.getValue().length > 0 && formatDateTime(info.getValue()[0]),
			exportAs: (info) => {
				const value = info.fields.timestamp[0]
				return value && value !== '--' ? formatDateTime(value) : '---'
			}
		},
		sortKey: '@timestamp'
	},
	{
		name: '_source.data.office365.Operation',
		label: 'Operation',
		sortKey: 'data.office365.Operation',
		searchKey: 'data.office365.Operation'
	},
	{
		name: '_source.data.office365.UserType',
		label: 'User Type',
		sortKey: 'data.office365.UserType',
		searchKey: 'data.office365.UserType',
		options: {
			cell: (info) => getOffice365MappingByUserType(info.getValue())?.label || 'N.A'
		}
	},
	{
		name: '_source.rule.level',
		label: 'Severity Level',
		options: {
			// cell: (info) => <SeverityByLevel level={info.getValue()} />
			cell: (info) => <DisplayRiskFactor riskValue={getSeverityNameFromLevel(info.getValue()).label} checkForSeverity />,
			exportAs: (info) => {
				const value = info._source.rule.level
				const severityLevel = getSeverityNameFromLevel(value)
				return value ? severityLevel.label : 'Unknown'
			}
		},
		sortKey: 'rule.level',
		searchKey: 'rule.level'
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		searchKey: 'rule.description',
		sortKey: 'rule.description'
	}
]

export const bitdefenderDefaultIntegrationTableCols = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false,
			cell: (info) => Array.isArray(info.getValue()) && info.getValue().length > 0 && formatDateTime(info.getValue()[0]),
			exportAs: (info) => {
				const value = info.fields.timestamp[0]
				return value && value !== '--' ? formatDateTime(value) : '---'
			}
		},
		sortKey: '@timestamp'
	},
	{
		name: '_source.data.dvchost',
		label: 'Host',
		sortKey: 'data.dvchost',
		searchKey: 'data.dvchost'
	},
	{
		name: '_source.data.msz',
		label: 'Type',
		sortKey: 'data.msz',
		searchKey: 'data.msz'
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		searchKey: 'rule.description',
		sortKey: 'rule.description'
	}
]

export const cloudflareDefaultIntegrationTableCols = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false,
			cell: (info) => Array.isArray(info.getValue()) && info.getValue().length > 0 && formatDateTime(info.getValue()[0]),
			exportAs: (info) => {
				const value = info.fields.timestamp[0]
				return value && value !== '--' ? formatDateTime(value) : '---'
			}
		},
		sortKey: '@timestamp'
	},
	{
		name: '_source.data.actionType',
		label: 'Action type',
		sortKey: 'data.actionType',
		searchKey: 'data.actionType'
	},
	{
		name: '_source.data.actorType',
		label: 'Actor Type',
		sortKey: 'data.actorType',
		searchKey: 'data.actorType'
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		searchKey: 'rule.description',
		sortKey: 'rule.description'
	}
]

export const prismaDefaultIntegrationTableCols = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false,
			cell: (info) => Array.isArray(info.getValue()) && info.getValue().length > 0 && formatDateTime(info.getValue()[0]),
			exportAs: (info) => {
				const value = info.fields.timestamp[0]
				return value && value !== '--' ? formatDateTime(value) : '---'
			}
		},
		sortKey: '@timestamp'
	},
	{
		name: '_source.data.user',
		label: 'User',
		sortKey: 'data.user',
		searchKey: 'data.user'
	},
	{
		name: '_source.data.actionType',
		label: 'Action Type',
		sortKey: 'data.actionType',
		searchKey: 'data.actionType'
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		searchKey: 'rule.description',
		sortKey: 'rule.description'
	}
]
export const microFocusIntegrationTableCols = [
	// {
	// 	name: 'fields.timestamp',
	// 	label: 'Date',
	// 	// options: {
	// 	// 	filter: false,
	// 	// 	cell: (info) => Array.isArray(info.getValue()) && info.getValue().length > 0 && formatDateTime(info.getValue()[0]),
	// 	// 	exportAs: (info) => {
	// 	// 		const value = info.fields.timestamp[0]
	// 	// 		return value && value !== '--' ? formatDateTime(value) : '---'
	// 	// 	}
	// 	// },
	// 	sortKey: '@timestamp'
	// },
	{
		name: '_source.data.user',
		label: 'User',
		sortKey: 'data.user',
		searchKey: 'data.user'
	}
	// {
	// 	name: '_source.data.actionType',
	// 	label: 'Action Type',
	// 	sortKey: 'data.actionType',
	// 	searchKey: 'data.actionType'
	// },
	// {
	// 	name: '_source.rule.description',
	// 	label: 'Description',
	// 	searchKey: 'rule.description',
	// 	sortKey: 'rule.description'
	// }
]

export const azureTable = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false,
			cell: (info) =>
				Array.isArray(info.getValue()) && info.getValue().length > 0
					? formatDateTime(info.getValue()[0])
					: formatDateTime(info.getValue()),
			exportAs: (info) => {
				const value = info?._source?._timestamp;
				return Array.isArray(value) && value.length > 0
					? formatDateTime(value[0])
					: formatDateTime(value)
			}

		},
		sortKey: '@timestamp'
	},
	{
		name: '_source.rule.level',
		label: 'Severity Level',
		options: {
			// cell: (info) => <SeverityByLevel level={info.getValue()} />
			cell: (info) => <DisplayRiskFactor riskValue={getSeverityNameFromLevel(info.getValue()).label} checkForSeverity />,
			exportAs: (info) => getSeverityNameFromLevel(info?._source?.rule?.level).label
		},
		sortKey: 'rule.level',
		searchKey: 'rule.level'
	},
	{
		name: '_source.data.operationType',
		label: 'Operation Type',
		searchKey: 'data.operationType',
		sortKey: 'data.operationType'
	},
	{
		name: '_source.data.initiatedBy.user.userPrincipalName',
		label: 'Principal Name',
		searchKey: 'data.initiatedBy.user.userPrincipalName',
		sortKey: 'data.initiatedBy.user.userPrincipalName'
	},
	{
		name: '_source.data.loggedByService',
		label: 'Logged By',
		searchKey: 'data.loggedByService',
		sortKey: 'data.loggedByService'
	}
]
// Why a callback? because "Rule" column will show dynamic data based on selected compliance.
export const hostComplianceHeadCells = (rule = 'rule.gdpr') => [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			filter: false,
			cell: (info) => formatDateTime(info.getValue()),
			exportAs: (info) => {
				const value = info?._source?.timestamp
				return value ? formatDateTime(value) : "..."
			}
		},
		// ? Perform action on each data
		elasticConfig: {
			callback: (data) => {
				return formatDateTime(data.pop())
			}
		},
		sortKey: '@timestamp'
	},
	{
		name: '_source.agent.name',
		label: 'Host'
	},
	// {
	// 	name: '_source.rule.mitre.technique',
	// 	label: 'ATTaCK Type'
	// },
	{
		name: '_source.rule.mitre.technique',
		label: 'ATT&CK Type',
		options: {
			cell: (info) => {
				const value = info.getValue()
				const restructuredArray = info.row.original?._source?.rule?.mitre?.id.map((id, index) => {
					return {
						id: id,
						technique: info.row.original?._source?.rule?.mitre?.technique[index]
					}
				})
				return value?.length > 0
					? restructuredArray?.map((each) => (
						<>
							<Link to={`/riskMitigation?mitigation_id=${each.id}`}>{each.technique}</Link>
							<br />
						</>
					))
					: '---'
			},
			exportAs: (info) => {
				const value = info._source.rule?.mitre?.technique

				return value ? value?.join(',') : '---'
			}
		}
	},
	{
		name: '_source.rule.level',
		label: 'Severity Level',
		options: {
			cell: (info) => {
				const value = info.getValue()
				return <DisplayRiskFactor riskValue={getSeverityNameFromLevel(value).label} checkForSeverity />
			},
			exportAs: (info) => {
				const value = info._source.rule.level
				const severityLevel = getSeverityNameFromLevel(value)
				return value ? severityLevel.label : 'Unknown'
			}
		},
		sortKey: 'rule.level'
	},
	{
		name: `_source.${rule}`,
		label: 'Rule',
		searchKey: `${rule}`,
		sortKey: 'rule'
	},
	{
		name: '_source.rule.description',
		label: 'Description',
		options: {
			// display: false,
			// viewColumns: false,
			filter: false
		}
	}
]

export const activeAndSolvedVulnerabilitiesHeadCells = [
	{
		name: '_source.agent.name',
		label: 'Host',
		sortKey: 'agent.name',
		searchKey: 'agent.name'
	},
	{
		name: '_source.rule.level',
		label: 'Severity',
		sortKey: 'rule.level',
		options: {
			cell: (info) => <DisplayRiskFactor riskValue={getSeverityNameFromLevel(info.getValue()).label} checkForSeverity />
		}
	},
	{
		name: '_source.data.vulnerability.cve',
		label: 'CVE',
		sortKey: 'data.vulnerability.cve',
		searchKey: 'data.vulnerability.cve'
	},
	{
		name: '_source.data.vulnerability.references',
		label: 'References',
		sortKey: 'data.vulnerability.references',
		searchKey: 'data.vulnerability.references',
		options: {
			colSpan: 2,
			cell: (info) => {
				const urlsString = info?.row?.original?._source?.data?.vulnerability?.reference
				const referencesArray = urlsString?.split(',').map((url) => url.trim())
				const value = info?.row?.original?._source?.data?.vulnerability?.reference ? referencesArray : info.getValue()
				console.log('value: ', info, referencesArray)
				return <ReferencesList references={value} />
			}
			// cell: (info) => formatDateTime(info.getValue()[0])
		}
	},
	{
		name: 'fields.timestamp',
		sortKey: 'timestamp',
		label: 'Date',
		options: {
			cell: (info) => formatDateTime(info.getValue()[0])
		}
	}
]

export const mostVulnerablePackagesHeadCells = [
	{
		name: 'key',
		label: 'Package',
		options: {
			enableSorting: false
		}
	},
	{
		name: 'host.buckets.0.key',
		label: 'Host',
		options: {
			enableSorting: false
		}
	}
]

export const mostCommonCve = [
	{
		name: 'key',
		label: 'CVE',
		options: {
			enableSorting: false
		}
	},
	{
		name: 'host.buckets.0.key',
		label: 'Host',
		options: {
			enableSorting: false
		}
	}
]

export const vulnerabilitiesSummaryHeadCells = [
	{
		name: 'key',
		label: 'Vulnerability',
		options: {
			enableSorting: false
		}
	},
	{
		name: 'severity.buckets.0.key',
		label: 'Severity',
		options: {
			enableSorting: false,
			cell: (info) => {
				return <DisplayRiskFactor riskValue={info.getValue()} checkForSeverity />
			}
		}
	},
	{
		name: 'severity.buckets.0.packages.buckets.0.key',
		label: 'Package',
		options: {
			enableSorting: false
		}
	}
]

export const incidentResponseHeadCells = [
	{
		name: 'fields.timestamp',
		label: 'Date',
		options: {
			cell: (info) => formatDateTime(info.getValue())
		},
		sortKey: '@timestamp'
	},
	{
		name: '_source.agent.name',
		label: 'Host',
		sortKey: 'agent.name',
		searchKey: 'agent.name'
	},
	{
		name: '_source.rule.firedtimes',
		label: 'Times Fired',
		sortKey: 'rule.firedtimes',
		searchKey: 'rule.firedtimes'
	},
	{
		name: '_source.rule.level',
		sortKey: 'rule.level',
		label: 'Level',
		options: {
			cell: (info) => {
				return <DisplayRiskFactor riskValue={info.getValue()} checkForSeverity />
			}
		},
		searchKey: 'rule.level'
	},
	{
		name: '_source.rule.gdpr',
		searchKey: 'rule.gdpr',
		sortKey: 'rule.gdpr',
		label: 'Rule'
	},
	{
		name: '_source.rule.description',
		searchKey: 'rule.description',
		sortKey: 'rule.description',
		label: 'Description'
	}
]

export const networkResponseHeadCells = [
	{
		name: '_source.timestamp',
		label: 'Date',
		options: {
			cell: (info) => {
				const data = info.getValue()
				return formatDateTime(data)
			},
			exportAs: (info) => {
				const data = info?._source?.timestamp;
				return formatDateTime(data)
			}
		},
		sortKey: 'timestamp'
	},
	{
		name: '_source.agent.name',
		label: 'Host name',
		searchKey: 'agent.name',
		sortKey: 'agent.name'
	},
	{
		name: '_source.data.win.eventdata.ipAddress',
		label: 'Attacker IP',
		options: {
			cell: (info) => {
				const row = info.row.original
				return Object.byString(row, '_source.data.srcip') || Object.byString(row, '_source.data.win.eventdata.ipAddress') || 'N.A'
			},
			enableSorting: false
		}
	},
	{
		name: '_source.rule.mitre.technique',
		label: 'Attack Type',
		searchKey: 'rule.mitre.technique',
		sortKey: 'rule.mitre.technique'
	},
	{
		name: '_source.GeoLocation.country_name',
		label: 'Country',
		searchKey: 'GeoLocation.country_name',
		sortKey: 'GeoLocation.country_name'
	},
	{
		name: '_source.GeoLocation.location',
		label: 'Latitude & Longitude',
		searchKey: 'GeoLocation.location',
		sortKey: 'GeoLocation.location',
		options: {
			cell: (info) => `Latitude: ${info.getValue().lat}, Longitude: ${info.getValue().lon}`,
			exportAs: (info) =>
				`Latitude: ${info._source.GeoLocation?.location?.lat}, Longitude: ${info._source.GeoLocation?.location?.lon}`
		}
	}
]

export const securityConfigScoreHeadcells = [
	{
		name: 'name',
		label: 'Name'
	},
	{
		name: 'description',
		label: 'Description'
	},
	{
		name: 'start_scan',
		label: 'Start Scan'
	},
	{
		name: 'end_scan',
		label: 'End Scan'
	},
	{
		name: 'policy_id',
		label: 'Policy Id'
	},
	{
		name: 'pass',
		label: 'Pass'
	},
	{
		name: 'fail',
		label: 'Fail'
	},
	{
		name: 'hash_file',
		label: 'Hash File'
	},
	{
		name: 'invalid',
		label: 'Invalid'
	},
	{
		name: 'references',
		label: 'References'
	},
	{
		name: 'score',
		label: 'Score'
	},
	{
		name: 'total_checks',
		label: 'Total Checks'
	}
]

export const hostScaCheckHeadCells = [
	{
		name: 'id',
		label: 'Id'
	},
	{
		name: 'title',
		label: 'Title',
		options: {
			filter: false
		}
	},

	{
		name: 'result',
		label: 'Result',
		options: {
			cell: (info) => {
				const value = info.getValue()
				return value ? (
					<DisplayStatus status={value} successValue='passed' failureValue='failed' />
				) : (
					<Text fontSize={12}>Unknown</Text>
				)
			}
		}
	},
	{
		name: 'ticketData',
		label: 'Action',
		options: {
			cell: (info) => {
				const value = info.getValue()
				const memberList = value.memberList.filter(member => member.label !== "undefined" && member.label !== "undefined undefined - " && member.label.trim() !== '-');
				return (
					<HostSecurityConfigRaiseTicket
						id={value.id}
						title={value.title}
						description={`<p><strong>Remediation</strong></p><p>${value.remediation}</p><p><strong>Description</strong></p><p>${value.description}</p><p><strong>Result</strong></p><p style='text-transform:capitalize;'>${value.result}</p>`}
						memberList={memberList}
						memberListById={value.memberListById}
					/>
				)
			},
			exportAs: (info) => {
				return ''
			}
		}
	}
]
