import { Box } from '@mui/material'

const TabPanel = ({ children, value, index, ...props }) => {
	return (
		<Box role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...props}>
			{children}
		</Box>
	)
}

export default TabPanel
