import { Box } from '@mui/material'
import React, { memo } from 'react'
import Button from '../Button/Button'
import Text from '../Text'
// eslint-disable-next-line react/display-name
const TableFilterOption = memo(({ title, filters = [], selectedFilter, requestBodyKey, onFilterChange }) => {
	const [seeMore, setSeeMore] = React.useState(false)

	const toggleSeeMore = React.useCallback(() => {
		setSeeMore((state) => !state)
	}, [])

	// To Prevent Unnecessary rerender
	const handleFilter = (requestBodyKey, each) => () => {
		onFilterChange(requestBodyKey, each)
	}

	React.useEffect(() => {
		if (selectedFilter?.index > 4 && !seeMore) {
			setSeeMore(true)
		}
	}, [selectedFilter?.index > 4])

	return (
		<Box marginTop='1.5rem'>
			{filters.length > 0 && (
				<Text
					// margin="0 0 0.75rem 0"
					fontWeight='600'
					textTransform='uppercase'
					letterSpacing='1px'
				>
					{title}
				</Text>
			)}
			{filters.length > 0 && (
				<Box display='flex' alignItems={'center'} flexWrap='wrap'>
					{(selectedFilter?.find((each) => each?.index > 4) || seeMore ? filters : filters.concat().slice(0, 5)).map((each) => (
						<Button
							key={each.id}
							textTransform='none'
							onClick={typeof onFilterChange === 'function' ? handleFilter(requestBodyKey, each) : undefined}
							padding='0.25rem 1.25rem'
							ascent={selectedFilter?.find((eachSelectedFilter) => eachSelectedFilter?.id === each.id) ? 'primary' : 'wash'}
							colorAscent={
								selectedFilter?.find((eachSelectedFilter) => eachSelectedFilter?.id === each.id) ? 'white' : 'textDark'
							}
							margin='0.75rem 0.75rem 0 0'
						>
							{console.log('selectedFiltersById each =>', {
								each,
								selectedFilter,
								find: selectedFilter?.find((eachSelectedFilter) => eachSelectedFilter?.id === each.id),
								filters
							})}
							{each?.LeftIcon && (
								<Box display='block' component={'span'} marginRight='0.75rem'>
									{each?.LeftIcon?.({
										isSelected: selectedFilter?.find((eachSelectedFilter) => eachSelectedFilter?.id === each.id)
									})}
								</Box>
							)}
							{each.text}
						</Button>
					))}
					{filters.length > 5 && (
						<Button
							padding='0.25rem .25rem'
							disableRipple
							variant='text'
							ascent='trans'
							colorAscent='primary'
							margin='0.75rem 0.75rem 0 0'
							onClick={toggleSeeMore}
							disabled={selectedFilter?.find((each) => each?.index > 4)}
							disableAscent='trans'
							disableColorAscent='grey'
						>
							{seeMore ? 'Show Less' : `Show ${filters.length - 5} More`}
						</Button>
					)}
				</Box>
			)}
		</Box>
	)
})

export default TableFilterOption
