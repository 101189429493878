import { customEvents } from '@constants/analytics'
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined'
import { IconButton } from '@mui/material'
import { keyframes } from '@mui/system'
import { updateNetworkStatus } from '@redux/networkStatus/slice'
import { refreshToken } from '@redux/refresh/slice'
import { renewTimeFrame } from '@redux/timeFrame/slice'
import { icons } from '@utils/icons'
import { logGAEvent } from '@utils/other'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
const spinit = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const RefreshButton = () => {
	const dispatch = useDispatch()

	const [spin, setSpin] = React.useState(false)

	const isOnline = useSelector((state) => state.isOnline)
	const quickFilter = useSelector((state) => state.timeFrame.quickFilter)

	const prevIsOnline = React.useRef(isOnline)

	const handleRefresh = () => {
		dispatch(refreshToken())

		if (quickFilter.index > -1) {
			dispatch(renewTimeFrame())
		}

		setSpin(true)

		logGAEvent(customEvents.PAGE_REFRESHED, {
			event_label: `api refetched`
		})

		setTimeout(() => {
			setSpin(false)
		}, 3000)
	}

	const handleOnlineStatus = () => {
		// Present in static html.
		if (window.navigator.onLine) {
			document.querySelector('body').classList.remove('offline')
		} else {
			document.querySelector('body').classList.add('offline')
		}

		dispatch(updateNetworkStatus(window.navigator.onLine))
	}

	React.useEffect(() => {
		window.addEventListener('offline', handleOnlineStatus)
		window.addEventListener('online', handleOnlineStatus)

		return () => {
			window.removeEventListener('offline', handleOnlineStatus)
			window.removeEventListener('online', handleOnlineStatus)
		}
	}, [])

	React.useEffect(() => {
		// Only refresh iff user went offline and then came online.
		if (prevIsOnline.current === false && isOnline === true) {
			handleRefresh()
		}

		prevIsOnline.current = isOnline
	}, [isOnline])

	return (
		<IconButton
			disableRipple
			color='primary'
			// className={clsx({
			//     [classes.spinIt]: spin,
			// })}
			sx={{
				'& > img': {
					animation: spin ? `${spinit} 1s linear infinite` : undefined
				}
			}}
			onClick={handleRefresh}
			disabled={spin}
			id='refreshButton'
		>
			{!isOnline ? (
				<WifiOffOutlinedIcon
					sx={{
						fontSize: '1.25rem',
						color: (theme) => `${theme.palette.ascents.red} !important`
					}}
					className={'offline-icon'}
				/>
			) : (
				<img src={icons.SYNC_PNG} alt='' />
			)}
		</IconButton>
	)
}

export default RefreshButton
