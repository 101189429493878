import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Box, IconButton, Tooltip } from '@mui/material'
import { memo } from 'react'
import Icon from '../Icon'

// eslint-disable-next-line react/display-name
const THead = memo(
	({
		enableDynamicColumns,
		tableColumns = [],
		children,
		className,
		bgAscent = 'washLight',
		bgColor,
		borderAscent = 'shadow',
		borderColor,
		colorAscent = 'secondary',
		color,
		textAlign = 'left',
		isAlignRight,
		sortedAs,
		sortable,
		onSort,
		colSpan,
		handleHideColumn = () => {},
		...props
	}) => {
		return (
			<Box
				component='th'
				sx={{
					position: 'relative',
					backgroundColor: (theme) => (bgColor ? bgColor : theme.palette.ascents[bgAscent]),
					padding: '1rem',
					borderTop: (theme) => `1px solid ${borderColor ? borderColor : theme.palette.ascents[borderAscent]}`,
					borderBottom: (theme) => `1px solid ${borderColor ? borderColor : theme.palette.ascents[borderAscent]}`,
					color: (theme) => (color ? color : theme.palette.ascents[colorAscent]),
					textTransform: 'capitalize',
					fontFamily: "'Open Sans', 'Roboto', sans-serif",
					fontWeight: '600',
					fontSize: 14,
					textAlign: isAlignRight ? 'right' : textAlign,
					lineBreak: 'break-word',
					'&:first-child': {
						borderTopLeftRadius: '0.65rem',
						borderBottomLeftRadius: '0.65rem',
						borderLeft: (theme) => `1px solid ${borderColor ? borderColor : theme.palette.ascents[borderAscent]}`
					},
					'&:last-child': {
						borderTopRightRadius: '0.65rem',
						borderBottomRightRadius: '0.65rem',
						borderRight: (theme) => `1px solid ${borderColor ? borderColor : theme.palette.ascents[borderAscent]}`
					},
					'& .visibilityToggler': {
						visibility: 'hidden'
					},
					'&:hover': {
						'& .visibilityToggler': {
							visibility: 'visible',
							'& .MuiSvgIcon-root': {
								color: (theme) => theme.palette.ascents.red
							}
						}
					}
				}}
				className={className}
				colSpan={colSpan}
				{...props}
			>
				<Box
					onClick={onSort}
					display='inline-block'
					padding={'0 0.5rem 0 0'}
					sx={
						sortable
							? {
									cursor: 'pointer',
									position: 'relative',
									'& .arrow': {
										transition: 'all 0.5s',
										top: '50%',
										right: enableDynamicColumns || isAlignRight ? '80%' : 0,
										left: !enableDynamicColumns || isAlignRight ? undefined : '97%',
										transform: `translate(${isAlignRight ? '-100%' : '100%'}, -50%) rotate(0deg)`,
										position: 'absolute',
										'&-asc': {
											transform: `translate(${isAlignRight ? '-100%' : '100%'}, -50%) rotate(180deg)`
										}
									}
							  }
							: undefined
					}
				>
					<Box component='span'>{children}</Box>
					{enableDynamicColumns && tableColumns.length > 1 && (
						<Tooltip title='Hide column'>
							<IconButton
								onClick={handleHideColumn}
								size='small'
								className='visibilityToggler'
								sx={{
									position: 'absolute',
									top: '50%',
									transform: 'translateY(-50%)',
									left: '94%'
								}}
							>
								<Icon fontSize={14} block ascent='washDark'>
									<VisibilityOffIcon />
								</Icon>
							</IconButton>
						</Tooltip>
					)}
					<Box
						width={'16px'}
						component='span'
						display={sortedAs === false ? 'none' : 'block'}
						className={`arrow arrow-${sortedAs === 'desc' ? 'desc' : 'asc'}`}
					>
						<Icon fontSize={18} block ascent='washDark'>
							<ArrowUpwardIcon />
						</Icon>
					</Box>
				</Box>
			</Box>
		)
	}
)

export default THead
