import Sidebar from '@commons/Sidebar/Sidebar'
import InitialTutorial from '@commons/Tutorial/InitialTutorial'
import adminRoutesConstant from '@constants/adminRoutesConstant'
import { Box } from '@mui/material'
import { refreshUserData } from '@redux/auth/reducers'
import { resetCompliancesDataReducer } from '@redux/compliances/reducers'
import { fetchAllNotificationsReducer } from '@redux/notifications/reducers'
import IndexRoute from '@routes/IndexRoute'
import SubscriptionExpiredScreen from '@screens/SubscriptionExpiredScreen'
import { BREAKPOINTS } from '@styles/theme'
import { fadeColor } from '@utils/styles'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import BaseScreenLayout from './BaseScreenLayout'

const BaseLayout = ({ ascent = 'base' }) => {
	const dispatch = useDispatch()
	const [open, setOpen] = React.useState(window.screen.width >= BREAKPOINTS.md && true)
	const currentLocation = useLocation()
	const [darkFlag, setDarkFlag] = React.useState(false)

	const pathName = currentLocation.pathname

	const isDashboardPage = React.useMemo(() => {
		return adminRoutesConstant.routesByUrls[pathName]?.routeName === 'DashboardScreen'
	}, [pathName])

	const toggleSidebar = () => {
		sessionStorage.setItem('sidebar', !open)
		setOpen(!open)
	}

	const getUserInfo = () => {
		dispatch(refreshUserData())
		dispatch(resetCompliancesDataReducer())
		dispatch(fetchAllNotificationsReducer())
	}

	const handleDarkThemePage = (value) => {
		console.log('This  is bas value dark', value)
		setDarkFlag(value)
	}

	React.useEffect(() => {
		getUserInfo()
	}, [])

	return (
		<Box
			sx={{
				padding: (theme) => theme.spacing(2),
				width: '100%',
				height: '100vh',
				background: (theme) => (!darkFlag ? theme.palette.ascents.white : theme.palette.ascents.black),
				// background:'black',
				minHeight: '667px'
			}}
		>
			<InitialTutorial />
			<Box
				sx={{
					display: 'flex',
					height: '100%',
					overflow: 'hidden',
					width: '100%',

					borderRadius: '1rem',
					boxShadow: (theme) =>
						`0px 2px 10px -1px ${fadeColor(theme.palette.ascents.wash, 0.8)}, 0px 4px 25px 0px ${fadeColor(
							theme.palette.ascents.wash,
							0.8
						)}, 0px 1px 30px 0px ${fadeColor(theme.palette.ascents.wash, 0.8)}`,
					background: (theme) => (!darkFlag ? theme.palette.ascents.white : theme.palette.ascents.dark),
					// background: 'grey',
					border: (theme) => `1px solid ${fadeColor(theme.palette.ascents.wash, 0.85)}`
				}}
			>
				<Sidebar openSidebar={open} ascent={ascent} setOpenSidebar={setOpen} />
				<Box
					component={'main'}
					id='page'
					sx={{
						flexGrow: 1,
						overflow: 'hidden',
						zIndex: 1205,
						position: 'relative',
						padding: 0,

						display: 'flex',
						flexDirection: 'column'
					}}
				>
					<Box
						sx={{
							flex: '1 1 auto',
							overflow: !isDashboardPage ? 'auto' : 'hidden',
							paddingTop: 0
						}}
					>
						<BaseScreenLayout
							sidebarIsOpen={open}
							handleToggleSidebar={toggleSidebar}
							handleDarkClickTheme={handleDarkThemePage}
						>
							<Outlet />
						</BaseScreenLayout>
					</Box>
					<SubscriptionExpiredScreen />
				</Box>
			</Box>
			{window.location.pathname === adminRoutesConstant.adminPaths.IndexRoute.url() && <IndexRoute />}
		</Box>
	)
}

export default BaseLayout
