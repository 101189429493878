import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { PERSIST, PURGE } from 'redux-persist/es/constants'
import persistReducer from 'redux-persist/es/persistReducer'
import persistStore from 'redux-persist/es/persistStore'
import storage from 'redux-persist/lib/storage'
import { createStateSyncMiddleware, withReduxStateSync } from 'redux-state-sync'
import { authReducer } from './auth/slice'
import { compliancesReducer } from './compliances/slice'
import { networkStatusReducer } from './networkStatus/slice'
import { notificationsReducer } from './notifications/slice'
import { policyListsReducer } from './policies/slice'
import { refreshReducer } from './refresh/slice'
import { roleListsReducer } from './roles/slice'
import { securityFactsReducer } from './securityFacts/slice'
import { timeFrameReducer } from './timeFrame/slice'
import { reportReducer } from './report/slice'
import { userDataReducer } from './users/slice'

const reducers = withReduxStateSync(
	combineReducers({
		// Add reducers here
		reloadToken: refreshReducer,
		isOnline: networkStatusReducer,
		timeFrame: timeFrameReducer,
		securityFacts: securityFactsReducer,
		auth: authReducer,
		roles: roleListsReducer,
		policy: policyListsReducer,
		compliances: compliancesReducer,
		notifications: notificationsReducer,
		report: reportReducer,
		users: userDataReducer
	})
)

const reducer = persistReducer(
	{
		key: 'Zeron',
		storage,
		whitelist: ['auth', 'plans']
	},
	reducers
)

export const store = configureStore({
	reducer,
	devTools: import.meta.env.VITE_APP_DISABLE_LOGS !== 'true' && typeof window !== 'undefined',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat([
			createStateSyncMiddleware({
				blacklist: [PERSIST, PURGE]
			})
		])
})
export const persistor = persistStore(store)

export default store
