import { Box } from '@mui/material'
import { useState } from 'react'
import AppChip from './AppChip'
import Text from './Text'

const RuleChipsGroup = ({ ruleGroup = [] }) => {
	const [showAll, setShowAll] = useState(false)
	const [maxChips] = useState(2)

	const handleShowAll = (e) => {
		e?.stopPropagation()
		setShowAll(true)
	}

	if (ruleGroup.length <= maxChips) {
		return ruleGroup.map((each) => <AppChip label={each} key={each} sx={{ mr: 1, mb: 1 }} />)
	}
	return !showAll ? (
		<Box display={'flex'} alignItems={'center'}>
			{ruleGroup.slice(0, maxChips).map((each) => (
				<AppChip label={each} key={each} sx={{ mr: 1, mb: 1 }} />
			))}
			<Text
				fontSize={12}
				fontWeight={600}
				fontFamily='Open Sans'
				onClick={handleShowAll}
				sx={{
					display: 'inline',
					cursor: 'pointer',
					mb: 1
				}}
			>
				{`+${ruleGroup.length - maxChips} more`}
			</Text>
		</Box>
	) : (
		ruleGroup.map((each) => <AppChip label={each} key={each} sx={{ mr: 1, mb: 1 }} />)
	)
}

export default RuleChipsGroup
