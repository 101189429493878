import loadable, { lazy } from '@loadable/component'
import ComplianceScreen from '@screens/Compliance/ComplianceScreen'
import { convertRouteNamesToLabel, getUuid, mapNestedRoutes, replaceVariableWithValueInURL } from '@utils/other'
import { frameworkKeyTypes } from './Compliance/complianceConstants'
import { SidebarListKeyConstants } from './SidebarOrderedListConstant'
import { mandateKeyTypes } from './mandatesConstant'

const ComplianceDashboard = loadable(() => import(/* webpackChunkName: "ComplianceDashboard" */ '@layouts/Compliance/ComplianceDashboard'))
const ComplianceControls = loadable(() =>
	import(/* webpackChunkName: "ComplianceControls" */ '@layouts/Compliance/Controls/ComplianceControls')
)
const ComplianceDocuments = loadable(() => import(/* webpackChunkName: "ComplianceDocuments" */ '@layouts/Compliance/ComplianceDocuments'))
const IndexRoute = loadable(() => import(/* webpackChunkName: "IndexRoute" */ '@routes/IndexRoute'))
const LoginScreen = loadable(() => import(/* webpackChunkName: "LoginScreen" */ '@screens/Auth/LoginScreen'))
const OrgRegisterScreen = loadable(() => import(/* webpackChunkName: "LoginScreen" */ '@screens/Auth/OrgRegisterScreen'))
const LoginWithMfaScreen = loadable(() => import(/* webpackChunkName: "LoginScreen" */ '@screens/Auth/LoginWithMfaScreen'))
const IntegrationViewScreen = loadable(() => import(/* webpackChunkName: "LoginScreen" */ '@screens/Integrations/IntegrationScreen'))
const ForgotPasswordScreen = loadable(() => import(/* webpackChunkName: "ForgotPasswordScreen" */ '@screens/Auth/ForgotPasswordScreen'))
const ResetPasswordScreen = loadable(() => import(/* webpackChunkName: "ResetPasswordScreen" */ '@screens/Auth/ResetPasswordScreen'))
const PlansScreen = loadable(() => import(/* webpackChunkName: "Plans" */ '@screens/Registration/RegistrationPlans'))
const TokenVerficationScreen = loadable(() =>
	import(/* webpackChunkName: "TokenVerficationScreen" */ '@screens/Auth/TokenVerficationScreen')
)
const UnAuthorizedScreen = loadable(() => import(/* webpackChunkName: "UnAuthorizedScreen" */ '@screens/UnAuthorizedScreen'))

const AdministrationScreen = lazy(() =>
	import(/* webpackChunkName: "AdministrationScreen" */ '@screens/Administration/AdministrationScreen')
)
const BillingScreen = lazy(() => import(/* webpackChunkName: "BillingScreen" */ '@screens/Billing/BillingScreen'))
const DashboardScreen = lazy(() => import(/* webpackChunkName: "DashboardScreen" */ '@screens/DashboardScreen'))
const RiskRegister = lazy(() => import(/* webpackChunkName: "DashboardScreen" */ '@screens/RiskRegister/RiskRegister'))
const HelpScreen = lazy(() => import(/* webpackChunkName: "HelpScreen" */ '@screens/Help/HelpScreen'))

//Attack Surface v2.0
const SecurityAssessmentWebScreen2 = lazy(() =>
	import(/* webpackChunkName: "SecurityAssessmentWebScreen" */ '@screens/AttackSurface2/OverView')
)
const SecurityAssesmentAssetScreen = lazy(() =>
	import(/* webpackChunkName: "SecurityAssessmentWebScreen" */ '@screens/AttackSurface2/Asset')
)
const SecurityAssesmentFindingScreen = lazy(() =>
	import(/* webpackChunkName: "SecurityAssessmentWebScreen" */ '@screens/AttackSurface2/FindingsNew')
)
const SecurityReport2Screen = lazy(() => import(/* webpackChunkName: "SecurityReportScreen" */ '@screens/AttackSurface2/AssetDetails'))

//Attack Surface v3.0
const Overview = lazy(() =>
	import(/* webpackChunkName: "SecurityAssessmentWebScreen" */ '@screens/AttackSurface3/OverView')
)
const Asset = lazy(() =>
	import(/* webpackChunkName: "SecurityAssessmentWebScreen" */ '@screens/AttackSurface3/Asset')
)
const Findings = lazy(() =>
	import(/* webpackChunkName: "SecurityAssessmentWebScreen" */ '@screens/AttackSurface3/FindingNew')
)
const DeepInsights = lazy(() =>
	import(/* webpackChunkName: "SecurityAssessmentWebScreen" */ '@screens/AttackSurface3/DeepInsights')
)
const AssetDetails = lazy(() => import(/* webpackChunkName: "SecurityReportScreen" */ '@screens/AttackSurface3/AssetDetails'))



const SecurityEventsScreen = lazy(() =>
	import(/* webpackChunkName: "SecurityEventsScreen" */ '@screens/SecurityOverview/SecurityEventsScreen')
)
const SecurityOverviewDetectionScreen = lazy(() =>
	import(/* webpackChunkName: "SecurityOverviewDetectionScreen" */ '@screens/SecurityOverview/SecurityOverviewDetectionScreen')
)
const MyAccountScreen = lazy(() => import(/* webpackChunkName: "MyAccountScreen" */ '@screens/MyAccount/MyAccountScreen'))
const NotificationsScreen = lazy(() => import(/* webpackChunkName: "NotificationsScreen" */ '@screens/MyAccount/NotificationsScreen'))
const HostNestedScreen = lazy(() => import(/* webpackChunkName: "HostNestedScreen" */ '@screens/SecurityOverview/HostNestedScreen'))
const IncidentResponseNestedScreen = lazy(() =>
	import(/* webpackChunkName: "IncidentResponseNestedScreen" */ '@screens/SecurityOverview/IncidentResponseNestedScreen')
)
const FeedbackScreen = lazy(() => import(/* webpackChunkName: "FeedbackScreen" */ '@screens/Feedback/FeedbackScreen'))
const NotificationSettingsScreen = lazy(() => import(/* webpackChunkName: "SettingScreen" */ '@screens/Setting/NotificationSettingsScreen'))
const InternalTicketScreen = lazy(() => import(/* webpackChunkName: "InternalTicketScreen" */ '@screens/Ticket/InternalTicketScreen'))
const PdfReportScreen = lazy(() => import(/* webpackChunkName: "PdfReportScreen" */ '@screens/PdfReport/PdfReportScreen'))
// const CreatePdfReportScreen = lazy(() => import(/* webpackChunkName: "CreatePdfReportScreen" */ '@screens/PdfReport/CreatePdfReportScreen'))
const CreatePdfReportScreen = lazy(() => import(/* webpackChunkName: "CreatePdfReportScreen" */ '@screens/PdfReport/PdfReportMainScreen'))

//Control Page Routing :::
const CRQControls = lazy(() =>
	import(/* webpackChunkName: "CreatePdfReportScreen" */ '@screens/BusinessPosture/Controls Page/ControlsMainPage')
)
const CRQDetails = lazy(() => import(/* webpackChunkName: "PdfReportScreen" */ '@screens/BusinessPosture/Controls Page/ControlDetails'))

const AwsCloudWatch = lazy(() => import(/* webpackChunkName: "AwsScreen" */ '@screens/CloudManager/AwsScreen'))
const GcpCloudWatch = lazy(() => import(/* webpackChunkName: "GcpScreen" */ '@screens/CloudManager/GcpScreen'))
const AzureCloudWatch = lazy(() => import(/* webpackChunkName: "AzureScreen" */ '@screens/CloudManager/AzureScreen'))
const ManagementScreen = lazy(() => import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/Management/ManagementScreen'))
const MandateScreen = lazy(() => import('@screens/Mandates/MandateScreen'))

const RiskMitigationScreen = lazy(() => import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/RiskMitigation/RiskMitigationScreen'))

const NetworkMapScreen = lazy(() => import('@screens/NetworkMap/NetworkMapScreen'))

const VulRemedyScreen = lazy(() => import('@screens/VulnerabilityRemediation/vulnerabilityRemediation'))

const BusinessPostureScreen = lazy(() =>
	import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/BusinessPosture/BusinessPostureScreen')
)

const ComplianceOverviewScreen = lazy(() =>
	import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/Compliance/ComplianceOverviewScreen')
)

const MandateOverviewScreen = lazy(() => import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/Compliance/MandateOverviewScreen'))

const VendorPulseOverview = lazy(() => import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/Compliance/VendorPulseOverview'))

const CyberSecurityCapabilityIndex = lazy(() => import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/Compliance/CCI/CyberSecurityCapabilityIndex'))
const CyberSecurityCapabilityIndexAuditor = lazy(() => import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/Compliance/CCI/Auditor/AuditorView'))

const QuantificationDashboard = lazy(() =>
	import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/QuantificationDemo/QuantificationDashboard')
)

const QuantificationDashboard2 = lazy(() =>
	import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/CRQ2/QuantificationDashboard')
)
const LOBDetailsView = lazy(() => import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/QuantificationDemo/LOBDetailsView'))

const AuditReadinessScreen = lazy(() => import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/Compliance/AuditReadinessScreen'))

const EvidenceDetailsScreen = lazy(() => import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/Compliance/EvidenceDetailsScreen'))

// const CspmOverviewScreen = lazy(() => import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/CloudManager/CspmOverviewScreen'))

const Cspm2OverviewScreen = lazy(() => import(/* webpackChunkName: "ZensorSetupScreen" */ '@screens/CloudManager/CspmOverviewScreen2'))

// Constants
export const GUARD = 'GUARD'
export const BASE = 'BASE'
export const AUTH = 'AUTH'
export const UNLICENSED = 'UNLICENSED'

export const routeTypes = {
	GUARD,
	BASE
}

export const crudType = {
	canCreate: 'canCreate',
	canRead: 'canRead',
	canUpdate: 'canUpdate',
	canDelete: 'canDelete'
}

export const complianceRoutes = [
	{
		label: 'GDPR',
		slug: 'GDPR',
		key: 'GdprScreen',
		parentRoute: 'ComplianceScreen',
		order: 2,
		children: [
			{
				label: 'Dashboard',
				key: 'ComplianceDashboardScreen',
				component: <ComplianceDashboard />
			},
			{
				label: 'Controls',
				key: 'ComplianceContolsScreen',
				component: <ComplianceControls />
			},
			{
				label: 'Policies',
				key: 'ComplianceDocumentsScreen',
				component: <ComplianceDocuments />
			}
		]
	},
	{
		label: 'PCI DSS',
		slug: 'PCI DSS',
		key: 'PciDssScreen',
		parentRoute: 'ComplianceScreen',
		order: 3,
		children: [
			{
				label: 'Dashboard',
				key: 'ComplianceDashboardScreen',
				component: <ComplianceDashboard />
			},
			{
				label: 'Controls',
				key: 'ComplianceContolsScreen',
				component: <ComplianceControls />
			},
			{
				label: 'Policies',
				key: 'ComplianceDocumentsScreen',
				component: <ComplianceDocuments />
			}
		]
	},
	{
		label: 'NIST',
		slug: 'NIST',
		key: 'NistScreen',
		parentRoute: 'ComplianceScreen',
		order: 4,
		children: [
			{
				label: 'Dashboard',
				key: 'ComplianceDashboardScreen',
				component: <ComplianceDashboard />
			},
			{
				label: 'Controls',
				key: 'ComplianceContolsScreen',
				component: <ComplianceControls />
			},
			{
				label: 'Policies',
				key: 'ComplianceDocumentsScreen',
				component: <ComplianceDocuments />
			}
		]
	},
	{
		label: 'ISO 27001',
		slug: 'ISO 27001',
		key: 'Iso27001Screen',
		parentRoute: 'ComplianceScreen',
		order: 5,
		children: [
			{
				label: 'Dashboard',
				key: 'ComplianceDashboardScreen',
				component: <ComplianceDashboard />
			},
			{
				label: 'Controls',
				key: 'ComplianceContolsScreen',
				component: <ComplianceControls />
			},
			{
				label: 'Policies',
				key: 'ComplianceDocumentsScreen',
				component: <ComplianceDocuments />
			}
		]
	},
	{
		label: 'ISO 27701',
		slug: 'ISO 27701',
		key: 'Iso27701Screen',
		parentRoute: 'ComplianceScreen',
		order: 6,
		children: [
			{
				label: 'Dashboard',
				key: 'ComplianceDashboardScreen',
				component: <ComplianceDashboard />
			},
			{
				label: 'Controls',
				key: 'ComplianceContolsScreen',
				component: <ComplianceControls />
			},
			{
				label: 'Policies',
				key: 'ComplianceDocumentsScreen',
				component: <ComplianceDocuments />
			}
		]
	},
	{
		label: 'ISO 27001:2022',
		slug: 'ISO 27001-2022',
		key: 'Iso270012022Screen',
		parentRoute: 'ComplianceScreen',
		order: 7,
		children: [
			{
				label: 'Dashboard',
				key: 'ComplianceDashboardScreen',
				component: <ComplianceDashboard />
			},
			{
				label: 'Controls',
				key: 'ComplianceContolsScreen',
				component: <ComplianceControls />
			},
			{
				label: 'Policies',
				key: 'ComplianceDocumentsScreen',
				component: <ComplianceDocuments />
			}
		]
	},
	{
		label: 'SOC2',
		slug: 'SOC2',
		key: 'Soc2Screen',
		parentRoute: 'ComplianceScreen',
		order: 8,
		children: [
			{
				label: 'Dashboard',
				key: 'ComplianceDashboardScreen',
				component: <ComplianceDashboard />
			},
			{
				label: 'Controls',
				key: 'ComplianceContolsScreen',
				component: <ComplianceControls />
			},
			{
				label: 'Policies',
				key: 'ComplianceDocumentsScreen',
				component: <ComplianceDocuments />
			}
		]
	},
	{
		label: 'CSA | STAR I',
		slug: 'CSA',
		key: 'CSAScreen',
		parentRoute: 'ComplianceScreen',
		order: 9,
		children: [
			{
				label: 'Dashboard',
				key: 'ComplianceDashboardScreen',
				component: <ComplianceDashboard />
			},
			{
				label: 'Controls',
				key: 'ComplianceContolsScreen',
				component: <ComplianceControls />
			},
			{
				label: 'Policies',
				key: 'ComplianceDocumentsScreen',
				component: <ComplianceDocuments />
			}
		]
	},
	{
		label: 'HIPAA',
		slug: 'HIPAA',
		key: 'HippaScreen',
		// parentRoute: 'ComplianceScreen',
		// order: 9,
		children: [
			{
				label: 'Dashboard',
				key: 'ComplianceDashboardScreen',
				component: <ComplianceDashboard />
			},
			{
				label: 'Controls',
				key: 'ComplianceContolsScreen',
				component: <ComplianceControls />
			},
			{
				label: 'Policies',
				key: 'ComplianceDocumentsScreen',
				component: <ComplianceDocuments />
			}
		]
	},
	{
		label: 'SEBI - Cloud Framework',
		slug: 'SEBI-CS',
		key: 'SebiCloudServicesMandatesOverviewScreen',
		// parentRoute: 'ComplianceScreen',
		// order: 9,
		children: [
			{
				label: 'Dashboard',
				key: 'ComplianceDashboardScreen',
				component: <ComplianceDashboard />
			},
			{
				label: 'Controls',
				key: 'ComplianceContolsScreen',
				component: <ComplianceControls />
			},
			{
				label: 'Policies',
				key: 'ComplianceDocumentsScreen',
				component: <ComplianceDocuments />
			}
		]
	},
	{
		label: 'BSP',
		slug: 'BSP',
		key: 'BSPMandatesOverviewScreen',
		// parentRoute: 'ComplianceScreen',
		// order: 9,
		children: [
			{
				label: 'Dashboard',
				key: 'ComplianceDashboardScreen',
				component: <ComplianceDashboard />
			},
			{
				label: 'Controls',
				key: 'ComplianceContolsScreen',
				component: <ComplianceControls />
			},
			{
				label: 'Policies',
				key: 'ComplianceDocumentsScreen',
				component: <ComplianceDocuments />
			}
		]
	},
	{
		label: 'SEBI CSCRF',
		slug: 'SEBICSCRF',
		key: 'SEBICSCRF',
		// parentRoute: 'ComplianceScreen',
		// order: 9,
		children: [
			{
				label: 'Dashboard',
				key: 'ComplianceDashboardScreen',
				component: <ComplianceDashboard />
			},
			{
				label: 'Controls',
				key: 'ComplianceContolsScreen',
				component: <ComplianceControls />
			},
			{
				label: 'Policies',
				key: 'ComplianceDocumentsScreen',
				component: <ComplianceDocuments />
			}
		]
	}

]

export const complianceSlugs = complianceRoutes.reduce((obj, item) => {
	obj[item.key] = item.slug.toLowerCase().replace(/ /g, '-')

	return obj
}, {})
export function GeneratePathForRoutes(
	permissionKey,
	crudType,
	url,
	path,
	type,
	component,
	props = {},
	config = {
		key: null,
		label: null,
		isShowOnSidebar: false,
		Icon: null,
		hideItem: false,
		order: 0
	}
) {
	this.permissionKey = permissionKey
	this.crudType = crudType
	this.url = function (values = []) {
		return values.length <= 0 ? url : replaceVariableWithValueInURL(url, values)
	}
	this.path = path
	this.type = type
	this.label = config.label
	this.isShownOnSidebar = config.isShownOnSidebar
	this.hideItem = config.hideItem
	this.parentRoute = config.parentRoute
	this.doNotShowOnDashboardLayout = config.doNotShowOnDashboardLayout
	this.donotShowNavbar = config.isShowonNavbar
	this.key = config.key || getUuid()
	this.routeName = config.routeName
	this.component = component
	this.props = props
	this.Icon = config.Icon
	this.order = config.order
}

const adminPaths = {
	// Public routes
	IndexRoute: new GeneratePathForRoutes(null, null, `/`, `/`, BASE, <IndexRoute />, { exact: true }, { routeName: 'IndexRoute' }),
	LoginWithMfaScreen: new GeneratePathForRoutes(
		null,
		null,
		`/login/mfa`,
		`/login/mfa`,
		AUTH,
		<LoginWithMfaScreen />,
		{},
		{ routeName: 'LoginWithMfaScreen' }
	),
	LoginScreen: new GeneratePathForRoutes(null, null, `/login`, `/login`, AUTH, <LoginScreen />, {}, { routeName: 'LoginScreen' }),

	OrgRegisterScreen: new GeneratePathForRoutes(
		null,
		null,
		`/org-register`,
		`/org-register`,
		AUTH,
		<OrgRegisterScreen />,
		{},
		{ routeName: 'OrgRegisterScreen' }
	),

	BusinessRiskPostureScreenParent: new GeneratePathForRoutes(
		null,
		null,
		`/business-posture-parent`,
		`/business-posture-parent`,
		GUARD,
		<></>,
		{},
		SidebarListKeyConstants.BusinessRiskPostureScreenParent
	),

	BusinessRiskPostureScreen: new GeneratePathForRoutes(
		null,
		null,
		`/business-posture`,
		`/business-posture`,
		GUARD,
		<BusinessPostureScreen />,
		{},
		{
			label: 'Business Risk Posture',
			parentRoute: 'BusinessRiskPostureScreenParent',
			key: '/business-posture',
			order: 1
		}
	),

	SecurityReport2Screen: new GeneratePathForRoutes(
		null,
		null,
		`/security-analysis/`,
		`/security-analysis/`,
		GUARD,
		<SecurityReport2Screen />,
		{},
		{ label: 'Attack Surface', routeName: 'SecurityReport2Screen' }
	),

	//ASM V3.0
	AssetDetails: new GeneratePathForRoutes(
		null,
		null,
		`/analysis/`,
		`/analysis/`,
		GUARD,
		<AssetDetails />,
		{},
		{ label: 'Attack Surface', routeName: 'AssetDetails' }
	),

	// SecurityReportScreen: new GeneratePathForRoutes(
	// 	null,
	// 	null,
	// 	`/security-report/:id`,
	// 	`/security-report/:id`,
	// 	GUARD,
	// 	<SecurityReportScreen />,
	// 	{},
	// 	{ doNotShowOnDashboardLayout: true, routeName: 'SecurityReportScreen' }
	// ),
	CreatePdfReportScreen: new GeneratePathForRoutes(
		null,
		null,
		`/pdf-report`,
		`/pdf-report`,
		GUARD,
		<CreatePdfReportScreen />,
		{},
		{ routeName: 'CreatePdfReportScreen', label: 'Reports' }
	),

	PdfReportScreen: new GeneratePathForRoutes(
		null,
		null,
		`/pdf-report/create`,
		`/pdf-report/create`,
		GUARD,
		<PdfReportScreen />,
		{},
		{ doNotShowOnDashboardLayout: true, routeName: 'PdfReportScreen' }
	),
	UnAuthorizedScreen: new GeneratePathForRoutes(
		null,
		null,
		`/unauthorized`,
		`/unauthorized`,
		BASE,
		<UnAuthorizedScreen />,
		{},
		{ doNotShowOnDashboardLayout: false, routeName: 'SecurityReportScreen' }
	),

	ForgotPasswordScreen: new GeneratePathForRoutes(
		null,
		null,
		`/forgotPassword`,
		`/forgotPassword`,
		AUTH,
		<ForgotPasswordScreen />,
		{},
		{ routeName: 'ForgotPasswordScreen' }
	),
	ResetPasswordScreen: new GeneratePathForRoutes(
		null,
		null,
		`/reset/password`,
		`/reset/password`,
		AUTH,
		<ResetPasswordScreen />,
		{},
		{ routeName: 'ForgotPasswordScreen' }
	),

	PlansScreen: new GeneratePathForRoutes(null, null, `/plans`, `/plans`, AUTH, <PlansScreen />, {}, { routeName: 'plans' }),

	TokenVerficationScreen: new GeneratePathForRoutes(
		null,
		null,
		`/verify`,
		`/verify`,
		AUTH,
		<TokenVerficationScreen />,
		{},
		{ routeName: 'ForgotPasswordScreen' }
	),

	// Protected routes
	DashboardScreen: new GeneratePathForRoutes(
		null,
		null,
		`/dashboard`,
		'/dashboard',
		GUARD,
		<DashboardScreen />,
		{ exact: true },
		SidebarListKeyConstants.DashboardScreen
	),
	RiskRegister: new GeneratePathForRoutes(
		null,
		null,
		`/risk-register`,
		'/risk-register',
		GUARD,
		<RiskRegister />,
		{ exact: true },
		SidebarListKeyConstants.RiskRegister
	),
	SecurityOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/securityOverview`,
		`/securityOverview`,
		GUARD,
		(
			// () => <Redirect to={"/securityOverview/securityEvents"} />,
			<></>
		),
		{ exact: true },
		SidebarListKeyConstants.SecurityOverviewScreen
	),

	ComplianceOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/compliance/overview`,
		`/compliance/overview`,
		GUARD,
		<ComplianceOverviewScreen />,
		{ exact: true },
		{
			label: 'Standards',
			key: '/compliance/overview',
			parentRoute: 'ComplianceScreen',
			order: 1
		}
	),

	MandateOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/compliance/mandate_overview`,
		`/compliance/mandate_overview`,
		GUARD,
		<MandateOverviewScreen />,
		{ exact: true },
		{
			label: 'Mandates',
			key: '/compliance/mandate_overview',
			parentRoute: 'ComplianceScreen',
			order: 2
		}
	),

	VendorPulseScreen: new GeneratePathForRoutes(
		null,
		null,
		`/compliance/vendor_pulse`,
		`/compliance/vendor_pulse`,
		GUARD,
		<VendorPulseOverview />,
		{ exact: true },
		{
			label: 'Vendor Pulse',
			key: '/compliance/vendor_pulse',
			parentRoute: 'ComplianceScreen',
			order: 3
		}
	),

	CyberSecurityCapabilityIndex: new GeneratePathForRoutes(
		null,
		null,
		`/compliance/cci_management`,
		`/compliance/cci_management`,
		GUARD,
		<CyberSecurityCapabilityIndex />,
		{ exact: true },
		{
			label: 'CCI Management',
			key: '/compliance/cci_management',
			parentRoute: 'ComplianceScreen',
			order: 4
		}
	),

	CyberSecurityCapabilityIndexAuditor: new GeneratePathForRoutes(
		null,
		null,
		`/audit`,
		`/audit`,
		AUTH,
		<CyberSecurityCapabilityIndexAuditor />,
		{},
		{ routeName: 'Auditor View Only' }
	),

	AuditReadinessScreen: new GeneratePathForRoutes(
		null,
		null,
		`/compliance/audit-readiness`,
		`/compliance/audit-readiness`,
		GUARD,
		<AuditReadinessScreen />,
		{ exact: true },
		{
			label: 'Audit Readiness',
			key: '/compliance/audit-readiness',
			parentRoute: 'ComplianceScreen',
			order: 5
		}
	),

	GdprScreen: new GeneratePathForRoutes(
		null,
		null,
		`/gdpr`,
		`/gdpr`,
		GUARD,
		<MandateScreen queryRule={mandateKeyTypes.GDPR.queryRule} mandateType={mandateKeyTypes.GDPR.mandateType} />,
		// <ComplianceScreen complianceType={frameworkKeyTypes.GDPR} />,
		{},
		{
			label: 'GDPR',
			key: '/gdpr'
			// parentRoute: 'ComplianceScreen',
			// order: 5
		}
	),

	PciDssScreen: new GeneratePathForRoutes(
		null,
		null,
		`/compliance/pci-dss`,
		`/compliance/pci-dss`,
		GUARD,
		<ComplianceScreen complianceType={frameworkKeyTypes.PCIDSS} />,
		{},
		{
			label: 'PCI DSS',
			key: '/compliance/pci-dss'
			// parentRoute: 'ComplianceScreen',
			// order: 6
		}
	),

	NistScreen: new GeneratePathForRoutes(
		null,
		null,
		`/compliance/nist`,
		`/compliance/nist`,
		GUARD,
		<ComplianceScreen complianceType={frameworkKeyTypes.NIST} />,
		{},
		{
			label: 'NIST',
			key: '/compliance/nist'
			// parentRoute: 'ComplianceScreen',
			// order: 7
		}
	),

	Iso27001Screen: new GeneratePathForRoutes(
		null,
		null,
		`/compliance/iso-27001`,
		`/compliance/iso-27001`,
		GUARD,
		<ComplianceScreen complianceType={frameworkKeyTypes.ISO_27001} />,
		{},
		{
			label: 'ISO 27001',
			key: '/compliance/iso-27001'
			// parentRoute: 'ComplianceScreen',
			// order: 8
		}
	),

	Iso27701Screen: new GeneratePathForRoutes(
		null,
		null,
		`/compliance/iso-27701`,
		`/compliance/iso-27701`,
		GUARD,
		<ComplianceScreen complianceType={frameworkKeyTypes.ISO_27701} />,
		{},
		{
			label: 'ISO 27701',
			key: '/compliance/iso-27701'
			// parentRoute: 'ComplianceScreen',
			// order: 9
		}
	),

	Iso270012022Screen: new GeneratePathForRoutes(
		null,
		null,
		`/compliance/iso-27001-2022`,
		`/compliance/iso-27001-2022`,
		GUARD,
		<ComplianceScreen complianceType={frameworkKeyTypes.ISO_27001_2022} />,
		{},
		{
			label: 'ISO 27001:2022',
			key: '/compliance/iso-27001-2022'
			// parentRoute: 'ComplianceScreen',
			// order: 10
		}
	),

	Soc2Screen: new GeneratePathForRoutes(
		null,
		null,
		`/compliance/soc2`,
		`/compliance/soc2`,
		GUARD,
		<ComplianceScreen complianceType={frameworkKeyTypes.SOC2} />,
		{},
		{
			label: 'SOC2',
			key: '/compliance/soc2'
			// parentRoute: 'ComplianceScreen',
			// order: 11
		}
	),

	CSAScreen: new GeneratePathForRoutes(
		null,
		null,
		`/compliance/csa`,
		`/compliance/csa`,
		GUARD,
		<ComplianceScreen complianceType={frameworkKeyTypes.CSASCREEN} />,
		{},
		{
			label: 'CSA | STAR I',
			key: '/compliance/csa'
			// parentRoute: 'ComplianceScreen',
			// order: 11
		}
	),

	HippaScreen: new GeneratePathForRoutes(
		null,
		null,
		`/hipaa`,
		`/hipaa`,
		GUARD,
		<MandateScreen queryRule={mandateKeyTypes.HIPAA.queryRule} mandateType={mandateKeyTypes.HIPAA.mandateType} />,
		// <ComplianceScreen complianceType={frameworkKeyTypes.HIPAA} />,
		{},
		{
			label: 'HIPAA',
			key: '/hipaa'
			// parentRoute: 'ComplianceScreen',
			// order: 12
		}
	),
	SebiCloudServicesMandatesOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/sebi-cs`,
		`/sebi-cs`,
		GUARD,
		<MandateScreen queryRule={mandateKeyTypes.SEBICS.queryRule} mandateType={mandateKeyTypes.SEBICS.mandateType} />,
		// <ComplianceScreen complianceType={frameworkKeyTypes.HIPAA} />,
		{},
		{
			label: 'SEBI - Cloud Framework',
			key: '/sebi-cs'
			// parentRoute: 'ComplianceScreen',
			// order: 12
		}
	),
	BSPMandatesOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/bsp`,
		`/bsp`,
		GUARD,
		<MandateScreen queryRule={mandateKeyTypes.BSP.queryRule} mandateType={mandateKeyTypes.BSP.mandateType} />,
		// <ComplianceScreen complianceType={frameworkKeyTypes.HIPAA} />,
		{},
		{
			label: 'BSP',
			key: '/bsp'
			// parentRoute: 'ComplianceScreen',
			// order: 12
		}
	),
	SEBICSCRF: new GeneratePathForRoutes(
		null,
		null,
		`/sebi-cscrf`,
		`/sebi-cscrf`,
		GUARD,
		<MandateScreen queryRule={mandateKeyTypes.SEBICSCRF.queryRule} mandateType={mandateKeyTypes.SEBICSCRF.mandateType} />,
		// <ComplianceScreen complianceType={frameworkKeyTypes.HIPAA} />,
		{},
		{
			label: 'SEBI CSCRF',
			key: `/sebi-cscrf`
			// parentRoute: 'ComplianceScreen',
			// order: 12
		}
	),
	EvidenceDetailsScreen: new GeneratePathForRoutes(
		null,
		null,
		`/evidence-details/:id`,
		`/evidence-details/:id`,
		GUARD,
		<EvidenceDetailsScreen />,
		{ exact: true },
		{ label: 'Evidence details' }
	),

	RiskMitigationScreen: new GeneratePathForRoutes(
		null,
		null,
		`/riskMitigation`,
		`/riskMitigation`,
		GUARD,
		<RiskMitigationScreen />,
		{ exact: true },
		{
			label: 'Risk Mitigation',
			key: '/risk-mitigation',
			parentRoute: 'SecurityOverviewScreen',
			order: 4
		}
	),

	IntegrationViewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/integration`,
		`/integration`,
		GUARD,
		<IntegrationViewScreen />,
		{ exact: true },
		{
			label: 'Integration',
			key: '/securityOverview/integration',
			parentRoute: 'SecurityOverviewScreen',
			order: 5
		}
	),

	NetworkMapScreen: new GeneratePathForRoutes(
		null,
		null,
		`/networks`,
		`/networks`,
		GUARD,
		<NetworkMapScreen />,
		{ exact: true },
		{
			label: 'Networks',
			key: '/networks',
			parentRoute: 'SecurityOverviewScreen',
			order: 6
		}
	),

	VulRemedyScreen: new GeneratePathForRoutes(
		null,
		null,
		`/vulnerability-remediation`,
		`/vulnerability-remediation`,
		GUARD,
		<VulRemedyScreen />,
		{ exact: true },
		{
			label: 'Vulnerability Remediation',
			key: '/vulnerability-remediation',
			parentRoute: 'SecurityOverviewScreen',
			order: 7
		}
	),

	CloudManagerScreen: new GeneratePathForRoutes(
		null,
		null,
		`/cloudMonitor`,
		`/cloudMonitor`,
		GUARD,
		(
			// () => <Redirect to={"/cloudMonitor/aws"} />,
			<></>
		),
		{ exact: true },
		SidebarListKeyConstants.CloudManagerScreen
	),

	// SecurityAssessmentScreen: new GeneratePathForRoutes(
	// 	null,
	// 	null,
	// 	`/attack-surface`,
	// 	`/attack-surface`,
	// 	GUARD,
	// 	<SecurityAssessmentWebScreen />,
	// 	{ exact: true },
	// 	SidebarListKeyConstants.SecurityAssessmentScreen
	// ),
	SecurityAssessmentScreen2: new GeneratePathForRoutes(
		null,
		null,
		`/attack-surface2`,
		`/attack-surface2`,
		GUARD,
		<></>,
		{},
		SidebarListKeyConstants.SecurityAssessmentScreen2
	),

	SecurityAssesmentOverViewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/attack-surface-overview`,
		`/attack-surface-overview`,
		GUARD,
		<SecurityAssessmentWebScreen2 />,
		{},
		{
			label: 'Overview',
			parentRoute: 'SecurityAssessmentScreen2',
			key: '/attack-surface-overview',
			order: 1
		}
	),
	SecurityAssessmentAssetViewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/attack-surface-asset`,
		`/attack-surface-asset`,
		GUARD,
		<SecurityAssesmentAssetScreen />,
		{},
		{
			label: 'Assets',
			parentRoute: 'SecurityAssessmentScreen2',
			key: '/attack-surface-asset',
			order: 2
		}
	),
	SecurityAssessmentFindingViewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/attack-surface-findings`,
		`/attack-surface-findings`,
		GUARD,
		<SecurityAssesmentFindingScreen />,
		{},
		{
			label: 'Findings',
			parentRoute: 'SecurityAssessmentScreen2',
			key: '/attack-surface-findings',
			order: 3
		}
	),

	//Attack Surface V3.0
	AttackSurfaceV3: new GeneratePathForRoutes(
		null,
		null,
		`/attack-surface3`,
		`/attack-surface3`,
		GUARD,
		<></>,
		{},
		SidebarListKeyConstants.AttackSurfaceV3
	),
	Overview: new GeneratePathForRoutes(
		null,
		null,
		`/overview`,
		`/overview`,
		GUARD,
		<Overview />,
		{},
		{
			label: 'Overview',
			parentRoute: 'AttackSurfaceV3',
			key: '/overview',
			order: 1
		}
	),
	Asset: new GeneratePathForRoutes(
		null,
		null,
		`/asset`,
		`/asset`,
		GUARD,
		<Asset />,
		{},
		{
			label: 'Assets',
			parentRoute: 'AttackSurfaceV3',
			key: '/asset',
			order: 2
		}
	),
	Findings: new GeneratePathForRoutes(
		null,
		null,
		`/findings`,
		`/findings`,
		GUARD,
		<Findings />,
		{},
		{
			label: 'Findings',
			parentRoute: 'AttackSurfaceV3',
			key: '/findings',
			order: 3
		}
	),
	DeepInsights: new GeneratePathForRoutes(
		null,
		null,
		`/deep-insights`,
		`/deep-insights`,
		GUARD,
		<DeepInsights />,
		{},
		{
			label: 'Deep Leaks',
			parentRoute: 'AttackSurfaceV3',
			key: '/deep-insights',
			order: 4
		}
	),


	//For CRQ v1 Permissions:::
	BusinnessPosturev2Parent: new GeneratePathForRoutes(
		null,
		null,
		`business-posturev2-parent`,
		`business-posturev2-parent`,
		GUARD,
		<></>,
		{},
		SidebarListKeyConstants.BusinnessPosturev2Parent
	),
	CRQOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/overview-analysis`,
		`/overview-analysis`,
		GUARD,
		<QuantificationDashboard />,
		{},
		{
			label: 'Overview Analysis',
			parentRoute: 'BusinnessPosturev2Parent',
			key: '/overview-analysis',
			order: 1
		}
	),

	CRQControls: new GeneratePathForRoutes(
		null,
		null,
		`/controls`,
		`/controls`,
		GUARD,
		<CRQControls />,
		{},
		{
			label: 'Controls',
			parentRoute: 'BusinnessPosturev2Parent',
			key: '/controls',
			order: 2
		}
	),
	CRQControlDetails: new GeneratePathForRoutes(
		null,
		null,
		`/controls-details/`,
		`/controls-details/`,
		GUARD,
		<CRQDetails />,
		{},
		{ label: 'Control Details', routeName: 'CRQControlDetails' }
	),
	CRQLOBAlanysis: new GeneratePathForRoutes(
		null,
		null,
		`/overview/details`,
		`/overview/details`,
		GUARD,
		<LOBDetailsView />,
		{},
		{ label: 'CRQ LOB Analysis', routeName: 'CRQLOBAlanysis' }
	),

	//For CRQ2 code permissions:
	BusinessPostureManagement: new GeneratePathForRoutes(
		null,
		null,
		`/quantification`,
		`/quantification`,
		GUARD,
		<QuantificationDashboard2 />,
		{},
		SidebarListKeyConstants.BusinessPostureManagement


	),

	//For ASM Regular

	// SecurityAssessmentScreen: new GeneratePathForRoutes(
	// 	null,
	// 	null,
	// 	`/attack-surface`,
	// 	`/attack-surface`,
	// 	GUARD,
	// 	<SecurityAssessmentWebScreen />,
	// 	{ exact: true },
	// 	SidebarListKeyConstants.SecurityAssessmentScreen
	// ),
	// SecurityAssessmentWebScreen: new GeneratePathForRoutes(
	//     null,
	//     null,
	//     `/securityAssessment/web`,
	//     `/securityAssessment/web`,
	//     GUARD,
	//     SecurityAssessmentWebScreen,
	//     { exact: true },
	//     {
	//         label: "Scan Web Page",
	//         key: "/securityAssessment/web",
	//         parentRoute: "SecurityAssessmentScreen",
	//     }
	// ),
	// SecurityAssessmentMobileAppScreen: new GeneratePathForRoutes(
	//     null,
	//     null,
	//     `/securityAssessment/mobileapp`,
	//     `/securityAssessment/mobileapp`,
	//     GUARD,
	//     SecurityAssessmentMobileAppScreen,
	//     { exact: true },
	//     {
	//         label: "Scan Mobile App",
	//         key: "/securityAssessment/mobileapp",
	//         parentRoute: "SecurityAssessmentScreen",
	//     }
	// ),

	ComplianceScreen: new GeneratePathForRoutes(
		null,
		null,
		`/compliance`,
		`/compliance`,
		GUARD,
		(
			// <Navigate replace to={"/compliance/gdpr"} />,
			<></>
		),
		{ exact: true },
		SidebarListKeyConstants.ComplianceScreen
	),

	ManagementScreenParent: new GeneratePathForRoutes(
		null,
		null,
		`/others`,
		`/others`,
		GUARD,
		(
			// () => <Redirect to={"/others"} />,
			<></>
		),
		// ManagementIPScreen,
		{ exact: true },
		SidebarListKeyConstants.ManagementScreenParent
	),

	ManagementScreen: new GeneratePathForRoutes(
		null,
		null,
		`/others/management`,
		`/others/management`,
		GUARD,
		<ManagementScreen />,
		{},
		{
			label: 'Management',
			key: '/others/management',
			parentRoute: 'ManagementScreenParent',
			order: 1
		}
	),

	AdministrationScreen: new GeneratePathForRoutes(
		null,
		null,
		`/others/administration`,
		`/others/administration`,
		GUARD,
		<AdministrationScreen />,
		{},
		{
			label: 'Administration',
			key: '/others/administration',
			parentRoute: 'ManagementScreenParent',
			order: 2
		}
	),
	HelpScreen: new GeneratePathForRoutes(
		null,
		null,
		`/others/help`,
		`/others/help`,
		GUARD,
		<HelpScreen />,
		{},
		{
			label: 'Help',
			key: '/others/help',
			parentRoute: 'ManagementScreenParent',
			order: 3
		}
	),
	BillingScreen: new GeneratePathForRoutes(null, null, `/billing`, `/billing`, GUARD, <BillingScreen />, {}),
	MyAccountScreen: new GeneratePathForRoutes(null, null, `/account`, `/account`, GUARD, <MyAccountScreen />, {}),
	NotificationsScreen: new GeneratePathForRoutes(null, null, `/notifications`, `/notifications`, GUARD, <NotificationsScreen />, {}),
	FeedbackScreen: new GeneratePathForRoutes(null, null, `/feedback`, `/feedback`, GUARD, <FeedbackScreen />, {}),
	InternalTicketScreen: new GeneratePathForRoutes(null, null, `/ticket`, `/ticket`, GUARD, <InternalTicketScreen />, {}),
	// DownloadReportScreen: new GeneratePathForRoutes(null, null, `/create-report`, `/create-report`, GUARD, DownloadReportScreen, {}),

	NotificationSettingsScreen: new GeneratePathForRoutes(
		null,
		null,
		`/configure-notifications`,
		`/configure-notifications`,
		GUARD,
		<NotificationSettingsScreen />,
		{},
		{ label: 'Configure notifications' }
	),

	SecurityEventsNestedScreen: new GeneratePathForRoutes(
		null,
		null,
		`/securityOverview/securityEvents`,
		`/securityOverview/securityEvents`,
		GUARD,
		<SecurityEventsScreen />,
		{},
		{
			label: 'Security Risks',
			key: '/securityOverview/securityEvents',
			parentRoute: 'SecurityOverviewScreen',
			order: 3
		}
	),
	HostNestedScreen: new GeneratePathForRoutes(
		null,
		null,
		`/securityOverview/hosts`,
		`/securityOverview/hosts`,
		GUARD,
		<HostNestedScreen />,
		{},
		{
			label: 'Hosts',
			key: '/securityOverview/hosts',
			parentRoute: 'SecurityOverviewScreen',
			order: 1
		}
	),

	//start
	RBIMandatesOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/rbi-nbfc`,
		`/rbi-nbfc`,
		GUARD,
		(
			<MandateScreen queryRule={mandateKeyTypes.NBFC.queryRule} mandateType={mandateKeyTypes.NBFC.mandateType} /> // parent
		),
		{},
		{
			label: 'RBI - NBFC',
			key: '/rbi-nbfc'
			// parentRoute: 'BusinessRiskPostureScreenParent',
			// order: 2
		}
	),
	RbiItgrcMandatesOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/rbi-itgrc`,
		`/rbi-itgrc`,
		GUARD,
		(
			<MandateScreen queryRule={mandateKeyTypes.ITGRC.queryRule} mandateType={mandateKeyTypes.ITGRC.mandateType} /> // parent
		),
		{},
		{
			label: 'RBI - ITGRC',
			key: '/rbi-itgrc'
			// parentRoute: 'BusinessRiskPostureScreenParent',
			// order: 2
		}
	),
	RbiPapgMandatesOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/rbi-papg`,
		`/rbi-papg`,
		GUARD,
		<MandateScreen queryRule={mandateKeyTypes.PAPG.queryRule} mandateType={mandateKeyTypes.PAPG.mandateType} />,
		{},
		{
			label: 'RBI - PAPG',
			key: '/rbi-papg'
			// parentRoute: 'BusinessRiskPostureScreenParent',
			// order: 3
		}
	),
	RbiSarMandatesOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/rbi-sar`,
		`/rbi-sar`,
		GUARD,
		<MandateScreen queryRule={mandateKeyTypes.SAR.queryRule} mandateType={mandateKeyTypes.SAR.mandateType} />,
		{},
		{
			label: 'RBI - SAR',
			key: '/rbi-sar'
			// parentRoute: 'BusinessRiskPostureScreenParent',
			// order: 4
		}
	),
	SebiMandatesOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/sebi`,
		`/sebi`,
		GUARD,
		<MandateScreen queryRule={mandateKeyTypes.SEBI.queryRule} mandateType={mandateKeyTypes.SEBI.mandateType} />,
		{},
		{
			label: 'SEBI',
			key: '/sebi'
			// parentRoute: 'BusinessRiskPostureScreenParent',
			// order: 5
		}
	),
	// SebiMandatesDetailsScreen: new GeneratePathForRoutes(
	// 	null,
	// 	null,
	// 	`/sebi-details`,
	// 	`/sebi-details`,
	// 	GUARD,
	// 	<SebiMandatesDetailsScreen />,
	// 	{},
	// 	{
	// 		label: 'SEBI (details)',
	// 		key: '/sebi-details',
	// 		parentRoute: 'BusinessRiskPostureScreenParent',
	// 		hideItem: true
	// 	}
	// ),
	// SebiMandatesDocumentScreen: new GeneratePathForRoutes(
	// 	null,
	// 	null,
	// 	`/sebi-documents`,
	// 	`/sebi-documents`,
	// 	GUARD,
	// 	<SebiMandatesDetailsScreen />,
	// 	{},
	// 	{
	// 		label: 'SEBI (documents)',
	// 		key: '/sebi-documents',
	// 		parentRoute: 'BusinessRiskPostureScreenParent',
	// 		hideItem: true
	// 	}
	// ),

	CertInMandatesOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/cert-in`,
		`/cert-in`,
		GUARD,
		<MandateScreen queryRule={mandateKeyTypes.CERTIN.queryRule} mandateType={mandateKeyTypes.CERTIN.mandateType} />,
		{},
		{
			label: 'CERT-IN',
			key: '/cert-in'
			// parentRoute: 'BusinessRiskPostureScreenParent',
			// order: 6
		}
	),

	IrdaiMandatesOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/irdai`,
		`/irdai`,
		GUARD,
		<MandateScreen queryRule={mandateKeyTypes.IRDAI.queryRule} mandateType={mandateKeyTypes.IRDAI.mandateType} />,
		{},
		{
			label: 'IRDAI',
			key: '/irdai'
			// parentRoute: 'BusinessRiskPostureScreenParent',
			// order: 7
		}
	),
	RmitScreen: new GeneratePathForRoutes(
		null,
		null,
		`/rmit`,
		`/rmit`,
		GUARD,
		<MandateScreen queryRule={mandateKeyTypes.RMIT.queryRule} mandateType={mandateKeyTypes.RMIT.mandateType} />,
		{},
		{
			label: 'RMiT',
			key: '/rmit'
			// parentRoute: 'BusinessRiskPostureScreenParent',
			// order: 8
		}
	),
	UAEIAR: new GeneratePathForRoutes(
		null,
		null,
		`/uae-iar`,
		`/uae-iar`,
		GUARD,
		<MandateScreen queryRule={mandateKeyTypes.UAEIAR.queryRule} mandateType={mandateKeyTypes.UAEIAR.mandateType} />,
		{},
		{
			label: 'UAE - IAR',
			key: '/uae-iar'
			// parentRoute: 'BusinessRiskPostureScreenParent',
			// order: 9
		}
	),
	//end

	DetectionNestedScreen: new GeneratePathForRoutes(
		null,
		null,
		`/securityOverview/detections`,
		`/securityOverview/detections`,
		GUARD,
		<SecurityOverviewDetectionScreen />,
		{},
		{
			label: 'Detections',
			key: '/securityOverview/detections',
			parentRoute: 'SecurityOverviewScreen',
			order: 2
		}
	),
	IncidentResponseNestedScreen: new GeneratePathForRoutes(
		null,
		null,
		`/securityOverview/incidentResponse`,
		`/securityOverview/incidentResponse`,
		GUARD,
		<IncidentResponseNestedScreen />,
		{},
		{
			label: 'Incident Response',
			key: '/securityOverview/incidentResponse',
			parentRoute: 'SecurityOverviewScreen'
		}
	),

	// ZensorSetupWizard: new GeneratePathForRoutes(null, null, `/zensor-setup`, `/zensor-setup`, GUARD, <ZensorSetupWizardScreen />, {
	// 	exact: true
	// }),
	// ZensorManagementScreen: new GeneratePathForRoutes(
	// 	null,
	// 	null,
	// 	`/others/zensors`,
	// 	`/others/zensors`,
	// 	GUARD,
	// 	<ManagementIPScreen />,
	// 	{},
	// 	{
	// 		label: 'Zensors',
	// 		key: '/others/zensors',
	// 		parentRoute: 'ManagementScreen',
	// 		hideItem: true
	// 	}
	// ),

	CspmOverviewScreen: new GeneratePathForRoutes(
		null,
		null,
		`/cloudMonitor/cspm-overview`,
		`/cloudMonitor/cspm-overview`,
		GUARD,
		<Cspm2OverviewScreen />,
		{},
		{
			label: 'CSPM',
			key: '/cloudMonitor/cspm-overview',
			parentRoute: 'CloudManagerScreen',
			order: 1
		}
	),
	// Cspm2OverviewScreen: new GeneratePathForRoutes(
	// 	null,
	// 	null,
	// 	`/cloudMonitor/cspm2-overview`,
	// 	`/cloudMonitor/cspm2-overview`,
	// 	GUARD,
	// 	<Cspm2OverviewScreen />,
	// 	{},
	// 	{
	// 		label: 'CSPM'
	// 	}
	// ),
	AwsCloudWatch: new GeneratePathForRoutes(
		null,
		null,
		`/cloudMonitor/aws`,
		`/cloudMonitor/aws`,
		GUARD,
		<AwsCloudWatch />,
		{},
		{
			label: 'AWS',
			key: '/cloudMonitor/aws',
			parentRoute: 'CloudManagerScreen',
			order: 2
		}
	),
	GcpCloudWatch: new GeneratePathForRoutes(
		null,
		null,
		`/cloudMonitor/gcp`,
		`/cloudMonitor/gcp`,
		GUARD,
		<GcpCloudWatch />,
		{},
		{
			label: 'GCP',
			key: '/cloudMonitor/gcp',
			parentRoute: 'CloudManagerScreen',
			order: 3
		}
	),
	AzureCloudWatch: new GeneratePathForRoutes(
		null,
		null,
		`/cloudMonitor/azure`,
		`/cloudMonitor/azure`,
		GUARD,
		<AzureCloudWatch />,
		{},
		{
			label: 'Azure',
			key: '/cloudMonitor/azure',
			parentRoute: 'CloudManagerScreen',
			order: 4
		}
	)

	// ...generateDeepNestedRoute(complianceRoutes.concat()),
}

// Admin urls
// Assigning keys as routeNames to all
const allRoutesWithLabels = Object.entries(adminPaths).reduce((obj, [routeName, routeObject]) => {
	obj[routeName] = {
		...routeObject,
		routeName: routeName,
		label: routeObject.label ? routeObject.label : convertRouteNamesToLabel(routeName)
	}

	return obj
}, {})

const routesByUrls = Object.entries(allRoutesWithLabels).reduce((obj, [, routeObject]) => {
	obj[routeObject.url()] = { ...routeObject }
	return obj
}, {})

const allRouteUrls = Object.entries(allRoutesWithLabels).map(([, route]) => {
	return route.url()
})
const unlicensedRoutes = Object.values(allRoutesWithLabels).filter((each) => each.type === UNLICENSED)
const baseRoutes = Object.values(allRoutesWithLabels).filter((each) => each.type === BASE)
const authRoutes = Object.values(allRoutesWithLabels).filter((each) => each.type === AUTH)
const guardRoutes = Object.values(allRoutesWithLabels).filter((each) => each.type === GUARD)
const nestedRoutesByKey = mapNestedRoutes(allRoutesWithLabels)
const nestedRoutes = Object.assign(
	{},
	...Object.keys(nestedRoutesByKey).map((item) => ({
		[item]: Object.values(nestedRoutesByKey[item])
	}))
)

// TODO: REMOVE
// TODO: REMOVE

export default {
	guardRoutes,
	baseRoutes,
	authRoutes,
	unlicensedRoutes,
	adminPaths,
	allRoutesWithLabels,
	routesByUrls,
	allRouteUrls,
	nestedRoutes,
	nestedRoutesByKey,
	routeTypes
}
