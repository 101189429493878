import AppModalWithStepper from '@commons/Modal/AppModalWithStepper'
import { Box, Grid } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import CustomStepper from './CustomStepper'
import QuestionComponent from './QuestionComponent'

const question = [
	{
		step_id: 0,
		question: 'Do you have Line of Business?',
		option: ['Yes', 'No'],
		fieldType: 'radio',
		fieldName: 'line_of_business_flag'
	},
	{
		step_id: 0,
		question: 'Enter Line Of Business Names',
		fieldType: 'text',
		fieldName: 'line-of-business'
	},
	{
		step_id: 0,
		question: 'Enter Your Company Name',
		fieldType: 'text',
		fieldName: 'name_of_company'
	},
	{
		step_id: 1,
		question: 'Is this line of business publicly listed?',
		option: ['Yes', 'No'],
		fieldType: 'radio',
		fieldName: 'lob-publicly-flag'
	},
	{
		step_id: 2
	},
	{
		step_id: 3
	}
]
const QuestionModal = ({ ...props }) => {
	const [activeStep, setActiveStep] = React.useState(0)
	const [flag, setFlag] = React.useState(true)
	const [lineOfBusinessFlag, setLineOfBusinessFlag] = React.useState('')
	const [steps, setSteps] = React.useState(['Business Details', 'Revenue Details', 'LOB Details', 'Review'])

	React.useEffect(() => {
		if (lineOfBusinessFlag === 'No') {
			setSteps(['Business Details', 'Revenue Details', 'Company Details', 'Review'])
		} else {
			setSteps(['Business Details', 'Revenue Details', 'LOB Details', 'Review'])
		}
	}, [lineOfBusinessFlag])

	const handleNext = (formData, listofLob) => {
		console.log('handle next', formData, listofLob.length)
		if (activeStep === 0) {
			if (formData.line_of_business_flag === '' || listofLob.length === 0) {
				enqueueSnackbar('Please fill in all required fields before proceeding.', {
					variant: 'error',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'center'
					}
				})
				return
			}
			setLineOfBusinessFlag(formData.line_of_business_flag)
		}
		// if (activeStep === 1) {
		// 	const allFieldsFilled = formData.lobDetails.every((lobDetail) => {
		// 		const isPubliclyListedFilled = lobDetail.publicly_listed !== ''
		// 		const isAnnualRevenueFilled = lobDetail.publicly_listed === 'No' ? lobDetail.annualRevenue !== '' : true
		// 		const areSegmentsValid = lobDetail.segments.every((segment) => {
		// 			const segmentNameHasNoLeadingOrTrailingSpace = /^[^\s].*[^\s]$/.test(segment.segmentName)
		// 			const segmentNameNotEmpty = segment.segmentName !== ''
		// 			const revenuePercentageNotEmpty = segment.revenuePercentage !== ''

		// 			return segmentNameHasNoLeadingOrTrailingSpace && segmentNameNotEmpty && revenuePercentageNotEmpty
		// 		})
		// 		const totalRevenuePercentage = lobDetail.segments.reduce((acc, segment) => {
		// 			return acc + parseFloat(segment.revenuePercentage) || 0 // Ensure numerical calculation and handle non-numerical inputs gracefully
		// 		}, 0)
		// 		const isTotalRevenuePercentageValid = totalRevenuePercentage <= 100

		// 		return isPubliclyListedFilled && areSegmentsValid && isAnnualRevenueFilled && isTotalRevenuePercentageValid
		// 	})

		// 	if (!allFieldsFilled) {
		// 		enqueueSnackbar('Ensure all fields are correct. Total revenue percentages must not exceed 100%.', {
		// 			variant: 'error',
		// 			autoHideDuration: 3000,
		// 			anchorOrigin: {
		// 				vertical: 'bottom',
		// 				horizontal: 'center'
		// 			}
		// 		})
		// 		return
		// 	}
		// }

		if (activeStep === 1) {
			let errorMessage = ''; // Initialize an empty error message

			const allFieldsFilled = formData.lobDetails.every((lobDetail) => {
				const isPubliclyListedFilled = lobDetail.publicly_listed !== '';

				// Check if tickerName is provided when publicly_listed is 'Yes'
				const isTickerNameFilled = lobDetail.publicly_listed === 'Yes' ? lobDetail.tickerName !== '' && lobDetail.tickerName !== null : true;
				if (lobDetail.publicly_listed === 'Yes' && !isTickerNameFilled) {
					errorMessage = 'Ticker Name is mandatory if publicly listed.';
					return false;
				}

				const isAnnualRevenueFilled = lobDetail.publicly_listed === 'No' ? lobDetail.annualRevenue !== '' : true;
				if (!isAnnualRevenueFilled) {
					errorMessage = 'Annual Revenue is required if the company is not publicly listed.';
					return false;
				}

				const areSegmentsValid = lobDetail.segments.every((segment) => {
					const segmentNameHasNoLeadingOrTrailingSpace = /^[^\s].*[^\s]$/.test(segment.segmentName);
					const segmentNameNotEmpty = segment.segmentName !== '';
					const revenuePercentageNotEmpty = segment.revenuePercentage !== '';

					if (!segmentNameNotEmpty || !segmentNameHasNoLeadingOrTrailingSpace) {
						errorMessage = 'Segment Name cannot be empty or have leading/trailing spaces.';
						return false;
					}

					if (!revenuePercentageNotEmpty) {
						errorMessage = 'Revenue Percentage is required for all segments.';
						return false;
					}

					return true;
				});

				if (!areSegmentsValid) {
					return false; // errorMessage already set inside segment validation
				}

				const totalRevenuePercentage = lobDetail.segments.reduce((acc, segment) => {
					return acc + (parseFloat(segment.revenuePercentage) || 0); // Ensure numerical calculation and handle non-numerical inputs gracefully
				}, 0);

				const isTotalRevenuePercentageValid = totalRevenuePercentage <= 100;
				if (!isTotalRevenuePercentageValid) {
					errorMessage = 'Total revenue percentages must not exceed 100%.';
					return false;
				}

				// Ensure all required fields are filled and valid
				return isPubliclyListedFilled && isTickerNameFilled && isAnnualRevenueFilled && areSegmentsValid && isTotalRevenuePercentageValid;
			});

			if (!allFieldsFilled) {
				enqueueSnackbar(errorMessage, {
					variant: 'error',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'center'
					}
				});
				return;
			}
		}


		// New validation for step 2
		if (activeStep === 2) {
			const allFieldsFilled = formData.lobDetails.every((lobDetail) => {
				const isCountryOfOperationValid = lobDetail.Country_of_Operation.length > 0
				const isEmployeeStrengthValid = lobDetail.employee_strength !== ''
				const isIndustryFilled = lobDetail.industry !== ''

				return isCountryOfOperationValid && isEmployeeStrengthValid && isIndustryFilled
			})

			if (!allFieldsFilled) {
				enqueueSnackbar('Please fill in all required fields before proceeding.', {
					variant: 'error',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'center'
					}
				})
				return
			}
		}

		if (activeStep != steps.length - 1) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1)
		} else {
			//show a loader and close the modal
		}
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const Stepper = () => {
		return (
			<Grid
				item
				xs={12}
				style={{
					position: 'absolute',
					width: '100%'
				}}
			>
				<CustomStepper steps={steps} activeStep={activeStep} />
			</Grid>
		)
	}

	React.useEffect(() => {
		if (!flag) {
			setSteps(['Business Details', 'Revenue Details', 'Company Details', 'Review'])
		} else {
			setSteps(['Business Details', 'Revenue Details', 'LOB Details', 'Review'])
		}
	}, [flag])

	const handleFlagChange = (flagValue) => {
		setFlag(flagValue)
	}

	return (
		<AppModalWithStepper
			contentProps={{
				sx: {
					padding: '0rem 0rem 0rem 0rem !important'
				}
			}}
			titleProps={{
				style: {
					position: 'absolute',
					width: '100%'
				}
			}}
			customQuantificationHeader={true}
			CustomHeader={<Stepper />}
			showActions={false}
			{...props}
			maxWidth='xl'
		>
			<Box sx={{ paddingTop: '15px', height: '69vh', overflow: 'scroll', backgroundColor: '#F5F7FA' }}>
				<QuestionComponent
					nextHandler={handleNext}
					backHandler={handleBack}
					steps={steps}
					activeStep={activeStep}
					question={question}
					onFlagChange={handleFlagChange}
					modalClose={props.onClose}
				/>
			</Box>
		</AppModalWithStepper>
	)
}

export default QuestionModal
