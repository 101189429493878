import PaymentIcon from '@mui/icons-material/Payment'
import { Box } from '@mui/material'
import { Buffer } from 'buffer'
import React from 'react'
import { useSelector } from 'react-redux'
import Icon from '../Icon'
import Text from '../Text'

import queryString from 'query-string'

const BuyZeronButton = ({ ...props }) => {
	const userRedux = useSelector((state) => state.auth.user.info)

	const name = React.useMemo(() => {
		return ((userRedux?.user?.first_name || '') + ' ' + (userRedux?.user?.last_name || '')).trim()
	}, [userRedux?.user])

	return (
		<Box
			px={1.125}
			py={1.125}
			mx={2}
			my={1}
			sx={{
				background: (theme) => theme.palette.ascents.primary,
				border: (theme) => `1px solid ${theme.palette.ascents.primary}`,
				borderRadius: '0.5rem',
				transition: 'background 0.2s ease-in-out'
			}}
			display='flex'
			alignItems='center'
			component={'a'}
			href={`${import.meta.env.VITE_APP_BUY_ZERON_FRONTEND_URL}?${queryString.stringify({
				user: Buffer.from(
					JSON.stringify({
						name,
						email: userRedux?.user?.email,
						organizationName: userRedux?.user?.organization?.name,
						phone: userRedux?.user?.phone
					})
				).toString('base64')
			})}`}
			target='_blank'
			{...props}
		>
			<Box mr={3}>
				<Icon ascent='washLight' block>
					<PaymentIcon />
				</Icon>
			</Box>

			<Box>
				<Text letterSpacing={1} fontWeight={500} ascent={'washLight'} isCustomColor>
					Buy Zeron Now
				</Text>
			</Box>
		</Box>
	)
}

export default BuyZeronButton
